import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

import { REQ_HEADER } from "./Tokens";
import ShippingAddress from "./ShippingAddress";
import invPath from "../../../assets/img/svg/invPath.svg";
import SelectShippingOptions from "./SelectShippingOptions";
import InventoryShippingConfirmed from "./InventoryShippingConfirmed";
import OrderSummary from "./OrderSummary";
import { setCartItems } from "../../../store/actions/shoppingCart";
import PickDate from "./common/PickDate";
import ChangeShippingMethod from "./common/ChangeShippingMethod";
import ShipOrderComplete from "./common/ShipOrderComplete";
import { loadCartData, loadCartItems, checkToken } from "./commonFunc";
import { serviceUpdateItemsPIM } from "./services/servicesPIM";
import NotificationCard from "./common/NotificationCard";
import DriverNote from "./common/DriverNote";
import plantsMap from "./mappings/plantsMap.json";
import { ErrorAlert } from "./common";
import { SHOPPING_CART } from "../../../components/common/RouterConstants";
import { getBrowserStorage } from "../../../utils/cookies";
import Img from "../../common/Img";
import arrowBackward from "../../../assets/img/svg/arrow_backward.svg";
import { updateCartConfiguration } from "app/services/servicesPIM";

class InventoryShipping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDis: false,
      showShippingOrders: "",
      menuOpen: "",
      noSplitShip: "",
      shipppingMethod: "",
      cartUrl: "",
      addresslist: "",
      namelist: "",
      cartData: [],
      skuList: [],
      selectedShippingOpts: null,
      cartItems: null,
      isCartStateChanged: false,
      pendingAvailable: true,
      hasBackorderItems: true,
      isInventoryChanged: false,
      confirmAvailable: true,
      homeBranch: "",
      errorShippingSelection: false,
      isAddressSelected: false,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.updateShippingChanges = this.updateShippingChanges.bind(this);
    this.updatCartdata = this.updatCartdata.bind(this);
    this.checkPendingAvailable = this.checkPendingAvailable.bind(this);
  }

  setIsAddressSelected = (isAddressSelected) => {
    this.setState({ isAddressSelected });
  };
  setIsInventoryChanged = (value) => {
    this.setState({ isInventoryChanged: value });
  };

  setShowShippingOrders = () => {};

  setNoSplitShip = () => {};
  setShipppingMethod = () => {};

  updateAttn = async () => {
    const { cartUrl } = this.props.shoppingCart.cartData;
    const { attnName } = this.props.shoppingCart.cartData.configuration;
    await updateCartConfiguration({ attnName }, "shipping_and_payment");
  };

  updateShippingChanges = async () => {
    await checkToken();
    this.updatCartdata();
  };

  async componentDidMount() {
    if (_.isEmpty(this.props.shoppingCart.cartData)) {
      await loadCartData();
    }

    if (_.isEmpty(this.props.shoppingCart.cartItems)) {
      await loadCartItems();
    }

    if (!this.props.shoppingCart.cartItems.length) {
      this.props.history.push("/");
    }
    let homeBranch = getBrowserStorage("defaultplant");
    homeBranch = plantsMap[homeBranch];
    this.setState({ homeBranch });
  }

  handleContinue = async () => {
    await checkToken();

    if (!this.state.isAddressSelected)
      return alert("Please select shipping address");
    try {
      this.setState({
        submitDis: true,
      });
      await this.updateDriverNote();
      await this.updateAttn();
      this.setState({
        submitDis: false,
      });
      this.props.history.push("/payments");
    } catch (error) {
      alert("Updating data failed, please check your connection and resubmit");
    }
  };

  checkWillCallConfirmation = (product) => {
    // if (product.editShipping) return false;
    const { willCallQuantity } = product.selectedOption;
    if (parseInt(product.quantity) === parseInt(willCallQuantity)) return true;
    else if (
      parseInt(product.quantity) > parseInt(willCallQuantity) &&
      product.pickupRemaining
    )
      return true;
    return false;
  };

  checkPendingAvailable = () => {
    this.setState({
      pendingAvailable: !this.props.shoppingCart.cartItems.every((item) =>
        Boolean(item.shippingConfirmed)
      ),
    });
  };

  checkConfirmAvailable = () => {
    const confirmAvailable = this.props.shoppingCart.cartItems.some((item) =>
      Boolean(item.shippingConfirmed)
    );
    this.setState({ confirmAvailable });
  };

  updateDriverNote = async () => {
    const { cartData } = this.props.shoppingCart;

    // update the driver note
    await updateCartConfiguration(
      {
        driverNote: cartData.configuration.driverNote,
      },
      "shipping_and_payment"
    );
  };

  updatCartdata = async () => {
    try {
      this.setState({ errorShippingSelection: false });
      const { cartItems } = this.props.shoppingCart;
      const updatedItems = await cartItems.map((item) => {
        if (!_.isEmpty(item.selectedOption)) {
          if (item.optionType === "willCall") {
            item.shippingConfirmed = this.checkWillCallConfirmation(item);
          } else {
            item.shippingConfirmed = true;
          }
        } else {
          this.setState({ errorShippingSelection: true });
        }
        return item;
      });
      await serviceUpdateItemsPIM(cartItems);
      await this.updateAttn();
      this.props.actions.setCartItems(updatedItems);
      this.checkPendingAvailable();
      this.checkConfirmAvailable();
    } catch (error) {}
  };

  render() {
    const { homeBranch, errorShippingSelection } = this.state;
    return (
      <section className="checkout-step-section">
        <div className="container">
          <div className="row">
            <div className="clearfix col-md-12 checkout-process">
              <Link
                title="Back"
                className="link-black d-flex"
                to={SHOPPING_CART}
              >
                <Img src={arrowBackward} alt="" /> Back
              </Link>
              <div className="step-wrapper">
                <img src={invPath} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="section-heading-with-line">
                <h2 role="heading" aria-level="2" tabIndex={0}>Inventory Check & Shipping</h2>
                <span />
              </div>
              {errorShippingSelection && (
                <ErrorAlert
                  // handleSuccessClose={this.handleErrorClose}
                  errorMessage="Please select the shipping options for all pending items."
                />
              )}
              <NotificationCard page="inventoryShipping" />
              <div className="row shipping-address-wrapper">
                <div className="col-md-6">
                  <div className="shipping-address-wraper">
                    <div>
                      <h4 role="heading" aria-level="4" tabIndex={0}>Shipping Address</h4>
                      <hr />
                    </div>
                    <ShippingAddress
                      setIsAddressSelected={this.setIsAddressSelected}
                    />
                    <DriverNote />
                  </div>
                </div>
                <div className="col-md-6 shipping-address-wrapper shipping-address float-right inventory-check-shipping">
                  <div className="col-8 pl-0 pr-0">
                    <PickDate />
                    <br />
                    {this.state.hasBackorderItems ? (
                      <>
                        <ShipOrderComplete />
                        <br />
                      </>
                    ) : null}
                    <ChangeShippingMethod
                      checkPendingAvailable={this.checkPendingAvailable}
                      homeBranch={homeBranch}
                    />
                  </div>
                </div>
              </div>
              <div className="full-sep-border" />
              {this.state.pendingAvailable &&
                !_.isEmpty(this.props.shoppingCart.cartItems) && (
                  <SelectShippingOptions
                    checkPendingAvailable={this.checkPendingAvailable}
                    checkConfirmAvailable={this.checkConfirmAvailable}
                  />
                )}
              {this.state.confirmAvailable && (
                <InventoryShippingConfirmed
                  checkPendingAvailable={this.checkPendingAvailable}
                  checkConfirmAvailable={this.checkConfirmAvailable}
                />
              )}
            </div>
            <div className="col-md-3 s-cart-right-side-bar m-t-30">
              <OrderSummary />

              {this.state.pendingAvailable ? (
                <div className="mt-2 summary-row btn-row">
                  <a
                    title="CONFIRM SHIPPING & UPDATE"
                    className="btn gray-dark-btn d-flex"
                    onClick={this.updateShippingChanges}
                    onKeyPress={this.updateShippingChanges}
                    tabIndex="0"
                  >
                    <span>CONFIRM SHIPPING & UPDATE</span>
                  </a>
                </div>
              ) : (
                <button
                  title="CONTINUE"
                  disabled={this.state.submitDis}
                  className="mt-2 summary-row btn-row btn red-btn d-flex justify-content-center"
                  onClick={this.handleContinue}
                >
                  CONTINUE
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryShipping);
