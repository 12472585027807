import { setOpenedAttributes } from "../../store/actions/categoryFilter";
import axios from "axios";
import { isMobileOnly } from "react-device-detect";
import { JSEncrypt } from "jsencrypt";
import { setTotalFavoriteItems } from "../../store/actions/global";
import { AUTHENTICATED } from "../../store/constants";
import { WURTHLAC, NEXT_SHIPTO } from "../common/Constants";
import { postShippingOption } from "../../utils/api/cortex";
import _ from "lodash";
import {
  removeBrowserStorage_Cookie,
  removeBrowserStorage,
  getAllBrowserStorage_Cookies,
  getBrowserStorage_Cookie,
} from "../../utils/cookies";
import { getShippingMethodsList } from "../routewise/shopping-cart/utils";
import { getTotalFavoriteItems } from "../../utils/api/myFavourite";
import { store } from "../../../index";
import { CHECK_PRODUCT_INFO_PIM } from "../routewise/shopping-cart/APIs";
import {
  AVAILBLE,
  MATCH,
  QUANTITY_MULTIPLIER_MATCH,
  NO_LONGER_AVAILABLE,
  CARTTOKEN,
} from "./Constants";
import {
  addToCartApi,
  ADD_TO_CART_URL,
} from "app/hooks/shopping-cart/useAddToCartMutation.hook";
import { fetchOrderPrice } from "../routewise/shopping-cart/commonFunc";
import { setCartItems } from "../../store/actions/shoppingCart";
import { SHOPPING_CART } from "./RouterConstants";
import { postSkusPrices } from "../../utils/api/cortex";
import { getShippingOptionsWithErrorCodes } from "../../components/routewise/shopping-cart/commonFunc";
import { ITEMS_DETAILS_URL } from "../routewise/shopping-cart/APIs";
import {
  TriggerEventAddCartItems,
  TriggerReset,
} from "../common/googleTagManager/TriggerFunction";

export const storeOpenAttributes = (
  filterItems,
  attributeName,
  data,
  visibleCount,
  paramId
) => {
  const dataAttributes = data.map((item) => {
    const filteredItem = filterItems.find(
      (el) => el.key === item.attribute_name && el.val === item.id
    );
    return filteredItem
      ? { ...item, checked: true }
      : { ...item, checked: false };
  });

  const attributeArray = {
    attribute: attributeName,
    data: dataAttributes.sort((a, b) => b.isActive - a.isActive),
    visible_count: visibleCount,
    parmId: paramId,
  };

  store.dispatch(setOpenedAttributes(attributeArray));
};

export const emptyOpenAttributes = () => {
  store.dispatch(setOpenedAttributes([]));
};

export const logoutUser = async (callBack = null) => {
  sessionStorage.clear();
  Object.keys(localStorage).map((local) => {
    if (local != "per_page") {
      removeBrowserStorage(local);
    }
  });

  await Object.keys(getAllBrowserStorage_Cookies()).map((cookieName) => {
    if (
      cookieName !== "wurthlac-showaddress" &&
      cookieName !== "region" &&
      cookieName !== "remember-me"
    ) {
      removeBrowserStorage_Cookie(cookieName);
    }
  });

  await store.dispatch({
    type: AUTHENTICATED,
    payload: {
      authenticated: false,
      user: {},
    },
  });

  if (callBack) {
    callBack();
  }
};

export const getLoginToken = () => {
  return getBrowserStorage_Cookie(WURTHLAC)
    ? getBrowserStorage_Cookie(WURTHLAC).access_token
    : false;
};

export const getShippingData = async (
  quantity,
  sku,
  willCallPlant,
  page = null
) => {
  let params = {};
  let returnData = "";
  if (willCallPlant) {
    params = {
      plant: willCallPlant,
      quantity,
      sku,
    };
  } else {
    params = {
      quantity,
      sku,
    };
  }

  try {
    await postShippingOption([params], page)
      .then((response) => response.data)
      .then((data) => {
        returnData = data[0];
      })
      .catch((error) => {});

    return returnData;
  } catch (error) {
    return {};
  }
};

export const getEnvironmentVariables = () => {
  try {
    let maxFileSize = 5242880;
    let maxFileSizeErrorMsg = "Maximum file size is 5MB.";
    let acceptableImageTypes = ["image/png", "image/jpg", "image/jpeg"];
    let acceptableImageTypesErrorMsg =
      "Invalid file type. Only JPG, JPEG and PNG types are accepted.";

    if (process.env.REACT_APP_MAX_FILE_SIZE) {
      maxFileSize = Number(process.env.REACT_APP_MAX_FILE_SIZE);
    }
    if (process.env.REACT_APP_MAX_FILE_SIZE_ERROR_MSG) {
      maxFileSizeErrorMsg = process.env.REACT_APP_MAX_FILE_SIZE_ERROR_MSG;
    }
    if (process.env.REACT_APP_ACCEPTABLE_IMAGE_TYPES) {
      acceptableImageTypes =
        process.env.REACT_APP_ACCEPTABLE_IMAGE_TYPES.split(",");
    }
    if (process.env.REACT_APP_ACCEPTABLE_IMAGE_TYPES_ERROR_MSG) {
      acceptableImageTypesErrorMsg =
        process.env.REACT_APP_ACCEPTABLE_IMAGE_TYPES_ERROR_MSG;
    }

    return {
      maxFileSize,
      maxFileSizeErrorMsg,
      acceptableImageTypes,
      acceptableImageTypesErrorMsg,
    };
  } catch (error) {
    console.error(error);
  }
};

export const verifyImage = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      let picFile = event.target;
      let imgNew = new Image();
      imgNew.addEventListener("load", () => {
        resolve(reader.result);
      });
      imgNew.addEventListener("error", () => {
        reject();
      });
      imgNew.src = picFile.result;
    });
    reader.readAsDataURL(img);
  });
};

export const getNextShipMethod = (
  selectedOption,
  selectedShipMenthod = null
) => {
  let defaultShipMethod;

  if (selectedShipMenthod !== null) {
    const availShipMethods = getShippingMethodsList(selectedShipMenthod);
    defaultShipMethod = availShipMethods.includes("C") ? "C" : NEXT_SHIPTO;
  } else if (selectedOption.index && !_.isEmpty(selectedOption.index)) {
    const shipMethodArr = selectedOption.index.split(",");
    const availShipMethods = getShippingMethodsList(
      selectedOption[`shippingMethods_${shipMethodArr[0]}`]
    );

    defaultShipMethod = availShipMethods.includes("C") ? "C" : NEXT_SHIPTO;
  } else {
    defaultShipMethod = NEXT_SHIPTO;
  }

  return defaultShipMethod;
};

export const fetchTotalFavoriteItems = async () => {
  try {
    let totalCount = 0;
    const totalFavoriteItems = await getTotalFavoriteItems();

    if (totalFavoriteItems.data.data) {
      totalCount = totalFavoriteItems.data.data;
    }
    store.dispatch(setTotalFavoriteItems(totalCount));
    return totalCount;
  } catch (error) {}
};

export const addOrRemoveFromTotalFavoriteCount = (total, type) => {
  try {
    if (type === "add") {
      total += 1;
    } else if (type === "subtract") {
      total -= 1;
    }
    store.dispatch(setTotalFavoriteItems(total));
  } catch (error) {}
};

export const handleBulkAddToCart = async (
  setErrors,
  setLoading,
  setIsAddtoCartDisabled,
  selectedItems,
  loadCartFunc
) => {
  let error = [];
  setErrors([]);
  setLoading(true);
  setIsAddtoCartDisabled(true);

  const bulkOrderItems = selectedItems;

  if (_.isEmpty(bulkOrderItems)) return;
  try {
    let res = await addToCartApi(bulkOrderItems, ADD_TO_CART_URL);
    if (res?.status >= 200 && res?.status < 300) {
      const items = [];
      const token = getBrowserStorage_Cookie(CARTTOKEN);
      const productsSkuListCart = [];
      bulkOrderItems.map(async (item) => {
        productsSkuListCart.push(encodeURIComponent(item.sku));
      });

      const itemDetailsUrl = await ITEMS_DETAILS_URL(
        productsSkuListCart.join()
      );

      const pimResponse = await axios.get(itemDetailsUrl, {
        headers: { apiToken: token },
      });

      if (pimResponse.data) {
        bulkOrderItems.map((item) => {
          const PIMProduct = pimResponse.data.find(
            (PIMProduct) => item.sku === PIMProduct.txt_wurth_lac_item
          );
          if (PIMProduct) {
            items.push({
              quantity: item.quantity,
              sku: item.sku,
              sale_price: 0,
              txt_uom: item.txt_uom,
              priceUnit: PIMProduct.txt_uom,
              price: item.amount / item.quantity,
              extended: item.amount,
              name: PIMProduct.txt_description_name,
              brand_name: PIMProduct.brand_name,
              sap_id: PIMProduct.txt_sap,
              sale_price: item.sale_price ? item.sale_price : 0, // for laminateItems its default 0
            });
          }
        });
      }
      if (items.length == bulkOrderItems.length) {
        TriggerEventAddCartItems(items);
      }

      setLoading(false);
      setIsAddtoCartDisabled(false);
      fetchOrderPrice();
      if (loadCartFunc) {
        store.dispatch(setCartItems([]));
        loadCartFunc();
      }
      window.location.href = SHOPPING_CART;
    } else {
      error.push("Unexpected Error");
      setErrors(error);
      setLoading(false);
      setIsAddtoCartDisabled(false);
    }
  } catch (error) {}
};

export const validateBulkorderItems = async (
  setLoading,
  selectedItems,
  setSelectedItems,
  setIsMissingData,
  isMissingData,
  setErrors,
  setIsAddtoCartDisabled,
  shoppingCart,
  loadCartFunc
) => {
  const bulkOrderItems = [];
  let newError = [];
  setLoading(true);
  // before push check if an item already there
  selectedItems.forEach((itm) => {
    const sku = itm.sku;
    const existingItem = bulkOrderItems.find(
      (e) => e.sku.trim().toLowerCase() === sku.trim().toLowerCase()
    );

    if (existingItem) {
      bulkOrderItems.map((item) => {
        if (
          item.sku.trim().toLowerCase() ===
          existingItem.sku.trim().toLowerCase()
        ) {
          item.quantity = item.quantity + itm.quantity;
        }
      });
    } else {
      bulkOrderItems.push(itm);
    }
  });

  setSelectedItems(bulkOrderItems);

  const dataAll = [];

  await bulkOrderItems.map(async (item) => {
    if (!item.sku && item.quantity) {
      item.status = "skuMissing";
      newError.push("Item Number is missing");
    } else if (item.sku && !item.quantity) {
      item.status = "qtyMissing";
      newError.push("Qty is missing");
      setIsMissingData(true);
    } else {
      const prod = {};
      prod.sku = item.sku.trim();
      prod.mqt = item.quantity;
      dataAll.push(prod);
      item.status = "";
      item.sku = item.sku.trim();
      return item;
    }
  });

  if (isMissingData) return;
  let data = [];

  data = dataAll.filter((item) => Boolean(item.sku) && Boolean(item.mqt));

  if (_.isEmpty(data)) {
    newError.push("No valid products added");
    return;
  }
  const token = getBrowserStorage_Cookie(CARTTOKEN);

  try {
    const res = await axios({
      method: "POST",
      url: CHECK_PRODUCT_INFO_PIM,
      data: JSON.stringify(data),
      headers: { apiToken: token },
    });

    const validatedItems = await res.data.map((item) => {
      let prod = [];

      prod = bulkOrderItems.find(
        (bItem) => bItem.sku === item.sku && bItem.quantity === item.requestedQT
      );

      if (
        item.availability === AVAILBLE &&
        item.qmm === QUANTITY_MULTIPLIER_MATCH &&
        item.mqt === MATCH &&
        item.product_exclution === false &&
        item.product_discontinue === false
      ) {
        prod.status = "OK";
      } else if (item.product_exclution !== false) {
        prod.status = item.product_exclution_msg;
      } else if (item.product_discontinue !== false) {
        prod.status = NO_LONGER_AVAILABLE;
      } else if (item.availability !== AVAILBLE) {
        prod.status = "Invalid SKU";
      } else if (item.mqt !== MATCH) {
        prod.status = `Please consider minimum order quantity of ${item.txt_min_order_amount} `;
      } else if (item.qmm !== QUANTITY_MULTIPLIER_MATCH) {
        prod.status = `This product is sold in multiples of ${item.qm}`;
      } else {
        prod.status = "Unexpected error";
      }
      prod.desc = item.txt_product_summary;
      prod.minOrderQty = item.txt_min_order_amount;

      return prod;
    });
    setSelectedItems(validatedItems);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.messages) {
      newError.push(error.response.data.messages);
    }
  }

  const isValidated = selectedItems.every((item) => item.status === "OK");
  if (isValidated && newError.length == 0) {
    handleBulkAddToCart(
      setErrors,
      setLoading,
      setIsAddtoCartDisabled,
      selectedItems,
      loadCartFunc
    );
  }

  setLoading(false);
  setErrors([...newError]);
};

export const getPriceListItems = async (
  itemDetails,
  setPriceList,
  setPriceListErrors,
  isSku = false,
  setPrice,
  setPriceUnit
) => {
  const skuIds = [];
  const skuList = [];

  if (itemDetails != null && itemDetails.length > 0) {
    itemDetails.forEach((element) => {
      const itemSku = isSku ? element.sku : element.txt_wurth_lac_item;
      skuIds.push({ quantity: 1, sku: itemSku });
      skuList.push(itemSku);
    });

    if (getBrowserStorage_Cookie(WURTHLAC)) {
      const results = await postSkusPrices(skuIds);
      setPriceList(results.data["list-sku-price"]);
      //set pricelist errors
      if (results.data && results.data.error) {
        setPriceListErrors(results.data.error);
      }
      if (
        results &&
        results.data &&
        results.data["list-sku-price"].length > 0
      ) {
        const breakDown = results.data["list-sku-price"].find((e) =>
          e.sku === isSku
            ? itemDetails[0].sku
            : itemDetails[0].txt_wurth_lac_item
        );

        if (breakDown) {
          setPrice && setPrice(breakDown["price"]);
          setPriceUnit && setPriceUnit(breakDown["price-unit"]);
        }
      }
    }
  }
};

export const getAvailableQty = async (
  setErrorItem,
  setIsLoadingShippingOption,
  setShowPriceBreakDown,
  selectedQty,
  itemSku,
  setShippingOptions,
  setErrorMsg
) => {
  setErrorItem(false);
  setIsLoadingShippingOption(true);
  setShowPriceBreakDown(false);
  // defualt available qty call option
  try {
    const cartItems = [
      {
        quantity: selectedQty,
        sku: itemSku,
      },
    ];

    const shippingOptions = await getShippingOptionsWithErrorCodes(cartItems);
    if (shippingOptions.data) {
      setShippingOptions(shippingOptions.data[0]);
      setIsLoadingShippingOption(false);
      setErrorItem(false);
    } else {
      setErrorItem(true);

      if (shippingOptions.data && shippingOptions.data.error) {
        if (
          shippingOptions.data.error &&
          shippingOptions.data.error.length > 0 &&
          shippingOptions.data.error[0]["error-code"]
        ) {
          setErrorMsg(shippingOptions.data.error[0]["message"]);
        }
      }
      setIsLoadingShippingOption(false);
    }
  } catch (error) {
    setErrorItem(true);
    setIsLoadingShippingOption(false);
  }
};

export const getAvailableStore = async (
  setErrorItem,
  tempItemArray,
  setAvailableOptions,
  setErrorMsg
) => {
  setErrorItem(false);

  // defualt available qty call option
  try {
    let cartItems = [];
    tempItemArray.map((i) => {
      cartItems.push({
        quantity: 0,
        sku: i.txt_wurth_lac_item,
      });
    });
    const shippingOptions = await getShippingOptionsWithErrorCodes(cartItems);
    if (shippingOptions.data) {
      setAvailableOptions(shippingOptions.data);

      setErrorItem(false);
    } else {
      setErrorItem(true);

      if (shippingOptions.data && shippingOptions.data.error) {
        if (
          shippingOptions.data.error &&
          shippingOptions.data.error.length > 0 &&
          shippingOptions.data.error[0]["error-code"]
        ) {
          setErrorMsg(shippingOptions.data.error[0]["message"]);
        }
      }
    }
  } catch (error) {
    setErrorItem(true);
  }
};

export const validateMultipleItem = async (itemList) => {
  //get price list
  const skuIds = [];
  const skuList = [];
  let itemPriceList = [];

  let errorItemList = [];
  itemList.forEach((element) => {
    const itemSku = element.sku.toUpperCase();
    if (itemSku != "") {
      skuIds.push({ quantity: element.quantity, sku: itemSku });
      skuList.push(itemSku);
    }
  });
  if (skuIds.length > 0) {
    const results = await postSkusPrices(skuIds);
    if (results && results.data) {
      itemPriceList = results.data["list-sku-price"];
    }
    itemList.forEach((item) => {
      const breakDown = itemPriceList.find((e) => e.sku === item.sku);
      if (breakDown) {
        if (breakDown.price == 0) {
          errorItemList.push({
            sku: item.sku,
            message:
              "Not available online. Please call Customer Service for availability",
          });
        }
      }
    });
  }

  return { errorItemList, itemPriceList };
};
export const getMobileDateValue = (value) => {
  var dateToday = new Date().toLocaleDateString("en-CA");
  dateToday = value ? new Date(value).toLocaleDateString("en-CA") : dateToday;
  return dateToday;
};

export const generateTokenEncrypt = (value) => {
  const publicKey = process.env.REACT_APP_LOGIN_PUBLIC_KEY;

  let RSAEncrypt = new JSEncrypt();
  RSAEncrypt.setPublicKey(publicKey);
  let encryptedPass = RSAEncrypt.encrypt(value);
  return encryptedPass;
};

export const getErrorMessages = (
  isAuthenticated,
  errorItem,
  priceBreakDown,
  price,
  item,
  checkQtyError,
  selectedQty,
  sku
) => {
  let response = [];

  if (isAuthenticated && item.is_product_exclude) {
    response.push({
      message: "This item is not available in your territory.",
      heading: "Error",
    });
  } else if (isAuthenticated && item.txt_x_pant_Mat_status == "DL") {
    response.push({
      message: "This item is no longer available",
      heading: "DISCONTINUED",
    });
  } else if (isAuthenticated && (errorItem || (priceBreakDown && price == 0))) {
    response.push({
      message:
        "Not available online. Please call Customer Service for availability",
      heading: "Error",
    });
  } else if (
    isAuthenticated &&
    (item.txt_x_pant_Mat_status == "DU" || item.txt_x_pant_Mat_status == "DV")
  ) {
    response.push({
      message: "Stock is limited",
      heading: "Will be Discontinued",
    });
  }
  if (
    isAuthenticated &&
    sku === checkQtyError["sku"] &&
    checkQtyError["minOrderQtyError"] &&
    selectedQty != 0
  ) {
    response.push({
      message: `Please consider min. order quantity of: ${checkQtyError.itemMinCount} `,
      heading: "Error",
    });
  } else if (
    isAuthenticated &&
    sku === checkQtyError["sku"] &&
    checkQtyError["qtyMultiError"]
  ) {
    response.push({
      message: `This product is sold in multiples of: ${checkQtyError.quantityMulti} `,
      heading: "Error",
    });
  }
  if (item.is_product_exclude && !isAuthenticated) {
    response.push({
      message: "This item is not available in your territory.",
      heading: "Error",
    });
  }
  return response;
};

export const validateCheckItem = (
  sku,
  minOrderQty,
  qtyIncrements,
  quantity,
  itemSku
) => {
  let itemMinCount = parseInt(minOrderQty) ? parseInt(minOrderQty) : 1;
  let quantityMulti = parseInt(qtyIncrements) ? parseInt(qtyIncrements) : 1;
  if (itemSku === sku) {
    if (quantity < itemMinCount) {
      return isMobileOnly
        ? `Please consider minimum order quantity of ${itemMinCount}`
        : `Min Order: ${itemMinCount}`;
    } else if (quantity % quantityMulti) {
      return isMobileOnly
        ? `This product is sold in multiples of ${quantityMulti}`
        : `Sold in multiple of: ${quantityMulti} `;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const AddToCartTriggerBulkUpload = async (
  bulkOrderItems,
  itemPriceList
) => {
  TriggerReset();
  const token = getBrowserStorage_Cookie(CARTTOKEN);
  const productsSkuListCart = [];
  bulkOrderItems.map((item) => {
    productsSkuListCart.push(encodeURIComponent(item.sku.toUpperCase()));
  });
  const itemDetailsUrl = await ITEMS_DETAILS_URL(productsSkuListCart.join());

  const pimResponse = await axios.get(itemDetailsUrl, {
    headers: { apiToken: token },
  });
  bulkOrderItems.map((item) => {
    const priceItem = itemPriceList.find(
      (bItem) => bItem.sku.toUpperCase() === item.sku.toUpperCase()
    );
    const pimProduct = pimResponse.data.find(
      (bItem) => bItem.txt_wurth_lac_item === item.sku.toUpperCase()
    );
    if (priceItem && pimProduct) {
      item.price = priceItem.price;
      item.extended = priceItem.extended;
      item.priceUnit = priceItem["price-unit"];
      item.pricebreakdowns = priceItem.pricebreakdowns;
      item.sku = pimProduct.txt_wurth_lac_item;
      item.txt_uom = pimProduct.txt_uom;
      item.name = pimProduct.txt_description_name;
      item.brand_name = pimProduct.brand_name;
      item.sap_id = pimProduct.txt_sap;
      item.sale_price = pimProduct.override_price;
    }
  });
  TriggerEventAddCartItems(bulkOrderItems);
};
