/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import moment from "moment";
import OrderSummary from "./OrderSummary";
import {
  VIEW_CART_DEFAULT_URL,
  ZOOM_SHIPPING_ADDRESS,
  CONFIRM_ORDER_URL,
} from "./APIs";
import axios from "axios";
import { REQ_HEADER } from "./Tokens";
import { bindActionCreators } from "redux";
import { Table } from "react-bootstrap";
import {
  setCartItems,
  setCartData,
  setCheckoutIsProcessing,
} from "../../../store/actions/shoppingCart";
import { connect } from "react-redux";
import ConfirmShoppingCartItem from "./ConfirmShoppingCartItem";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import {
  handleCheckout,
  loadCartData,
  loadCartItems,
  fetchOrderPrice,
  getAddress,
  getAvailData,
} from "../shopping-cart/commonFunc";
import { getPaymentMethod } from "../../../services/paymentAPI";
import { paymentDetails } from "../../routewise/utils";
import paymentTypesMap from "../shopping-cart/mappings/paymentTypesMap.json";
import CrediCardDetails from "./common/CreditCardDetails";
import CreditCardDetailsMobile from "./common/CreditCardDetailsMobile";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import ShippingAddressView from "./common/ShippingAddressView";
import CartItemListing from "./common/CartItemListing";
import ConfirmOrderItem from "./ConfirmOrderItem";
import ConfirmOrderItemMobile from "./ConfirmOrderItemMobile";
import { setShippingOption } from "./commonFunc";
import confPath from "../../../assets/img/svg/confPath.svg";
import ErrorAlert from "../../common/ErrorAlert";
import Spinner from "./common/Spinner";
import { SHIPPING_ADDRESS } from "../../common/Constants";
import { INVENTORY_SHIPPING } from "../../../components/common/RouterConstants";
import Img from "../../common/Img";
import arrowBackward from "../../../assets/img/svg/back_arrow_gray.svg";
import {
  setBrowserStorage,
  removeBrowserStorage,
  getBrowserStorage,
  getBrowserStorage_Cookie,
  setBrowserStorage_Cookie,
} from "../../../utils/cookies";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { isMobileOnly } from "react-device-detect";
import { store } from "../../../../index";
import { setTempCreditCard } from "../../../store/actions/shoppingCart";
import PageTitle from "../../common/seo/PageTitle";

class ConfirmOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isMounted: false,
      cartUrl: "",
      submitDis: false,
      shippingAddress: "",
      cartConfig: "",
      cartItems: [],
      priceUrl: null,
      orderUrl: null,
      checkoutUrlMain: null,
      purchaseformUrl: null,
      submitUrl: null,
      lineitemupdateUrl: "",
      orderNumber: null,
      addressCheck: "",
      errors: [],
      isLoading: true,
      paymentToken: "",
      error: "",
      showLoader: false,
    };
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  getPaymantToken = async () => {
    try {
      const paymentMethod = await getPaymentMethod(this.props.cartData.cartUrl);
      const cartData = Object.assign({}, this.props.cartData);
      cartData.configuration.paymentToken = paymentMethod.token;
      this.props.actions.setCartData(cartData);
    } catch (error) {}
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true, _isMounted: true });
    try {
      if (
        this.props.shoppingCart &&
        _.isEmpty(this.props.shoppingCart.cartData)
      ) {
        await loadCartData();
        this.getPaymantToken();
      }

      const cartItemsData = await loadCartItems(true);
      if (cartItemsData[1] && cartItemsData[1].length) {
        fetchOrderPrice();
      }

      if (!this.props.shoppingCart.cartItems.length) {
        this.props.history.push("/");
      }
      //await setShippingOption(this.props.shoppingCart.cartItems);
      try {
        let items = _.cloneDeep(this.props.shoppingCart.cartItems);
        if (items.length) {
          getAvailData(items, "shoppingCart");
        }
      } catch (error) {}

      const { cartItems, cartData } = this.props.shoppingCart;
      if (_.isEmpty(cartData) || _.isEmpty(cartItems))
        return this.props.history.replace("/shopping-cart");

      axios.get(VIEW_CART_DEFAULT_URL, { headers: REQ_HEADER }).then((res) => {
        this.setState({
          addressCheck: res.data.configuration.addressCheck,
        });
        this.comfirmOrederData();
        this.loadShippingAddress();
      });
      this.setState({ cartItems: this.props.shoppingCart.cartItems });
    } catch (error) {}

    this.setState({ isLoading: false });
  };

  comfirmOrederData = async () => {
    const url = (await this.state.cartUrl) + CONFIRM_ORDER_URL;
    axios
      .get(url, {
        headers: REQ_HEADER,
      })
      .then((res) => {
        this.setState({ cartConfig: res.data.configuration });
      });
  };

  loadShippingAddress = async () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address`;
    const response = await axios.get(url, {
      headers: REQ_HEADER,
    });
    if (this.state.addressCheck === "T") {
      const shippingAddressId =
        store.getState()?.shoppingCart?.cartData?.configuration
          ?.shippingAddressId;

      this.setState({
        shippingAddress: response.data.find((item) =>
          shippingAddressId ? item.id === shippingAddressId : item.default
        ),
      });
    } else {
      const address = await getBrowserStorage_Cookie(SHIPPING_ADDRESS);
      this.setState({
        shippingAddress: address,
      });
    }
  };

  handleCheckout() {
    new Promise(async (resolve, reject) => {
      try {
        this.setState({
          submitDis: true,
          showLoader: true,
        });

        await this.props.actions.setCheckoutIsProcessing(true);
        await setBrowserStorage_Cookie("isCheckoutProcessing", true);

        const res = await handleCheckout();
        const { orderNumber, orderDate } = res;
        if (orderNumber) {
          await setBrowserStorage("orderNum", orderNumber);
          await setBrowserStorage("orderDate", orderDate);
          removeBrowserStorage("cartItems");

          const successCallback = () => {
            this.props.history.push("/order-reciept");
          };
          await this.props.actions.setCheckoutIsProcessing(
            false,
            successCallback
          );
          await setBrowserStorage_Cookie("isCheckoutProcessing", false);

          resolve("done");
        }
      } catch (error) {
        this.setState({
          submitDis: false,
        });
        if (error.message) this.setState({ error: error.message });
        else this.setState({ error: "Unexpected Error" });
        reject(error);
      } finally {
        this.setState({
          showLoader: false,
        });
        await this.props.actions.setCheckoutIsProcessing(false);
        await setBrowserStorage_Cookie("isCheckoutProcessing", false);
        await store.dispatch(setTempCreditCard(null));
      }
    });
  }

  displayEmailAddress() {
    if (sessionStorage.getItem("changedEmail")) {
      return sessionStorage.getItem("changedEmail");
    } else if (
      !!this.props.cartData &&
      !!this.props.cartData.configuration &&
      this.props.cartData.configuration.orderEmail
    ) {
      return this.props.cartData.configuration.orderEmail;
    } else if (
      !!this.props.cartData &&
      !!this.props.cartData.configuration &&
      this.props.cartData.configuration.user_email
    ) {
      return this.props.cartData.configuration.user_email;
    } else {
      return "";
    }
  }

  render() {
    const { cartItems, cartData } = this.props.shoppingCart;
    const { shippingAddress, isLoading, error } = this.state;
    const addressDetails = JSON.parse(
      getBrowserStorage("selectedAddressDetails")
    );
    const selectedShippingAddress = JSON.parse(
      getBrowserStorage("selectedShippingAddress")
    );

    const { configuration } = this.props.cartData;
    const { paymentToken } = configuration ? configuration : "";
    const { driverNote } = cartData.configuration ? cartData.configuration : "";
    return (
      <BlockUi tag="div" blocking={this.state.showLoader} loader={<Spinner />}>
        <PageTitle tabIndex={0} title={"Review Your Order"}></PageTitle>
        <ErrorBoundary>
          <section
            className={`${
              isMobileOnly
                ? "checkout-step-mob mob-review-order"
                : "checkout-step-section"
            }`}
          >
            <div className="container">
              <div className="row">
                <div className="clearfix col-md-12 checkout-process">
                  <Link
                    title="Back"
                    className="link-black d-flex"
                    to={INVENTORY_SHIPPING}
                    tabIndex="0"
                  >
                    <Img src={arrowBackward} alt="" />{" "}
                    {isMobileOnly ? "Shipping & Payment" : "Back"}
                  </Link>
                  {!isMobileOnly && (
                    <div className="step-wrapper">
                      <img src={confPath} alt="" />
                    </div>
                  )}
                </div>
                <div className="col-md-9 mob-col">
                  <div className="section-heading-with-line">
                    <h1 role="heading" aria-level="1" tabIndex={0}>
                      {isMobileOnly ? "Review Order" : "Review Your Order"}{" "}
                    </h1>
                    <span />
                  </div>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div className="confirm-order-wrapper">
                      <div className={`${!isMobileOnly && "row"}`}>
                        <div className="col-md-4 info-box">
                          <h4 role="heading" aria-level="4" tabIndex={0}>Payment Method</h4>
                          <p tabIndex={0}>
                          {configuration &&
                          paymentToken &&
                          configuration.paymentMethod[0] === "V" ? (
                            isMobileOnly ? (
                              <CreditCardDetailsMobile
                                configuration={configuration}
                                paymentToken={paymentToken}
                              />
                            ) : (
                              <CrediCardDetails
                                configuration={configuration}
                                paymentToken={paymentToken}
                              />
                            )
                          ) : (
                            paymentTypesMap[configuration.paymentMethod]
                          )}
                          </p>
                        </div>
                        <div className="col-md-4 info-box">
                          <h4 role="heading" aria-level="4" tabIndex={0}>Billing Address</h4>

                          <div>
                            {addressDetails ? (
                              // ? getAddress(addressDetails["billing-address"])
                              <p className="address-text mb-0" tabIndex={0}>
                                <strong>
                                  {addressDetails["billing-address"].name}
                                </strong>
                                <br />
                                {
                                  addressDetails["billing-address"][
                                    "street-address"
                                  ]
                                }
                                , <br />
                                {
                                  addressDetails["billing-address"]["locality"]
                                }, {addressDetails["billing-address"]["region"]}
                                ,{" "}
                                {/* {addressDetails["billing-address"]["country-name"]},{" "} */}
                                {
                                  addressDetails["billing-address"][
                                    "postal-code"
                                  ]
                                }
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 info-box">
                          <h4 className="d-flex justify-content-between" role="heading" aria-level="4" tabIndex={0}>
                            Shipping Address
                          </h4>
                          {selectedShippingAddress ? (
                            <p tabIndex={0}>
                              <ShippingAddressView
                                selectedShippingAddress={
                                  selectedShippingAddress
                                }
                                cartAttn={cartData.configuration.attnName}
                              />
                            </p>
                          ) : (
                            <p className="address-text">{shippingAddress}</p>
                          )}
                          {driverNote && (
                            <div className="mt-3">
                              <h4 role="heading" aria-level="4" tabIndex={0}>Driver's Note:</h4>
                              <p tabIndex={0}>{driverNote}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={!isMobileOnly && "row mt-3"}>
                        {isMobileOnly ? (
                          <div className="d-flex po-info">
                            <div className="info-box">
                              <h4>Job Name</h4>
                              {!!configuration && configuration.jobName && (
                                <p>{configuration.jobName}</p>
                              )}
                            </div>
                            <div className="info-box">
                              {!!configuration && configuration.po && (
                                <p>
                                  <h4 className="bold">PO No:</h4>
                                  {configuration.po}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-4 info-box">
                            <h4 role="heading" aria-level="4" tabIndex={0}>PO No / Job Name</h4>
                            {!!configuration && configuration.jobName && (
                              <p tabIndex={0}>
                                <span className="bold">Job Name:</span>{" "}
                                {configuration.jobName}
                              </p>
                            )}
                            {!!configuration && configuration.po && (
                              <p tabIndex={0}>
                                <span className="bold">PO No:</span>{" "}
                                {configuration.po}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="col-md-4 info-box">
                          <h4 role="heading" aria-level="4" tabIndex={0}>Future Ship Date</h4>
                          {!!configuration && configuration.pickDate ? (
                            <p tabIndex={0}>
                              {moment(
                                configuration.pickDate,
                                "MM-DD-YYYY"
                              ).format("MM/DD/YYYY")}
                            </p>
                          ) : (
                            <p tabIndex={0}>Pick date not available</p>
                          )}
                        </div>
                        <div className="col-md-4 info-box">
                          <h4 role="heading" aria-level="4" tabIndex={0}>Order Confirmation Email</h4>
                          <p tabIndex={0}>{this.displayEmailAddress()}</p>
                        </div>
                      </div>
                      {configuration && configuration.coupon && (
                        <div className={`${!isMobileOnly && "row"}`}>
                          <div className="col-md-4 mt-3 info-box">
                            <h4 role="heading" aria-level="4" tabIndex={0}>Promo Code Applied</h4>
                            <p className="promo-code-applied bold" tabIndex={0}>
                              {configuration.coupon}
                            </p>
                          </div>
                        </div>
                      )}
                      {/* {!isMobileOnly && <br />} */}
                      {!isMobileOnly &&
                      configuration.completeDelivery === "T" ? (
                        <span className="clearfix mt-5 mb-4 not-ship-info-text color-text red-text" tabIndex={0}>
                          <i className="material-icons">info</i> This order will
                          not ship until backorder item(s) are in stock.
                        </span>
                      ) : null}

                      <div>
                        <div className="section-heading-with-line gray-heading">
                          <h5 role="heading" aria-level="5" tabIndex={0}>Delivery Options</h5>
                          <span />
                        </div>
                      </div>
                      {isMobileOnly &&
                      configuration.completeDelivery === "T" ? (
                        <span
                          className={`${
                            isMobileOnly
                              ? "clearfix mt-0 mb-4 not-ship-info-text color-text red-text"
                              : "clearfix mt-5 mb-4 not-ship-info-text color-text red-text"
                          }`}
                        >
                          <i className="material-icons">info</i> This order will
                          not ship until backorder item(s) are in stock.
                        </span>
                      ) : null}
                      <div className="cart-table-wrapper">
                        {error && (
                          <ErrorAlert
                            errorMessage={error}
                            handleErrorClose={() =>
                              this.setState({ error: "" })
                            }
                          />
                        )}
                        <div
                          className={
                            isMobileOnly && "row main-item-wrapper-mob"
                          }
                        >
                          {/* {true && <Spinner isLoading={this.state.isLoading} />} */}
                          {cartItems &&
                            cartItems.length &&
                            (!isMobileOnly ? (
                              <Table className="table">
                                <thead className="thread table-heading-wrapper">
                                  <tr>
                                    <th className="align-middle description" tabIndex={0}>
                                      Product Description
                                    </th>
                                    <th className="unit-price" tabIndex={0}>Unit Price</th>
                                    <th className="qty text-center" tabIndex={0}>
                                      Quantity
                                    </th>
                                    <th className="umo text-center" tabIndex={0}>UOM</th>
                                    <th className="sub-total" tabIndex={0}>Subtotal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.shoppingCart.cartItems.map(
                                    (item) => (
                                      <ConfirmOrderItem
                                        key={item.sku}
                                        item={item}
                                      />
                                    )
                                  )}
                                </tbody>
                              </Table>
                            ) : (
                              this.props.shoppingCart.cartItems.map((item) => (
                                <ConfirmOrderItemMobile
                                  key={item.sku}
                                  item={item}
                                />
                              ))
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-3 s-cart-right-side-bar m-t-30">
                  <OrderSummary redirectRoute="" />
                  <div
                    className="summary-row btn-row review-order-summary-row"
                    style={{ marginBottom: 20 }}
                  >
                    <button
                      title="CHECKOUT"
                      disabled={
                        this.state.submitDis ||
                        isLoading ||
                        this.props.isCheckoutProcessing
                      }
                      className="btn red-btn d-flex justify-content-center"
                      onClick={this.handleCheckout}
                    >
                      CHECKOUT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ErrorBoundary>
      </BlockUi>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCartItems,
      setCartData,
      setCheckoutIsProcessing,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
  isCheckoutProcessing: state.shoppingCart.isCheckoutProcessing,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmOrder));
