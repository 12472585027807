import axios from "axios";
import { useMutation } from "@tanstack/react-query";

// Exported as a separate function so that it can be used in
// class components
export const removeCartItemApi = async (itemId?: string) => {
  // If the itemId is not specified, remove all items
  const response = await axios.delete(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/cart${
      itemId ? `/${itemId}` : ""
    }`
  );

  return response;
};

const useRemoveCartItemMutation = (itemId?: string) => {
  return useMutation({
    mutationKey: ["remove-cart-item", itemId ?? "all"],
    mutationFn: () => removeCartItemApi(itemId),
  });
};

export default useRemoveCartItemMutation;
