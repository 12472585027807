import React from "react";
import ContentLoader from "react-content-loader";

const IframeLoading = (props) => (
  <div className="ifram-loader">
    <ContentLoader
      speed={2}
      width={200}
      height={37}
      viewBox="0 0 200 37"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="5" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="27" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="49" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="71" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="93" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="115" y="8" rx="4" ry="4" width="20" height="20" />
      <rect x="137" y="8" rx="4" ry="4" width="20" height="20" />
    </ContentLoader>
  </div>
);

export default IframeLoading;
