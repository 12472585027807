import { SET_FILTER_ITEMS, SET_OPENED_ATTRIBUTES } from "../constants";

export const setFilterItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_FILTER_ITEMS,
    payload,
  });
};

export const setOpenedAttributes = (payload) => (dispatch) => {
  dispatch({
    type: SET_OPENED_ATTRIBUTES,
    payload,
  });
};
