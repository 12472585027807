import axios from "axios";
import jwt_decode from "jwt-decode";
import qs from "querystring";
import queryString from "query-string";
import { REQ_HEADER } from "./Tokens";
import {
  VIEW_CART_DEFAULT_URL,
  ZOOM_VIEW_ITEMS_CART,
  ITEMS_DETAILS_URL,
  GET_SHIPPING_OPTIONS_URL,
  CHECK_TOKEN_URL,
  SEND_ERROR_EMAIL,
  SEND_ERROR_EMAIL_LOGIN,
} from "./APIs";
import {
  setCartItems,
  setOrderPrice,
  setPromoCode,
  setCartData,
  updateCartProduct,
} from "../../../store/actions/shoppingCart";
import plantsMap from "./mappings/plantsMap.json";
import shippingMethodsMap from "./mappings/shippingMethodsMap";
import moment from "moment";
import { store } from "../../../../index";
import _ from "lodash";
import { getShippingMethodsList, getDefaultShippTo } from "./utils";
import { serviceUpdateItemsPIM } from "./services/servicesPIM";
import { removeCartItemApi } from "app/hooks/shopping-cart/useRemoveCartItemMutation.hook";
import { CHECK_PRODUCT_INFO_PIM } from "./APIs";
import { postSkusPrices } from "../../../utils/api/cortex";
import {
  getLoginToken,
  getNextShipMethod,
  generateTokenEncrypt,
} from "../../common/commonFunc";
import {
  CARTTOKEN,
  TOTAL_QUANTITY,
  AVAILBLE,
  MATCH,
  QUANTITY_MULTIPLIER_MATCH,
  USER_TOKEN,
} from "../../common/Constants";
import {
  setBrowserStorage,
  getBrowserStorage,
  getBrowserStorage_Cookie,
  removeBrowserStorage,
} from "../../../utils/cookies";
import {
  TriggerEventAddCartItems,
  TriggerReset,
} from "../../common/googleTagManager/TriggerFunction";
import { setDefaultShippingAddress } from "./services/servicesPIM.new";

export const getProductsDetailsPim = async (itemsList) => {
  try {
    const skuList = await itemsList.map((item) => {
      return item.sku;
    });
    const productsInfoUrl = await ITEMS_DETAILS_URL(skuList);
    const productsInfoPim = await axios.get(productsInfoUrl);
    const productsInfoCombined = await itemsList.map((cartProduct) => {
      const PIMProduct = productsInfoPim.data.filter(
        (PIMProduct) => cartProduct.sku === PIMProduct.txt_wurth_lac_item
      )[0];
      if (PIMProduct) {
        const combinedProductData = {};
        // data from PIM
        combinedProductData.name = PIMProduct.txt_description_name;
        combinedProductData.description = PIMProduct.txt_sub_description;
        combinedProductData.selBrand = PIMProduct.sel_assigned_brand;
        // data from cart
        combinedProductData.sku = cartProduct.sku;
        combinedProductData.quantity = cartProduct.quantity;

        return combinedProductData;
      } else {
        return;
      }
    });
    return _.compact(productsInfoCombined);
  } catch (error) {}
};

export const getAvailData = async (productList, page = null) => {
  const combinedArr = productList.map((prod) => {
    let reqItemJson = {};
    if (prod.configuration.will_call_plant) {
      reqItemJson = {
        plant: prod.configuration.will_call_plant,
        quantity: prod.quantity,
        sku: prod.sku,
      };
    } else {
      reqItemJson = {
        quantity: prod.quantity,
        sku: prod.sku,
      };
    }
    return reqItemJson;
  });
  const data = { skuidqty: combinedArr };
  try {
    const res = await axios({
      method: "post",
      url: GET_SHIPPING_OPTIONS_URL,
      headers: REQ_HEADER,
      data,
    });

    const availData = res.data;
    var seviceEPUpdatedItems = [];

    const items = await store
      .getState()
      .shoppingCart.cartItems.filter((prod) => {
        const availableItems = availData.find(
          (itemAvail) => prod.sku === itemAvail.sku
        );
        if (availableItems) {
          prod.avail = availableItems;
          prod.shippingOptionsData = availableItems;
          setSelectedShippingOption(prod, seviceEPUpdatedItems);
          return prod;
        }
      });

    if (seviceEPUpdatedItems.length) {
      await serviceUpdateItemsPIM(seviceEPUpdatedItems);
    }

    if (page === "shoppingCart") {
      if (items.length) {
        store.dispatch(setCartItems(items));
      }
    } else {
      return items;
    }
  } catch (error) {
    return [];
  }
};

const setSelectedShippingOption = (product, shouldUpdateItems) => {
  const willCallPlant = product.configuration.will_call_plant;
  const shippingData = product.shippingOptionsData;
  product.status = shippingData.status;
  product.xplant = shippingData.xplant;

  if (!_.isEmpty(willCallPlant)) {
    if (
      shippingData.willcallanywhere.hash === product.configuration.hashvalue
    ) {
      const selectedOption = shippingData.willcallanywhere;

      if (
        product.configuration.plant_1 ||
        product.configuration.plant_2 ||
        product.configuration.plant_3 ||
        product.configuration.plant_4 ||
        product.configuration.plant_5
      ) {
        product.pickupRemaining = true;
      }
      product.selectedOption = selectedOption;
      product.optionType = "willCall";
      product.shipMethod = selectedOption.shippingMethods_1
        ? product.configuration.shipping_method_1
        : getNextShipMethod(selectedOption);
      product.status = selectedOption.status;
    } else {
      const selectedOption = shippingData.options[0];
      setBestShippingOption(product, selectedOption);
      shouldUpdateItems.push(product);
    }
  } else {
    let selectedOption =
      shippingData.options.find(
        (option) => option.hash === product.configuration.hashvalue
      ) || {};
    if (!_.isEmpty(selectedOption)) {
      product.selectedOption = selectedOption;
      product.shipMethod = selectedOption.shippingMethods_1
        ? product.configuration.shipping_method_1
        : getNextShipMethod(selectedOption);
    } else {
      const selectedOption = shippingData.options[0];
      setBestShippingOption(product, selectedOption);
      shouldUpdateItems.push(product);
    }
  }
};

const setBestShippingOption = (product, selectedOption) => {
  let shipMethod;
  if (selectedOption.shippingMethods_1) {
    shipMethod = getDefaultShippTo(selectedOption);

    const availShipMethods = getShippingMethodsList(
      selectedOption.shippingMethods_1
    );

    shipMethod =
      shipMethod === "G" && availShipMethods.includes("C") ? "C" : shipMethod;
  } else {
    shipMethod = getNextShipMethod(selectedOption);
  }

  product.selectedOption = selectedOption;
  product.configuration[`shipping_method_${selectedOption.index}`] = shipMethod;
  product.shipMethod = shipMethod;
  product.isSetDefaultShipMethod = true;
  product.optionType = "default";
  product.configuration.hashvalue = selectedOption.hash;
};

const DateFormat = "dddd, MMM. D, YY";
export const getAvailabilityDetails = (prodsWithAvailData) => {
  try {
    const updatedProds =
      prodsWithAvailData.length &&
      prodsWithAvailData.map((prod) => {
        const plant1 = prod.avail[0].plants[0];
        const plant2 = prod.avail[0].plants[1];
        const plant3 = prod.avail[0].plants[2];
        const quantity = parseInt(prod.quantity);
        const shipOption = {};
        if (plant1.availability >= quantity) {
          // available
          shipOption.option = 1;
          shipOption.type = "A";
          shipOption.availStatus = "Available";
          shipOption.availPlant = plantsMap[plant1["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant1["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // partial on home plant & back order home plant
          plant1.availability < quantity &&
          plant1.availability > 0 &&
          plant1["back-order-date"] !== ""
        ) {
          shipOption.option = 2;
          shipOption.type = "PB"; // partial backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant1["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity - plant1.availability;
          shipOption.availQuantity = plant1.availability;
          shipOption.availPlant = plantsMap[plant1["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant1["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // only back order home plant
          plant1.availability === 0 &&
          plant1["back-order-date"] !== ""
        ) {
          shipOption.option = 3;
          shipOption.type = "AB"; // all backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant1["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity;
          shipOption.availQuantity = 0;
          shipOption.availPlant = plantsMap[plant1["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant1["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 2 full available
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability >= quantity
        ) {
          shipOption.option = 4;
          shipOption.type = "A"; // available
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant2["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = 0;
          shipOption.availQuantity = quantity;
          shipOption.availPlant = plantsMap[plant2["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant2["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 2 partial and backorder from plant 2
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability < quantity &&
          plant2.availability > 0 &&
          plant2["back-order-date"] !== ""
        ) {
          shipOption.option = 5;
          shipOption.type = "PB"; // partial backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant2["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity - plant2.availability;
          shipOption.availQuantity = plant2.availability;
          shipOption.availPlant = plantsMap[plant2["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant2["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 2 no stock and backorder all from plant 2
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability === 0 &&
          plant2["back-order-date"] !== ""
        ) {
          shipOption.option = 6;
          shipOption.type = "AB"; // all backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant2["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity;
          shipOption.availQuantity = 0;
          shipOption.availPlant = plantsMap[plant2["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant2["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 3 all available
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability === 0 &&
          plant2["back-order-date"] === "" &&
          plant3.availability >= quantity
        ) {
          shipOption.option = 7;
          shipOption.type = "A"; // available
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant3["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = 0;
          shipOption.availQuantity = quantity;
          shipOption.availPlant = plantsMap[plant3["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant3["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 3 partila available backorder plant 3
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability === 0 &&
          plant2["back-order-date"] === "" &&
          plant3.availability < quantity &&
          plant3.availability > 0 &&
          plant3["back-order-date"] !== ""
        ) {
          shipOption.option = 8;
          shipOption.type = "PB"; // partial backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant3["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity - plant3.availability;
          shipOption.availQuantity = plant3.availability;
          shipOption.availPlant = plantsMap[plant3["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant3["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // plant 3 not avail and backorder everythin
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability === 0 &&
          plant2["back-order-date"] === "" &&
          plant3.availability === 0 &&
          plant3["back-order-date"] !== ""
        ) {
          shipOption.option = 9;
          shipOption.type = "AB"; // all backorder
          shipOption.availStatus = "Stock Limited";
          shipOption.availBackOrderDate = moment(
            new Date(plant3["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = quantity;
          shipOption.availQuantity = 0;
          shipOption.availPlant = plantsMap[plant3["plant-id"]];
          shipOption.availShipMethods =
            shippingMethodsMap[
              plant3["shipping-method"].split(",")[0]
            ].codeName;
        } else if (
          // No Stock
          plant1.availability === 0 &&
          plant1["back-order-date"] === "" &&
          plant2.availability === 0 &&
          plant2["back-order-date"] === "" &&
          plant3.availability === 0 &&
          plant3["back-order-date"] === ""
        ) {
          shipOption.option = 0;
          shipOption.type = "NS"; // all backorder
          shipOption.availStatus = "No Stock";
          shipOption.availBackOrderDate = moment(
            new Date(plant3["back-order-date"])
          ).format(DateFormat);
          shipOption.backOrderQty = 0;
          shipOption.availQuantity = 0;
          shipOption.availPlant = "";
          shipOption.availShipMethods = [];
        } else {
          shipOption.option = -1;
        }
        prod.shipOption = shipOption;
        return prod;
      });
    return updatedProds;
  } catch (error) {}
};

export const loadCartData = async () => {
  const response = await axios.get(VIEW_CART_DEFAULT_URL, {
    headers: REQ_HEADER,
  });

  const cartData = {};
  // cartData.cartUrl = await response.data.self.href;
  // cartData.cartTotalUrl = await response.data.links.filter(
  //   (item) => item.rel === "total"
  // )[0].href;
  // cartData.priceUrl = await response.data.links.filter(
  //   (item) => item.rel === "lineitemsprice"
  // )[0].href;
  // cartData.lineitemupdateUrl = await response.data.links.filter(
  //   (link) => link.rel === "lineitemupdateform"
  // )[0].href;
  // cartData.bulkOrderUrl = await response.data.links.filter(
  //   (link) => link.rel === "additemstocartform"
  // )[0].href;
  // cartData.configuration = await response.data.configuration;
  // cartData.orderUrl = await response.data.links.filter(
  //   (item) => item.rel === "order"
  // )[0].href;
  // cartData.lineItemsUrl = await response.data.links.filter(
  //   (item) => item.rel === "lineitems"
  // )[0].href;
  // await setBrowserStorage("cartData", JSON.stringify(cartData));
  // store.dispatch(setCartData(cartData));
  return cartData;
};

export const loadCartItems = async (cartPage = false) => {
  let cartArrayError = [];
  let returnDataArray = [];
  let errorProduct = false;
  const token = getBrowserStorage_Cookie(CARTTOKEN);
  let productsDataListCart = [];
  const productsSkuListCart = [];
  let pimResponse = null;
  let combinedProdDataList = [];
  let cartItems = [];
  let existingCartItems = store.getState().shoppingCart
    ? store.getState().shoppingCart.cartItems
    : [];
  removeBrowserStorage("updatedCartItems");

  const { cartUrl } = await store.getState().shoppingCart.cartData;

  const url = ZOOM_VIEW_ITEMS_CART;
  try {
    const itemsZoomRes = await axios.get(url, { headers: REQ_HEADER });
    if (itemsZoomRes.data) {
      store.dispatch(setCartData(itemsZoomRes.data));

      if (itemsZoomRes.data["total-quantity"] === 0) {
        return [];
      }
      const cartItemsDetails = itemsZoomRes.data.cartItems;
      productsDataListCart = cartItemsDetails.map((item) => {
        const productData = {};
        productData.sku = item.code;
        productData.quantity = item.quantity;
        productData.lineItemUrl = "item.self.href";
        productData.lineItemguid = item.guid;
        productData.configuration = item.configuration;
        productData.itemExist = existingCartItems.find(
          (el) => el.sku === item.code && el.quantity === item.quantity
        )
          ? true
          : false;

        existingCartItems = existingCartItems.map((exItem) => {
          if (exItem.sku === item.code && exItem.quantity === item.quantity) {
            exItem.configuration = item.configuration;
          }
          return exItem;
        });

        return productData;
      });
      //remove their Min Order Qty
      //remove X-Plant Material Status changed to DL in SAP
      //remove item excluded from the region the cart is tied to (ship-to)
      const dataApi = [];
      await Promise.all(
        productsDataListCart.length &&
          productsDataListCart.map(async (cartProduct) => {
            if (!cartProduct.itemExist) {
              dataApi.push({
                sku: cartProduct.sku,
                mqt: cartProduct.quantity,
                poOrJobName: "",
              });
            }
          })
      );

      try {
        if (dataApi.length) {
          let errorMsg = null;

          const res = await axios.post(
            CHECK_PRODUCT_INFO_PIM,
            JSON.stringify(dataApi),
            { headers: { apiToken: token } }
          );

          res.data.map(async (item) => {
            const cartProduct = productsDataListCart.find(
              (product) => product.sku === item.sku && !product.itemExist
            );
            if (
              item.availability === AVAILBLE &&
              item.mqt === MATCH &&
              item.qmm === QUANTITY_MULTIPLIER_MATCH &&
              item.product_exclution === false &&
              item.product_discontinue === false
            ) {
              productsSkuListCart.push(encodeURIComponent(cartProduct.sku));
            } else if (item.product_exclution !== false) {
              errorMsg = item.product_exclution_msg;
            } else if (item.product_discontinue !== false) {
              errorMsg = "This item is no longer available";
            } else if (item.availability !== AVAILBLE) {
              errorMsg = "Invalid SKU";
            } else if (item.mqt !== MATCH) {
              errorMsg = `Please consider min. order quantity of: ${item.txt_min_order_amount} `;
            } else if (item.qmm !== QUANTITY_MULTIPLIER_MATCH) {
              errorMsg = `This product is sold in multiples of: ${item.qm}`;
            } else {
              errorMsg =
                "Unexpected error occurred while checking out. The web support team will get in touch with you soon.";
            }

            if (errorMsg) {
              let obj = {
                sku: cartProduct.sku,
                error: errorMsg,
              };

              errorProduct = true;
              cartArrayError.push(obj);
              await removeCartItemApi(cartProduct.lineItemguid);
            }
          });
        }
      } catch (error) {}

      if (productsSkuListCart.length) {
        try {
          const itemDetailsUrl = await ITEMS_DETAILS_URL(
            productsSkuListCart.join()
          );

          pimResponse = await axios.get(itemDetailsUrl, {
            headers: { apiToken: token },
          });

          let priceCheckSkus = [];
          let skuData = [];

          if (pimResponse) {
            productsDataListCart.map((cartProduct) => {
              const PIMProduct = pimResponse.data.find(
                (PIMProduct) =>
                  cartProduct.sku === PIMProduct.txt_wurth_lac_item
              );

              if (PIMProduct) {
                const combinedProductData = {};
                // data from PIM

                combinedProductData.name = PIMProduct.txt_description_name;
                combinedProductData.description =
                  PIMProduct.txt_sub_description;
                combinedProductData.selBrand = PIMProduct.sel_assigned_brand;
                combinedProductData.img = PIMProduct.img;
                combinedProductData.ciNumber = PIMProduct.txt_wurth_CInumber;
                combinedProductData.hazardous = PIMProduct.txt_hazardous;
                combinedProductData.specialShipping =
                  PIMProduct.txt_special_shipping;
                combinedProductData.webDirect = PIMProduct.txt_web_direct;
                combinedProductData.uom = PIMProduct.txt_uom;
                combinedProductData.uomLable = PIMProduct.txt_uom_label;
                combinedProductData.sap_id = PIMProduct.txt_sap;
                combinedProductData.brand_name = PIMProduct.brand_name;
                combinedProductData.originalprice = parseFloat(
                  PIMProduct.originalprice
                );
                combinedProductData.price = PIMProduct.price;
                combinedProductData.min_order_amount = parseInt(
                  PIMProduct.txt_min_order_amount
                )
                  ? parseInt(PIMProduct.txt_min_order_amount)
                  : 1;
                combinedProductData.order_qty_increments = parseInt(
                  PIMProduct.txt_order_qty_increments
                )
                  ? parseInt(PIMProduct.txt_order_qty_increments)
                  : 1;
                combinedProductData.groupId = PIMProduct.groupId;
                // data from cart
                combinedProductData.sku = cartProduct.sku;
                combinedProductData.quantity = cartProduct.quantity;
                combinedProductData.lineItemUrl = cartProduct.lineItemUrl;
                combinedProductData.lineItemguid = cartProduct.lineItemguid;
                combinedProductData.configuration = cartProduct.configuration;
                combinedProductData.itemExist = cartProduct.itemExist;

                combinedProdDataList.push(combinedProductData);
                priceCheckSkus.push({
                  quantity: cartProduct.quantity,
                  sku: cartProduct.sku,
                });
              } else if (cartProduct.itemExist) {
                const combinedProductData = {};
                combinedProductData.sku = cartProduct.sku;
                combinedProductData.quantity = cartProduct.quantity;
                combinedProductData.lineItemUrl = cartProduct.lineItemUrl;
                combinedProductData.lineItemguid = cartProduct.lineItemguid;
                combinedProductData.configuration = cartProduct.configuration;
                combinedProductData.itemExist = cartProduct.itemExist;

                combinedProdDataList.push(combinedProductData);
              }
            });

            cartItems = combinedProdDataList.filter((prod) => {
              if (prod.itemExist) {
                const existingData = existingCartItems.find(
                  (item) => item.sku === prod.sku
                );
                if (existingData) {
                  prod.groupId = existingData.groupId;
                  prod.name = existingData.name;
                  prod.description = existingData.description;
                  prod.selBrand = existingData.selBrand;
                  prod.img = existingData.img;
                  prod.ciNumber = existingData.ciNumber;
                  prod.hazardous = existingData.hazardous;
                  prod.specialShipping = existingData.specialShipping;
                  prod.webDirect = existingData.webDirect;
                  prod.uom = existingData.uom;
                  prod.uomLable = existingData.uomLable;
                  prod.min_order_amount = existingData.min_order_amount;
                  prod.order_qty_increments = existingData.order_qty_increments;
                  prod.unitPrice = existingData.unitPrice;
                  prod.initialUnitPrice = existingData.initialUnitPrice;
                  prod.productUnit = existingData.productUnit;
                  prod.priceUnit = existingData.priceUnit;
                  prod.extended = existingData.extended;
                  prod.sap_id = existingData.sap_id;
                  prod.brand_name = existingData.brand_name;
                  prod.originalprice = parseFloat(existingData.originalprice);
                  prod.price = existingData.price;
                  return prod;
                }
              } else {
                prod.unitPrice = "loading";
                prod.initialUnitPrice = "loading";
                prod.productUnit = prod.uom;
                prod.priceUnit = "loading";
                prod.extended = "loading";
                return prod;
              }
            });

            if (cartPage) {
              let returnData = [];
              returnData.push(cartItems);
              returnData.push(cartArrayError);
              returnDataArray = returnData;
            } else {
              returnDataArray = cartItems;
            }

            if (cartItems.length) {
              store.dispatch(setCartItems(cartItems));
            } else {
              store.dispatch(setCartItems([]));
            }

            cartArrayError = [];
            return returnDataArray;
          }
        } catch (error) {}
      } else {
        if (cartPage) {
          let returnData = [];

          returnData.push(existingCartItems);
          returnData.push(cartArrayError);

          returnDataArray = returnData;
        } else {
          returnDataArray = existingCartItems;
        }

        if (!existingCartItems.length) {
          store.dispatch(setCartItems([]));
        }

        cartArrayError = [];
        return returnDataArray;
      }
    }
  } catch (error) {
    console.log(error, "error2", "cartUrlcartUrlcartUrl");
    productsDataListCart = [];
    if (cartPage) {
      let returnData = [];

      returnData.push(existingCartItems);
      returnData.push(cartArrayError);

      returnDataArray = returnData;
    } else {
      returnDataArray = existingCartItems;
    }

    if (!existingCartItems.length) {
      store.dispatch(setCartItems([]));
    }

    // if (cartItems.length) {
    //   store.dispatch(setCartItems(cartItems));
    // } else {
    //   store.dispatch(setCartItems([]));
    // }
    cartArrayError = [];
    return returnDataArray;
  }
};

const triggerAddToCart = (product, itemData) => {
  if (product && itemData) {
    const items = [
      {
        quantity: itemData.quantity
          ? itemData.quantity
          : itemData.selected_qty
          ? itemData.selected_qty
          : 0,
        sku: product.sku,
        sale_price: itemData.override_price,
        txt_uom: product.uom,
        priceUnit: product.priceUnit,
        price: product.itemPrice,
        extended: product.extended,
        name: product.description,
        brand_name: product.brand_name,
        sap_id: product.sap_id,
      },
    ];
    TriggerEventAddCartItems(items);
  }
};
export const setCartItemStore = async (
  itemData,
  sub_description,
  itemImg,
  priceData,
  priceUnit = null,
  extended = 0
) => {
  TriggerReset();
  let cartUrl = "";
  let productsDataListCart = {};
  let cartItems = [];
  let pimResponse = null;
  let addToCart = [];
  let itemQuantity = itemData.quantity
    ? itemData.quantity
    : itemData.selected_qty
    ? itemData.selected_qty
    : 1;
  const token = getBrowserStorage_Cookie(CARTTOKEN);

  if (store.getState().shoppingCart && store.getState().shoppingCart.cartData) {
    cartUrl = store.getState().shoppingCart.cartData.cartUrl;
  } else {
    let cartData = loadCartData();
    cartUrl = cartData.cartUrl;
  }

  const url = ZOOM_VIEW_ITEMS_CART;

  try {
    const itemsZoomRes = await axios.get(url, { headers: REQ_HEADER });

    if (itemsZoomRes.data[TOTAL_QUANTITY] === 0) {
      return [];
    }

    productsDataListCart = itemsZoomRes.data.cartItems.filter((item) => {
      if (item._item[0]._code[0].code === itemData.txt_wurth_lac_item) {
        item.sku = item._item[0]._code[0].code;
        item.quantity = item.quantity;
        item.lineItemUrl = item.self.href;
        item.lineItemguid = item._lineitemguid[0].guid;
        item.configuration = item.configuration;
        return item;
      }
    });

    productsDataListCart.map(async (cartProduct) => {
      let priceCheckSkus = [];
      priceCheckSkus.push({
        quantity: cartProduct.quantity,
        sku: cartProduct.sku,
      });

      const product = store
        .getState()
        .shoppingCart.cartItems.find(
          (item) => item.sku === itemData.txt_wurth_lac_item
        );

      const itemDetailsUrl = await ITEMS_DETAILS_URL(cartProduct.sku);

      pimResponse = await axios.get(itemDetailsUrl, {
        headers: { apiToken: token },
      });

      if (product) {
        await postSkusPrices(priceCheckSkus)
          .then((response) => response.data)
          .then((data) => {
            const priceData = data["list-sku-price"][0];
            if (priceData && priceData.price > 0) {
              product.priceUnit = priceData["price-unit"];
              product.extended = priceData["extended"];
            }
          })
          .catch((error) => {});

        product.quantity = cartProduct.quantity;
        //getting total added item price value from cart
        product.unitPrice = getUnitPriceByQuantity(
          priceData.pricebreakdowns,
          cartProduct.quantity,
          priceData.price
        );
        // only getting current adding qty price value
        product.itemPrice = getUnitPriceByQuantity(
          priceData.pricebreakdowns,
          itemQuantity,
          priceData.price
        );

        if (pimResponse) {
          const PIMProduct = pimResponse.data.find(
            (PIMProduct) =>
              itemData.txt_wurth_lac_item === PIMProduct.txt_wurth_lac_item
          );
          product.sap_id = PIMProduct.txt_sap;
          product.brand_name = PIMProduct.brand_name;
          product.originalprice = parseFloat(PIMProduct.originalprice);
          product.price = PIMProduct.price;
        }
        await triggerAddToCart(product, itemData);
        await store.dispatch(updateCartProduct(product));
      } else {
        const combinedProductData = {};
        if (itemData.group_id) {
          combinedProductData.groupId = itemData.group_id;
        } else if (itemData.groupId) {
          combinedProductData.groupId = itemData.groupId;
        }

        combinedProductData.name = itemData.txt_description_name
          ? itemData.txt_description_name
          : sub_description;
        combinedProductData.description = sub_description;
        combinedProductData.selBrand = itemData.sel_assigned_brand;
        combinedProductData.img = itemImg;
        combinedProductData.ciNumber = itemData.txt_CI_number;
        combinedProductData.hazardous = itemData.txt_hazardous;
        combinedProductData.specialShipping = itemData.txt_special_shipping;
        combinedProductData.webDirect = itemData.txt_web_direct;
        combinedProductData.uom = itemData.txt_uom;
        combinedProductData.uomLable = itemData.txt_uom_label;
        combinedProductData.min_order_amount = parseInt(
          itemData.txt_min_order_amount
        )
          ? parseInt(itemData.txt_min_order_amount)
          : 1;
        combinedProductData.order_qty_increments = parseInt(
          itemData.txt_order_qty_increments
        )
          ? parseInt(itemData.txt_order_qty_increments)
          : 1;

        // data from cart
        combinedProductData.sku = cartProduct.sku;
        combinedProductData.quantity = cartProduct.quantity;
        combinedProductData.lineItemUrl = cartProduct.lineItemUrl;
        combinedProductData.lineItemguid = cartProduct.lineItemguid;
        combinedProductData.configuration = cartProduct.configuration;

        //getting total added item price value from cart
        combinedProductData.unitPrice = getUnitPriceByQuantity(
          priceData.pricebreakdowns,
          cartProduct.quantity,
          priceData.price
        );
        // only getting current adding qty price value
        combinedProductData.itemPrice = getUnitPriceByQuantity(
          priceData.pricebreakdowns,
          itemQuantity,
          priceData.price
        );
        combinedProductData.productUnit = itemData.txt_uom;
        combinedProductData.initialUnitPrice = priceData.price;
        combinedProductData.pricebreakdowns = priceData.pricebreakdowns;
        combinedProductData.priceUnit = priceUnit;
        combinedProductData.extended = extended;
        if (pimResponse) {
          const PIMProduct = pimResponse.data.find(
            (PIMProduct) =>
              itemData.txt_wurth_lac_item === PIMProduct.txt_wurth_lac_item
          );
          combinedProductData.sap_id = PIMProduct.txt_sap;
          combinedProductData.brand_name = PIMProduct.brand_name;
          combinedProductData.originalprice = parseFloat(
            PIMProduct.originalprice
          );
          combinedProductData.price = PIMProduct.price;
        }
        if (store.getState().shoppingCart.cartItems) {
          cartItems = store.getState().shoppingCart.cartItems;
        }
        await cartItems.push(combinedProductData);
        await store.dispatch(setCartItems(cartItems));
        triggerAddToCart(combinedProductData, itemData);
      }
      // add to cart item GA$ trigger
    });
  } catch (error) {}
};

export const getUnitPriceByQuantity = (
  priceBreakDowns,
  quantity,
  currentPrice
) => {
  if (quantity <= 0) return 0;

  var unitPrice = currentPrice;

  for (var i = 1; i <= 4; i++) {
    if (priceBreakDowns[`quantity${i}`] > 0) {
      if (quantity >= priceBreakDowns[`quantity${i}`]) {
        unitPrice = priceBreakDowns[`price${i}`];
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return unitPrice;
};

export const fetchOrderPrice = async () => {
  // const cartUrl = await getCartUrl();
  try {
    // const priceSimUrl = cartUrl + "?zoom=order:simulation";
    const priceSimUrl = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/simulation-checkout`;
    const responseSim = await axios.get(priceSimUrl, {
      headers: REQ_HEADER,
    });
    const orderSummaryData = {};

    orderSummaryData.itemCount = responseSim.data.cartItemsCount;

    orderSummaryData.totalQuantity = responseSim.data[TOTAL_QUANTITY];

    orderSummaryData.shippingcost = responseSim.data.shippingcost;

    orderSummaryData.tax = responseSim.data.tax;
    orderSummaryData.total = responseSim.data.total;
    orderSummaryData.net = responseSim.data.net;

    orderSummaryData.productslist = responseSim.data.productslist;

    orderSummaryData.errors = responseSim?.data?.error ?? [];

    store.dispatch(setOrderPrice(orderSummaryData));

    if (
      store.getState().shoppingCart &&
      store.getState().shoppingCart.cartItems
    ) {
      let cartItems = store.getState().shoppingCart.cartItems.map((product) => {
        const existingItemData = responseSim.data.productslist.find(
          (item) => item.sku === product.sku
        );
        if (existingItemData) {
          product.extended = existingItemData.extendedprice;
          product.unitPrice = existingItemData.price;
          product.priceUnit = existingItemData.priceunit;
          product.initialUnitPrice = existingItemData.price;
          product.originalprice = parseFloat(existingItemData.originalprice);
          product.price = existingItemData.price;
        }
        return product;
      });
      store.dispatch(setCartItems(cartItems));
    }

    return orderSummaryData;
  } catch (error) {
    if (error?.response?.data?.error_code) {
      store.dispatch(
        setPromoCode({
          promoCode: error?.response?.data?.error_code,
        })
      );
    }
  }
};

// only options
export const getShippingOptions = async (productList) => {
  try {
    const combinedArr = await productList.map((prod) => {
      const reqItemJson = {
        quantity: prod.quantity,
        sku: prod.sku,
      };
      return reqItemJson;
    });

    const reqBody = { skuidqty: combinedArr };
    const resShip = await axios({
      method: "post",
      url: GET_SHIPPING_OPTIONS_URL,
      headers: REQ_HEADER,
      data: reqBody,
    });

    const shippingOptions = await resShip.data;
    return shippingOptions;
  } catch (error) {
    return error;
  }
};

export const validatePoJobName = async () => {
  const { po_job, po, jobName, ...rest } = await store.getState().shoppingCart
    .cartData.configuration;
  const errors = [];
  if ((po_job === "P" || po_job === "B") && !po?.trim()) {
    const error = {};
    error["debug-message"] = "PO number is required";
    errors.push(error);
    window.scroll(0, 0);
  }

  if ((po_job === "J" || po_job === "B") && !jobName?.trim()) {
    const error = {};
    error["debug-message"] = "Job name is required";
    errors.push(error);
  }
  return errors;
};

export const handleCheckout = async () => {
  const errors = [];
  let resOrder = {};
  let submitOrderRes = {};
  const {
    shoppingCart: { cartData },
    shoppingCart,
  } = await store.getState();
  const cartItems = await Object.assign([], shoppingCart.cartItems);
  const AllShippingConfirmed = await cartItems.every((item) =>
    Boolean(item.configuration.hashvalue)
  );
  if (!AllShippingConfirmed) {
    throw {
      name: "OrderUrlError",
      message: "Shipping not confirmed for all products",
    };
  }

  const cartInfo = await loadCartData();

  const orderUrl = cartInfo.orderUrl;
  // const orderUrlSplitArry = orderUrl.split("orders");
  // const CHECKOUT_URL = `${orderUrlSplitArry[0]}purchases/orders${orderUrlSplitArry[1]}/form`;
  const CHECKOUT_URL = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/checkout`;

  let orderNumber = "";
  let orderDate = "";
  await axios({
    method: "POST",
    //url: CHECKOUT_URL + "?FollowLocation",
    url: CHECKOUT_URL,
    headers: REQ_HEADER,
    data: {},
  })
    .then(async (response) => {
      if (
        response &&
        response.data["external-order-number"] &&
        response.data["purchase-date"]
      ) {
        orderNumber = await response.data["external-order-number"];
        orderDate = response.data["purchase-date"]["display-value"];
      }
    })
    .catch(async (error) => {
      let ERR_MSG =
        "Unexpected error occurred while checking out. The web support team will get in touch with you soon.";
      if (error.response.data) {
        const { messages } = error.response.data;
        // check the status of the debug message
        if (messages === "SAP Credit Issue") {
          ERR_MSG = `Please contact the Wurth Louis and Company Credit Department regarding your account. Phone: (800) 444-0043 or <br/>email: <a href="mailto:creditdepartment@wurthlac.com">creditdepartment@wurthlac.com</a>`;
        }
      }
      // error is handled in catch block
      // status code out of the range of 2xx
      throw {
        name: "SubmitOrderActionError",
        message: ERR_MSG,
      };
    });
  return { orderNumber, orderDate };
};

export const sendErrorEmailLogin = async (ERR_CODE, ERR_MSG) => {
  const userToken = getLoginToken();
  const response = await axios({
    method: "post",
    url: SEND_ERROR_EMAIL_LOGIN,
    headers: {
      Authorization: "Bearer " + userToken,
      "Content-Type": "application/json",
    },
    data: {
      ERR_CODE: ERR_CODE,
      ERR_MSG: ERR_MSG,
    },
  });

  return response;
};

export const getAddress = (address) =>
  // `${address["name"]},
  `${address["street-address"]},
  ${address["locality"]}, ${address["region"]},
  ${address["postal-code"]}`;

export const getShippingData = async (sku, quantity, willCallPlant) => {
  let reqItemJson = "";
  if (willCallPlant) {
    reqItemJson = {
      plant: willCallPlant,
      quantity: quantity,
      sku: sku,
    };
  } else {
    reqItemJson = {
      quantity: quantity,
      sku: sku,
    };
  }
  const data = { skuidqty: [reqItemJson] };
  try {
    const resShipOptions = await axios({
      method: "POST",
      url: GET_SHIPPING_OPTIONS_URL,
      headers: REQ_HEADER,
      data,
    });
    return resShipOptions.data[0];
  } catch (error) {
    return {};
  }
};

export const setShippingOption = async (items) => {
  const itemsProms = await items.map(async (i) => {
    let product = { ...i };
    const willCallPlant = product.configuration.will_call_plant;

    let selectedOption = {};

    if (!_.isEmpty(willCallPlant)) {
      product.optionType = "willCall";
      if (
        product.configuration.plant_1 ||
        product.configuration.plant_2 ||
        product.configuration.plant_3 ||
        product.configuration.plant_4 ||
        product.configuration.plant_5
      ) {
        product.pickupRemaining = true;
      }
    }

    const shippingData = await getShippingData(
      i.sku,
      i.quantity,
      willCallPlant
    );
    selectedOption =
      shippingData.options.find(
        (option) => option.hash === product.configuration.hashvalue
      ) || {};
    // check normal shipping option
    if (_.isEmpty(selectedOption)) {
      // if no default option then check WCA option
      if (
        shippingData.willcallanywhere.hash === product.configuration.hashvalue
      ) {
        selectedOption = shippingData.willcallanywhere;
      }
    }
    if (!_.isEmpty(selectedOption)) {
      // if hashvalue available
      product.selectedOption = selectedOption;
      if (product.editShipping === true) {
        product.shippingConfirmed = false;
      } else {
        product.shippingConfirmed = true;
      }
    } else {
      // if hashvalue not available set best(firstOption)
      const selectedOption = shippingData.options[0];
      const shipMethod = getShippingMethodsList(
        selectedOption.shippingMethods_1
      );
      product.selectedOption = selectedOption;
      product.shippingConfirmed = false;
      product.configuration.shipping_method_1 = shipMethod[0];
      product.configuration.hashvalue = selectedOption.hash;
    }
    product.shippingData = shippingData;
    return product;
  });
  const itemsNew = await Promise.all(itemsProms).then((i) => i);
  store.dispatch(setCartItems(itemsNew));
  return itemsNew;
};

export const accountSelect = async (shipTo, accNo) => {
  const reqBody = `{
    "ship-to": "${shipTo}",
    "accountNo":  "${accNo}"
  }`;

  const tokenResp = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_ENDPOINT}/am/account-select`,
    headers: {
      "Content-Type": "application/json",
    },
    data: reqBody,
  });

  // Set the default shipping address
  const addressResponse = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address`,
    {
      headers: REQ_HEADER,
    }
  );
  const selectedShippingAddress = addressResponse?.data?.find(
    (item) => item["ship-to"] === shipTo
  );
  if (selectedShippingAddress) {
    await setDefaultShippingAddress(
      selectedShippingAddress?.id,
      selectedShippingAddress?.["is_primary"]
    );
  }

  return tokenResp;
};

export const logOffTime = async () => {
  const logOff = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_ENDPOINT}/am/user-settings/log_off_time`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return logOff;
};

export const checkToken = async () => {
  const userToken = getLoginToken();
  const checkToken = await axios({
    method: "get",
    url: CHECK_TOKEN_URL,
    headers: {
      Authorization: "Bearer " + userToken,
      "Content-Type": "application/json",
    },
  });

  return checkToken;
};

export const passwordPolicy = async () => {
  const passwordPolicy = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/passwordpolicy?requestBy=FE`,
  });

  return passwordPolicy;
};

export const validateToken = async (token) => {
  const validateToken = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT}/am/registration/validate-registration-uuid`,
    headers: {
      uuid: token,
      "Content-Type": "application/json",
    },
  });
  return validateToken;
};

export const setRegistrationPassword = async (
  token,
  password,
  repassword,
  search
) => {
  try {
    let getUUID = [];
    const tokenDecode = Buffer.from(token, "base64").toString("ascii");
    getUUID = tokenDecode.split(".");
    let UUID = getUUID.length > 0 ? getUUID[0] : "";
    const encryptedPass = generateTokenEncrypt(password);
    const encryptedConfirmPass = generateTokenEncrypt(repassword);
    // get email
    const { email } = queryString.parse(search);
    if (email) {
      const emailDecode = Buffer.from(email, "base64").toString("ascii");
      // get JWT token
      const res = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/am/auth/registration-token?email=${emailDecode}`,
        {
          headers: { uuid: UUID },
        }
      );
      // set password API call if  JWT token  exist
      if (res.data && res.data.token) {
        const encryptedKey = `${encryptedPass}:${encryptedConfirmPass}:${res.data.uuid}`;
        const base64Key = Buffer.from(encryptedKey).toString("base64");
        const requestBody = {
          password: base64Key,
          userName: res.data.uuid,
        };
        const setRegistrationPassword = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_ENDPOINT}/am/registration/set-registration-password`,
          headers: {
            Authorization: `Bearer ${res.data.token}`,
            "Content-Type": "application/json",
            "x-api-key": token,
          },
          data: requestBody,
        });
        return setRegistrationPassword;
      }
    }
    return;
  } catch (error) {}
};

export const showResult = async (password) => {
  const encryptedPass = generateTokenEncrypt(password);
  const base64Key = Buffer.from(encryptedPass).toString("base64");
  const showResult = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/passwordvalidation`,
    { password: base64Key, request_by: "FE" }
  );

  return showResult;
};

export const searchResult = (cateId, sWord) => {
  var axiosGet = axios.create();
  delete axiosGet.defaults.headers.common["Authorization"];
  return axiosGet.get(
    `${
      process.env.REACT_APP_API_ENDPOINT
    }/pim/webservice/rest/search?id=${cateId}&search=${encodeURIComponent(
      sWord
    )}`
  );
};

export const homeBanners = () => {
  let carouselBanner = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/carouselbanner`;
  let topBottomBanner = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/topbottombanner`;

  const requestOne = axios.get(carouselBanner);
  const requestTwo = axios.get(topBottomBanner);

  return axios.all([requestOne, requestTwo]).then(
    axios.spread((...responses) => {
      return responses;
    })
  );
};

export const homeFeaturedProduct = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/getfeatureproducts`,
    config
  );
};

export const updateFeatureProductOutOfStock = (sku) => {
  const encryptedSku = generateTokenEncrypt(sku);
  const base64Key = Buffer.from(encryptedSku).toString("base64");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const reqItemJson = {
    sku: base64Key,
  };

  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/updatefeatureproduct`,
    headers: config,
    data: reqItemJson,
  });
};

export const clouditems = (sku) => {
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/clouditems?sku=${sku}`
  );
};

export const cloudsearch = (value) => {
  return fetch(
    `https://cloudsearch.x-cart.com/api/v1/search?apiKey=${process.env.REACT_APP_CLOUDE_API_KEY}&q=${value}&sort=score%20desc,sort_int_rank%20asc&limits[categories]=100`
  );
};

export const getNewUsers = () => {
  const userToken = getLoginToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: userToken,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/am/manage_users`,
    config
  );
};

export const getJobRoles = () => {
  const userToken = getLoginToken();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: userToken,
    },
  };

  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/am/registration/get-roles`,
    config
  );
};

export const updateApprowedUser = (data) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_ENDPOINT}/am/approve_contacts`,
    data: data,
  });
};

export const deleteApprowedUser = (data) => {
  return axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_ENDPOINT}/am/delete_approve_contacts`,
    data: data,
  });
};

export const getbrandlist = (sku) => {
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/getbrandlist`
  );
};

export const getShippingOptionsWithErrorCodes = async (productList) => {
  try {
    const combinedArr = await productList.map((prod) => {
      const reqItemJson = {
        quantity: prod.quantity,
        sku: prod.sku,
      };
      return reqItemJson;
    });

    const reqBody = { skuidqty: combinedArr };
    const resShip = await axios({
      method: "post",
      url: GET_SHIPPING_OPTIONS_URL,
      headers: REQ_HEADER,
      data: reqBody,
    });

    return resShip;
  } catch (error) {
    return error;
  }
};
