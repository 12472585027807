/* eslint-disable prettier/prettier */
import React from "react";
import { Table } from "react-bootstrap";
import ProductDescription from "./ProductDescription";
import ConfirmedShippingDetailsCard from "./ConfirmedShippingDetailsCard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setCartItems } from "../../../store/actions/shoppingCart";

import plantsMap from "./mappings/plantsMap.json";
import { getBrowserStorage } from "../../../utils/cookies";

class InventoryShippingConfirmed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      willCall: false,
      willCallQuantity: 0,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("swillCallPLant")) {
      this.setState({
        willCall: true,
        willCallQuantity: sessionStorage.getItem("willCallQuantity"),
      });
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const defaultPlat = getBrowserStorage("defaultplant");

    return (
      <div className="shipping-items-list-wrapper">
        <div className="clearfix shiping-items-header">
          <h3 role="heading" aria-level="3" tabIndex={0}>
            <i className="material-icons">check_circle</i> Shipping Confirmed
            <a
              className="expand-link"
              onClick={this.handleOpen}
              onKeyPress={this.handleOpen}
              tabIndex="0"
            >
              {" "}
              {this.state.isOpen ? (
                <i className="material-icons">unfold_less</i>
              ) : (
                <i className="material-icons">unfold_more</i>
              )}
            </a>
          </h3>
          <p tabIndex={0}>
            {/* <span>45 Items</span>  */}
            confirmed
          </p>
        </div>
        <div className="pending-table-header">
          {this.state.isOpen ? (
            <div className="product-table">
              <Table bordered>
                <thead>
                  <tr>
                    <th><span tabIndex={0}>Product Description</span></th>
                    <th><span tabIndex={0}>Shipping Information</span></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.shoppingCart.cartItems
                    ? this.props.shoppingCart.cartItems.map((item) =>
                        item.shippingConfirmed && item.selectedOption ? (
                          <tr key={item.sku}>
                            <td tabIndex={0}>
                              <ProductDescription item={item} />
                            </td>
                            <td style={{ width: "45%" }} tabIndex={0}>
                              <ConfirmedShippingDetailsCard
                                sku={item.sku}
                                quantity={item.quantity}
                                selectedOption={item.selectedOption}
                                configuration={item.configuration}
                                shippingConfirmed={item.shippingConfirmed}
                                checkPendingAvailable={
                                  this.props.checkPendingAvailable
                                }
                                checkConfirmAvailable={
                                  this.props.checkConfirmAvailable
                                }
                              />
                            </td>
                          </tr>
                        ) : null
                      )
                    : null}
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryShippingConfirmed);
