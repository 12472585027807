export const CART_TOKEN = "cartToken";
export const CT_TIME = "ctime";
export const SHIP_TO = "shipTo";
export const ACC_NO = "accNo";
export const USER_TOKEN = "userToken";
export const U_TIMEOUT = "u_timeout";
export const CURRENT_ADDRESS = "currentAddress";
export const REGION = "region";

export const FILTER_BRAND = "filter_brand";
export const FILTER_ATTRIBUTE = "filter_attribute";
export const ACTIVE_ATTRIBUTE = "active_attribute";
export const FILTER_BRANDS = "filter_brands";
export const FILTER_ATTRIBUTES = "filter_attributes";
export const ACTIVE_ATTRIBUTES = "active_attributes";
export const OPEN_FILTER = "openfilter";
export const STEXT = "sText";
export const SSLUG = "sslug";
export const SVAL = "sval";
export const SELECTED = "selectId";
export const SORT = "sort";
export const PAGE = "page";
export const PAGES = "pages";
