import CrediCardDetails from "./shopping-cart/common/CreditCardDetails";
import paymentTypesMap from "./shopping-cart/mappings/paymentTypesMap.json";
import React from "react";

export const getValidatedQuantity = (quantity) => {
  if (parseInt(quantity) && parseInt(quantity) >= 1) {
    return parseInt(quantity);
  } else {
    return "";
  }
};

export const paymentDetails = (configuration, paymentToken) => {
  if (configuration.paymentMethod[0] === "V") {
    return (
      <CrediCardDetails
        configuration={configuration}
        paymentToken={paymentToken}
      />
    );
  } else {
    return <div>{paymentTypesMap[configuration.paymentMethod]}</div>;
  }
};
