import moment from "moment";
import React from "react";

export const getKeyValueArr = (arr = [], except = []) => {
  return Object.keys(arr)
    .filter((name) => !except.includes(name))
    .reduce((o, key) => Object.assign(o, { [key]: arr[key].value }), {});
};

export const toQueryString = (obj, options = 0) => {
  let flattenObj = (x, path) => {
    let result = [];
    path = path || [];
    Object.keys(x).forEach(function (key) {
      let newPath = path.slice();
      newPath.push(key);

      let vals = [];
      if (typeof x[key] === "object" && x[key] !== null) {
        vals = flattenObj(x[key], newPath);
      } else {
        vals.push({ name: newPath, data: x[key] + "" });
      }

      vals.forEach(function (obj) {
        result.push(obj);
      });
    });

    return result;
  };

  let parts = flattenObj(obj);

  parts = parts.map(function (varInfo) {
    if (varInfo.name.length === 1) varInfo.name = varInfo.name[0];
    else {
      let first = varInfo.name[0];
      let rest = varInfo.name.slice(1);
      varInfo.name = first + "[" + rest.join("][") + "]";
    }
    return varInfo;
  });

  if (options === 1) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  } else if (options === 2) {
    let form = new FormData();
    parts.forEach(function (varInfo) {
      form.append(varInfo.name, varInfo.data + "");
    });
    return form;
  }
  return parts;
};

export const deepGet = (obj, properties, defaultVal = undefined) => {
  if (obj === undefined || obj === null) {
    return defaultVal;
  }

  if (properties.length === 0) {
    return obj;
  }

  let foundSoFar = obj[properties[0]];
  let remainingProperties = properties.slice(1);

  return deepGet(foundSoFar, remainingProperties, defaultVal);
};

export const formatDate = (
  dateString,
  momentParserFormat = "MMMM DD, YYYY hh:mm:ss A"
) => {
  const DATE_FORMAT = "MM/DD/YYYY";
  return moment(dateString, momentParserFormat).format(DATE_FORMAT);
};
