import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";

const ShowDriverNoteMobile = ({
  isMobileModalOpen,
  handleChange,
  saveDriverNote,
}) => {
  const [driverNote, setDriverNote] = useState("");
  return (
    <Modal
      className="bottom-modal price-breakdown-popup"
      show={isMobileModalOpen}
      backdrop="static"
      tabIndex="0"
      data-backdrop="static"
      onHide={() => {
        setDriverNote("");
        handleChange();
      }}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Driver's Notes</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mob-driver-note">
          <textarea
            className="form-control mb-3"
            onChange={(e) => {
              setDriverNote(e.target.value);
            }}
            value={driverNote}
            type="text"
            rows="6"
            placeholder="Your note here..."
          />
          <button
            className="btn red-btn"
            onClick={() => {
              saveDriverNote(driverNote);
              handleChange();
            }}
          >
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ShowDriverNoteMobile;
