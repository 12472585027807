import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {
  setCartData,
  setTempCreditCard,
  setPaymentMethod,
} from "../../../../store/actions/shoppingCart";
import visaLogo from "../../../../assets/img/payment/visa.svg";
import amexLogo from "../../../../assets/img/payment/amex.svg";
import mcLogo from "../../../../assets/img/payment/mc.svg";
import disLogo from "../../../../assets/img/payment/dis.svg";
import { withRouter } from "react-router-dom";
import { serviceDeleteCreditCard } from "../services/servicesEP";
import deleteIcon from "../../../../assets/img/svg/delete.svg";
import mobDelete from "../../../../assets/img/svg/mob-delete.svg";
import Img from "./../../../common/Img";
import { isMobileOnly } from "react-device-detect";
import error_outline from "../../../../assets/img/error_outline.svg";
import { isExpiredCreditCard } from "../utils";

const lableStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const CreditCard = ({
  number,
  name,
  expiry_date,
  type,
  shoppingCart,
  actions,
  reloadCardList,
  setErrorMessage,
  selectLatest,
  loading,
  ...props
  // default_payment_card
}) => {
  const isExpired = isExpiredCreditCard(expiry_date);
  // handle default selection
  // useEffect(() => {
  //   onSelect();
  // }, []);
  React.useEffect(() => {
    if (selectLatest) {
      onSelect();
    }
  }, [selectLatest]);

  const maskedCardNumber = (cardNumber) => {
    const cardLastDigs = String(cardNumber).slice(12);
    return `•••• •••• •••• ${cardLastDigs}`;
  };

  const onSelect = async () => {
    try {
      const cartData = Object.assign({}, shoppingCart.cartData);

      cartData.configuration.cardToken = number;
      cartData.configuration.cardName = name;
      cartData.configuration.cardType = type;
      cartData.configuration.paymentMethod = "V000";
      cartData.configuration.expireDate = expiry_date;
      cartData.configuration.selectedPaymentOption = `credit-card-${String(
        number
      ).slice(0, 8)}`;
      actions.setCartData(cartData);

      actions.setPaymentMethod(`credit-card-${String(number).slice(0, 8)}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getExpDate = (expDate) => {
    return String(expDate).split(".").join("/");
  };

  const [selectedPaymentOption, setSelectedPayment] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  useEffect(() => {
    if (shoppingCart && shoppingCart.selectedShippingMethod) {
      const selectedPaymentOption = shoppingCart.selectedShippingMethod;
      if (
        selectedPaymentOption &&
        shoppingCart.cartData &&
        shoppingCart.cartData.configuration &&
        shoppingCart.cartData.configuration.selectedPaymentOption
      ) {
        setSelectedPayment(selectedPaymentOption);
      }
    }
  }, [shoppingCart, shoppingCart.selectedShippingMethod]);

  const handleDelete = async () => {
    try {
      if (
        props.tempCreditCards &&
        props.tempCreditCards.number === number &&
        props.tempCreditCards.type === type
      ) {
        actions.setTempCreditCard(null);
      } else {
        await serviceDeleteCreditCard(number, type);
        reloadCardList();
      }
      if (
        selectedPaymentOption === `credit-card-${String(number).slice(0, 8)}`
      ) {
        actions.setPaymentMethod("");
      }
      handleClose();
    } catch (error) {
      setErrorMessage("Error in deleting credit card.");
    }
  };

  const handleClose = () => {
    setDeleteModalShow(false);
    isMobileOnly && props.showHidePaymentOptionMobileModal();
  };

  return (
    <div
      className={
        selectedPaymentOption === `credit-card-${String(number).slice(0, 8)}`
          ? "card mb-3 shadow-on-white payment-card checked-blue-border"
          : "card mb-3 shadow-on-white payment-card"
      }
    >
      <div className="card-body">
        <div className="custom-control custom-radio">
          <div>
            <input
              type="radio"
              id={`credit-card-${String(number).slice(0, 8)}`}
              checked={
                selectedPaymentOption ===
                `credit-card-${String(number).slice(0, 8)}`
              }
              name="PaymentMethod"
              onClick={onSelect}
              className="custom-control-input"
              disabled={loading || isExpired}
              tabIndex="0"
            />
            <label
              style={lableStyle}
              className={
                isExpired
                  ? "custom-control-label error"
                  : "custom-control-label"
              }
              htmlFor={`credit-card-${String(number).slice(0, 8)}`}
            >
              {isExpired ? "Expired Credit Card" : "Credit Card"}
              {!isMobileOnly && type === "VISA" && (
                <img src={visaLogo} alt="" />
              )}
              {!isMobileOnly && type === "MC" && <img src={mcLogo} alt="" />}
              {!isMobileOnly && type === "AMEX" && (
                <img src={amexLogo} alt="" />
              )}
              {!isMobileOnly && type === "DIS" && <img src={disLogo} alt="" />}
            </label>
            {!isMobileOnly && <div>{maskedCardNumber(number)}</div>}

            <div style={lableStyle}>
              {isMobileOnly ? (
                <div className="card-info">
                  <div className="d-flex info-row">
                    <div className="card-number">
                      {maskedCardNumber(number)}
                    </div>
                    <div className="icon">
                      {type === "VISA" && <img src={visaLogo} alt="" />}
                      {type === "MC" && <img src={mcLogo} alt="" />}
                      {type === "AMEX" && <img src={amexLogo} alt="" />}
                      {type === "DIS" && <img src={disLogo} alt="" />}
                    </div>
                  </div>
                  <div className="d-flex info-row">
                    <div className="info">
                      <p>{name}</p>
                      <p>
                        Exp: {getExpDate(expiry_date).substring(0, 2)}/
                        {getExpDate(expiry_date).substring(5)}
                      </p>
                    </div>
                    <div className="delete-icon">
                      <a
                        className={isMobileOnly && "mob-delete-link"}
                        onClick={() => {
                          setDeleteModalShow(true);
                          props.showHidePaymentOptionMobileModal();
                        }}
                        onKeyPress={() => {
                          setDeleteModalShow(true);
                          props.showHidePaymentOptionMobileModal();
                        }}
                        tabIndex="0"
                      >
                        <img src={mobDelete} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    {name}
                    {" - "}
                    {getExpDate(expiry_date)}
                  </div>
                  <div>
                    <a
                      className={isMobileOnly && "mob-delete-link"}
                      onClick={() => setDeleteModalShow(true)}
                      onKeyPress={() => setDeleteModalShow(true)}
                      tabIndex="0"
                    >
                      <img src={deleteIcon} alt="" />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobileOnly ? (
        <Modal
          className={
            isMobileOnly
              ? "small-modal login-modal delete-shopping-cart-popup bottom-modal"
              : "small-modal login-modal delete-shopping-cart-popup"
          }
          show={deleteModalShow}
          onHide={handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              <h5 className="modal-title" tabIndex="0">
                Remove Credit Card Details
              </h5>
            </Modal.Title>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center" tabIndex="0">
              <Img src={error_outline} alt="" width={50} />
              <br />
              <br />
              <strong className="cart-error">Are you sure ?</strong> <br />
              You want to remove this credit card details
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              title="Cancel"
              onClick={handleClose}
              className="btn outline-red-btn"
            >
              Cancel
            </Button>
            <Button
              title="Delete"
              onClick={handleDelete}
              className="btn red-btn"
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={deleteModalShow} onHide={handleClose}>
          <Modal.Body>Are you sure you want to delete ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCartData, setTempCreditCard, setPaymentMethod },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  tempCreditCards: state.shoppingCart.tempCreditCards,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreditCard));
