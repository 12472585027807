import axios from "axios";
import { REQ_HEADER } from "../Tokens";
import { store } from "index";

type ApiAddress = {
  "country-name": string;
  locality: string;
  "phone-number": string;
  "postal-code": string;
  region: string;
  "street-address": string;
  id: string;
  default: boolean;
  "family-name": string;
  organization: string;
  "ship-to": string;
  is_primary: boolean;
};

type Address = {
  addressId: string;
  addressLines: {
    "country-name": string;
    locality: string;
    "phone-number": string;
    "postal-code": string;
    region: string;
    "street-address": string;
    organization: string;
  };
  name: {
    "family-name": string;
  };
  shipTo: string;
  isPrimary: boolean;
  isSelected: boolean;
};

export const serviceGetAddresses = async () => {
  const itemMapper = (item: ApiAddress, isSelected: boolean) =>
    ({
      addressId: item.id,
      addressLines: {
        "country-name": item["country-name"],
        locality: item.locality,
        "phone-number": item["phone-number"],
        "postal-code": item["postal-code"],
        region: item.region,
        "street-address": item["street-address"],
        organization: item.organization,
      },
      name: {
        "family-name": item["family-name"],
      },
      shipTo: item["ship-to"],
      isPrimary: item.is_primary,
      isSelected,
    } satisfies Address);

  try {
    const response = await axios.get<Array<ApiAddress>>(
      `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address`,
      {
        headers: REQ_HEADER,
      }
    );

    const choice: Array<Address> = response.data
      .filter((item) => !item.default)
      .map((item) => itemMapper(item, false));
    const chosen: Array<Address> = response.data
      .filter((item) => item.default)
      .map((item) => itemMapper(item, true));

    return {
      choice,
      chosen,
    };
  } catch {
    return {
      choice: [],
      chosen: [],
    };
  }
};

export const setDefaultShippingAddress = async (
  addressId: string,
  isPrimary: boolean
) => {
  return await axios.post<"OK">(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address/default/${addressId}`,
    {
      is_primary: isPrimary,
    },
    {
      headers: REQ_HEADER,
    }
  );
};

export const serviceDeleteAddress = async (addressId: string) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address/${addressId}`
  );
};

export const getDefaultWillCallPlant = async () => {
  const response = await axios.get<{ plant: string }>(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/will-call-plant/default`,
    { headers: REQ_HEADER }
  );

  return response.data;
};

export const setDefaultWillCallPlant = async (plant: string) => {
  return await axios.put<"OK">(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/will-call-plant/default`,
    {
      plant,
    },
    { headers: REQ_HEADER }
  );
};
