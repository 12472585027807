/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import { connect } from "react-redux";
import orderReciept from "../../../assets/img/order-reciept.svg";
import Img from "../../common/Img";
import QuickOrderRecieptBody from "./QuickOrderRecieptBody";

import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import _ from "lodash";
import { bindActionCreators } from "redux";

import {
  setCartData,
  setCartItems,
  setOrderPrice,
} from "../../../store/actions/shoppingCart";
import { CARTTOKEN } from "../../common/Constants";
import {
  removeBrowserStorage_Cookie,
  getBrowserStorage,
  getBrowserStorage_Cookie,
} from "../../../utils/cookies";

class QuickOrderReciept extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billingAddress: {},
      selectedShippingAddress: {},
      selectedAddressDetails: {},
      orderNumber: "",
      paymentToken: "",
    };
  }

  async componentDidMount() {
    try {
      const { cartItems, cartData } = this.props.shoppingCart;
      if (_.isEmpty(cartItems) || _.isEmpty(cartData))
        return this.props.history.replace("/");

      const { paymentToken } = await this.props.cartData.configuration;
      this.setState({ paymentToken });
      const orderNumber = await getBrowserStorage("orderNum");
      this.setState({ orderNumber });
      const selectedAddressDetails = await JSON.parse(
        getBrowserStorage("selectedAddressDetails")
      );
      const selectedShippingAddress = await JSON.parse(
        getBrowserStorage("selectedShippingAddress")
      );
      this.setState({ selectedShippingAddress, selectedAddressDetails });
      const billingAddress = await selectedAddressDetails["billing-address"];
      const shipTo = await getBrowserStorage_Cookie("shipTo");
      const shippingAddress = selectedAddressDetails.addresses.filter(
        (address) => address["address-id"] === shipTo
      )[0];
      this.setState({ billingAddress, shippingAddress });
      this.props.actions.setOrderPrice({});
    } catch (error) {}
  }

  componentWillUnmount() {
    this.props.actions.setCartItems([]);
    this.props.actions.setOrderPrice({});
    removeBrowserStorage_Cookie(CARTTOKEN);
  }

  render() {
    const { cartItems, cartData } = this.props.shoppingCart;
    if (_.isEmpty(cartItems) || _.isEmpty(cartData)) return null;
    return (
      <ErrorBoundary>
        <section className="checkout-step-section">
          <div className="container">
            <div
              className="quick-order-reciept-wrapper"
              style={{ paddingLeft: 30 }}
            >
              <div className="order-msg">
                <img src="assets/img/reciept-icon.svg" alt="" />
                <div className="order-success">
                  <Img src={orderReciept} alt="" />
                  <h1 role="heading" aria-level="1" tabIndex={0}>Thank you for your order!</h1>
                  <p tabIndex={0}>The order placed successfully.</p>
                  <p tabIndex={0}>The order number: {getBrowserStorage("orderNum")}</p>
                </div>
                <p tabIndex={0}>
                  A confirmation will be sent. Go to{" "}
                  <a className="link-red" href="#">
                    My Account
                  </a>{" "}
                  to check your order status.
                </p>
              </div>
            </div>
            {/* <ReactToPrint
              trigger={() => (
                <BtnPrintReceipt
                  style={{
                    float: "right",
                    marginRight: 30,
                  }}
                />
              )}
              content={() => this.componentRef}
            /> */}
            <QuickOrderRecieptBody
              ref={(el) => (this.componentRef = el)}
              configuration={this.props.cartData.configuration}
              orderNumber={this.state.orderNumber}
              cartItems={this.props.shoppingCart.cartItems}
              {...this.state}
            />
          </div>
        </section>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.shoppingCart.cartData,
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCartData, setCartItems, setOrderPrice },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrderReciept);
