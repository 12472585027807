import {
  SHOW_HIDE_ADDRESSES,
  SET_CATEGORY_TOKEN,
  SET_PREV_PATH,
  SET_COMPANY_LOGO,
  SET_TOTAL_FAVORITE_ITEMS,
  SET_MYPURCHASE_HISTRY_ITEM,
} from "../constants";

export const toggleAddressWindow = (value) => ({
  type: SHOW_HIDE_ADDRESSES,
  payload: value,
});

export const setCategoryAxioToken = (payload) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_TOKEN,
    payload,
  });
};

export const setPreviousPath = (payload) => (dispatch) => {
  dispatch({
    type: SET_PREV_PATH,
    payload,
  });
};

export const setCompanyLogo = (payload) => (dispatch) => {
  dispatch({
    type: SET_COMPANY_LOGO,
    payload,
  });
};

export const setTotalFavoriteItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_TOTAL_FAVORITE_ITEMS,
    payload,
  });
};

export const setMyPurchaseHistryItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_MYPURCHASE_HISTRY_ITEM,
    payload,
  });
};
