import React from "react";
import Img from "../../common/Img";
import errorOutline from "../../../assets/img/svg/error_outline.svg";
import close from "../../../assets/img/svg/close.svg";
import { isMobileOnly } from "react-device-detect";

export const ErrorAlert = ({
  errorMessage,
  errorHeader,
  handleErrorClose,
  errorHeaderMsg,
  marginTop = 5,
  marginRight = 0,
  width = "100%",
}) => {
  return (
    <div
      style={{
        display: "flex",
        marginTop,
        marginRight,
        justifyContent: "space-between",
        alignItems: "center",
        width,
      }}
      className="alert alert-error"
      role="alert"
    >
      <div style={{ display: "flex" }}>
        <div className="alert-icon">
          <Img src={errorOutline} alt="" width="auto" />
        </div>
        <div className="error-content">
          <div className="error-title">
            <span style={{ color: "#c00" }} tabIndex={0}>
              {" "}
              {errorHeader ? errorHeader : errorHeaderMsg ? null : "Error!"}
            </span>
          </div>

          <span style={{ fontSize: 14 }} tabIndex={0}>
            {errorMessage}
          </span>
        </div>
      </div>
      {handleErrorClose && (
        <a
          onClick={() => handleErrorClose(errorMessage)}
          onKeyPress={() => handleErrorClose(errorMessage)}
          tabIndex="0"
        >
          <Img src={close} alt="Close button" />
        </a>
      )}
    </div>
  );
};

export const SuccessAlert = ({ errorMessage, handleSuccessClose }) => {
  return (
    <div
      style={{
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="alert alert-important"
      role="alert"
    >
      <div style={{ display: "flex" }}>
        <div className="alert-icon">
          <Img src={errorOutline} alt="" />
        </div>
        <p>{errorMessage}</p>
      </div>
      {handleSuccessClose && (
        <a
          onClick={() => handleSuccessClose(errorMessage)}
          onKeyPress={() => handleSuccessClose(errorMessage)}
          tabIndex="0"
        >
          <i className="material-icons">close</i>
        </a>
      )}
    </div>
  );
};

export const ErrorAlertInline = ({
  errorMessage,
  errorHeader,
  handleErrorClose,
  errorHeaderMsg,
  marginRight = 0,
  marginTop = 0,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        marginRight,
        justifyContent: "space-between",
        alignItems: "center",
        marginTop,
      }}
      className="alert-inline alert-error"
      role="alert"
      tabIndex={0}
    >
      {!isMobileOnly ? (
        <div className="d-flex align-items-center">
          <div className="alert-icon">
            <Img src={errorOutline} alt="" />
          </div>
          <div
            className="error-title-inline"
            style={{ marginTop: 2 }}
            tabIndex={0}
          >
            {errorHeader ? errorHeader : errorHeaderMsg ? null : "Error!"}
          </div>
          <div className="error-msg-sub" style={{ marginTop: 4 }}>
            <p
              style={{ fontSize: 14, marginLeft: 3 }}
              className="mb-0"
              tabIndex={0}
            >
              {errorMessage}
            </p>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="alert-icon">
            <Img src={errorOutline} alt="" />
          </div>
          <div style={{ textAlign: "left" }}>
            <div className="error-title-inline" tabIndex={0}>
              {errorHeader ? errorHeader : errorHeaderMsg ? null : "Error!"}
            </div>
            <p style={{ fontSize: 14 }} tabIndex={0}>
              {errorMessage}
            </p>
          </div>
        </div>
      )}
      {handleErrorClose && (
        <a
          onClick={() => handleErrorClose(errorMessage)}
          onKeyPress={() => handleErrorClose(errorMessage)}
          tabIndex="0"
        >
          <i className="material-icons">close</i>
        </a>
      )}
    </div>
  );
};

export const InfoAlertInline = ({
  errorMessage,
  errorHeader,
  handleErrorClose,
  errorHeaderMsg,
  marginRight = 0,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        marginRight,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="alert-inline alert-important"
      role="alert"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="alert-icon">
          <Img src={errorOutline} alt="" />
        </div>
        {/* <div className="error-content ml-1">
        </div> */}
        <div className="info-title-inline">
          {errorHeader ? errorHeader : errorHeaderMsg ? null : "Important!"}
        </div>
        <p style={{ fontSize: 14 }} className="ml-1">
          {errorMessage}
        </p>
      </div>
      {handleErrorClose && (
        <a
          onClick={() => handleErrorClose(errorMessage)}
          onKeyPress={() => handleErrorClose(errorMessage)}
          tabIndex="0"
        >
          <i className="material-icons">close</i>
        </a>
      )}
    </div>
  );
};
