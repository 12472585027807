import {
  SET_NAVIGATION_SETTINGS,
  SET_FAVORITE_FILTERS,
  SET_MYPURCHASE_FILTER_ITEM,
  SET_MYPURCHASE_DATE_FILTER,
} from "../constants";

const initialState = {
  navigationSettings: {},
  currentAddress: {},
  favoriteFilters: [],
  PurchaseFilters: [],
  purchaseDateFilter: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION_SETTINGS:
      return {
        ...state,
        navigationSettings: action.payload,
      };
    case SET_FAVORITE_FILTERS:
      return {
        ...state,
        favoriteFilters: action.payload,
      };
    case SET_MYPURCHASE_FILTER_ITEM:
      return {
        ...state,
        PurchaseFilters: action.payload,
      };
    case SET_MYPURCHASE_DATE_FILTER:
      return {
        ...state,
        purchaseDateFilter: action.payload,
      };
    default:
      return state;
  }
};
