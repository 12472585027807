import React, { Suspense } from "react";
import AuthenticatedRoute from "../components/common/authenticated-route";
import UnauthenticatedRoute from "../components/common/unauthenticated-route";
import InventoryShipping from "../components/routewise/shopping-cart/InventoryShipping";
import InventoryShippingNew from "../components/routewise/shopping-cart/InventoryShippingNew";

import ConfirmOrder from "../components/routewise/shopping-cart/ConfirmOrder";
// import payments from "./payments";
import { Route, Switch } from "react-router-dom";
import OrderReciept from "../components/routewise/shopping-cart/OrderReciept";
import QuickOrderReciept from "../components/routewise/shopping-cart/QuickOrderReciept";
import Loadable from "react-loadable";
import Spinner from "../components/common/Spinner";
import NotFound from "./NotFound";

// const Homepage = lazy(() => import('./homepage'));
// const ProductCategories = lazy(() => import('./product-categories'));
// const ProductLanding = lazy(() => import('./product-landing'));
// const Register = lazy(() => import('./register'));
// const Profile = lazy(() => import('./profile'));
// const ShoppingCart = lazy(() => import('./shopping-cart'));
// const ComingSoon = lazy(() => import('./coming-soon'));
// const Payments = lazy(() => import('./payments'));
// const ProductBrands = lazy(() => import('./product-brands'));
// const ProductAllBrands = lazy(() => import('./product-brands-main'));

const ProductBrands = Loadable({
  loader: () => import("./product-brands"),
  loading: () => null,
  modules: ["product-brands"],
});

const ProductBrandsCategory = Loadable({
  loader: () => import("./product-brands-category"),
  loading: () => null,
  modules: ["product-brands-category"],
});

const ProductAllBrands = Loadable({
  loader: () => import("./product-brands-main"),
  loading: () => null,
  modules: ["product-brands-main"],
});

const CustomFinishing = Loadable({
  loader: () => import("./custom-finishings"),
  loading: () => null,
  modules: ["custom-fiishings"],
});

const Catalog = Loadable({
  loader: () => import("./catalog"),
  loading: () => null,
  modules: ["catalog"],
});

const LaminateFinder = Loadable({
  loader: () => import("./laminate-finder"),
  loading: () => null,
  modules: ["laminate-finder"],
});

const ResourseCenter = Loadable({
  loader: () => import("./resourse-center"),
  loading: () => null,
  modules: ["resourse-center"],
});

const ShoppingCart = Loadable({
  loader: () => import("./shopping-cart"),
  loading: () => null,
  modules: ["shopping-cart"],
});

const ComingSoon = Loadable({
  loader: () => import("./coming-soon"),
  loading: () => null,
  modules: ["coming-soon"],
});

/* AboutUs */
const AboutUs = Loadable({
  loader: () => import("./about-us"),
  loading: () => null,
  modules: ["about-us"],
});

const Promotions = Loadable({
  loader: () => import("./promotions"),
  loading: () => null,
  modules: ["Promotions"],
});

const Careers = Loadable({
  loader: () => import("./careers"),
  loading: () => null,
  modules: ["careers"],
});

const Compliance = Loadable({
  loader: () => import("./compliance"),
  loading: () => null,
  modules: ["compliance"],
});

const Faqs = Loadable({
  loader: () => import("./faqs"),
  loading: () => null,
  modules: ["faqs"],
});

const BranchFinder = Loadable({
  loader: () => import("./branch-finder"),
  loading: () => null,
  modules: ["branch-finder"],
});

const PrivacyPolicy = Loadable({
  loader: () => import("./privacy-policy"),
  loading: () => null,
  modules: ["privacy-policy"],
});

const TermsOfSale = Loadable({
  loader: () => import("./terms-of-sale"),
  loading: () => null,
  modules: ["terms-of-sale"],
});

const TermsForSuppliers = Loadable({
  loader: () =>
    import(
      "./Terms-and-Conditions-for-WLACs-Purchase-of-Products-from-Suppliers"
    ),
  loading: () => null,
  modules: ["terms-for-suppliers"],
});

const Payments = Loadable({
  loader: () => import("./payments"),
  loading: () => null,
  modules: ["payments"],
});

const Homepage = Loadable({
  loader: () => import("./homepage"),
  loading: () => null,
  modules: ["homepage"],
});

const ProductCategories = Loadable({
  loader: () => import("./product-categories"),
  loading: () => null,
  modules: ["product-categories"],
});

const Search = Loadable({
  loader: () => import("./search"),
  loading: () => null,
  modules: ["search"],
});

const SearchResult = Loadable({
  loader: () => import("./search-result"),
  loading: () => null,
  modules: ["search-result"],
});

const ProductLanding = Loadable({
  loader: () => import("./product-landing"),
  loading: () => null,
  modules: ["product-landing"],
});

const Register = Loadable({
  loader: () => import("./register"),
  loading: () => null,
  modules: ["register"],
});

const Join = Loadable({
  loader: () => import("./join"),
  loading: () => null,
  modules: ["join"],
});

const Profile = Loadable({
  loader: () => import("./profile"),
  loading: () => null,
  modules: ["profile"],
});

const MyAccount = Loadable({
  loader: () => import("./myaccount"),
  loading: () => null,
  modules: ["myaccount"],
});

const QuickOrderPad = Loadable({
  loader: () => import("./quick-order-pad"),
  loading: () => null,
  modules: ["quick-order-pad"],
});

const SingleCheckoutForm = Loadable({
  loader: () => import("./single-checkout-form"),
  loading: () => null,
  modules: ["single-checkout-form"],
});

const UploadOrderForm = Loadable({
  loader: () => import("./upload-order-form"),
  loading: () => null,
  modules: ["upload-order-form"],
});

const RecentlyAdded = Loadable({
  loader: () => import("./recently-added"),
  loading: () => null,
  modules: ["recently-added"],
});

const Government = Loadable({
  loader: () => import("./government"),
  loading: () => null,
  modules: ["government"],
});

const And = Loadable({
  loader: () => import("./a-and-d"),
  loading: () => null,
  modules: ["a-and-d"],
});

const Machinery = Loadable({
  loader: () => import("./machinery"),
  loading: () => null,
  modules: ["machinery"],
});

const SupplyChain = Loadable({
  loader: () => import("./supply-chain"),
  loading: () => null,
  modules: ["supply-chain"],
});

const CatalogsLit = Loadable({
  loader: () => import("./catalogs-literature"),
  loading: () => null,
  modules: ["catalogs-literature"],
});

const CatalogMain = Loadable({
  loader: () => import("./catalog-main"),
  loading: () => null,
  modules: ["catalog-main"],
});

const SubscriberThankYou = Loadable({
  loader: () => import("./subscribe-thank-you"),
  loading: () => null,
  modules: ["subscribe-thank-you"],
});

const SubscribeExists = Loadable({
  loader: () => import("./subscriber-exists"),
  loading: () => null,
  modules: ["subscriber-exists"],
});

const loadingOveraly = () => (
  <>
    <div className="loading__overlay" />
    <div className="animation__wrapper">
      <Spinner />
    </div>
  </>
);

const TaxForm = Loadable({
  loader: () => import("../components/common/head/SaleAndTaxModal"),
  loading: () => null,
  modules: ["tax-form"],
});

const ShowroomGiveaway = Loadable({
  loader: () => import("./showroom-giveaway"),
  loading: () => null,
  modules: ["showroom-giveaway"],
});

const MakitaPromo = Loadable({
  loader: () => import("./makita-promo"),
  loading: () => null,
  modules: ["makita-promo"],
});

// eslint-disable-next-line react/display-name
export default () => (
  <Suspense fallback={null}>
    <Switch>
      <AuthenticatedRoute path="/myaccount" component={MyAccount} />
      <AuthenticatedRoute
        exact
        path="/shopping-cart"
        component={ShoppingCart}
      />
      <AuthenticatedRoute
        exact
        path="/inventory-shipping"
        // component={InventoryShipping}
        component={InventoryShippingNew}
      />
      <AuthenticatedRoute
        exact
        path="/inventory-shipping-2"
        component={InventoryShippingNew}
      />
      <AuthenticatedRoute
        exact
        path="/confirm-order"
        component={ConfirmOrder}
      />
      {/* <Route exact path="/productallbrands" component={ProductAllBrands} /> */}
      <Route exact path="/productbrands/:brandid" component={ProductBrands} />
      <Route
        exact
        path="/productbrandsbycategory/:brandid/:maincat"
        component={ProductBrandsCategory}
      />

      <Route exact path="/catalog" component={Catalog} />
      <Route exact path="/laminate-finder" component={LaminateFinder} />
      <Route exact path="/promotions" component={Promotions} />
      <Route exact path="/resourse-center" component={ResourseCenter} />
      <Route exact path="/custom-finishing" component={CustomFinishing} />
      <Route exact path="/comingsoon" component={ComingSoon} />
      <AuthenticatedRoute exact path="/payments" component={Payments} />
      <AuthenticatedRoute
        exact
        path="/order-reciept"
        component={OrderReciept}
      />
      <AuthenticatedRoute
        exact
        path="/quick-order-pad"
        component={QuickOrderPad}
      />
      <AuthenticatedRoute
        exact
        path="/upload-order-form"
        component={UploadOrderForm}
      />
      <AuthenticatedRoute
        exact
        path="/single-checkout-form"
        component={SingleCheckoutForm}
      />

      <Route exact path="/" component={Homepage} />
      <AuthenticatedRoute
        exact
        path="/quick-order-receipt"
        component={QuickOrderReciept}
      />
      <UnauthenticatedRoute
        path="/register"
        component={Register}
        redirect={false}
      />
      <Route
        exact
        path="/product-item/:itemID/:itmSku"
        component={ProductLanding}
      />
      <Route exact path="/product-item/:itemID/" component={ProductLanding} />
      <Route path="/category/:catName" component={ProductCategories} />
      <Route path="/search/:id/:word" component={Search} />
      <Route path="/search-result/:resultType/:word" component={SearchResult} />
      <Route
        path="/category/:catName/subCatName"
        component={ProductCategories}
      />

      <AuthenticatedRoute path="/recently-added" component={RecentlyAdded} />

      <UnauthenticatedRoute path="/join" component={Join} />
      <AuthenticatedRoute exact path="/profile/:id" component={Profile} />

      {/* <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />*/}

      {/* <AuthenticatedRoute exact path="/logout" component={Logout} />*/}
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/careers" component={Careers} />
      <Route exact path="/compliance" component={Compliance} />
      <Route exact path="/faqs" component={Faqs} />
      <Route exact path="/branch-finder" component={BranchFinder} />
      <Route exact path="/terms-of-sale" component={TermsOfSale} />
      <Route
        exact
        path="/Terms-and-Conditions-for-WLACs-Purchase-of-Products-from-Suppliers"
        component={TermsForSuppliers}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/login-from-pim" component={Homepage} />
      <Route exact path="/promotions" component={Promotions} />
      <Route exact path="/government" component={Government} />
      <Route exact path="/a-and-d" component={And} />
      <Route exact path="/machinery" component={Machinery} />
      <Route exact path="/supply-chain" component={SupplyChain} />
      <Route exact path="/catalogs-literature" component={CatalogsLit} />
      <Route exact path="/catalog-main" component={CatalogMain} />
      <Route exact path="/subscribe-thank-you" component={SubscriberThankYou} />
      <Route exact path="/subscriber-exists" component={SubscribeExists} />
      <Route exact path="/tax-form" component={TaxForm} />
      <Route exact path="/showroom-giveaway" component={ShowroomGiveaway} />
      <Route exact path="/makita-promo" component={MakitaPromo} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Suspense>
);
