import {
  SET_SHIPPING_ADDRESS,
  SET_CART_ITEMS,
  SET_CART_TOKEN,
  SET_ORDER_PRICE,
  SET_LOAD_CART_FUNC,
  SET_SHIPPING_PROD_LANDING,
  SET_CART_DATA,
  UPDATE_CART_PRODUCT,
  SET_NOTIFICATIONS,
  USE_SINGLE_CHECKOUT,
  SET_TEMP_CREDIT_CARD,
  SET_PAYMENT_METHOD,
  SET_OTHER_PAYMENT_METHOD,
  SET_CHECKOUT_IS_PROCESSING,
  SET_PROMO_CODE,
} from "../constants";
import {
  setBrowserStorage,
  getBrowserStorage_Cookie,
} from "../../utils/cookies";

const initialState = {
  shippingAddress: null,
  cartItems: [],
  cartToken: null,
  orderPrice: {
    totalQuantity: null,
    shippingcost: null,
    tax: null,
    total: null,
    net: null,
  },
  loadCartFunc: null,
  shippingProdLanding: {},

  cartData: {},
  notifications: {
    shoppingCart: [],
    inventoryShipping: [],
  },
  useSingleCheckout: false,
  tempCreditCards: null,
  selectedShippingMethod: "",
  isCheckoutProcessing: getBrowserStorage_Cookie("isCheckoutProcessing")
    ? getBrowserStorage_Cookie("isCheckoutProcessing") === "true"
      ? true
      : false
    : false,
  promoErrorsCheck: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case SET_CART_TOKEN:
      return {
        ...state,
        cartToken: action.payload,
      };
    case SET_ORDER_PRICE:
      return {
        ...state,
        orderPrice: action.payload,
      };
    case SET_LOAD_CART_FUNC:
      return {
        ...state,
        loadCartFunc: action.payload,
      };
    case SET_SHIPPING_PROD_LANDING:
      return {
        ...state,
        shippingProdLanding: action.payload,
      };
    case SET_CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case UPDATE_CART_PRODUCT:
      const newState = state.cartItems.map((item) => {
        return item.sku === action.payload.sku ? action.payload : item;
      });
      setBrowserStorage("cartItems", JSON.stringify(newState));
      return {
        ...state,
        cartItems: newState,
      };
    case SET_NOTIFICATIONS:
      const newNotifications = Object.assign({}, state.notifications);
      newNotifications[action.page] = action.payload;
      return {
        ...state,
        notifications: newNotifications,
      };
    case USE_SINGLE_CHECKOUT:
      return {
        ...state,
        useSingleCheckout: action.payload,
      };
    case SET_TEMP_CREDIT_CARD:
      return {
        ...state,
        tempCreditCards: action.payload,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        selectedShippingMethod: action.payload,
      };
    case SET_CHECKOUT_IS_PROCESSING:
      return {
        ...state,
        isCheckoutProcessing: action.payload,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoErrorsCheck: action.payload,
      };
    default:
      return state;
  }
};

export function selectShippingAddress({ shippingAddress }) {
  return shippingAddress
    ? `${shippingAddress["street-address"]},
                  ${shippingAddress["locality"]},
                  ${shippingAddress["region"]}
                  ${shippingAddress["postal-code"]}
                  ${shippingAddress["country-name"]}`
    : "";
}
