import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getBrowserStorage } from "../../../../utils/cookies";
import { updateCartConfiguration } from "app/services/servicesPIM";

const ShipOrderComplete = () => {
  const [shipOrderComplete, setShipOrderComplete] = useState("");
  useEffect(() => {
    async function updateShipOrderComplete() {
      try {
        const { cartUrl } = await JSON.parse(getBrowserStorage("cartData"));

        const res = await updateCartConfiguration(
          {
            completeDelivery: shipOrderComplete,
          },
          "shipping_and_payment"
        );
      } catch (error) {}
    }
    updateShipOrderComplete();
  }, [shipOrderComplete]);

  const handleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setShipOrderComplete("T");
    } else {
      setShipOrderComplete("F");
    }
  };
  return (
    <div className="cart-info-tile">
      <div className="ship-order-complete" tabIndex={0}>
        {"Ship Order Complete"}
        {/* <i class="material-icons">info</i> */}
      </div>
      <span className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="noSplitShip"
          checked={shipOrderComplete === "T"}
          onChange={handleChange}
        />
        <label className="custom-control-label" htmlFor="noSplitShip">
          No split shipments
        </label>
      </span>
    </div>
  );
};

export default ShipOrderComplete;
