export const isString = (val: unknown) =>
  typeof val === "string" || val instanceof String;

export const compareStringsIgnoreCase = (str1: string, str2: string) =>
  str1.toLowerCase() === str2.toLowerCase();

export const compareStringsTrimAndIgnoreCase = (str1: string, str2: string) =>
  isString(str1) &&
  isString(str2) &&
  compareStringsIgnoreCase(str1.trim(), str2.trim());

export const arrayIncludesIgnoreCase = (arr: string[], value: string) =>
  Array.isArray(arr) &&
  arr.some((item) => compareStringsTrimAndIgnoreCase(item, value));

export const isValidHttpUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const removeDomainFromUrlString = (
  urlString: string | undefined,
  replaceWord: string
) => {
  if (urlString == null || !isValidHttpUrl(urlString)) {
    return urlString;
  }
  const url = new URL(urlString);
  const pathWithoutDomain = url.pathname + url.search + url.hash;
  return replaceWord
    ? pathWithoutDomain?.replace(replaceWord, "")
    : pathWithoutDomain;
};

export const replaceOneDoubleQuoteWithTwoSingleQuote = (query: string) =>
  query.replace(/"/g, "''");

export const removeLastSubdirectoryFromUrl = (url: string = "") =>
  url.substring(0, url.lastIndexOf("/"));

export const removeSpacesAndHyphens = (query: string = "") =>
  query.replace(/-|\s/g, "").toLocaleLowerCase();

// Use a regular expression to remove special characters except hyphen (-)
export const removeSpecialCharsExceptHyphen = (inputString: string) =>
  inputString.replace(/[^\w\s-]/g, "");

export const getQueryStringsFromObject = (
  paramsObj: Record<string, string>
) => {
  const params = new URLSearchParams(paramsObj);
  return params.toString();
};
