import React, { useState, Fragment } from "react";
import PriceView from "./PriceView";

const PriceCalculation = ({
  price,
  priceUnit,
  salePrice,
  hideSavingAmount,
  isShoppingCartMobile,
  hideCutAmount=false

}) => {
  const getShowingPrice = () => {
    return salePrice > 0 && price > salePrice ? salePrice : price;
  };

  const getRemovePrice = () => {
    return salePrice > 0 && price > salePrice ? price : 0;
  };

  const getSavingPrice = () => {
    return salePrice > 0 && price > salePrice ? price - salePrice : 0;
  };
  const getDiscountPercent = () => {
    return salePrice > 0 && price > salePrice
      ? (((price - salePrice) / price) * 100).toFixed(0.5)
      : 0;
  };

  return (
    <PriceView
      displayPrice={getShowingPrice()}
      priceUnit={priceUnit}
      savingAmount={getSavingPrice()}
      discountPercentage={getDiscountPercent()}
      hideSavingAmount={hideSavingAmount}
      removePrice={getRemovePrice()}
      isShoppingCartMobile={isShoppingCartMobile}
      hideCutAmount={hideCutAmount}
    />
  );
};

export default PriceCalculation;
