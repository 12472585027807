/* eslint-disable prettier/prettier */
import React from "react";
import $ from "jquery";
import _ from "lodash";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setCartItems,
  updateCartProduct,
  setShippingProdLanding,
  setNotifications,
} from "../../../store/actions/shoppingCart";
import ShippingOption from "./ShippingOption";
import { REQ_HEADER } from "./Tokens";
import availabilityMap from "./mappings/availabilityMap.json";
import { getShippingMethodsList } from "./utils";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import { getNextShipMethod } from "../../common/commonFunc";
import plantsMap from "./mappings/plantsMap.json";
import WillCall from "./WillCall";
import { GET_SHIPPING_OPTIONS_URL } from "./APIs";
import downLineArrowWhite from "../../../assets/img/svg/down_line_arrow_white.svg";
import downLineArrowBlack from "../../../assets/img/svg/down_line_arrow_black_lg.svg";
import upLineArrowGray from "../../../assets/img/svg/up_line_arrow_gray.svg";
import { isMobileOnly } from "react-device-detect";
import Img from "../../common/Img";
import { getDefaultShippTo } from "../../routewise/shopping-cart/utils";

const StockStatus = ({ color, title }) => (
  <span
    style={{
      fontFamily: "Arial",
      fontSize: 16,
      color,
      fontWeight: "700",
    }}
  >
    {title}
  </span>
);

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);
    let willCall = this.props.sku;
    willCall = willCall.replace(".", "");
    willCall = willCall.replace(".", "");

    this.state = {
      willCallSku: willCall,
      shippingData: {},
      product: {},
      changeShippingStatus: {},
      availabilityError: false,
      viewCount: 1,
      ifshowmore: false,
      isLoadingShippingDetails: true,
      showWillCallOption: false,
      willCallSelected: false,
    };
    this.handleSelectWillCall = this.handleSelectWillCall.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shippingOptionsQty !== this.props.shippingOptionsQty) {
      this.componentDidMount();
    }
  }
  setBestShippingOption = (product, selectedOption) => {
    const shipMethod = getShippingMethodsList(
      selectedOption[`shippingMethods_${selectedOption.index}`]
    );
    product.selectedOption = selectedOption;
    product.shippingConfirmed = false;
    product.configuration[`shipping_method_${selectedOption.index}`] =
      shipMethod[0];
    product.optionType = "default";
    product.configuration.hashvalue = selectedOption.hash;
  };

  async componentDidMount() {
    this.setState({ isLoadingShippingDetails: true });
    const { sku, quantity, actions, onProdLanding, onShoppingCart } =
      this.props;
    const product =
      this.props.shoppingCart.cartItems.find((item) => item.sku === sku) || {};
    this.setState({ product });
    const shippingData = product.shippingOptionsData
      ? product.shippingOptionsData
      : product.shippingData;
    if (!_.isEmpty(product) && !onProdLanding) {
      // handle willcall in cart

      this.setState({ shippingData });

      const willCallPlant = product.configuration.will_call_plant;
      if (!_.isEmpty(willCallPlant)) {
        // willCall option selected
        if (
          shippingData.willcallanywhere.hash === product.configuration.hashvalue
        ) {
          // matching hash found

          const selectedOption = shippingData.willcallanywhere;
          this.setState({ willCallSelected: true });
          this.setState({ showWillCallOption: true });
          if (
            product.configuration.plant_1 ||
            product.configuration.plant_2 ||
            product.configuration.plant_3 ||
            product.configuration.plant_4 ||
            product.configuration.plant_5
          ) {
            product.pickupRemaining = true;
          }
          product.selectedOption = selectedOption;
          if (product.editShipping === true) {
            product.shippingConfirmed = false;
          } else {
            product.shippingConfirmed = true;
          }
        } else {
          // when maching hash not found
          const messages = ["The Following Item(s) have inventory adjustments"];
          actions.setNotifications(messages, "inventoryShipping");

          // setting first default option as selected option
          const selectedOption = shippingData.options[0];
          this.setBestShippingOption(product, selectedOption);
        }
      } else {
        // default shipping option selected
        let selectedOption =
          shippingData.options.find(
            (option) => option.hash === product.configuration.hashvalue
          ) || {};

        if (!_.isEmpty(selectedOption)) {
          // if hashvalue available
          product.selectedOption = selectedOption;
          if (product.editShipping === true) {
            product.shippingConfirmed = false;
          } else {
            product.shippingConfirmed = true;
            this.setState({
              isLoadingShippingDetails: false,
            });
          }
        } else {
          // no matching hash for default ship options
          const messages = ["The Following Item(s) have inventory adjustments"];
          actions.setNotifications(messages, "inventoryShipping");

          // setting first default option as selected option
          const selectedOption = shippingData.options[0];
          this.setBestShippingOption(product, selectedOption);
        }
      }
      actions.updateCartProduct(product);
    } else {
      // if product is not in cart
      if (onShoppingCart) {
        const shoppingCartProduct =
          this.props.shoppingCart.cartItems.find((item) => item.sku === sku) ||
          {};
        if (!_.isEmpty(shoppingCartProduct)) {
          shoppingCartProduct.isSetDefaultShipMethod = false;
          actions.updateCartProduct(shoppingCartProduct);
        }
      }

      const shippingData = await this.getShippingData(this.props.quantity, "");
      this.setState({ shippingData });
      const product = {};
      product.sku = await this.props.sku;
      product.shipMethod = shippingData.options[0].shippingMethods_1
        ? getDefaultShippTo(shippingData.options[0])
        : getNextShipMethod(shippingData.options[0]);
      product.status = shippingData.status;
      product.selectedOption = await shippingData.options[0];
      product.hashvalue = await shippingData.options[0].hash;
      product.quantity = parseInt(this.props.quantity);
      product.pickupRemaining = false;
      product.optionType = "default";
      product.xplant = shippingData.xplant;
      await this.props.actions.setShippingProdLanding(product);
    }

    this.setState({
      isLoadingShippingDetails: false,
    });
    if (this.props.checkPendingAvailable) {
      this.props.checkPendingAvailable();
    }
    if (this.props.checkConfirmAvailable) {
      this.props.checkConfirmAvailable();
    }
    this.handleChangeShippingStatus(this.props.shippingProdLanding);
  }

  setDefualtPaymentMethod = async () => {
    const shippingData = await this.getShippingData(this.props.quantity, "");
    this.setState({ shippingData });
    const product = {};
    product.sku = await this.props.sku;
    product.shipMethod = shippingData.options[0].shippingMethods_1
      ? getDefaultShippTo(shippingData.options[0])
      : getNextShipMethod(shippingData.options[0]);
    product.status = shippingData.status;
    product.selectedOption = await shippingData.options[0];
    product.hashvalue = await shippingData.options[0].hash;
    product.quantity = parseInt(this.props.quantity);
    product.pickupRemaining = false;
    product.optionType = "default";
    product.xplant = shippingData.xplant;
    await this.props.actions.setShippingProdLanding(product);
  };

  getShippingData = async (quantity, willCallPlant) => {
    const sku = this.props.sku.replace("/", "\\/");
    $("#btn" + sku).prop("disabled", true);
    let reqItemJson = "";
    if (willCallPlant) {
      reqItemJson = {
        plant: willCallPlant,
        quantity: quantity,
        sku: this.props.sku,
      };
    } else {
      reqItemJson = {
        quantity: quantity,
        sku: this.props.sku,
      };
    }
    const data = { skuidqty: [reqItemJson] };
    try {
      const resShipOptions = await axios({
        method: "POST",
        url: GET_SHIPPING_OPTIONS_URL,
        headers: REQ_HEADER,
        data,
      });
      $("#btn" + sku).prop("disabled", false);
      return resShipOptions.data[0];
    } catch (error) {
      return {};
    }
  };

  handleSelectWillCall(e) {
    if (this.props.setIsChangeQtyError) {
      this.props.setIsChangeQtyError(false);
    }
    const { checked } = e.target;
    this.setState({ showWillCallOption: checked });
    if (this.props.onProdLanding && !this.props.onShoppingCart) {
      // for product variation table
      if (!checked) {
        this.setDefualtPaymentMethod();
      } else {
        const selectedProduct = this.props.shippingProdLanding;
        this.props.actions.setShippingProdLanding(selectedProduct);
      }
    }
    if (this.props.onShoppingCart && !checked) {
      // for shopping cart
      const currentProduct = this.props.shoppingCart.cartItems.find(
        (item) => item.sku === this.props.sku
      );

      currentProduct.selectedOption = null;
      this.props.actions.updateCartProduct(currentProduct);
    }
  }

  handleChangeShippingStatus = (value) => {
    if (value && value.selectedOption) {
      if (value.selectedOption.backOrder === "F") {
        if (
          value.selectedOption.type === "shipDifferentLocation" ||
          value.selectedOption.type === "shipAlternativeBranch" ||
          value.selectedOption.type === "availableAll"
        ) {
          this.setState({ changeShippingStatus: availabilityMap["inStock"] });
        }
      } else if (value.selectedOption.backOrder === "T") {
        if (
          value.selectedOption.type === "shipAlternativeBranch" ||
          value.selectedOption.type === "takeOnHand"
        ) {
          this.setState({
            changeShippingStatus: availabilityMap["limitedStock"],
          });
        } else if (value.selectedOption.type === "backOrderAll") {
          this.setState({
            changeShippingStatus: availabilityMap["Backordered"],
          });
        }
      }
    }
  };

  render() {
    const { shippingData, isLoadingShippingDetails, willCallSelected } =
      this.state;
    const { sku, onProdLanding, onVariations, onShoppingCart } = this.props;
    if (!shippingData) return null;
    const items = [];
    let Shoppingstatus = {};

    if (this.props.shippingProdLanding.status) {
      if (this.props.shippingProdLanding.selectedOption.backOrder === "F") {
        if (
          this.props.shippingProdLanding.selectedOption.type ===
            "shipDifferentLocation" ||
          this.props.shippingProdLanding.selectedOption.type ===
            "shipAlternativeBranch" ||
          this.props.shippingProdLanding.selectedOption.type === "availableAll"
        ) {
          Shoppingstatus = availabilityMap["inStock"];
        }
      } else if (
        this.props.shippingProdLanding.selectedOption.backOrder === "T"
      ) {
        if (
          this.props.shippingProdLanding.selectedOption.type ===
            "shipAlternativeBranch" ||
          this.props.shippingProdLanding.selectedOption.type === "takeOnHand"
        ) {
          Shoppingstatus = availabilityMap["limitedStock"];
        } else if (
          this.props.shippingProdLanding.selectedOption.type === "backOrderAll"
        ) {
          Shoppingstatus = availabilityMap["Backordered"];
        }
      }
    }

    for (var item in plantsMap) {
      items.push(
        <option key={item} value={item}>
          {plantsMap[item]}
        </option>
      );
    }

    // prevent rendering options if "shippingProdLanding" not set
    // if ( onProdLanding && shippingProdLanding.sku !== sku) return null;
    // const renderOptions = Boolean(
    //   !_.isEmpty(product) || !_.isEmpty(shippingProdLanding)
    // );

    const getChangeShippingOption = (option, idx) => {
      return (
        <ShippingOption
          onShoppingCart={onShoppingCart}
          key={idx}
          xplant={shippingData.xplant}
          option={option}
          optionNum={idx}
          sku={sku}
          quantity={this.props.quantity}
          onProdLanding={onProdLanding}
          onVariations={onVariations}
          checkShipingMethodValue={(b) =>
            this.props.checkShipingMethodValue
              ? this.props.checkShipingMethodValue(b)
              : null
          }
          setIsChangeQtyError={this.props.setIsChangeQtyError}
          handleQtyError={this.props.handleQtyError}
          handleChangeShippingStatus={(val) =>
            this.handleChangeShippingStatus(val)
          }
          pageType={"variationPage"}
        />
      );
    };

    const getWillCallOption = () => {
      return (
        <>
          {!this.state.showWillCallOption && (
            <div class="custom-control custom-checkbox setdefault-check mt-3">
              <input
                type="checkbox"
                class="custom-control-input"
                checked={this.state.showWillCallOption}
                id={`selectWillCall${onVariations}-${this.props.sku}`}
                onClick={this.handleSelectWillCall}
              />
              <label
                class="custom-control-label"
                tabIndex={0}
                htmlFor={`selectWillCall${onVariations}-${this.props.sku}`}
              >
                <strong>Will Call Anywhere. </strong>
                Pickup this item from an alternative location.
              </label>
            </div>
          )}
          {this.state.showWillCallOption && (
            <>
              <div class="custom-control custom-checkbox setdefault-check mt-3">
                <input
                  type="checkbox"
                  checked={this.state.showWillCallOption}
                  class="custom-control-input"
                  id={`selectWillCall${onVariations}-${this.props.sku}`}
                  onClick={this.handleSelectWillCall}
                />
                <label
                  class="custom-control-label"
                  tabIndex={0}
                  htmlFor={`selectWillCall${onVariations}-${this.props.sku}`}
                >
                  Uncheck to return to home branch shipping options
                </label>
              </div>
              <WillCall
                onProdLanding={this.props.onProdLanding}
                onVariations={onVariations}
                onShoppingCart={this.props.onShoppingCart}
                willCallData={this.state.shippingData}
                sku={this.props.sku}
                xplant={shippingData.xplant}
                quantity={this.props.quantity}
                onChangeQuantity={this.props.onChangeQuantity}
                handleSelectWillCall={this.handleSelectWillCall}
                addToCartVisible={this.props.addToCartVisible}
                setErrorChangeQty={this.props.setErrorChangeQty}
                willCallSelected={willCallSelected}
                checkShipingMethodValue={this.props.checkShipingMethodValue}
                handleQtyError={this.props.handleQtyError}
                setIsChangeQtyError={this.props.setIsChangeQtyError}
              />
            </>
          )}
        </>
      );
    };

    const getMoreCall = () => {
      return (
        <div>
          {shippingData.options.length > this.state.viewCount &&
            !this.state.ifshowmore && (
              <div className="shipping-view-more viewmore">
                <a
                  tabIndex="0"
                  onClick={() =>
                    this.setState({
                      viewCount: shippingData.options.length,
                      ifshowmore: true,
                    })
                  }
                  onKeyPress={() =>
                    this.setState({
                      viewCount: shippingData.options.length,
                      ifshowmore: true,
                    })
                  }
                >
                  More Options{" "}
                  {this.props.pageType === "product-page" ||
                  this.props.pageType === "edge-banding" ? (
                    <i class="material-icons">keyboard_arrow_right</i>
                  ) : (
                    <i class="material-icons">add</i>
                  )}
                </a>
              </div>
            )}

          {this.state.ifshowmore ? (
            <div className="shipping-view-more viewmore">
              <a tabIndex={0}
                onClick={() =>
                  this.setState({
                    viewCount: 1,
                    ifshowmore: false,
                  })
                }
                onKeyPress={() =>
                  this.setState({
                    viewCount: 1,
                    ifshowmore: false,
                  })
                }
              >
                Less Options{" "}
                {this.props.pageType === "product-page" ||
                this.props.pageType === "edge-banding" ? (
                  <i class="material-icons">keyboard_arrow_left</i>
                ) : (
                  <i class="material-icons">remove</i>
                )}
              </a>
            </div>
          ) : null}
        </div>
      );
    };

    return (
      <div id={"ShippingDetails" + this.state.willCallSku}>
        <ErrorBoundary>
          {shippingData.options && !isLoadingShippingDetails ? (
            <div
              className={
                this.props.pageType === "product-page" ||
                this.props.pageType === "edge-banding"
                  ? "shipping-details-wrapper shipping-details-view-animation"
                  : "shipping-details-wrapper "
              }
            >
              <div
                className={
                  !isMobileOnly &&
                  (this.props.pageType === "product-page" ||
                    this.props.pageType === "edge-banding")
                    ? "shipping-options-list d-flex scroll-wrapper"
                    : ""
                }
              >
                <div
                  className={
                    (this.props.pageType === "product-page" ||
                      this.props.pageType === "edge-banding") &&
                    "first-options-wrapper"
                  }
                >
                  {!this.state.showWillCallOption &&
                    this.props.shoppingCart.cartItems && (
                      <>
                        {!_.isEmpty(this.state.changeShippingStatus) ? (
                          <StockStatus {...this.state.changeShippingStatus} />
                        ) : (
                          <StockStatus {...Shoppingstatus} />
                        )}
                        {shippingData.options.map((option, idx) =>
                          idx < this.state.viewCount && idx == 0
                            ? getChangeShippingOption(option, idx)
                            : null
                        )}
                        {!isMobileOnly &&
                          !this.state.showWillCallOption &&
                          (this.props.pageType === "product-page" ||
                            this.props.pageType === "edge-banding") &&
                          getMoreCall()}
                      </>
                    )}
                  {!isMobileOnly &&
                    (this.props.pageType === "product-page" ||
                      this.props.pageType === "edge-banding") &&
                    getWillCallOption()}
                </div>

                {!this.state.showWillCallOption &&
                  shippingData.options.map((option, idx) =>
                    idx < this.state.viewCount && idx != 0 ? (
                      <div className="sub-options-wrapper">
                        {getChangeShippingOption(option, idx)}
                      </div>
                    ) : null
                  )}

                {isMobileOnly && !this.state.showWillCllOption && (
                  <div>
                    {shippingData.options.length > this.state.viewCount ? (
                      <div className="shipping-view-more viewmore">
                        {!isMobileOnly ? (
                          <button
                            className="btn blue-btn"
                            onClick={() => {
                              this.setState({
                                viewCount: shippingData.options.length,
                              });
                              this.setState({ ifshowmore: true });
                            }}
                          >
                            CHECK OTHER BRANCHES{" "}
                            <Img src={downLineArrowWhite} alt="" />
                          </button>
                        ) : (
                          <button
                            className="btn black-line-btn"
                            onClick={() => {
                              this.setState({
                                viewCount: shippingData.options.length,
                              });
                              this.setState({ ifshowmore: true });
                            }}
                          >
                            CHECK OTHER BRANCHES{" "}
                            <Img src={downLineArrowBlack} alt="" />
                          </button>
                        )}
                      </div>
                    ) : this.state.ifshowmore ? (
                      <div className="shipping-view-more viewmore">
                        <button
                          className="btn gray-btn"
                          onClick={() => this.setState({ viewCount: 1 })}
                        >
                          HIDE OTHER BRANCHES{" "}
                          <Img src={upLineArrowGray} alt="" />
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              {isMobileOnly && getWillCallOption()}
              {!isMobileOnly &&
                !this.state.showWillCallOption &&
                this.props.pageType !== "product-page" &&
                this.props.pageType !== "edge-banding" &&
                getMoreCall()}
              {!isMobileOnly &&
                this.props.pageType !== "product-page" &&
                this.props.pageType !== "edge-banding" &&
                getWillCallOption()}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span className="spinner"></span>
            </div>
          )}
        </ErrorBoundary>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCartItems,
      updateCartProduct,
      setShippingProdLanding,
      setNotifications,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  shippingProdLanding: state.shoppingCart.shippingProdLanding,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingDetails);
