import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { REQ_HEADER } from "../Tokens";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setCartItems } from "../../../../store/actions/shoppingCart";
import { getShippingMethodsList } from "../utils";
import { getBrowserStorage_Cookie } from "../../../../utils/cookies";
import { CURRENT_ADDRESS } from "../../../common/Constants";

const ChangeShippingMethod = ({
  shoppingCart,
  actions,
  checkPendingAvailable,
  homeBranch,
}) => {
  const [shippingMethod, setShippingMethod] = useState("");

  const handleChange = async (e) => {
    const value = await e.target.value;
    await setShippingMethod(value);
    const cartItems = shoppingCart.cartItems.map((item) => {
      if (!item.selectedOption.shippingMethods_1) {
        return item;
      }
      const availShipMethods = getShippingMethodsList(
        item.selectedOption.shippingMethods_1
      );
      if (availShipMethods.includes(value)) {
        item.configuration.shipping_method_1 = value;
        item.shippingConfirmed = false;
        item.editShipping = true;
      }
      return item;
    });
    actions.setCartItems(cartItems);
    if (checkPendingAvailable) checkPendingAvailable();
  };

  const routeCode = getBrowserStorage_Cookie(CURRENT_ADDRESS)[0].route_info
    .route;

  return (
    <div className="cart-info-tile">
      <div className="pt-0 mt-0" tabIndex={0}>{"Change Shipping Method"}</div>
      <span className="text" tabIndex={0}>
        Change shipping method for the item(s) shipping from{" "}
        <strong>home branch: {homeBranch}</strong>
      </span>
      <div className="input-group mb-3 mt-2 pl-0">
        <select
          value={shippingMethod}
          onChange={handleChange}
          className="custom-select"
        >
          <option value="">Choose...</option>

          {!!routeCode && <option value="T">WLAC Truck</option>}

          <option value="G">Ground Shipping</option>
          <option value="W">Will Call</option>
        </select>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeShippingMethod);
