import { REQ_HEADER } from "../Tokens";
import http from "../../../../services/httpService";
import {
  ZOOM_VIEW_ITEMS_CART,
  GET_SHIPPING_OPTIONS_URL,
  CREDIT_CARDS_URL,
  ZOOM_SHIPPING_ADDRESS,
  BILLING_ADDRESSES_API,
} from "../APIs";
import _ from "lodash";
import axios from "axios";
import {
  BACK_ORDER_ALL,
  TRUE,
  MEXICAN_ADDRESS,
} from "../../../common/Constants";
import { getNextShipMethod } from "../../../common/commonFunc";
import { updateCartConfiguration } from "app/services/servicesPIM";
import { store } from "index";

const setAddressValue = async (API) => {
  try {
    const res = await axios.get(API, {
      headers: REQ_HEADER,
    });

    let choice = [];
    let chosen = [];

    chosen.push(res.data);

    return { choice, chosen };
  } catch (error) {}
};

export const serviceLoadItemsEP = (cartUrl) => {
  try {
    const url = cartUrl + ZOOM_VIEW_ITEMS_CART;
    const res = http.get(url, { headers: REQ_HEADER });
    return res;
  } catch (error) {}
};

export const serviceGetShippingOptions = async (itemsList) => {
  try {
    const combinedArr = await itemsList.map((prod) => {
      const reqItemJson = {
        quantity: prod.quantity,
        sku: prod.sku,
      };
      return reqItemJson;
    });

    const data = { skuidqty: combinedArr };
    const res = await http.post(GET_SHIPPING_OPTIONS_URL, data, {
      headers: REQ_HEADER,
    });
    const shippingOptions = await res.data;
    return shippingOptions;
  } catch (error) {}
};

export const serviceFetchCreditCardListEP = async () => {
  const res = await axios.get(CREDIT_CARDS_URL, { headers: REQ_HEADER });
  return res.data.creditcard;
};

export const serviceDeleteCreditCard = async (token, cardType) => {
  const data = `{
    "tocken": "${token}",
    "card_type":"${cardType}"
    }`;
  await axios.delete(CREDIT_CARDS_URL, {
    headers: REQ_HEADER,
    data,
  });
};

export const serviceSetPaymentMethod = async (cartConfig, cartUrl) => {
  try {
    const res = await updateCartConfiguration(
      {
        paymentMethod: cartConfig.paymentMethod,
      },
      "shipping_and_payment"
    );
    return res;
  } catch (error) {}
};

export const serviceGetAddressesOrder = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address`,
      {
        headers: REQ_HEADER,
      }
    );

    const shippingAddressId =
      store.getState()?.shoppingCart?.cartData?.configuration
        ?.shippingAddressId;

    let choice = [];
    let chosen = [];

    if (shippingAddressId) {
      // If shippingAddressId is available get the default address matching it with the
      // address id
      choice = res.data.filter((item) => item.id !== shippingAddressId);
      chosen = res.data.filter((item) => item.id === shippingAddressId);
    } else {
      // If not, fallback to the previous logic
      choice = res.data.filter((item) => !item.default);
      chosen = res.data.filter((item) => item.default);
    }

    return {
      choice,
      chosen,
    };
  } catch (error) {}
};

export const serviceGetBillingAddresses = async () => {
  return setAddressValue(BILLING_ADDRESSES_API);
};
