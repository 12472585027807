export const WURTHLAC_SHOWADDRESS = "wurthlac-showaddress";
export const USER_TOKEN = "userToken";
export const IS_OSR = "isOsr";
export const IS_SINGLE_CHECKOUT = "useSingleCheckout";
export const WURTHLAC = "wurthlac";
export const IS_OSR_CLICK = "isOsrClick";
export const YES = "yes";
export const DEFAULT_PLANT = "defaultplant";
export const ACC_NO = "accNo";
export const SHIP_TO = "shipTo";
export const SELECTED_ADDRESS_DETAILS = "selectedAddressDetails";
export const CURRENT_ADDRESS = "currentAddress";
export const SHIPPING_ADDRESS = "shipping_address";
export const U_TIMEOUT = "u_timeout";
export const PERMISSON = "permisson";
export const CARTTOKEN = "cartToken";
export const PWDTOKEN = "pwd-token";
export const RELOGIN = "RE_LOGIN";
export const IS_LINE_ITEM_PO_EDITOPEN = "isLineItemPoEditOpen";
export const LAX = "lax";
export const ADDRESS_ID = "address-id";
export const TOTAL_QUANTITY = "total-quantity";
export const INVENTORY_SHIPPING = "/inventory-shipping";
export const SINGLE_CHECKOUT_FORM = "/single-checkout-form";
export const TAKEONHAND = "takeOnHand";
export const BACK_ORDER_ALL = "backOrderAll";
export const TRUE = "T";
export const DISCONTINUED = "DL";
export const WILL_BE_DISCONTINUED_DU = "DU";
export const WILL_BE_DISCONTINUED_DV = "DV";
export const AVAILBLE = "available";
export const MATCH = "match";
export const QUANTITY_MULTIPLIER_MATCH = "quantity_multiplier_match";
export const TRUCK = "truck";
export const ROUTER_STR_LENGTH = "9";
export const ACC_NO_COOKIE = 0;
export const SHIP_TO_COOKIE = 1;
export const MAIN_OPT_COOKIE = 2;
export const NO_LONGER_AVAILABLE = "No longer available";
export const INTERNAL_SERVER_ERROR =
  "Sorry your specific request is currently inaccessible. Please contact websupport@wurthlac.com for assistance.";
export const DEFAULT_SHIPTO = "G";
export const DEFAULT_SHIPTO_ERROR = "C";
export const SKU_MATCHED = "sku_matched";
export const NORMAL_SEARCH = "normal_serch";
export const NO_RESULT = "no-result";
export const ADMIN_OSR = "ADMIN";
export const OSR = "OSR";
export const PRODUCT = "Product";
export const CATEGORIES = "Categories";
export const BRANDS = "Brands";
export const REGION = "region";
export const SEARCH_VALUE = "sval";
export const SEARCH_TEXT = "sText";
export const SEARCH_SLUG = "sslug";
export const SELECT_ID = "selectId";
export const PIMSET = "pimSet";
export const PIMACCOUNTSET = "pimSetAccount";
export const SPECIAL_SHIPPING_FLAG = ["LONG", "NOCO", "NOUF", "NOUP", "YLTL"];
export const WEB_DIRECT_FLAG_TRUE = "Y";
export const HAZARDOUS_FLAG_TRUE = "Y";
export const MY_PURCHASE_ITEMS = "myPurchaseItems";
export const ACCEPTABLE_IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"];
export const CATEGORY_PATH_NAME = "catPathName";
export const NEXT_SHIPTO = "G";
export const SEE_MORE = "seeMore";
export const CLICKED_FAVORITE_ITEM = "clickedFavoriteItem";
export const PSWD_REST_ACTIVE_MSG =
  "Password reset email has been successfully sent to user.";
export const PSWD_REST_PENDING_MSG =
  "An email has been sent to the User to  complete thier registration. If they do not receive this email within 15 minutes, please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const PSWD_REST_DEACTIVE_MSG =
  "This User is currently flagged as inactive in the system. please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const PSWD_REST_INACTIVE_MSG =
  "This User is currently flagged as inactive in the system. please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const USER_REGISTER_PENDING_MSG =
  "An email has been sent to you to complete your registration. If you do not receive this email within 15 minutes, please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const USER_REGISTER_DEACTIVE_MSG =
  "This email already exists, but is  currently flagged as inactive in the system.please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const USER_REGISTER_INACTIVE_MSG =
  "This User is currently flagged as inactive in the system.please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const USER_REGISTER_REQUEST_MSG =
  "Your request is already in progress and is awaiting approval from the Key User in your company.Would you like to send another message to them? ";
export const PSWD_REST_LOGIN_DEACTIVE_MSG =
  "Your Profile is Inactive. Please Contact Web Support for more information. Email websupport@wurthlac.com or Call 800-422-4389 x1014.";
export const PSWD_REST_LOGIN_INACTIVE_MSG =
  "Your Profile is Inactive. Please Contact Web Support for more information. Email websupport@wurthlac.com or Call 800-422-4389 x1014.";
export const PSWD_REST_LOGIN_REQUEST_MSG =
  "Your Registration Request has not been approved yet by Key User in your company. Please contact them to approve your request.";
export const PSWD_REST_LOGIN_ACTIVE_MSG =
  "we have sent an email to reset your password. If you’re still experiencing difficulties, please contact us at websupport@wurthlac.com";
export const PSWD_REST_LOGIN_PENDING_MSG =
  "An email has been sent to you to complete your registration. If you do not receive this email within 15 minutes, please contact web support at websupport@wurthlac.com, or call 800-422-4389 x1014.";
export const PASSWORD_FEILD_TYPE_TEXT = "text";
export const PASSWORD_NOT_MATCH = "Password does not match, Please Re Enter";
export const FORBIDDEN_MSG =
  "Not allowed to change password, Please contact web support (800-422-4389 X1014 or websupport@wurthlac.com)";
export const INVALID_SIGNATURE =
  "Link is expired. Please contact web support (800-422-4389 X1014 or websupport@wurthlac.com)";
export const UNEXPECTED_ERROR =
  "Unexpected error, Please contact web support (800-422-4389 X1014 or websupport@wurthlac.com)";
export const PASSWORD_FEILD_TYPE_PASSWORD = "password";
export const IS_LOGIN_PAGE = "isLogin";
export const IS_CONTINUE_BUTTON = "isContinueButton";
export const REMEMBER_ME = "remember-me";
export const MEXICAN_ADDRESS = "MX";

