import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { BACK_ORDER_ALL, TRUE } from "app/components/common/Constants";
import { getNextShipMethod } from "app/components/common/commonFunc";
import { REQ_HEADER } from "app/components/routewise/shopping-cart/Tokens";

const WILLCALL_VAR = "willCall";

type Item = {
  hashvalue: string;
  optionType: string;
  pickupRemaining: boolean;
  quantity: number;
  selectedOption: {
    backOrder: string;
    backOrderDate_1: string;
    backOrderQuantity_1: string;
    hash: string;
    index: string;
    plant_1?: string;
    plant_2?: string;
    plant_3?: string;
    plant_4?: string;
    plant_5?: string;
    shippingMethods_1?: string;
    shippingMethods_2?: string;
    shippingMethods_3?: string;
    shippingMethods_4?: string;
    shippingMethods_5?: string;
    type: string;
    willCallPlant: string;
    willCallQuantity: string;
    quantity_1?: string;
    quantity_2?: string;
    quantity_3?: string;
    quantity_4?: string;
    quantity_5?: string;
  };
  shipMethod: string;
  sku: string;
  status: string;
  xplant: string;
  configuration?: {
    shipping_method_1?: string;
    will_call_plant?: string;
    will_call_avail: string;
  };
  poOrJobName?: string;
};

export const ADD_TO_CART_URL = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/cart`;

// A separate export from the hook so that the function can be used
// in class components
export const addToCartApi = async (itemsList: Item[], url: string) => {
  const itemsConfigList = await itemsList.map((item) => {
    let backOrderAll = "";
    if (item.selectedOption)
      backOrderAll = item.selectedOption.type === BACK_ORDER_ALL ? TRUE : "";
    let shippingMehtod1 = "";
    if (item.configuration && item.configuration.shipping_method_1) {
      shippingMehtod1 = item.configuration.shipping_method_1;
    } else if (item.shipMethod) {
      shippingMehtod1 = item.shipMethod;
    } else {
      shippingMehtod1 = TRUE;
    }

    let willCallPlant = "";
    let willCallQty = "";
    if (item.configuration && item.configuration.will_call_plant) {
      willCallPlant = item.configuration.will_call_plant;
      willCallQty = item.configuration.will_call_avail;
    } else if (item.optionType && item.optionType === WILLCALL_VAR) {
      willCallPlant = item.selectedOption.willCallPlant;
      willCallQty = item.selectedOption.willCallQuantity;
    }

    let hashvalue = "";
    if (item.selectedOption) hashvalue = item.selectedOption.hash || "";

    const itemConfig = {
      code: item.sku.trim().toUpperCase(),
      quantity: item.quantity,
      configuration: {
        avail_1:
          item.selectedOption && item.selectedOption.quantity_1
            ? item.selectedOption.quantity_1
            : "",
        avail_2:
          item.selectedOption && item.selectedOption.quantity_2
            ? item.selectedOption.quantity_2
            : "",
        avail_3:
          item.selectedOption && item.selectedOption.quantity_3
            ? item.selectedOption.quantity_3
            : "",
        avail_4:
          item.selectedOption && item.selectedOption.quantity_4
            ? item.selectedOption.quantity_4
            : "",
        avail_5:
          item.selectedOption && item.selectedOption.quantity_5
            ? item.selectedOption.quantity_5
            : "",
        plant_1:
          item.selectedOption && item.selectedOption.plant_1
            ? item.selectedOption.plant_1
            : "",
        plant_2:
          item.selectedOption && item.selectedOption.plant_2
            ? item.selectedOption.plant_2
            : "",
        plant_3:
          item.selectedOption && item.selectedOption.plant_3
            ? item.selectedOption.plant_3
            : "",
        plant_4:
          item.selectedOption && item.selectedOption.plant_4
            ? item.selectedOption.plant_4
            : "",
        plant_5:
          item.selectedOption && item.selectedOption.plant_5
            ? item.selectedOption.plant_5
            : "",
        poOrJobName: item.poOrJobName || "",
        shipping_method_1: shippingMehtod1,
        shipping_method_2:
          item.selectedOption && item.selectedOption.shippingMethods_2
            ? getNextShipMethod(
                item.selectedOption,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                item.selectedOption.shippingMethods_2
              )
            : "",
        shipping_method_3:
          item.selectedOption && item.selectedOption.shippingMethods_3
            ? getNextShipMethod(
                item.selectedOption,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                item.selectedOption.shippingMethods_3
              )
            : "",
        shipping_method_4:
          item.selectedOption && item.selectedOption.shippingMethods_4
            ? getNextShipMethod(
                item.selectedOption,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                item.selectedOption.shippingMethods_4
              )
            : "",
        shipping_method_5:
          item.selectedOption && item.selectedOption.shippingMethods_5
            ? getNextShipMethod(
                item.selectedOption,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                item.selectedOption.shippingMethods_5
              )
            : "",
        will_call_avail: willCallQty,
        will_call_plant: willCallPlant,
        hashvalue: hashvalue,
        selectedOption: "",
        backorder_all: backOrderAll,
      },
    };
    return itemConfig;
  });

  const response = await axios.post<"OK">(
    url,
    {
      "configurable-items": itemsConfigList,
    },
    { headers: REQ_HEADER }
  );

  return response;
};

const useAddToCartMutation = () => {
  //   const url = useAppSelector(
  //     (state) => state?.shoppingCart?.cartData?.bulkOrderUrl
  //   ) as string;
  const url = ADD_TO_CART_URL;

  return useMutation({
    mutationKey: ["add-to-cart"],
    mutationFn: (itemsList: Item[]) => addToCartApi(itemsList, url),
  });
};

export default useAddToCartMutation;
