/* eslint-disable prettier/prettier */
import React from "react";
import { GET_SHIPPING_OPTIONS_URL } from "./APIs";
import {
  getDefaultWillCallPlant,
  setDefaultWillCallPlant,
} from "./services/servicesPIM.new";
import axios from "axios";
import { REQ_HEADER } from "./Tokens";
import plantsMap from "./mappings/plantsMap.json";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setCartItems,
  updateCartProduct,
  setShippingProdLanding,
} from "../../../store/actions/shoppingCart";
import $ from "jquery";
import _ from "lodash";
import { serviceUpdateItemsPIM } from "./services/servicesPIM";
import { priceCheckApi } from "app/hooks/check/usePriceCheckMutation.hook";
import moment from "moment";
import ShippingMethodSelect from "./common/ShippingMethodSelect";
import { fetchOrderPrice } from "./commonFunc";
import { setDefaultWillCallBranch } from "../../../store/actions/profile";

import { getBrowserStorage } from "../../../utils/cookies";
import { isMobileOnly } from "react-device-detect";

const initState = {
  loading: false,
  willCallData: {},
  willCallLocation: "",
  willCallSelected: false,
  willCallPlants: [],
  defaultBranchSelected: false,
  homePlant: "",
  shipRemainStock: false,
  availableOptions: {},
  displayYourShippingChangedMsg: false,
  showQtyChangeMsg: false,
  defaultWillCallPlant: "",
};

let mounted = true;

class WillCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentWillUnmount() {
    mounted = false;
  }

  async componentDidMount() {
    mounted = true;
    const { cartItems } = this.props.shoppingCart;
    const product = cartItems.find((i) => i.sku === this.props.sku) || {};
    const prod = Object.assign({}, product);
    if (!_.isEmpty(prod) && !this.props.onProdLanding) {
      if (!_.isEmpty(prod.configuration.will_call_plant)) {
        this.setState({ willCallLocation: prod.configuration.will_call_plant });

        await this.setState({
          willCallData: this.props.willCallData.willcallanywhere,
          availableOptions: this.props.willCallData,
        });
        prod.selectedOption = this.state.willCallData;
      }

      // persist selection on pickup remain on edit
      if (
        prod.selectedOption.plant_1 ||
        prod.selectedOption.plant_2 ||
        prod.selectedOption.plant_3 ||
        prod.selectedOption.plant_4 ||
        prod.selectedOption.plant_5
      ) {
        const e = { target: { checked: true } };
        this.setShipRemainStock(e);
      }
      // if (prod.pickupRemaining)
      // this.setShipRemainStock(e);
    }
    const defaultPlat = getBrowserStorage("defaultplant");
    let removePlants = [defaultPlat];
    if (process.env.REACT_APP_REMOVE_PLANTS_FROM_WILLCALL) {
      process.env.REACT_APP_REMOVE_PLANTS_FROM_WILLCALL.split(",").map(
        (plants) => {
          removePlants.push(plants);
        }
      );
    }

    this.setState({ homePlant: defaultPlat });
    const willCallPlantsOmited = _.omit(plantsMap, removePlants);
    const willCallPlants = Object.entries(willCallPlantsOmited).map(
      (plant) => plant[0]
    );

    this.setState({ willCallPlants });

    // above new
    const response = await getDefaultWillCallPlant();

    if (response.plant) {
      const defaultWillCallPlant = response.plant;

      if (!_.isEmpty(defaultWillCallPlant)) {
        this.setState({
          // willCallLocation: defaultWillCallPlant,
          defaultWillCallPlant,
          defaultBranchSelected: true,
          displayYourShippingChangedMsg: true,
        });

        const e = { target: { value: defaultWillCallPlant } };
        if (!this.props.willCallSelected) this.handleLocationSelect(e);
      }
    }
  }

  getWillCallOption = async (sku, quantity, plant) => {
    const json = {
      quantity: quantity,
      plant: plant,
      sku: sku,
    };
    const data = { skuidqty: [json] };

    try {
      const res = await axios({
        method: "POST",
        url: GET_SHIPPING_OPTIONS_URL,
        headers: REQ_HEADER,
        data,
      });

      this.setState({
        willCallData: res.data[0].willcallanywhere,
        availableOptions: res.data[0],
      });
    } catch (error) {}
  };

  handleWillCallSelect = async (e) => {
    if (mounted) {
      if (this.props.setIsChangeQtyError) {
        this.props.setIsChangeQtyError();
      }
      const { checked } = e.target;
      this.setState({ showQtyChangeMsg: false });

      if (checked) {
        const product =
          this.props.shoppingCart.cartItems.find(
            (i) => i.sku === this.props.sku
          ) || {};
        if (product && !this.props.onProdLanding) {
          product.selectedOption = this.state.willCallData;
          product.configuration.hashvalue = this.state.willCallData.hash;
          product.optionType = "willCall";
          product.configuration.avail_1 = "";
          product.configuration.avail_2 = "";
          product.configuration.avail_3 = "";
          product.configuration.avail_4 = "";
          product.configuration.avail_5 = "";
          product.configuration.plant_1 = "";
          product.configuration.plant_2 = "";
          product.configuration.plant_3 = "";
          product.configuration.plant_4 = "";
          product.configuration.plant_5 = "";
          product.configuration.backorder_all = "";
          product.configuration.will_call_avail =
            this.state.willCallData.willCallQuantity || "";
          product.configuration.will_call_plant =
            this.state.willCallData.willCallPlant || "";
          product.pickupRemaining = true;
          this.props.actions.updateCartProduct(product);
        } else {
          if (this.props.onShoppingCart) {
            const newItem2 = this.props.shoppingCart.cartItems.find(
              (e) => e.sku === this.props.sku
            );

            let qty;

            if (e.target.quantity) {
              qty = e.target.quantity;
            } else {
              qty = this.props.quantity;
            }

            if (
              Number(this.state.willCallData.willCallQuantity) === Number(qty)
            ) {
              newItem2.quantity = qty;
              newItem2.selectedOption = this.state.willCallData;
              newItem2.status = this.state.willCallData.status;

              newItem2.hashvalue = this.state.willCallData.hash;
              newItem2.pickupRemaining = false;
              newItem2.optionType = "willCall";
              newItem2.showQtyChangeMsg = false;

              this.props.handleQtyError(false);

              await this.props.actions.updateCartProduct(newItem2);

              const { cartItems } = this.props.shoppingCart;
              await serviceUpdateItemsPIM(cartItems);
              fetchOrderPrice();
            } else {
              newItem2.showQtyChangeMsg = true;
              this.props.actions.updateCartProduct(newItem2);
              this.setState({
                showQtyChangeMsg: true,
              });

              this.props.handleQtyError(true);
            }
          } else {
            const product = this.props.shippingProdLanding;
            product.sku = this.props.sku;
            product.quantity = this.props.quantity;
            product.selectedOption = this.state.willCallData;
            product.status = this.state.willCallData.status;

            product.hashvalue = this.state.willCallData.hash;
            product.pickupRemaining = false;
            product.optionType = "willCall";
            this.props.actions.setShippingProdLanding(product);
          }
        }
      } else {
        const product =
          this.props.shoppingCart.cartItems.find(
            (i) => i.sku === this.props.sku
          ) || {};
        if (product && !this.props.onProdLanding) {
          product.selectedOption = {};
          product.optionType = "";
          product.configuration.hashvalue = "";
          product.configuration.will_call_avail = "";
          product.configuration.will_call_plant = "";
          product.configuration.avail_1 = "";
          product.configuration.avail_2 = "";
          product.configuration.avail_3 = "";
          product.configuration.avail_4 = "";
          product.configuration.avail_5 = "";
          product.configuration.plant_1 = "";
          product.configuration.plant_2 = "";
          product.configuration.plant_3 = "";
          product.configuration.plant_4 = "";
          product.configuration.plant_5 = "";
          product.configuration.backorder_all = "";
          product.pickupRemaining = false;
          product.optionType = "";
          this.props.actions.updateCartProduct(product);
        } else {
          const product = this.props.shippingProdLanding;
          product.sku = this.props.sku;
          product.quantity = this.props.quantity;
          product.selectedOption = this.state.willCallData;
          product.hashvalue = this.state.willCallData.hash;
          product.pickupRemaining = false;
          product.optionType = "willCall";
          this.props.actions.setShippingProdLanding(product);
        }
      }
    }
  };

  handleLocationSelect = async (e) => {
    const { value } = e.target;
    this.setState({ showQtyChangeMsg: false });
    await this.setState({ willCallLocation: value });
    await this.getWillCallOption(this.props.sku, this.props.quantity, value);
    if (this.state.defaultWillCallPlant === value)
      this.setState({ defaultBranchSelected: true });
    else this.setState({ defaultBranchSelected: false });

    if (this.state.willCallData.status === "notAvailable") {
      if (!this.props.onProdLanding) {
        const product =
          this.props.shoppingCart.cartItems.find(
            (i) => i.sku === this.props.sku
          ) || {};
        product.selectedOption = {};
        product.optionType = "";
        product.configuration.hashvalue = "";
        product.configuration.will_call_avail = "";
        product.configuration.will_call_plant = "";
        product.configuration.avail_1 = "";
        product.configuration.avail_2 = "";
        product.configuration.avail_3 = "";
        product.configuration.avail_4 = "";
        product.configuration.avail_5 = "";
        product.configuration.plant_1 = "";
        product.configuration.plant_2 = "";
        product.configuration.plant_3 = "";
        product.configuration.plant_4 = "";
        product.configuration.plant_5 = "";
        product.configuration.backorder_all = "";
        product.pickupRemaining = false;
        product.optionType = "";
        this.props.actions.updateCartProduct(product);
      } else if (this.props.onProdLanding) {
        const product = this.props.shippingProdLanding;
        product.sku = this.props.sku;
        product.quantity = this.props.quantity;
        product.selectedOption = {};
        product.hashvalue = "";
        product.pickupRemaining = false;
        product.optionType = "willCall";
        this.props.actions.setShippingProdLanding(product);

        if (this.props.onShoppingCart) {
          const newItem2 = this.props.shoppingCart.cartItems.find(
            (e) => e.sku === this.props.sku
          );

          newItem2.selectedOption = null;
          newItem2.hashvalue = "";
          newItem2.showQtyChangeMsg = true;
          // set show Qty error

          this.props.handleQtyError(true);
          this.props.actions.updateCartProduct(newItem2);
        }
      }
    } else {
      const checkWillCall = { target: { checked: true } };
      await this.handleWillCallSelect(checkWillCall);
      // const checkShipRemain = { target: { checked: true } };
      // this.setShipRemainStock(checkShipRemain);
    }
  };

  handleDefaultBranch = async (e) => {
    const { checked } = e.target;
    this.setState({
      defaultBranchSelected: checked,
    });
    if (checked) {
      const res = await setDefaultWillCallPlant(this.state.willCallLocation);
      if (res?.status >= 200 && res?.status < 300)
        this.props.actions.setDefaultWillCallBranch(
          this.state.willCallLocation
        );
      else this.props.actions.setDefaultWillCallBranch("");
    } else {
      this.setState({ displayYourShippingChangedMsg: false });
      setDefaultWillCallPlant("");
      this.props.actions.setDefaultWillCallBranch("");
    }
  };

  remainingStockLable = (willCallData) => {
    if (willCallData.index && willCallData.index === "1") {
      return (
        <div>
          {"Want to pickup remaining stock from your home branch? "}
          {/* <strong>{plantsMap[this.state.homePlant]}</strong> */}
        </div>
      );
    } else {
      return (
        <div>
          {"Remaining stock will be shipping from an alternative location"}
          {/* <strong>
            {plantsMap[willCallData[`plant_${willCallData.index}`]]}
          </strong> */}
        </div>
      );
    }
  };

  setShipRemainStock = (e) => {
    const { checked } = e.target;

    this.setState({ shipRemainStock: checked });
    if (checked) {
      this.setState({ showQtyChangeMsg: false });
      if (this.props.onShoppingCart) {
        const newItem2 = this.props.shoppingCart.cartItems.find(
          (e) => e.sku === this.props.sku
        );

        newItem2.quantity = this.props.quantity;
        newItem2.selectedOption = this.state.willCallData;
        newItem2.status = this.state.willCallData.status;

        newItem2.hashvalue = this.state.willCallData.hash;
        newItem2.pickupRemaining = false;
        newItem2.optionType = "willCall";
        newItem2.showQtyChangeMsg = false;

        this.props.handleQtyError(false);

        this.props.actions.updateCartProduct(newItem2);
      }

      const product =
        this.props.shoppingCart.cartItems.find(
          (i) => i.sku === this.props.sku
        ) || {};
      if (product && !this.props.onProdLanding) {
        product.pickupRemaining = true;
        product.configuration[`plant_${this.state.willCallData.index}`] =
          this.state.willCallData[`plant_${this.state.willCallData.index}`] ||
          "";

        this.props.actions.updateCartProduct(product);
      } else {
        const product = Object.assign({}, this.props.shippingProdLanding);
        product.pickupRemaining = true;

        this.props.actions.setShippingProdLanding(product);
      }
    } else {
      this.setState({ showQtyChangeMsg: true });
      const product =
        this.props.shoppingCart.cartItems.find(
          (i) => i.sku === this.props.sku
        ) || {};
      if (this.props.onShoppingCart) {
        const newItem2 = this.props.shoppingCart.cartItems.find(
          (e) => e.sku === this.props.sku
        );

        newItem2.showQtyChangeMsg = true;

        this.props.handleQtyError(true);

        this.props.actions.updateCartProduct(newItem2);
      }
      if (product && !this.props.onProdLanding) {
        product.configuration.plant_1 = "";
        product.configuration.plant_2 = "";
        product.configuration.plant_3 = "";
        product.configuration.plant_4 = "";
        product.configuration.plant_5 = "";
        product.configuration.avail_1 = "";
        product.configuration.avail_2 = "";
        product.configuration.avail_3 = "";
        product.configuration.avail_4 = "";
        product.configuration.avail_5 = "";
        product.pickupRemaining = false;

        this.props.actions.updateCartProduct(product);
      } else {
        const product = Object.assign({}, this.props.shippingProdLanding);
        product.pickupRemaining = false;

        this.props.actions.setShippingProdLanding(product);
      }
    }
  };

  handleQtyChange = async (quantity) => {
    this.setState({ showQtyChangeMsg: false });
    if (this.props.handleQtyError) {
      this.props.handleQtyError(false);
    }
    const { cartItems } = this.props.shoppingCart;
    const product = cartItems.find((i) => i.sku === this.props.sku) || {};
    const item = Object.assign({}, product);
    if (!_.isEmpty(product) && !this.props.onProdLanding) {
      // if product in cart
      item.quantity = quantity;
      const data = await priceCheckApi(this.props.sku, this.props.quantity);
      const unitPrice = data["list-sku-price"][0].price;
      item.unitPrice = unitPrice;
      this.props.actions.updateCartProduct(item);
      await serviceUpdateItemsPIM(this.props.shoppingCart.cartItems);
      fetchOrderPrice();
      await this.getWillCallOption(
        this.props.sku,
        quantity,
        this.state.willCallData.willCallPlant
      );
      const e = { target: { checked: true } };

      this.handleWillCallSelect(e);
    } else if (this.props.onProdLanding) {
      // handle change on prod landing
      const e = { target: { value: quantity } };
      await this.props.onChangeQuantity(e);

      await this.getWillCallOption(
        this.props.sku,
        quantity,
        this.state.willCallData.willCallPlant
      );
      const ew = { target: { checked: true, quantity } };
      this.handleWillCallSelect(ew);
      this.props.addToCartVisible(true);

      $("#txt" + this.props.sku).val(quantity);
    }
    if (this.props.setErrorChangeQty) this.props.setErrorChangeQty(false);
  };

  pickupRemainingDetails = (availableOptions) => {
    const willCallData = availableOptions.willcallanywhere;
    return (
      <div>
        <div className="item-count">
          <div>
            {willCallData.index &&
            willCallData.plant_1 &&
            willCallData.index === "1" ? (
              <div>
                <span style={{ marginRight: 5 }}>
                  {plantsMap[willCallData.plant_1]}
                </span>

                <ShippingMethodSelect
                  shippingMethods={
                    willCallData[`shippingMethods_${willCallData.index}`]
                  }
                  xplant={this.props.xplant}
                  shippingProdLanding={this.props.shippingProdLanding}
                  sku={availableOptions.sku}
                  cartItems={this.props.shoppingCart.cartItems}
                  checkShipingMethodValue={this.props.checkShipingMethodValue}
                />
              </div>
            ) : null}
            {willCallData.index && (
              // willCallData.index !== "1" && ( // removed due to bug raised on ticket-
              <div>
                {willCallData.index !== "1" && (
                  <>
                    <span style={{ marginRight: 5 }}>
                      {plantsMap[willCallData[`plant_${willCallData.index}`]]}
                    </span>
                    {"|"}
                    <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                      {"Ground Shipping "}
                    </span>
                  </>
                )}
                {willCallData[`quantity_${willCallData.index}`] && (
                  <div className="font-weight-bold">
                    {willCallData[`quantity_${willCallData.index}`] &&
                      willCallData[`quantity_${willCallData.index}`]}{" "}
                    <span style={{ color: "#c3cf23" }}>Available</span> now
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {willCallData.backOrder === "T" ? (
          <div className="backorder-date-wrapper">
            {willCallData[`backOrderQuantity_${willCallData.index}`]} Backorder,{" "}
            {moment(
              new Date(willCallData[`backOrderDate_${willCallData.index}`])
            ).format("dddd, MMM. D, YY")}
          </div>
        ) : null}
      </div>
    );
  };

  renderShippingDetails = (willCallData) => {
    if (willCallData.status === "notInStock") {
      return (
        <div>
          <h4 role="heading" aria-level="3" tabIndex={0} style={{ color: "#c00", fontSize: 15 }}>{"Not In Stock"}</h4>
          {willCallData.willCallBackOrder ? (
            <div className="backorder-date-wrapper" tabIndex={0}>
              {willCallData.willCallQuantity} Backorder,{" "}
              {moment(new Date(willCallData.willCallBackOrder)).format(
                "dddd, MMM. D, YY"
              )}
              <p className={isMobileOnly ? "mob-notice-box" : "notice-box"} tabIndex={0}>
                Dates are subject to change by suppliers without notice.
              </p>
            </div>
          ) : null}
          <hr />
          {this.uncheckToSeeMoreMsg()}
        </div>
      );
    } else if (willCallData.status === "notAvailable") {
      return (
        <div>
          <h4 style={{ color: "#c00", fontSize: 15 }}>{"Not Available"}</h4>
          {this.uncheckToSeeMoreMsg()}
        </div>
      );
    } else if (willCallData.status === "limitedStock") {
      return (
        <div>
          <div>
            <div id="limitedStock" className="limit-stock-wrapper">
              <span className="blue-text color-text">Limited stock</span>
              {this.state.willCallData.willCallQuantity && (
                <div className="available-item-count">
                  {this.state.willCallData.willCallQuantity}
                  <strong className="green-text color-text">Available</strong>
                  now for pickup
                  {!this.state.shipRemainStock && (
                    <button
                      className="btn blue-btn"
                      onClick={() =>
                        this.handleQtyChange(
                          parseInt(this.state.willCallData.willCallQuantity)
                        )
                      }
                    >
                      Change Qty to {this.state.willCallData.willCallQuantity}
                    </button>
                  )}
                </div>
              )}
            </div>
            {willCallData.index && willCallData.index !== "1" ? (
              <div
                style={{
                  fontWeight: 600,
                  color: "#c00",
                  fontSize: 15,
                  fontFamily: "Arial",
                  marginBottom: 10,
                }}
              >
                <hr style={{ margin: 10 }} />
                This item is not stocked in {plantsMap[this.state.homePlant]}
              </div>
            ) : null}
            <div className="ship-remaining-stock">
              {this.state.willCallData.index && (
                <div className="custom-control custom-checkbox">
                  <input
                    onClick={this.setShipRemainStock}
                    type="checkbox"
                    className="custom-control-input"
                    id="pickup"
                    checked={this.state.shipRemainStock}
                  />
                  <label className="custom-control-label" for="pickup">
                    {this.remainingStockLable(willCallData)}
                  </label>
                </div>
              )}
              {this.state.shipRemainStock &&
                this.pickupRemainingDetails(this.state.availableOptions)}
            </div>
          </div>
        </div>
      );
    } else if (willCallData.status === "inStock") {
      return (
        <div className="item-count font-weight-bold">
          {willCallData.willCallQuantity}{" "}
          <span style={{ color: "#c3cf23" }}>Available</span> now for pick up
        </div>
      );
    }
  };

  uncheckToSeeMoreMsg = () => (
    <div className="not-a-msg mt-2">
      Uncheck <strong>Will Call Anywhere</strong> to see more stock availability
      options.
    </div>
  );

  render() {
    let selectedOption;
    if (!this.props.onProdLanding) {
      const item =
        this.props.shoppingCart.cartItems.find(
          (i) => i.sku === this.props.sku
        ) || {};
      selectedOption = item.selectedOption;
    } else {
      if (this.props.onShoppingCart) {
        const item =
          this.props.shoppingCart.cartItems.find(
            (i) => i.sku === this.props.sku
          ) || {};
        selectedOption = item.selectedOption;
      } else {
        const item = this.props.shippingProdLanding || {};

        selectedOption = item.selectedOption;
      }
    }
    let willCallSelected = false;
    if (
      !!this.state.willCallData &&
      !!selectedOption &&
      selectedOption.hash &&
      selectedOption.hash === this.state.willCallData.hash
    ) {
      willCallSelected = true;
    }

    const { willCallPlants, willCallLocation, willCallData } = this.state;
    return (
      <div className="wil-call-wrapper clearfix">
        {this.state.displayYourShippingChangedMsg && (
          <div style={{ color: "#c00", display: "flex" }}>
            <i class="material-icons" style={{ width: 20 }}>
              info_outlined
            </i>{" "}
            <p style={{ paddingLeft: 5, color: "#c00", fontSize: 15 }} tabIndex={0}>
              <strong>Your shipping option has changed</strong>, Uncheck default
              settings to view more stock availability options.
            </p>
          </div>
        )}
        <div className="willcall willcall-list clearfix shadow-on-white">
          <div class="custom-control custom-radio willcall-check-main" tabIndex={0}>
            <input
              type="radio"
              id={`willCallSelect-${this.props.sku}`}
              class="custom-control-input"
              onClick={this.handleWillCallSelect}
              checked={willCallSelected}
              disabled
            />
            <label
              class="custom-control-label"
              for={`willCallSelect-${this.props.sku}`}
            />
          </div>

          <label>
            <i class="material-icons">store</i> Will Call Anywhere
          </label>
          <span tabIndex={0}>Pickup this item from any of our branch locations.</span>

          {/* select will call location */}
          <div className="store-select-wrapper">
            <label tabIndex={0}>Select a will call location</label>
            <select
              className="form-control select-box"
              onChange={this.handleLocationSelect}
              value={willCallLocation}
            >
              <option disabled value="" selected>
                Select a Will Call location{" "}
              </option>
              {willCallPlants &&
                willCallPlants.map((plantId) => (
                  <option key={plantId} value={plantId}>
                    {plantsMap[plantId]}
                  </option>
                ))}
            </select>
            <span tabIndex={0}>Stock availability is subject to the selected location</span>

            <div class="custom-control custom-checkbox setdefault-check">
              <input
                type="checkbox"
                checked={this.state.defaultBranchSelected}
                class="custom-control-input"
                id="setDefaultBranch"
                onClick={this.handleDefaultBranch}
              />
              <label class="custom-control-label" for="setDefaultBranch" tabIndex={0}>
                Set this branch as default
              </label>
            </div>
          </div>

          {this.state.showQtyChangeMsg && (
            <div class="alert alert-error clearfix" role="alert" tabIndex={0}>
              <div class="alert-icon">
                <i class="material-icons">error_outline</i>
              </div>
              <div class="alert-text ">
                <h6 tabIndex={0}>Warning!</h6>
                <p tabIndex={0}>Please select change quantity or pickup remaining</p>
              </div>
            </div>
          )}

          {/* bottom stock message */}
          {willCallData && (
            <div>{this.renderShippingDetails(willCallData)}</div>
          )}

          {this.state.loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCartItems,
      updateCartProduct,
      setShippingProdLanding,
      setDefaultWillCallBranch,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
  shippingProdLanding: state.shoppingCart.shippingProdLanding,
});

export default connect(mapStateToProps, mapDispatchToProps)(WillCall);
