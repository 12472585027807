import _ from "lodash";
import paymentTypesMap from "../../routewise/shopping-cart/mappings/paymentTypesMap.json";
import shippingMethodsMap from "../../routewise/shopping-cart/mappings/shippingMethodsMap.json";

export const TriggerEventViewItem = (
  event,
  productData = {},
  priceData = {}
) => {
  window.dataLayer = window.dataLayer || [];
  const item_qty = productData.selected_item
    ? parseInt(productData.selected_item.txt_min_order_amount)
    : 1;

  switch (event) {
    case "view_item":
      window.dataLayer.push({
        event,
        value: (productData.selected_item.override_price &&
        productData.selected_item.override_price < priceData.extended
          ? productData.selected_item.override_price * item_qty
          : priceData.extended
        ).toFixed(2),
        currency: "USD",
        ecommerce: {
          items: [
            {
              item_name: productData.page_title,
              item_id: productData.selected_item.txt_sap,
              price: (productData.selected_item.override_price &&
              productData.selected_item.override_price < priceData.extended
                ? productData.selected_item.override_price / item_qty
                : priceData.extended / item_qty
              ).toFixed(2),
              item_brand: productData.brand_name,
              quantity: item_qty,
            },
          ],
        },
      });
      break;
    case "view_item_logout":
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_name: productData.page_title,
              item_id: productData.selected_item.txt_sap,
              item_brand: productData.brand_name,
              quantity: item_qty,
            },
          ],
        },
      });
      break;
    default:
      return false;
  }
};

export const TriggerEventPurchase = (
  priceDetails,
  cartItems,
  cartData,
  orderNumber
) => {
  const TempCartItem = [];
  cartItems.map((item) => {
    const newItem = {
      item_name: item.name,
      item_id: item.sap_id,
      price: (item.extended / parseInt(item.quantity)).toFixed(2),
      item_brand: item.brand_name,
      quantity: item.quantity,
    };
    TempCartItem.push(newItem);
  });

  if (TempCartItem.length > 0 && priceDetails) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "USD",
        value: priceDetails.net.toFixed(2),
        tax: priceDetails.tax.toFixed(2),
        shipping: priceDetails.shippingcost.toFixed(2),
        transaction_id: orderNumber,
        coupon: !_.isEmpty(cartData.configuration.coupon)
          ? cartData.configuration.coupon
          : null,
        items: TempCartItem,
        is_osr_purchase: !_.isEmpty(cartData.configuration.osr),
        purchase_entered_by_osr: !_.isEmpty(cartData.configuration.osr)
          ? cartData.configuration.osr
          : null,
      },
    });
  }
};

export const TriggerEventViewCart = (TempCartItem, orderPrice) => {
  if (orderPrice && TempCartItem) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "USD",
        value: orderPrice.net.toFixed(2),
        items: TempCartItem,
      },
    });
  }
};

export const TriggerEventAddPayment = (paymentMethod, shoppingCart) => {
  const { cartData } = shoppingCart;
  const { cartItems } = shoppingCart;
  const orderPrice = shoppingCart.orderPrice ? shoppingCart.orderPrice.net : 0;

  const TempCartItem = [];
  if (cartItems && cartItems.length > 0) {
    cartItems.map((item) => {
      if (item.extended && item.extended > 0) {
        const newItem = {
          item_name: item.name,
          item_id: item.sap_id,
          price: (item.extended / parseInt(item.quantity)).toFixed(2),
          item_brand: item.brand_name,
          quantity: item.quantity,
        };
        TempCartItem.push(newItem);
      }
    });
  }

  if (cartItems.length == TempCartItem.length) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: "USD",
        value: orderPrice.toFixed(2),
        coupon: !_.isEmpty(cartData.configuration.coupon)
          ? cartData.configuration.coupon
          : null,
        payment_type: paymentTypesMap[paymentMethod],
        items: TempCartItem,
      },
    });
  }
};

export const TriggerEventShippingInfo = (shoppingCart) => {
  const { cartData } = shoppingCart;
  const { cartItems } = shoppingCart;
  const orderPrice = shoppingCart.orderPrice ? shoppingCart.orderPrice.net : 0;
  const shippingTier =
    cartData && cartData.configuration
      ? cartData.configuration.newShippingMethod
        ? cartData.configuration.newShippingMethod
        : cartData.configuration.ship_via
      : "";
  const TempCartItem = [];
  if (cartItems && cartItems.length > 0) {
    cartItems.map((item) => {
      if (item.extended && item.extended > 0) {
        const newItem = {
          item_name: item.name,
          item_id: item.sap_id,
          price: (item.extended / parseInt(item.quantity)).toFixed(2),
          item_brand: item.brand_name,
          quantity: item.quantity,
        };
        TempCartItem.push(newItem);
      }
    });
  }
  if (cartItems.length == TempCartItem.length) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: "USD",
        value: orderPrice.toFixed(2),
        coupon: !_.isEmpty(cartData.configuration.coupon)
          ? cartData.configuration.coupon
          : null,
        shipping_tier: shippingMethodsMap[shippingTier].displayName,
        items: TempCartItem,
      },
    });
  }
};

export const TriggerEventRemoveCartItems = (cartItems, shoppingCart) => {
  const { cartData } = shoppingCart;
  let orderPrice = shoppingCart.orderPrice ? shoppingCart.orderPrice.net : 0;
  const TempCartItem = [];
  if (cartItems && cartItems.length > 0) {
    if (cartItems.length === 1) {
      orderPrice = cartItems[0].extended;
    }
    cartItems.map((item) => {
      if (item.extended && item.extended > 0) {
        const newItem = {
          item_name: item.name,
          item_id: item.sap_id,
          price: (item.extended / parseInt(item.quantity)).toFixed(2),
          item_brand: item.brand_name,
          quantity: item.quantity,
        };
        TempCartItem.push(newItem);
      }
    });
  }
  if (cartItems.length == TempCartItem.length) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "USD",
        value: orderPrice.toFixed(2),
        items: TempCartItem,
      },
    });
  }
};

export const TriggerReset = () => {
  window.dataLayer.push(function () {
    this.reset();
  });
};

export const TriggerEventAddCartItems = (items) => {
  const cartItems = items;
  let netPrice = 0;
  const TempCartItem = [];
  if (cartItems && cartItems.length > 0) {
    cartItems.map((item) => {
      if (item.extended && item.extended > 0) {
        const overridePrice = parseFloat(
          item.sale_price && item.price > item.sale_price
            ? item.sale_price
            : item.price
        );
        const newItem = {
          item_name: item.name,
          item_id: item.sap_id,
          price: overridePrice.toFixed(2),
          item_brand: item.brand_name,
          quantity: item.quantity,
        };
        TempCartItem.push(newItem);
        const net =
          item.txt_uom == item.priceUnit
            ? parseFloat(item.quantity * overridePrice)
            : item.extended;
        netPrice += net;
      }
    });
  }
  if (cartItems.length == TempCartItem.length) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: netPrice.toFixed(2),
        items: TempCartItem,
      },
    });
  }
};

export const TriggerEventAddToCartFrom = (page) => {
  // add trigger for update add to cart action
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "item_added_from",
    element_id: page,
  });
};

export const TriggerEventSearch = (searchTerm, searchFrom) => {
  // Add trigger for search event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "search",
    search_term: searchTerm,
    search_from: searchFrom,
  });
};

export const TriggerEventSearchResultClick = (
  searchTerm,
  searchFrom,
  itemId
) => {
  // Add trigger for search results click event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "selected_search_item",
    search_term: searchTerm,
    search_from: searchFrom,
    Item_id: itemId,
  });
};
