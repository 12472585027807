// The basics
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
// Action creators and helpers
import { establishCurrentUser } from "./store/actions/auth";
import { setPreviousPath } from "./store/actions/global";
// import '../app/lib/api/interceptors';
import Routes from "./pages";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import CanonicalLinkSetter from "./components/common/CanonicalLinkSetter";

const Head = Loadable({
  loader: () => import("./components/common/head"),
  loading: () => null,
  modules: ["head"],
});

const Footer = Loadable({
  loader: () => import("./components/common/footer"),
  loading: () => null,
  modules: ["footer"],
});

// const loadingOveraly = () => (
//   <>
//     <div className="loading__overlay" />
//     <div className="animation__wrapper">
//       <Spinner />
//     </div>
//   </>
// );

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
    };
    this.setBlock = this.setBlock.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.props.setPreviousPath(JSON.stringify(this.props.location.pathname));
    }
  }

  setBlock() {
    this.setState({
      blocking: !this.state.blocking,
    });
  }

  componentDidMount() {
    this.props.establishCurrentUser();
  }
  render() {
    return (
      <div id="app">
        <BlockUi tag="div" blocking={this.state.blocking}>
          <Suspense fallback={null}>
            <Head fun={this.setBlock} />
            <div
              style={isMobileOnly ? { minHeight: 420 } : { minHeight: 1024 }}
            >
              <ToastContainer />
              <Routes />
            </div>
            <Footer />
          </Suspense>
        </BlockUi>

        <CanonicalLinkSetter />
      </div>
    );
  }
}

App.propTypes = {
  establishCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ establishCurrentUser, setPreviousPath }, dispatch);

const withConnect = connect(mapStateToProps, mapDispatchToProps)(App);
export default withRouter(withConnect);
