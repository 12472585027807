import type { Browser } from './elasticSearch/esTypes';

export const getBrowserName = (): Browser => {
  const { userAgent = '' } = window.navigator;
  let browserName: Browser = 'unknown';

  if (userAgent.includes('Chrome')) {
    browserName = 'chrome';
  } else if (userAgent.includes('Firefox')) {
    browserName = 'firefox';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browserName = 'safari';
  } else if (userAgent.includes('Edge')) {
    browserName = 'edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    browserName = 'internet-explorer';
  }
  return browserName;
};
