import React from "react";
import parse from "html-react-parser";
import { formatPrice } from "./utils";
import ProductStockDetails from "../product-item/ProductStockDetails";
import {
  SPECIAL_SHIPPING_FLAG,
  WEB_DIRECT_FLAG_TRUE,
  HAZARDOUS_FLAG_TRUE,
} from "../../common/Constants";
import DisclaimerIcons from "../../common/disclaimer-icons/disclaimerIcons";
import Img from "../../common/Img";
import AvailabilityBreakdownLoader from "../../common/loading/AvailabilityBreakdownLoader";
import { Link } from "react-router-dom";

const ConfirmOrderItemMobile = ({ item }) => {
  const getItemUrl = () => {
    const sku = item.sku ? item.sku : "";
    const groupId = item.groupId ? item.groupId : "";

    return `/product-item/${groupId}/${sku}`;
  };
  return (
    <div className="product-item-mob">
      <div className="d-flex pl-3 pr-3">
        <Link to={getItemUrl()}>
          <Img
            title={parse(item.name)}
            alt={parse(item.name)}
            width="92"
            height="92"
            src={`${process.env.REACT_APP_IMAGE_URL}/${item.img}`}
          />
        </Link>
        <div className="info-wrapper">
          <p className="item__link">
            <Link to={getItemUrl()}>{item.sku}</Link>
            {item.ciNumber && (
              <span style={{ color: "#CC0000" }}>
                {" | CI: "}
                {item.ciNumber || "CI not available"}
              </span>
            )}
          </p>
          <h4>{item.name}</h4>
          <p>{item.title}</p>
          <p>{item.ksNumber}</p>
          <DisclaimerIcons
            disclaimerIconClassName="shopping-cart-disclaimer-icon"
            webDirectStatus={
              item.webDirect === WEB_DIRECT_FLAG_TRUE ? true : false
            }
            hazardousStatus={
              item.hazardous === HAZARDOUS_FLAG_TRUE ? true : false
            }
            specialShippingStatus={
              SPECIAL_SHIPPING_FLAG.find((el) => el === item.specialShipping)
                ? true
                : false
            }
          />
          <div>
            <label>PO/Job Name:</label> {item.configuration.poOrJobName}
          </div>
          <div className="d-flex price-qty-row">
            <div>
              Qty: {item.quantity} {item.uomLable}
            </div>
            <div className="price-tag strong">
              ${item.extended ? formatPrice(item.extended) : "0.00"}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex availability-wrapper-mob ml-3 mr-3 mt-2">
        {item && item.selectedOption ? (
          <ProductStockDetails data={item} />
        ) : (
          <AvailabilityBreakdownLoader />
        )}
      </div>
    </div>
  );
};

export default ConfirmOrderItemMobile;
