import React from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import NotFoundIcon from "./NotFoundIcon";
import { toggleSalesAndTaxWindow } from "./../../store/actions/auth";
import styles from "./NotFound.module.scss";

const NotFoundPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  if (location.pathname === "/tax-form") {
    dispatch(toggleSalesAndTaxWindow(true));
  }

  return (
    <>
      <Helmet>
        {/* Do not index this page */}
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className={styles.notFound}>
        <div className={styles.notFoundBanner}>
          <NotFoundIcon />

          <h1 className={styles.title} role="heading" aria-level="1" tabIndex={0}>Page Not Found</h1>

          <h2 className={styles.subTitle} role="heading" aria-level="2" tabIndex={0}>404 error</h2>

          <p className={styles.details} tabIndex={0}>
            The link you clicked may be broken or the page may have been
            removed.
          </p>

          <Link to="/" replace className={styles.link}>
            Go to homepage
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
