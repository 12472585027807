/* eslint-disable camelcase */
export const VIEW_CART_DEFAULT_URL = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/cart`;

export const ZOOM_VIEW_ITEMS_CART =
  process.env.REACT_APP_API_ENDPOINT + `/pim/webservice/ecommerce/cart`;

export const ZOOM_SHIPPING_ADDRESS =
  "?zoom=order:deliveries:element:destinationinfo:selector:choice,order:deliveries:element:destinationinfo:selector:choice:description,order:deliveries:element:destinationinfo:selector:chosen,order:deliveries:element:destinationinfo:selector:chosen:description";

export const ITEMS_DETAILS_URL = (skuList) =>
  process.env.REACT_APP_API_ENDPOINT +
  `/pim/webservice/rest/skugetiteminfo?sku=${skuList}`;

export const getItemConfing = ({
  lineItemguid = "",
  quantity = "",
  _avail_1 = "",
  _avail_2 = "",
  _avail_3 = "",
  _avail_4 = "",
  _avail_5 = "",
  _plant_1 = "",
  _plant_2 = "",
  _plant_3 = "",
  _plant_4 = "",
  _plant_5 = "",
  _shipping_method_1 = "",
  _shipping_method_2 = "",
  _shipping_method_3 = "",
  _shipping_method_4 = "",
  _shipping_method_5 = "",
  _will_call_avail = "",
  _will_call_plant = "",
  _hashvalue = "",
} = {}) => {
  const jsonRes = `{
    "lineitemconfiguration": {
      "avail_1": "${_avail_1}",
      "avail_2": "${_avail_2}",
      "avail_3": "${_avail_3}",
      "avail_4": "${_avail_4}",
      "avail_5": "${_avail_5}",
      "plant_1": "${_plant_1}",
      "plant_2": "${_plant_2}",
      "plant_3": "${_plant_3}",
      "plant_4": "${_plant_4}",
      "plant_5": "${_plant_5}",
      "shipping_method_1": "${_shipping_method_1}",
      "shipping_method_2": "${_shipping_method_2}",
      "shipping_method_3": "${_shipping_method_3}",
      "shipping_method_4": "${_shipping_method_4}",
      "shipping_method_5": "${_shipping_method_5}",
      "will_call_avail": "${_will_call_avail}",
      "will_call_plant": "${_will_call_plant}",
      "hashvalue": "${_hashvalue}",
      "selectedOption": "",
      "backorder_all": ""
      },
    "lineitemguid": "${lineItemguid}",
    "quantity": ${quantity}
  }`;
  return jsonRes;
};

export const CONFIRM_ORDER_URL =
  "?zoom=order:purchaseform&format=standardlinks,zoom.noself";
export const PAYMENT_TOKEN_URL =
  "?zoom=order:paymentmethodinfo:paymenttokenform";

export const GET_SHIPPING_OPTIONS_URL = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/availability-check`;

export const ZOOM_PAYMENT_TOKEN =
  "?zoom=order:paymentmethodinfo:paymenttokenform";

export const ZOOM_VIEW_PAYMENT_METHOD =
  "?zoom=order:paymentmethodinfo:paymentmethod";

export const HELP_URL = "";

export const CHECK_PRODUCT_INFO_PIM = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/getstatus`;

export const CREDIT_CARDS_URL = `${process.env.REACT_APP_API_ENDPOINT}/am/creditcards`;

export const NAVIGATION_SETTINGS_API = `${process.env.REACT_APP_API_ENDPOINT}/am/user-settings`;

export const BILLING_ADDRESSES_API = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/billing-address`;

export const OSR_DETAILS_API = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/osrdetails/`;

export const SOLDTOACC_IMAGE_UPLOAD_API = `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/uploadosrimage/`;

export const USER_IMAGE_UPLOAD_API = `${process.env.REACT_APP_API_ENDPOINT}/am/userimage/`;

export const CHECK_TOKEN_URL = `${process.env.REACT_APP_API_ENDPOINT}/am/token_check`;

export const SEND_ERROR_EMAIL = `${process.env.REACT_APP_API_ENDPOINT}/am/send-error-email`;
export const SEND_ERROR_EMAIL_LOGIN = `${process.env.REACT_APP_API_ENDPOINT}/am/send-error-email-login`;
