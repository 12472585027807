import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import OrderSummary from "./OrderSummary";
import { getAddress, setShippingOption } from "./commonFunc";
import { getSubTotal, formatPrice } from "./utils";
import Img from "../../common/Img";
import paymentTypesMap from "../shopping-cart/mappings/paymentTypesMap.json";
import CrediCardDetails from "./common/CreditCardDetails";
import ShippingAddressView from "./common/ShippingAddressView";
import _ from "lodash";
import CartItemListing from "./common/CartItemListing";
import { formatDate } from "../../../lib/utils";
import { getBrowserStorage } from "../../../utils/cookies";

class OrderRecieptBody extends React.Component {
  render() {
    const {
      paymentToken,
      configuration,
      driverNote,
      emailAddress,
      cartItemFullData,
    } = this.props;

    const selectedAddressDetails = JSON.parse(
      getBrowserStorage("selectedAddressDetails")
    );
    const selectedShippingAddress = JSON.parse(
      getBrowserStorage("selectedShippingAddress")
    );
    const billingAddress = selectedAddressDetails["billing-address"];
    const orderNumber = getBrowserStorage("orderNum");
    const orderDate = formatDate(getBrowserStorage("orderDate"));

    return (
      <div
        className="order-receipt"
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          padding: 60,
          marginTop: 30,
        }}
      >
        <div>
          <div className="row">
            <div className="col-4 column-1 receipt-col-1">
              <p tabIndex={0} style={{ fontWeight: 600, fontSize: 19 }}>Order Receipt</p>
              <div>
                <p tabIndex={0}>
                  <span className="bold">Order No: </span> {orderNumber}
                </p>
                <p tabIndex={0}>
                  <span className="bold">Order Date:</span> {orderDate}
                </p>
              </div>
              {configuration.jobName && (
                <p tabIndex={0}>
                  <span className="bold">Job Name:</span>{" "}
                  {configuration.jobName}
                </p>
              )}
              {configuration.po && (
                <p tabIndex={0}>
                  <span className="bold">PO No:</span> {configuration.po}
                </p>
              )}
            </div>
            <div className="col-4">
              <div>
                <div className="bold mb-2" tabIndex={0}>Billed To:</div>
                <p tabIndex={0}>
                  {selectedAddressDetails ? (
                    // ? getAddress(billingAddress)
                    <span className="address-text mb-0">
                      <strong>{billingAddress.name}</strong>
                      <br />
                      {billingAddress["street-address"]}, <br />
                      {billingAddress["locality"]}, {billingAddress["region"]},{" "}
                      {/* {billingAddress["country-name"]},{" "} */}
                      {billingAddress["postal-code"]}
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
            <div className="col-4">
              <div>
                <p className="bold mb-2" tabIndex={0}>Shipping To:</p>
                {/* <div className="bold">{selectedAddressDetails.name}</div> */}
                <span tabIndex={0}>
                {!_.isEmpty(selectedShippingAddress) && (
                  <ShippingAddressView
                    selectedShippingAddress={selectedShippingAddress}
                    cartAttn={configuration.attnName}
                  />
                )}
                </span>
                {driverNote && (
                  <div className="mt-3">
                    <p tabIndex={0}>
                      <span className="bold">Driver's Note: </span>
                      {driverNote}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <p className="bold" tabIndex={0}>Payment Terms:</p>
              <div tabIndex={0}>
                {configuration &&
                paymentToken &&
                configuration.paymentMethod[0] === "V" ? (
                  <CrediCardDetails
                    configuration={configuration}
                    paymentToken={paymentToken}
                  />
                ) : (
                  paymentTypesMap[configuration.paymentMethod]
                )}
              </div>
            </div>
            <div className="col-4">
              <p className="bold" tabIndex={0}>Future Ship Date</p>
              {configuration && configuration.pickDate ? (
                <p tabIndex={0}>
                  {moment(configuration.pickDate, "MM-DD-YYYY").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              ) : (
                <p tabIndex={0}>Pick date not available</p>
              )}
            </div>
            <div className="col-4">
              {emailAddress && (
                <div>
                  <p className="bold mb-2" tabIndex={0}>Order Confirmation Email</p>
                  <p tabIndex={0}>{emailAddress}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <br />

        <div>
          {!_.isEmpty(cartItemFullData) && cartItemFullData.length > 0 && (
            <table className="table table-striped border-non-table ">
              <thead>
                <tr>
                  <th />
                  <th style={{ width: "40%" }}><span>Product Description</span></th>
                  <th><span tabIndex={0}>Qty</span></th>
                  <th><span tabIndex={0}>UOM</span></th>
                  <th><span tabIndex={0}>Unit Price</span></th>
                  <th><span tabIndex={0}>Sub Total</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                </tr>
                {cartItemFullData && (
                  <CartItemListing cartItems={cartItemFullData} />
                )}
              </tbody>
            </table>
          )}
          <div
            className="row"
            style={{ borderTop: "1px solid gray", marginTop: 20 }}
          >
            <div className="col-8" />
            <div
              className="col-4 s-cart-right-side-bar float-right"
              style={{ marginTop: 30 }}
            >
              <OrderSummary />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderRecieptBody;
