/* eslint-disable prettier/prettier */
import React from "react";
import { Table } from "react-bootstrap";
import ShippingDetails from "./ShippingDetails";
import WillCall from "./WillCall";
import _ from "lodash";
import ProductDescription from "./ProductDescription";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setCartItems } from "../../../store/actions/shoppingCart";
import axios from "axios";
import { REQ_HEADER } from "./Tokens";
import { GET_SHIPPING_OPTIONS_URL } from "./APIs";
import { getDefaultWillCallPlant } from "./services/servicesPIM.new";
import { ErrorAlert } from "./common";

class SelectShippingOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPending: 0,
      isOpen: true,
      willCall: false,
      shippingOptionsAll: [],
      error: "",
      defaultWillCallPlant: "",
      showWarningForShipingMethod: false,
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.shoppingCart !== prevProps.shoppingCart) {
      const itemsPending = this.props.shoppingCart.cartItems.filter(
        (item) => !item.shippingConfirmed
      );
      if (itemsPending) this.setState({ itemsPending: itemsPending.length });
    }
    if (this.props.shoppingCart.cartItems != prevProps.shoppingCart.cartItems) {
      if (!_.isEmpty(this.props.shoppingCart.cartItems)) {
        this.props.shoppingCart.cartItems.map((item) => {
          if (item.configuration.shipping_method_1 == "C") {
            this.setState({ showWarningForShipingMethod: true });
          } else {
            this.setState({ showWarningForShipingMethod: false });
          }
        });
      }
    }
  }

  setWillCall = (willCall) => {
    this.setState({ willCall });
  };

  componentDidMount() {
    this.processShippingOptions();
    this.getDefaultWillCallPlant();
  }

  getDefaultWillCallPlant = async () => {
    const response = await getDefaultWillCallPlant();
    let defaultWillCallPlant;
    if (response?.plant) {
      defaultWillCallPlant = response?.plant;
    }
    this.setState({ defaultWillCallPlant });
    return defaultWillCallPlant;
  };

  // getShippingData = async (quantity, willCallPlant) => {
  //   let reqItemJson = "";
  //   if (willCallPlant) {
  //     reqItemJson = await `{
  //       "plant": "${willCallPlant}",
  //       "quantity": ${quantity},
  //       "sku": "${this.props.sku}"
  //     }`;
  //   } else {
  //     reqItemJson = await `{
  //       "quantity": ${quantity},
  //       "sku": "${this.props.sku}"
  //     }`;
  //   }
  //   const data = await `{ "skuidqty":[ ${reqItemJson} ]  }`;

  //   try {
  //     const resShipOptions = await axios({
  //       method: "POST",
  //       url: GET_SHIPPING_OPTIONS_URL,
  //       headers: REQ_HEADER,
  //       data
  //     });

  //     return resShipOptions.data["available-options"][0];
  //   } catch (error) {

  //     return {};
  //   }
  // };

  processShippingOptions = async () => {
    this.setState({ error: "" });
    const itemData = this.props.shoppingCart.cartItems.map((item) => {
      let reqItemJson = {};
      if (item.configuration.will_call_plant) {
        reqItemJson = {
          plant: item.configuration.will_call_plant,
          quantity: item.quantity,
          sku: item.sku,
        };
      } else {
        reqItemJson = {
          quantity: item.quantity,
          sku: item.sku,
        };
      }
      return reqItemJson;
    });
    const data = { skuidqty: itemData };
    let resShipOptions;
    try {
      resShipOptions = await axios({
        method: "POST",
        url: GET_SHIPPING_OPTIONS_URL,
        headers: REQ_HEADER,
        data,
      });
    } catch (error) {
      return this.setState({ error: "Error loading shipping options" });
    }
    const shippingOptionsCombinedItems = resShipOptions.data;
    const items = this.shippingOptionsCombinedItems(
      shippingOptionsCombinedItems
    );
    this.props.actions.setCartItems(items);
  };

  shippingOptionsCombinedItems = (shippingOptionsAll) => {
    let items = [...this.props.shoppingCart.cartItems];
    items = items.map((item) => {
      const shippingOptions = shippingOptionsAll.find(
        (i) => i.sku === item.sku
      );
      item.shippingOptionsData = shippingOptions;
      return item;
    });
    return items;
  };

  renderOptions = (item) => {
    if (!item.shippingConfirmed) {
      return (
        <tr key={item.sku}>
          <td>
            <tr>
              {" "}
              <ProductDescription item={item} />
            </tr>
            <tr>
              {" "}
              {this.state.showWarningForShipingMethod ? (
                <div
                  className="alert alert-success clearfix"
                  role="alert"
                  style={{
                    marginTop: 20,
                  }}
                >
                  <div className="alert-icon">
                    <i className="material-icons">error_outline</i>
                  </div>
                  <div className="alert-text ">
                    <h6>Important!</h6>
                    <p>Additional shipping charges may apply</p>
                  </div>
                </div>
              ) : null}
            </tr>
          </td>
          <td style={{ width: "45%" }}>
            {!this.state.willCall && item.shippingOptionsData && (
              <ShippingDetails
                defaultWillCallPlant={this.state.defaultWillCallPlant}
                sku={item.sku}
                quantity={item.quantity}
                checkPendingAvailable={this.props.checkPendingAvailable}
                checkConfirmAvailable={this.props.checkConfirmAvailable}
                onProdLanding={false}
              />
            )}
          </td>
        </tr>
      );
    }
  };

  handleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="pending-table-header">
        {this.state.error && <ErrorAlert errorMessage={this.state.error} />}
        <div className="shipping-items-list-wrapper">
          <div className="clearfix pending-items-header">
            <h3 role="heading" aria-level="3" tabIndex={0}>
              <i className="material-icons">error_outline</i>{" "}
              {this.state.itemsPending} Items Pending shipping confirmation
              <a className="expand-link" onClick={this.handleOpen}>
                {" "}
                {this.state.isOpen ? (
                  <i className="material-icons" title="Unfold less">unfold_less</i>
                ) : (
                  <i className="material-icons" title="Unfold more">unfold_more</i>
                )}
              </a>
            </h3>
          </div>
          {this.state.isOpen ? (
            <div className="product-table">
              <Table bordered>
                <thead>
                  <tr>
                    <th><span tabIndex={0}>Product Description</span></th>
                    <th><span tabIndex={0}>Shipping Information</span></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.shoppingCart.cartItems.map((item, i) =>
                    this.renderOptions(item)
                  )}
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectShippingOptions);
