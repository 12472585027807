import {
  AUTHENTICATED,
  IS_AUTHENTICATING,
  SUBSC_TOKEN_REFRESH,
  ON_TOKEN_REFRESH,
  TOKEN_REFRESHING
} from '../constants';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isTokenRefreshing: false,
  tokenSubscribers: [],
  currentUser: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
        isAuthenticating: false,
        currentUser: action.payload.user
      };

    case IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.payload.authenticating,
        isAuthenticated: false
      };

    case SUBSC_TOKEN_REFRESH:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        tokenSubscribers: [...state.tokenSubscribers, action.payload]
      };

    case ON_TOKEN_REFRESH:
      return {
        ...state,
        isAuthenticating: false,
        isTokenRefreshing: false,
        tokenSubscribers: []
      };

    case TOKEN_REFRESHING:
      return {
        ...state,
        isTokenRefreshing: action.payload
      };

    default:
      return state;
  }
};
