import React, { Fragment } from "react";
import DisclaimerIcon from "./disclaimerIcon";
import additionalShippingCost from "../../../assets/img/icons-svg/additional_shiping_cost.svg";
import localShipping from "../../../assets/img/icons-svg/local_shipping.svg";
import radiation from "../../../assets/img/icons-svg/radiation.svg";

const DisclaimerIcons = ({
  disclaimerIconClassName,
  webDirectStatus,
  hazardousStatus,
  specialShippingStatus,
}) => {
  return specialShippingStatus || hazardousStatus || webDirectStatus ? (
    <div className={`clearfix ${disclaimerIconClassName}`}>
      {hazardousStatus && (
        <DisclaimerIcon
          icon={radiation}
          iconClassName={"hazardous-icon"}
          title={"Hazardous material, special shipping costs may apply"}
        />
      )}
      {specialShippingStatus && (
        <DisclaimerIcon
          icon={additionalShippingCost}
          iconClassName={"special-shipping-icon"}
          title={"Additional shipping costs and lead times may apply"}
        />
      )}
      {webDirectStatus && (
        <DisclaimerIcon
          icon={localShipping}
          iconClassName={"direct-shipping-icon"}
          title={"Drop Ship Item, additional freight charges may apply"}
        />
      )}
    </div>
  ) : (
    <Fragment />
  );
};
export default DisclaimerIcons;
