import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";

const ShowPaymentMethodMobile = ({
  isMobileModalOpen,
  handleChange,
  otherPaymentOption,
  clickAddCreditCard,
  paymentOptionPopupDisplayHide,
}) => {
  return (
    <Modal
      className="bottom-modal payment-method-popup"
      show={isMobileModalOpen}
      backdrop="static"
      tabIndex="0"
      onHide={() => handleChange()}
      style={{ display: paymentOptionPopupDisplayHide ? "none" : "block" }}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Payment Methods</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>{clickAddCreditCard}</div>

        <div className="row two-btn-row">
          <button
            className="btn red-border-btn mr-2"
            onClick={() => handleChange()}
          >
            cancel
          </button>
          <button className="btn red-btn" onClick={() => handleChange()}>
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ShowPaymentMethodMobile;
