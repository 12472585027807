import "./polyfills";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import jwtDecode from "jwt-decode";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import createStore from "./store";
import i18n from "./i18n";
import axios from "axios";
import App from "./app/app";
import "./app/assets/scss/custom.scss";
import { CARTTOKEN, PIMACCOUNTSET } from "./app/components/common/Constants";
import { getLoginToken } from "./app/components/common/commonFunc";
import {
  MYACCOUNT,
  PIMURL2,
  SESSION_EXPIRED,
} from "./app/components/common/RouterConstants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Cookies from "./app/lib/utils/cookies";
import {
  setBrowserStorage,
  getBrowserStorage,
  clearBrowserStorage,
  getBrowserStorage_Cookie,
  setBrowserStorage_Cookie,
} from "./app/utils/cookies";

import {
  CT_TIME,
  SHIP_TO,
  ACC_NO,
  U_TIMEOUT,
  CURRENT_ADDRESS,
  REGION,
} from "./app/utils/cookies/cookiesList";
import { ELASTIC_SEARCH_API_URLS } from "./app/utils/elasticSearch/constants";

if (process.env.NODE_ENV === "development") {
  // Workaround for the error overlay iframe positioning itself
  // across the entire screen with `position: fixed;`
  // https://stackoverflow.com/a/63972162
  import("react-error-overlay").then((m) => {
    m.stopReportingRuntimeErrors();
  });
}

const currentUrl = window.location.pathname;

if (currentUrl === MYACCOUNT && !getBrowserStorage_Cookie(CARTTOKEN)) {
  sessionStorage.setItem(PIMACCOUNTSET, "ok");
  window.location.href = PIMURL2;
}

const REGION_BASED_URLS = [
  "productlandinggrouplist",
  "getindividualitem",
  "productlandingattributevalues",
  "productlandingattributeheading",
  "getbrandlist",
  "clouditems",
  "webservice/rest/getstatus",
  "landinginfo",
  "landingattachment",
  "landingvariant",
  "oderhistoryitems",
  "laminatefinderlist",
  "laminatefinderattributevalues",
  "laminatefinderattributeheading",
  "laminatefinderviewattribute",
  "laminatefinderviewitems",
  "getfeatureproducts",
];

const AUTH_URL = `${process.env.REACT_APP_API_ENDPOINT}/am/account-select`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000, // 1 min
    },
  },
});

export const { store, history } = createStore();
const Application = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConnectedRouter>

        <ReactQueryDevtools buttonPosition="button-left" />
      </QueryClientProvider>
    </Provider>
  </I18nextProvider>
);

const root = document.querySelector("#root");

// if (root.hasChildNodes() === true) {

//   // If it's an SSR, we use hydrate to get fast page loads by just
//   // attaching event listeners after the initial render
//   Loadable.preloadReady().then(() => {
//     hydrate(Application, root);
//   });
// } else {

//   // If we're not running on the server, just render like normal
//   render(Application, root);
// }

render(Application, root);

//serviceWorker.register();

const refreshToken = async (userToken) => {
  const shipTo = getBrowserStorage_Cookie(SHIP_TO);
  const accNo = getBrowserStorage_Cookie(ACC_NO);

  const reqBody = `{
        "ship-to": "${shipTo}",
        "accountNo":  "${accNo}"
      }`;

  const tokenResp = await fetch(AUTH_URL, {
    method: "post",
    headers: {
      Authorization: "Bearer " + userToken,
      "Content-Type": "application/json",
    },
    body: reqBody,
  })
    .then((res) => res.json())
    .then((data) => data);

  const token = tokenResp.token;
  if (token) {
    setBrowserStorage_Cookie(CARTTOKEN, token);
  }
};

axios.defaults.params = {};

axios.interceptors.request.use(
  async function (config) {
    const isElasticSearchEndPoint = ELASTIC_SEARCH_API_URLS.some((url) =>
      config?.url?.includes(url)
    );
    // Do not change any headers for elastic search requests, since headers are already included in the request
    // If it is a elastic search url, simply return the same config without modifying it
    if (isElasticSearchEndPoint) {
      return config;
    }

    // Do something before request is sent
    let cartToken = getBrowserStorage_Cookie(CARTTOKEN);
    const loginToken = getLoginToken();
    const endpoint = config.url.split("/")[config.url.split("/").length - 1];

    if (loginToken && jwtDecode(loginToken).exp <= Date.now() / 1000) {
      clearBrowserStorage();
      sessionStorage.clear();

      Object.keys(Cookies.get()).forEach(function (cookieName) {
        if (
          cookieName !== "wurthlac-showaddress" &&
          cookieName !== "region" &&
          cookieName !== "remember-me"
        ) {
          Cookies.remove(cookieName);
        }
      });
      sessionStorage.setItem(
        "lmsdg",
        "Your session has expired, please sign in to continue"
      );
      window.location.href = "/?pim=1";
    }

    if (!loginToken && !cartToken && getBrowserStorage_Cookie(U_TIMEOUT)) {
      clearBrowserStorage();
      sessionStorage.clear();
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        if (
          cookieName !== "wurthlac-showaddress" &&
          cookieName !== "region" &&
          cookieName !== "remember-me"
        ) {
          Cookies.remove(cookieName);
        }
      });
      window.location.assign("/");
    }
    if (
      loginToken &&
      (!cartToken || jwtDecode(cartToken).exp <= Date.now() / 1000)
    ) {
      await refreshToken(loginToken);
      cartToken = getBrowserStorage_Cookie(CARTTOKEN);
    }

    if (loginToken && cartToken) {
      let ctime = new Date();

      if (
        getBrowserStorage_Cookie(CT_TIME) &&
        getBrowserStorage_Cookie(U_TIMEOUT)
      ) {
        const oldTime = getBrowserStorage_Cookie(CT_TIME);

        const diffMs = ctime - new Date(oldTime);

        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

        if (diffMins > getBrowserStorage_Cookie(U_TIMEOUT)) {
          clearBrowserStorage();
          sessionStorage.clear();
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            if (
              cookieName !== "wurthlac-showaddress" &&
              cookieName !== "region" &&
              cookieName !== "remember-me"
            ) {
              Cookies.remove(cookieName);
            }
          });
          sessionStorage.setItem(
            "lmsdg",
            "Your session has expired, please sign in to continue"
          );
          window.location.href = SESSION_EXPIRED;
        }

        setBrowserStorage_Cookie(CT_TIME, ctime);
      } else {
        setBrowserStorage_Cookie(CT_TIME, ctime);
      }
    }

    let address;
    try {
      address = getBrowserStorage_Cookie(CURRENT_ADDRESS)[0];
    } catch (error) {}
    const isLoginTokenEndpoint =
      endpoint === "account-select" ||
      endpoint === "account-list" ||
      endpoint === "favourite-skus";

    const isRegionBasedUrl = REGION_BASED_URLS.some((url) =>
      config.url.includes(url)
    );

    if (isRegionBasedUrl && getBrowserStorage_Cookie(REGION) && !address) {
      const region = getBrowserStorage_Cookie(REGION);
      let res = region.split("&");

      let arg1 = res[0].split("=");
      config.params["country"] = arg1[1];

      let arg2 = res[1].split("=");
      config.params["region"] = arg2[1];

      let arg3 = res[2].split("=");
      config.params["county"] = arg3[1];
    }

    if (config.url.includes("temp-password-change")) {
      if (sessionStorage.getItem("tempPassChangeToken")) {
        const access_token = sessionStorage.getItem("tempPassChangeToken");
        config.headers.authorization = `Bearer ${access_token}`;
      }
    } else if (isLoginTokenEndpoint && loginToken) {
      config.headers.Authorization = "Bearer " + loginToken;
    } else if (loginToken && cartToken) {
      if (isRegionBasedUrl && address) {
        config.params["country"] = address["country-name"];
        config.params["county"] = address["county"];
        config.params["region"] = address["region"];
      }
      config.headers.Authorization = "Bearer " + cartToken;
    } else if (endpoint === "pimauthtoken") {
      return config;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.status_code === "RE_LOGIN"
    ) {
      clearBrowserStorage();
      sessionStorage.clear();

      Object.keys(Cookies.get()).forEach(function (cookieName) {
        if (
          cookieName !== "wurthlac-showaddress" &&
          cookieName !== "region" &&
          cookieName !== "remember-me"
        ) {
          Cookies.remove(cookieName);
        }
      });
      sessionStorage.setItem(
        "lmsdg",
        "Your password needs to be reset, please sign in to continue"
      );
      window.location.href = "/?pim=1";
    } else if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.status_code === "SESSION_EXPIRED"
    ) {
      clearBrowserStorage();
      sessionStorage.clear();

      Object.keys(Cookies.get()).forEach(function (cookieName) {
        if (
          cookieName !== "wurthlac-showaddress" &&
          cookieName !== "region" &&
          cookieName !== "remember-me"
        ) {
          Cookies.remove(cookieName);
        }
      });
      sessionStorage.setItem(
        "lmsdg",
        "Your session has expired, please sign in to continue"
      );
      window.location.href = SESSION_EXPIRED;
    }
    return Promise.reject(error);
  }
);
