/* eslint-disable prettier/prettier */
import React, { Suspense, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setCartItems,
  updateCartProduct,
  setShippingProdLanding,
} from "../../../store/actions/shoppingCart";
import plantsMap from "./mappings/plantsMap.json";
import moment from "moment";
import ShippingMethodSelect from "./common/ShippingMethodSelect";
import _ from "lodash";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import { TAKEONHAND } from "../../common/Constants";
import {
  getDefaultShippTo,
  getShippingMethodsList,
} from "../../routewise/shopping-cart/utils";
import { serviceUpdateItemsEP } from "./services/servicesEP";
import { fetchOrderPrice } from "./commonFunc";
import { isMobileOnly } from "react-device-detect";

const OptionBreakDown = ({
  option,
  sku,
  xplant,
  optionEneble,
  checkShipingMethodValue,
}) => {
  const index = option.index.split(",");
  return (
    <div className="">
      {index.map((i) => (
        <div key={i}>
          {option[`quantity_${i}`] ? (
            <div className="select-method-box clearfix border-b-dash">
              <div className="method-option">
                <span tabIndex={0}>{plantsMap[option[`plant_${i}`]]}</span>
                {i === "1" ? (
                  <span>
                    <ShippingMethodSelect
                      sku={sku}
                      xplant={xplant}
                      optionEneble={optionEneble}
                      shippingMethods={option.shippingMethods_1}
                      checkShipingMethodValue={(v) =>
                        checkShipingMethodValue(v)
                      }
                    />
                  </span>
                ) : (
                  <span tabIndex={0}>{" | Ground Shipping"}</span>
                )}
              </div>

              <div className="stock-count" tabIndex={0}>
                {option[`quantity_${i}`]}
                <span className="green-text color-text">Available </span> now
              </div>
            </div>
          ) : null}
        </div>
      ))}

      {option.backOrder === "T" ? (
        <div className="backorder-date-wrapper" tabIndex={0}>
          {option[`backOrderQuantity_${index[0]}`]} Backorder,{" "}
          {moment(new Date(option[`backOrderDate_${index[0]}`])).format(
            "dddd, MMM. D, YY"
          )}
          <p className={isMobileOnly ? "mob-notice-box" : "notice-box"} tabIndex={0}>
            Dates are subject to change by suppliers without notice.
          </p>
        </div>
      ) : null}
    </div>
  );
};

const ShippingOption = ({
  optionNum,
  shippingProdLanding,
  sku,
  xplant,
  quantity,
  option,
  actions,
  onProdLanding,
  cartItems,
  onVariations,
  checkShipingMethodValue,
  onShoppingCart,
  setIsChangeQtyError,
  handleQtyError,
  cartData,
  shoppingCart,
  handleChangeShippingStatus,
}) => {
  const [optionEneble, setOptionEneble] = useState(true);
  const [isChecked, setIsChecked] = useState();

  const item = cartItems.find((item) => item.sku === sku);

  const handleSelect = async (optionStatus) => {
    if (setIsChangeQtyError) {
      setIsChangeQtyError(true);
    }
    if (handleQtyError) {
      handleQtyError(false);
    }
    setOptionEneble(true);
    if (!_.isEmpty(item) && !onProdLanding) {
      const product = Object.assign({}, item);
      product.selectedOption = option;
      product.configuration.hashvalue = option.hash;
      product.configuration.will_call_avail = "";
      product.configuration.will_call_plant = "";
      product.optionType = "default";
      if (!!option.index && option.index.split(",")[0] !== "1") {
        product.shipMethod = "G";
      } else {
        product.shipMethod = getDefaultShippTo(option.index);
      }
      actions.updateCartProduct(product);
    } else {
      if (onShoppingCart) {
        const product = cartItems.find((e) => e.sku === sku);
        product.selectedOption = option;
        product.hashvalue = option.hash;
        product.optionType = "default";
        product.quantity = quantity;
        product.pickupRemaining = false;

        product.configuration.hashvalue = option.hash;
        product.configuration.will_call_avail = "";
        product.configuration.will_call_plant = "";
        product.showQtyChangeMsg = false;
        if (!!option.index && option.index.split(",")[0] !== "1") {
          product.shipMethod = "G";
        } else {
          product.shipMethod = getDefaultShippTo(option.index);
        }
        handleChangeShippingStatus(product);
        await actions.updateCartProduct(product);
        await serviceUpdateItemsPIM(cartItems);
        fetchOrderPrice();
      } else {
        const product = Object.assign({}, shippingProdLanding);
        product.sku = sku;
        product.selectedOption = await option;
        product.hashvalue = option.hash;
        product.optionType = "default";
        product.quantity = quantity;
        product.pickupRemaining = false;
        if (!!option.index && option.index.split(",")[0] !== "1") {
          product.shipMethod = "G";
        } else {
          product.shipMethod = getDefaultShippTo(option.index);
        }

        if (
          optionStatus === "shipDifferentLocation" &&
          shippingProdLanding.selectedOption.backOrder === "F"
        ) {
          product.status = "inStock";
        } else if (
          optionStatus === "shipAlternativeBranch" &&
          shippingProdLanding.selectedOption.backOrder === "F"
        ) {
          product.status = "inStock";
        } else if (
          optionStatus === "takeOnHand" &&
          shippingProdLanding.selectedOption.backOrder === "T"
        ) {
          product.status = "limitedStock";
        } else if (
          optionStatus === "shipAlternativeBranch" &&
          shippingProdLanding.selectedOption.backOrder === "T"
        ) {
          product.status = "limitedStock";
        } else if (optionStatus === "backOrderAll") {
          product.status = "Backordered";
        }
        if (
          product.selectedOption.type == "backOrderAll" &&
          product.shipMethod == "0"
        ) {
          product.shipMethod = getShippingMethodsList(
            product.selectedOption.shippingMethods_1
          )[0];
        }

        if (handleChangeShippingStatus) {
          handleChangeShippingStatus(product);
        }

        actions.setShippingProdLanding(product);
      }
    }
  };

  const index = option.index.split(",");
  useEffect(() => {
    let isCheckedStatus;
    if (!option) return null;
    if (item && !onProdLanding) {
      isCheckedStatus = item.configuration.hashvalue === option.hash;
    } else if (onShoppingCart) {
      isCheckedStatus = item.selectedOption
        ? item.selectedOption.hash === option.hash
        : false;
    } else if (
      shippingProdLanding.sku === sku &&
      !!shippingProdLanding.selectedOption
    ) {
      isCheckedStatus =
        shippingProdLanding.hashvalue === option.hash &&
        !_.isEmpty(shippingProdLanding.selectedOption);
    }

    setIsChecked(isCheckedStatus);
  }, [
    option,
    onProdLanding,
    item,
    item && item.selectedOption,
    shippingProdLanding,
  ]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<h2>Loading...</h2>}>
        <div className="clearfix shadow-on-white shipping-option-wrapper mb-3">
          <div>
            <div className="custom-control custom-radio custom-control-inline mb-0" tabIndex={0}>
              <input
                onChange={() => handleSelect(option.type)}
                type="radio"
                defaultChecked={isChecked}
                value={optionNum}
                id={`${option.type}${onVariations}-${sku}-${optionNum}`}
                name={`option-${sku}`}
                className="custom-control-input"
              />
              <label
                className="custom-control-label"
                htmlFor={`${option.type}${onVariations}-${sku}-${optionNum}`}
              >
                {option.type === "availableAll" ? <span>In Stock</span> : null}

                {option.type === "takeOnHand" ? (
                  <span> Take what's on hand </span>
                ) : null}

                {option.type === "backOrderAll" ? (
                  <span> Backorder Everything </span>
                ) : null}

                {option.type === "shipAlternativeBranch" ? (
                  <span> Ship From Alternative Branch(es) </span>
                ) : null}
                {option.type === "shipDifferentLocation" ? (
                  <span> Ship From Different Location </span>
                ) : null}
              </label>
            </div>
          </div>
          <div>
            <div className="select-method-box clearfix">
              {option.type === "shipAlternativeBranch" ||
              option.type === "shipDifferentLocation" ? null : (
                <div className="method-option">
                  <span tabIndex={0}>{plantsMap[option[`plant_${index[0]}`]]}</span>
                  {option.shippingMethods_1 ? (
                    <span>
                      <ShippingMethodSelect
                        sku={sku}
                        optionEneble={isChecked ? false : optionEneble}
                        xplant={xplant}
                        onShoppingCart={onShoppingCart}
                        shoppingItem={item}
                        shippingMethods={option.shippingMethods_1}
                        checkShipingMethodValue={(v) =>
                          checkShipingMethodValue(v)
                        }
                        optionType={option.type}
                      />
                    </span>
                  ) : (
                    <span tabIndex={0}>{" | Ground Shipping"}</span>
                  )}
                </div>
              )}
            </div>
            <div>
              {true ? (
                <div>
                  {option.type === "availableAll" ? (
                    <div className="stock-count" tabIndex={0}>
                      {option[`quantity_${index[0]}`]}
                      <span className="green-text color-text">
                        Available{" "}
                      </span>{" "}
                      now
                    </div>
                  ) : null}

                  {option.type === "takeOnHand" ? (
                    <div>
                      <div className="stock-count" tabIndex={0}>
                        {option[`quantity_${index[0]}`]}
                        <span className="green-text color-text">
                          Available{" "}
                        </span>{" "}
                        now
                      </div>
                      <div className="backorder-date-wrapper" tabIndex={0}>
                        {option[`backOrderQuantity_${index[0]}`]} Backordered,{" "}
                        ETA{" "}
                        {moment(
                          new Date(option[`backOrderDate_${index[0]}`])
                        ).format("ddd MMM. D, YY")}
                        <p
                          tabIndex={0}
                          className={
                            isMobileOnly ? "mob-notice-box" : "notice-box"
                          }
                        >
                          Dates are subject to change by suppliers without
                          notice.
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {option.type === "backOrderAll" ? (
                    <div className="backorder-date-wrapper" tabIndex={0}>
                      {option[`backOrderQuantity_${index[0]}`]} Backordered, ETA{" "}
                      {moment(
                        new Date(option[`backOrderDate_${index[0]}`])
                      ).format("ddd MMM. D, YY")}
                      <p
                        tabIndex={0}
                        className={
                          isMobileOnly ? "mob-notice-box" : "notice-box"
                        }
                      >
                        Dates are subject to change by suppliers without notice.
                      </p>
                    </div>
                  ) : null}

                  {/* shipping breakdowns for other types */}
                  {option.type === "shipAlternativeBranch" ||
                  option.type === "shipDifferentLocation" ? (
                    <OptionBreakDown
                      option={option}
                      sku={sku}
                      optionEneble={isChecked ? false : optionEneble}
                      xplant={xplant}
                      checkShipingMethodValue={(v) =>
                        checkShipingMethodValue(v)
                      }
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCartItems, updateCartProduct, setShippingProdLanding },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  cartItems: state.shoppingCart.cartItems,
  cartData: state.shoppingCart.cartData,
  shippingProdLanding: state.shoppingCart.shippingProdLanding,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOption);
