import { REQ_HEADER } from "../components/routewise/shopping-cart/Tokens";
import axios from "axios";
import {
  ZOOM_PAYMENT_TOKEN,
  ZOOM_VIEW_PAYMENT_METHOD,
} from "../components/routewise/shopping-cart/APIs";

export const getTokenActionUrl = async (cartData) => {
  const orderUrl = cartData.orderUrl;
  const orderUrlSplitArry = orderUrl.split("orders");
  return `${orderUrlSplitArry[0]}paymenttokens/orders${orderUrlSplitArry[1]}`;
};

export const getPaymentMethod = async (cartUrl) => {
  try {
    const url = cartUrl + ZOOM_VIEW_PAYMENT_METHOD;
    const response = await axios({
      method: "GET",
      url,
      headers: REQ_HEADER,
    });
    const paymentMethod =
      response.data._order[0]._paymentmethodinfo[0]._paymentmethod[0];
    return paymentMethod;
  } catch (error) {
    return {};
  }
};

export const setPaymentToken = async (token, tokenActionUrl) => {
  const reqBody = await `{
    "display-name": "paymentToken",
      "token": "${token}"
    }`;

  const response = await axios({
    method: "POST",
    url: tokenActionUrl,
    headers: REQ_HEADER,
    data: reqBody,
  });
  return response;
};

export const getPaymentToken = async (cartUrl) => {
  const paymenttokenform =
    cartUrl + "?zoom=order:paymentmethodinfo:paymentmethod";

  const response = await axios({
    method: "GET",
    url: paymenttokenform,
  });
  return response;
};
