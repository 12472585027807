import { SET_STEP_1, SET_STEP_2, SET_STEP_3 } from '../constants';

const initialState = {
  completedStep: 0,
  accNumber: '',
  organization: '',
  fname: '',
  lname: '',
  email: '',
  password: '',
  confirmpw: '',

  verifyFname: '',
  verifyLname: '',
  verifyEmail: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP_1:
      return {
        ...state,
        accNumber: action.payload.accNumber,
        organization: action.payload.organization,
        fname: action.payload.fname,
        lname: action.payload.lname,
        email: action.payload.email,
        password: action.payload.password,
        confirmpw: action.payload.confirmpw,
        completedStep: 1
      };
    case SET_STEP_2:
      return {
        ...state,
        verifyFname: action.payload.fname,
        verifyLname: action.payload.lname,
        verifyEmail: action.payload.email,
        completedStep: 2
      };
    case SET_STEP_3:
      return {
        ...state,
        completedStep: 3
      };

    default:
      return state;
  }
};
