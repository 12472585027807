// auth
export const IS_AUTHENTICATING = "auth:IS_AUTHENTICATING";
export const AUTHENTICATED = "auth:AUTHENTICATED";
export const SHOW_HIDE_LOGIN = "auth:SHOW_HIDE_LOGIN";
export const SHOW_HIDE_JOINNEW = "auth:SHOW_HIDE_JOINNEW";
export const SUBSC_TOKEN_REFRESH = "auth:SUBSC_TOKEN_REFRESH";
export const ON_TOKEN_REFRESH = "auth:ON_TOKEN_REFRESH";
export const TOKEN_REFRESHING = "auth:TOKEN_REFRESHING";
export const SHOW_HIDE_FORGOT = "auth:SHOW_HIDE_FORGOT";
export const SHOW_HIDE_SUCCESS = "auth:SHOW_HIDE_SUCCESS";
export const SHOW_HIDE_REGISTER_SUCCESS = "auth:SHOW_HIDE_REGISTER_SUCCESS";
export const SHOW_FORGET_PSWD_MSG = "auth:SHOW_FORGET_PSWD_MSG";
export const SHOW_PSWD_RESET_SUCCESS = "auth:SHOW_PSWD_RESET_SUCCESS";
export const SHOW_HIDE_RESET = "auth:SHOW_HIDE_RESET";
export const SHOW_HIDE_RESET_PASSOWRD = "auth:SHOW_HIDE_RESET_PASSOWRD";
export const SHOW_HIDE_FORCE_RESET = "auth:SHOW_HIDE_FORCE_RESET";


// global
export const SHOW_HIDE_ADDRESSES = "global:SHOW_HIDE_ADDRESSES";
export const SET_ADDRESSES = "global:SET_ADDRESSES";
export const SET_CATEGORY_TOKEN = "SET_CATEGORY_TOKEN";
export const SET_PREV_PATH = "SET_PREV_PATH";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const SET_ADDRESSES_TRUE = "global:SET_ADDRESSES_TRUE"
export const SHOW_HIDE_SALES_TAX ="global:SHOW_HIDE_SALES_TAX"

// register
export const SET_STEP_1 = "register:SET_STEP_1";
export const SET_STEP_2 = "register:SET_STEP_2";
export const SET_STEP_3 = "register:SET_STEP_3";

// shopping cart
export const SET_SHIPPING_ADDRESS = "cart:SET_SHIPPING_ADDRESS";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_UPDATE_STATE_FUNC = "SET_CART_ITEMS";
export const SET_CART_TOKEN = "SET_CART_TOKEN";
export const SET_ORDER_PRICE = "SET_ORDER_PRICE";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_LOAD_CART_FUNC = "SET_LOAD_CART_FUNC";
export const SET_SHIPPING_PROD_LANDING = "SET_SHIPPING_PROD_LANDING";
export const SET_CART_DATA = "SET_CART_DATA";
export const UPDATE_CART_PRODUCT = "UPDATE_CART_PRODUCT";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const USE_SINGLE_CHECKOUT = "USE_SINGLE_CHECKOUT";
export const PASSWORD_ISSUE = "PASSWORD_ISSUE";
export const SET_TEMP_CREDIT_CARD = "SET_TEMP_CREDIT_CARD";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_OTHER_PAYMENT_METHOD = "SET_OTHER_PAYMENT_METHOD";
export const SET_CHECKOUT_IS_PROCESSING = "SET_CHECKOUT_IS_PROCESSING";

// MY ACCOUNT
export const SET_NAVIGATION_SETTINGS = "SET_NAVIGATION_SETTINGS";
export const SET_WILLCALL_BRANCH = "SET_WILLCALL_BRANCH";
export const SET_FAVORITE_FILTERS = "SET_FAVORITE_FILTERS";
export const SET_TOTAL_FAVORITE_ITEMS = "SET_TOTAL_FAVORITE_ITEMS";

export const SET_FILTER_ITEMS = "SET_FILTER_ITEMS";
export const SET_OPENED_ATTRIBUTES = "SET_OPENED_ATTRIBUTES";

export const SET_MYPURCHASE_FILTER_ITEM = "SET_MYPURCHASE_FILTER_ITEM";
export const SELECTED_MYPURCHASE_FILTER_ITEM =
  "SELECTED_MYPURCHASE_FILTER_ITEM";
export const REMOVE_MYPURCHASE_FILTER_ITEM = "REMOVE_MYPURCHASE_FILTER_ITEM";

export const SET_MYPURCHASE_HISTRY_ITEM = "SET_MYPURCHASE_HISTRY_ITEM";
export const SET_MYPURCHASE_DATE_FILTER = "SET_MYPURCHASE_DATE_FILTER";

//BULK UPLOAD
export const SET_BULK_UPLOAD_LOADING = "SET_BULK_UPLOAD_LOADING";

export const SET_SELECTED_LAMINATE_GROUP = "SET_SELECTED_LAMINATE_GROUP";
