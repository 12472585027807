import { SET_WILLCALL_BRANCH } from "../constants";

export function setDefaultWillCallBranch(willCallBranch) {
  return async dispatch => {
    dispatch({
      type: SET_WILLCALL_BRANCH,
      payload: willCallBranch
    });
  };
}
