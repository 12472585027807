import {
  SHOW_HIDE_ADDRESSES,
  SHOW_HIDE_LOGIN,
  SHOW_HIDE_JOINNEW,
  SHOW_HIDE_FORGOT,
  SHOW_HIDE_RESET,
  SHOW_HIDE_FORCE_RESET,
  SHOW_HIDE_SUCCESS,
  SHOW_HIDE_REGISTER_SUCCESS,
  SHOW_FORGET_PSWD_MSG,
  SHOW_PSWD_RESET_SUCCESS,
  SET_ADDRESSES,
  SHOW_HIDE_RESET_PASSOWRD,
  SET_CATEGORY_TOKEN,
  SET_PREV_PATH,
  SET_COMPANY_LOGO,
  SET_TOTAL_FAVORITE_ITEMS,
  SET_MYPURCHASE_HISTRY_ITEM,
  SET_ADDRESSES_TRUE,
  SHOW_HIDE_SALES_TAX
} from "../constants";

const initialState = {
  showAddresses: false,
  addresses: [],
  showLogin: false,
  showJoinNew: false,
  showForgot: false,
  SHOW_HIDE_RESET: false,
  showHideForceReset: false,
  showForgotSuccess: false,
  showRegisterMsgModel: false,
  showForgotPswdMsg: "",
  showForgetPswdReset: false,
  showHideReset: false,
  showHideResetPassword: false,
  categoryAxioRequestToken: null,
  prevPath: "",
  companyLogo: "",
  totalFavoriteItem: 0,
  PurchaseHistryItm: [],
  isLoadingAddress: true,
  showSalesAndTax: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HIDE_ADDRESSES:
      return { ...state, showAddresses: action.payload };
    case SHOW_HIDE_LOGIN:
      return { ...state, showLogin: action.payload };
    case SHOW_HIDE_JOINNEW:
      return { ...state, showJoinNew: action.payload };
    case SHOW_HIDE_FORGOT:
      return { ...state, showForgot: action.payload };
    case SHOW_HIDE_SUCCESS:
      return { ...state, showForgotSuccess: action.payload };
    case SHOW_HIDE_REGISTER_SUCCESS:
      return { ...state, showRegisterMsgModel: action.payload };
    case SHOW_FORGET_PSWD_MSG:
      return { ...state, showForgotPswdMsg: action.payload };
    case SHOW_PSWD_RESET_SUCCESS:
      return { ...state, showForgetPswdReset: action.payload };

    case SHOW_HIDE_RESET:
      return { ...state, showHideReset: action.payload };
    case SHOW_HIDE_RESET_PASSOWRD:
      return { ...state, showHideResetPassword: action.payload };
    case SHOW_HIDE_FORCE_RESET:
      return { ...state, showHideForceReset: action.payload };
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case SET_ADDRESSES_TRUE:
      return {
        ...state,
        isLoadingAddress: action.payload,
      };
    case SET_CATEGORY_TOKEN:
      return { ...state, categoryAxioRequestToken: action.payload };
    case SET_PREV_PATH:
      return { ...state, prevPath: action.payload };
    case SET_COMPANY_LOGO:
      return { ...state, companyLogo: action.payload };
    case SET_TOTAL_FAVORITE_ITEMS:
      return {
        ...state,
        totalFavoriteItem: action.payload,
      };
    case SET_MYPURCHASE_HISTRY_ITEM:
      return {
        ...state,
        PurchaseHistryItm: action.payload,
      };
    case SHOW_HIDE_SALES_TAX:
      return { ...state, showSalesAndTax: action.payload };
    default:
      return state;
  }
};
