import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Route, Redirect } from "react-router-dom";
import AuthenticatingMsg from "../AuthenticatingMsg";
import { CARTTOKEN } from "../../common/Constants";
import { getBrowserStorage_Cookie } from "../../../utils/cookies";

const AuthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  let query = queryString.parse(rest.location.search);
  let isLogin = false;
  if (getBrowserStorage_Cookie(CARTTOKEN)) {
    isLogin = true;
  }

  return (
    <Route
      {...rest}
      render={
        (props) => (isLogin ? <Component {...props} /> : <Redirect to={`/`} />)
        //null
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
});

export default connect(mapStateToProps)(AuthenticatedRoute);
