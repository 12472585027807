import {
  SET_FILTER_ITEMS,
  SET_OPENED_ATTRIBUTES,
  SET_SELECTED_LAMINATE_GROUP,
} from "../constants";

const initialState = {
  filterItems: [],
  selectedLaminateGroup:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_ITEMS:
      return {
        ...state,
        filterItems: action.payload,
      };
    case SET_OPENED_ATTRIBUTES:
      return {
        ...state,
        openedAttributes: action.payload,
      };
    case SET_SELECTED_LAMINATE_GROUP:
      return {
        ...state,
        selectedLaminateGroup: action.payload,
      };
    default:
      return state;
  }
};
