import React from "react";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";
import _ from "lodash";
import moment from "moment";
import Img from "../../common/Img";
import { formatPrice, getShippingMethodsList } from "./utils";
import plantsMap from "./mappings/plantsMap.json";
import { getUOMString } from "./mappings/UomMapFunction";
import shippingMethodsMap from "./mappings/shippingMethodsMap.json";
import { SHIP_TO_COOKIE } from "../../common/Constants";
import { isMobileOnly } from "react-device-detect";
import ConfirmshoppingCartItemMobile from "../../mobile/shopping-cart/ConfirmshoppingCartItemMobile";

const ShoppingCartItem = ({ item, type }) => {
  let index;
  try {
    index = item.selectedOption.index.split(",").map((i) => parseInt(i));
  } catch (error) {
    index = [];
  }

  const shipMethod =
    item?.shipMethod ??
    item?.configuration?.shipping_method_1 ??
    item?.configuration?.shippingMethod1;

  // Render shipping method for backOrderQuantity_1
  const renderShippingMethodBOQ1 = () => {
    if (item?.status === "notInStock" || item?.status === "limitedStock") {
      if (index > 1) {
        return shippingMethodsMap["G"].displayName;
      } else {
        if (shipMethod === "0") {
          return shippingMethodsMap[
            getShippingMethodsList(item?.selectedOption.shippingMethods_1)[0]
          ].displayName;
        } else {
          return shipMethod ? shippingMethodsMap[shipMethod].displayName : "";
        }
      }
    }
    return "";
  };

  return !isMobileOnly ? (
    <>
      {type === "itemsInStockAlter" && (
        <>
          <tr>
            <td className="lineitem-shipping-details-blue" colSpan="8" tabIndex={0}>
              Qty {item.quantity} | Ship from{" "}
              {
                plantsMap[
                  item.selectedOption[`plant_${item.selectedOption.index}`]
                ]
              }{" "}
              | Ground Shipping
            </td>
          </tr>
        </>
      )}

      {type === "itemsComBackorder" &&
        index.map((i) => (
          <>
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_${i}`]} | Ship from{" "}
                {plantsMap[item.selectedOption[`plant_${i}`]]}: Backordered |{" "}
                {i === SHIP_TO_COOKIE
                  ? shippingMethodsMap[shipMethod].displayName
                  : "Ground Shipping"}
              </td>
            </tr>
          </>
        ))}

      {type === "itemsSplitBackorder" && (
        <>
          {index.map((i) => (
            <>
              {item.selectedOption[`quantity_${i}`] ? (
                <tr>
                  <td className="lineitem-shipping-details-blue" colSpan="8" tabIndex={0}>
                    Qty {item.selectedOption[`quantity_${i}`]} | Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_${i}`]]} |{" "}
                    {i === SHIP_TO_COOKIE
                      ? shippingMethodsMap[shipMethod].displayName
                      : "Ground Shipping"}
                  </td>
                </tr>
              ) : null}
            </>
          ))}
          {!_.isEmpty(item.selectedOption.backOrderQuantity_1) && (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_1`]} | Backordered:
                Ship from {plantsMap[item.selectedOption[`plant_1`]]}{" "}
                {moment(new Date(item.selectedOption.backOrderDate_1)).format(
                  "dddd, MMM. D, YYYY"
                )}{" "}
                | {renderShippingMethodBOQ1()}
              </td>
            </tr>
          )}
          {!_.isEmpty(item.selectedOption.backOrderQuantity_2) && (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_2`]} | Backordered:
                Ship from {plantsMap[item.selectedOption[`plant_2`]]}{" "}
                {moment(new Date(item.selectedOption.backOrderDate_2)).format(
                  "dddd, MMM. D, YYYY"
                )}
                | {"Ground Shipping"}
              </td>
            </tr>
          )}
          {!_.isEmpty(item.selectedOption.backOrderQuantity_3) && (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_3`]} | Backordered:
                Ship from {plantsMap[item.selectedOption[`plant_3`]]}
                {moment(new Date(item.selectedOption.backOrderDate_3)).format(
                  "dddd, MMM. D, YYYY"
                )}{" "}
                | {"Ground Shipping"}
              </td>
            </tr>
          )}
          {!_.isEmpty(item.selectedOption.backOrderQuantity_4) && (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_4`]} | Backordered:
                Ship from {plantsMap[item.selectedOption[`plant_4`]]}{" "}
                {moment(new Date(item.selectedOption.backOrderDate_4)).format(
                  "dddd, MMM. D, YYYY"
                )}
                | {"Ground Shipping"}
              </td>
            </tr>
          )}
          {!_.isEmpty(item.selectedOption.backOrderQuantity_5) && (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_5`]} | Backordered:
                Ship from {plantsMap[item.selectedOption[`plant_5`]]}{" "}
                {moment(new Date(item.selectedOption.backOrderDate_5)).format(
                  "dddd, MMM. D, YYYY"
                )}
                | {"Ground Shipping"}
              </td>
            </tr>
          )}
        </>
      )}

      {type === "itemsWillCall" && (
        <>
          <tr>
            <td className="lineitem-shipping-details-blue" colSpan="8" tabIndex={0}>
              Qty {item.selectedOption[`willCallQuantity`]} | Ship from{" "}
              {plantsMap[item.selectedOption[`willCallPlant`]]} |{" "}
              {"Will Call Pickup"}
            </td>
          </tr>
          {index[0] && item.selectedOption[`quantity_${index[0]}`] && (
            <tr>
              <td className="lineitem-shipping-details-blue" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`quantity_${index[0]}`]} | Ship from{" "}
                {plantsMap[item.selectedOption[`plant_${index[0]}`]]} |{" "}
                {index[0] === 1
                  ? shippingMethodsMap[
                      item.configuration[`shipping_method_${index[0]}`]
                    ].displayName
                  : "Ground Shipping"}
              </td>
            </tr>
          )}
          {index[0] ? (
            <tr>
              <td className="lineitem-shipping-details-gray" colSpan="8" tabIndex={0}>
                Qty {item.selectedOption[`backOrderQuantity_${index[0]}`]} |
                Ship from {plantsMap[item.selectedOption[`plant_${index[0]}`]]}:
                Backordered |{" "}
                {index[0] === 1
                  ? shippingMethodsMap[
                      item.configuration[`shipping_method_${index[0]}`]
                    ].displayName
                  : "Ground Shipping"}
              </td>
            </tr>
          ) : null}
        </>
      )}
      <tr>
        <td className="comfirm-order-img" tabIndex={0}>
          <a href="#/" title={parse(item.name)}>
            <Img
              title={item.name}
              style={{ height: 80 }}
              src={`${process.env.REACT_APP_IMAGE_URL}/${item.img}`}
              alt={item.name}
            />
          </a>
        </td>
        <td>
          <div className="d-flex">
            <span className="mr-1" tabIndex={0}>{item.sku} </span>
            {item.ciNumber && (
              <span className="red-text" tabIndex={0}> | CI: {item.ciNumber}</span>
            )}
          </div>
          <strong tabIndex={0}> {item.name}</strong>
          <br />
          <div>
            <span tabIndex={0}>PO/Job name: {item.configuration.poOrJobName}</span>
          </div>
        </td>
        <td tabIndex={0}>{item.quantity}</td>
        <td tabIndex={0}>{item.uomLable}</td>
        <td tabIndex={0}>
          $ {formatPrice(item.unitPrice, 3, false)}
          {item.priceUnit && getUOMString(item.priceUnit)
            ? ` / ${getUOMString(item.priceUnit)}`
            : ""}
        </td>
        <td tabIndex={0}>$ {item.extended ? formatPrice(item.extended) : "0.00"}</td>
      </tr>
    </>
  ) : (
    <ConfirmshoppingCartItemMobile item={item} type={type} />
  );
};

export default withRouter(ShoppingCartItem);
