import axios from "axios";
import { toQueryString } from "../utils";
import Cookies from "../utils/cookies";

import { getBrowserStorage, clearBrowserStorage } from "../../utils/cookies";

export default class Api {
  static init = (cb) => {
    cb(new Api());
  };

  constructor() {
    this.request = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    });

    this.cancelToken = axios.CancelToken.source();
    this.request.interceptors.request.use(
      (reqConfig) => {
        if (Cookies.check()) {
          const ck = Cookies.getJSON("wurthlac");
          if (!ck) return reqConfig;

          // if (reqConfig.url.includes("/auth/password-reset-confirm")) {

          //   reqConfig.headers.authorization = `Bearer ${userToken}`;
          // } else

          if (!reqConfig.url.includes("/auth/generate-token")) {
            const { expires_in } = ck;
            if (expires_in && expires_in > Date.now()) {
              const { access_token, token_type } = ck;
              reqConfig.headers.authorization = `${token_type} ${access_token}`;
            }
          } else if (reqConfig.method === "delete") {
            const { access_token, token_type } = ck;
            reqConfig.headers.authorization = `${token_type} ${access_token}`;
          }
        } else {
          if (sessionStorage.getItem("pwd-token")) {
            const access_token = sessionStorage.getItem("pwd-token");

            reqConfig.headers.authorization = `Bearer ${access_token}`;
          }
        }

        return reqConfig;
      },
      (err) => Promise.reject(err)
    );

    this.request.interceptors.response.use(
      (response) => {
        return response;
      },
      async function(error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.status_code === "RE_LOGIN"
        ) {
          clearBrowserStorage();
          sessionStorage.clear();

          Object.keys(Cookies.get()).forEach(function(cookieName) {
            if (
              cookieName !== "wurthlac-showaddress" &&
              cookieName !== "region" && 
              cookieName !== "remember-me"
            ) {
              Cookies.remove(cookieName);
            }
          });
          sessionStorage.setItem(
            "lmsdg",
            "Your password needs to be reset, please sign in to continue"
          );
          window.location.href = "/?pim=1";
        }
        return Promise.reject(error);
      }
    );
  }
  makePostRequest(url, data = null, config = null) {
    let params = data ? toQueryString(data, 1) : null;
    return this.request.post(url, params, {
      ...config,
      // ...{ cancelToken: this.cancelToken }
    });
  }

  makeGetRequest(url, data = null, config = null) {
    let params = data ? "?" + toQueryString(data, 1) : "";
    return this.request.get(url + params, {
      ...config,
      // ...{ cancelToken: this.cancelToken }
    });
  }

  makeDeleteRequest(url, config = null) {
    return this.request.delete(url, {
      ...config,
      // ...{ cancelToken: this.cancelToken }
    });
  }

  forceLogout(err = {}) {
    console.error("force logging out");
    Cookies.removeAll();
    return Promise.reject(err);
  }
}
