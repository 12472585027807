import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setCartData } from "../../../../store/actions/shoppingCart";
import { withRouter } from "react-router-dom";
import CreditCard from "./CreditCard";
import { serviceFetchCreditCardListEP } from "../services/servicesEP";
import _ from "lodash";
import ErrorBoundary from "../../../common/errorHandling/ErrorBoundary";
import AddCreditCard from "../AddCreditCard";
import { Prompt } from "react-router";
import { handleError } from "../../../../utils/utils";
import { isMobileOnly } from "react-device-detect";

class CreditCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardList: [],
      paymentOptions: [],
      cardsAvail: false,
    };
  }

  appendTempCard = async (tempCard) => {
    try {
      document
        .getElementById(`credit-card-${String(tempCard.number).slice(0, 8)}`)
        .click();
      this.props.setPaymentTokenServer(tempCard.number);
    } catch (error) {}
  };

  async componentDidMount() {
    if (this.props.cartData && this.props.cartData.configuration) {
      const paymentOptions = this.props.cartData.configuration.avail_payment_options
        .slice(1, -1)
        .split(",");
      const cardsAvail = paymentOptions.find((option) => option[1] === "V");
      this.setState({ cardsAvail: !!cardsAvail });
      if (!_.isEmpty(cardsAvail)) {
        const creditCardList = await serviceFetchCreditCardListEP();
        if (creditCardList) await this.setState({ creditCardList });
      }
    }
  }

  reloadCardList = async (token) => {
    try {
      const creditCardList = await serviceFetchCreditCardListEP();
      await this.setState({ creditCardList });
      const ele = document.getElementById(
        `credit-card-${String(token).slice(0, 8)}`
      );
      // .click();
      if (ele) {
        ele.click();
        this.props.setPaymentTokenServer(token);
      }
    } catch (error) {
      this.props.setErrorMessage(handleError(error));
    }
  };

  render() {
    const { creditCardList, selectLatest } = this.state;
    return (
      <ErrorBoundary>
        <div className={isMobileOnly && "set-scroll"}>
          {isMobileOnly && this.props.otherPaymentOption}
          {!_.isEmpty(creditCardList) &&
            creditCardList.map((card, idx) => (
              <CreditCard
                reloadCardList={this.reloadCardList}
                key={idx}
                setErrors={this.props.setErrors}
                selectLatest={selectLatest}
                setPaymentTokenServer={this.props.setPaymentTokenServer}
                setErrorMessage={this.props.setErrorMessage}
                loading={this.props.loading}
                showHidePaymentOptionMobileModal={() =>
                  this.props.showHidePaymentOptionMobileModal()
                }
                {...card}
              />
            ))}
          {this.props.tempCreditCards && (
            <CreditCard
              reloadCardList={this.reloadCardList}
              setErrors={this.props.setErrors}
              selectLatest={selectLatest}
              setPaymentTokenServer={this.props.setPaymentTokenServer}
              setErrorMessage={this.props.setErrorMessage}
              loading={this.props.loading}
              {...this.props.tempCreditCards}
              showHidePaymentOptionMobileModal={() =>
                this.props.showHidePaymentOptionMobileModal()
              }
            />
          )}
          {this.state.cardsAvail && (
            <AddCreditCard
              onSingleCheckout={false}
              reloadCardList={this.reloadCardList}
              appendTempCard={this.appendTempCard}
              creditCardList={this.state.creditCardList}
              showHidePaymentOptionMobileModal={() =>
                this.props.showHidePaymentOptionMobileModal()
              }
              tempCreditCards={this.props.tempCreditCards}
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  cartData: state.shoppingCart.cartData,
  tempCreditCards: state.shoppingCart.tempCreditCards,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreditCardList));
