import { SET_FILTER_ITEMS, SET_OPENED_ATTRIBUTES } from "../constants";

const initialState = {
  filterItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_ITEMS:
      return {
        ...state,
        filterItems: action.payload,
      };
    case SET_OPENED_ATTRIBUTES:
      return {
        ...state,
        openedAttributes: action.payload,
      };

    default:
      return state;
  }
};
