import { SET_BULK_UPLOAD_LOADING } from "../constants";

const initialState = {
  bulkUploadLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BULK_UPLOAD_LOADING:
      return {
        bulkUploadLoading: action.payload,
      };
    default:
      return state;
  }
};
