import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import shippingMethodsMap from "../mappings/shippingMethodsMap.json";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateCartProduct,
  setShippingProdLanding,
} from "../../../../store/actions/shoppingCart";
import { withRouter } from "react-router";

import { getShippingMethodsList, getDefaultShippTo } from "../utils";
import plantsMap from "../mappings/plantsMap.json";
import xPlantsMap from "../mappings/xPlantsMap.json";
import {
  DEFAULT_PLANT,
  INVENTORY_SHIPPING,
  SINGLE_CHECKOUT_FORM,
  DEFAULT_SHIPTO,
} from "../../../common/Constants";

import { getBrowserStorage } from "../../../../utils/cookies";

const ShippingMethodSelect = ({
  shippingMethods,
  sku,
  xplant,
  actions,
  setIsEditMode,
  shippingProdLanding,
  location,
  cartItems,
  optionEneble,
  checkShipingMethodValue,
  onShoppingCart,
  shoppingItem,
  shoppingCart,
  optionType,
}) => {
  const [defaultSelect, setDefault] = useState(DEFAULT_SHIPTO);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(
    DEFAULT_SHIPTO
  );
  const [allShippingMethods, setAllShippingMethods] = useState([]);
  const [defaultPlant, setDefaultPlant] = useState(
    getBrowserStorage(DEFAULT_PLANT)
  );
  const inInventoryPage =
    location.pathname === INVENTORY_SHIPPING ? true : false;
  const inSingleCheckout =
    location.pathname === SINGLE_CHECKOUT_FORM ? true : false;
  const item = cartItems.find((item) => item.sku === sku);

  // const notOnProdLanding =
  //   !_.isEmpty(item) && (inInventoryPage || inSingleCheckout);
  const notOnProdLanding = !_.isEmpty(item) && !shippingProdLanding;

  const setShippingMethod = async (shipMethod) => {
    setDefault(shipMethod);
    checkShipingMethodValue(shipMethod);
    if (notOnProdLanding) {
      if (setIsEditMode) setIsEditMode(true);
      const itemUpdated = Object.assign({}, item);

      itemUpdated.configuration.shipping_method_1 = shipMethod;
      actions.updateCartProduct(itemUpdated);
    } else if (!onShoppingCart && shippingProdLanding.sku === sku) {
      // handle if item not in cart
      const product = Object.assign({}, shippingProdLanding);
      product.shipMethod = shipMethod;
      actions.setShippingProdLanding(product);
    }
  };

  useEffect(() => {
    let defaultShipMethod = getDefaultShippTo();
    const availShipMethods = getShippingMethodsList(shippingMethods);

    defaultShipMethod =
      defaultShipMethod === "G" && availShipMethods.includes("C")
        ? "C"
        : defaultShipMethod;

    if (optionType === "backOrderAll" && defaultShipMethod === "0") {
      defaultShipMethod = availShipMethods[0];
    }
    if (notOnProdLanding) {
      setDefault(defaultShipMethod);
      checkShipingMethodValue(defaultShipMethod);
      if (setIsEditMode) setIsEditMode(true);
      const itemUpdated = Object.assign({}, item);

      itemUpdated.configuration.shipping_method_1 = defaultShipMethod;
      actions.updateCartProduct(itemUpdated);
    } else if (onShoppingCart) {
      setDefault(shoppingItem.shipMethod);
    } else if (shippingProdLanding.sku === sku) {
      setDefault(defaultShipMethod);
      checkShipingMethodValue(defaultShipMethod);
      // handle if item not in cart
      const product = Object.assign({}, shippingProdLanding);

      product.shipMethod = defaultShipMethod;

      actions.setShippingProdLanding(product);
    }
  }, []);

  useEffect(() => {
    const selectedItems = cartItems.find((item) => item.sku === sku);
    setSelectedShippingMethod(selectedItems);
    let defaultShipMethod = getDefaultShippTo();
    if (optionType === "backOrderAll" && defaultShipMethod === "0") {
      const types = shippingMethods
        .split(",")
        .slice(0, -1)
        .join(",");
      setAllShippingMethods(getShippingMethodsList(types));
    } else {
      setAllShippingMethods(getShippingMethodsList(shippingMethods));
    }
  }, [shoppingCart && cartItems]);

  const showAllShippingMethods = (shipMethod) => {
    if (shipMethod) {
      const filterShippingMethod = allShippingMethods.filter(
        (methods) => methods !== shipMethod
      );
      if (filterShippingMethod) {
        return (
          <Fragment>
            {filterShippingMethod.map((item, idx) => {
              return item == "W" ? (
                <option value={item} key={idx}>
                  {" "}
                  {shippingMethodsMap[item].codeName}
                  {plantsMap[defaultPlant]}
                </option>
              ) : item == "X" ? (
                <option value={item} key={idx}>
                  {" "}
                  {shippingMethodsMap[item].codeName}
                  {plantsMap[xPlantsMap[xplant]]}
                  {""}
                </option>
              ) : (
                <option value={item} key={idx}>
                  {" "}
                  {shippingMethodsMap[item].codeName}
                  {""}
                </option>
              );
            })}
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          {allShippingMethods.map((item, idx) => {
            return item == "W" ? (
              <option value={item} key={idx}>
                {" "}
                {shippingMethodsMap[item].codeName}
                {plantsMap[defaultPlant]}
              </option>
            ) : item == "X" ? (
              <option value={item} key={idx}>
                {" "}
                {shippingMethodsMap[item].codeName}
                {plantsMap[xPlantsMap[xplant]]}
                {""}
              </option>
            ) : (
              <option value={item} key={idx}>
                {" "}
                {shippingMethodsMap[item].codeName}
                {""}
              </option>
            );
          })}
        </Fragment>
      );
    }
  };

  const showSelectedShippingMethod = () => {
    return (
      <Fragment>
        {allShippingMethods.map((item, idx) => {
          if (selectedShippingMethod.shipMethod === item) {
            if (selectedShippingMethod.shipMethod && item === "W") {
              return (
                <Fragment>
                  <option value={selectedShippingMethod.shipMethod} key={idx}>
                    {" "}
                    {
                      shippingMethodsMap[selectedShippingMethod.shipMethod]
                        .codeName
                    }
                    {plantsMap[defaultPlant]}
                  </option>
                  {showAllShippingMethods(selectedShippingMethod.shipMethod)}
                </Fragment>
              );
            } else if (selectedShippingMethod.shipMethod && item === "X") {
              return (
                <Fragment>
                  <option value={selectedShippingMethod.shipMethod} key={idx}>
                    {" "}
                    {
                      shippingMethodsMap[selectedShippingMethod.shipMethod]
                        .codeName
                    }
                    {plantsMap[xPlantsMap[xplant]]}
                    {""}
                  </option>
                  {showAllShippingMethods(selectedShippingMethod.shipMethod)}
                </Fragment>
              );
            } else if (selectedShippingMethod.shipMethod && item === "T") {
              return (
                <Fragment>
                  <option value={selectedShippingMethod.shipMethod} key={idx}>
                    {" "}
                    {
                      shippingMethodsMap[selectedShippingMethod.shipMethod]
                        .codeName
                    }
                    {""}
                  </option>
                  {showAllShippingMethods(selectedShippingMethod.shipMethod)}
                </Fragment>
              );
            } else if (selectedShippingMethod.shipMethod && item === "G") {
              return (
                <Fragment>
                  <option value={selectedShippingMethod.shipMethod} key={idx}>
                    {" "}
                    {
                      shippingMethodsMap[selectedShippingMethod.shipMethod]
                        .codeName
                    }
                    {""}
                  </option>
                  {showAllShippingMethods(selectedShippingMethod.shipMethod)}
                </Fragment>
              );
            } else if (selectedShippingMethod.shipMethod && item === "0") {
              return (
                <Fragment>
                  <option value={selectedShippingMethod.shipMethod} key={idx}>
                    {" "}
                    {
                      shippingMethodsMap[selectedShippingMethod.shipMethod]
                        .codeName
                    }
                    {""}
                  </option>
                  {showAllShippingMethods(selectedShippingMethod.shipMethod)}
                </Fragment>
              );
            }
          }
        })}
      </Fragment>
    );
  };

  const showDefaultShippingMethod = () => {
    if (
      _.isEmpty(selectedShippingMethod) === true &&
      getShippingMethodsList(shippingMethods)
    ) {
      return defaultSelect;
    }
  };

  return (
    <select
      value={showDefaultShippingMethod()}
      className="custom-select mr-sm-2"
      as="select"
      disabled={optionEneble}
      onChange={(e) => setShippingMethod(e.target.value)}
    >
      {_.isEmpty(selectedShippingMethod) === true &&
        getShippingMethodsList(shippingMethods) &&
        showAllShippingMethods()}
      {_.isEmpty(selectedShippingMethod) === false &&
        shoppingCart.cartItems.length > 0 &&
        showSelectedShippingMethod()}
    </select>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { updateCartProduct, setShippingProdLanding },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartItems: state.shoppingCart.cartItems,
  cartData: state.shoppingCart.cartData,
  shippingProdLanding: state.shoppingCart.shippingProdLanding,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShippingMethodSelect));
