import React from "react";
import ContentLoader from "react-content-loader";

const AvailabilityBreakdownLoader = (props) => (
  <ContentLoader rtl speed={1} width="100%" height={78} {...props}>
    <rect x="0" y="0" rx="4" ry="4" width="200" height="14" />
    <rect x="0" y="24" rx="4" ry="4" width="300" height="14" />
    <rect x="0" y="48" rx="4" ry="4" width="570" height="14" />
  </ContentLoader>
);

export default AvailabilityBreakdownLoader;
