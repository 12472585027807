import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { SHOPPING_CART } from "../../../components/common/RouterConstants";
import invPath from "../../../assets/img/svg/invPath.svg";
import { Link } from "react-router-dom";
import OrderSummary from "./OrderSummary";
import ShippingAddress from "./ShippingAddressNew";
import ShippingInstructions from "./ShippingInstructions";
import { checkToken } from "./commonFunc";
import { setCartData } from "../../../store/actions/shoppingCart";
import PaymentOptions from "./PaymentOptions";
import {
  setPaymentToken,
  getTokenActionUrl,
} from "../../../services/paymentAPI";
import { serviceGetBillingAddresses } from "./services/servicesEP";
import { serviceSetCreditCardDetails } from "./services/servicesPIM";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ErrorAlert from "../../common/ErrorAlert";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Spinner from "./common/Spinner";
import Img from "../../common/Img";
import arrowBackward from "../../../assets/img/svg/back_arrow_gray.svg";
import { isMobileOnly } from "react-device-detect";
import {
  TriggerReset,
  TriggerEventAddPayment,
  TriggerEventShippingInfo,
} from "../../common/googleTagManager/TriggerFunction";
import PageTitle from "../../common/seo/PageTitle";
import { REQ_HEADER } from "./../shopping-cart/Tokens";
import { updateCartConfiguration } from "app/services/servicesPIM";
import { isExpiredCreditCard } from "./utils";

const InventoryShippingNew = ({ ...props }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [disableContinueBtn, setDisableContinueBtn] = useState(false);

  useEffect(() => {
    TriggerReset();
    const cartData = props.shoppingCart.cartData;
    if (
      cartData &&
      cartData.configuration &&
      cartData.configuration.selectedPaymentOption
    ) {
      setErrorMsg("");
    }
  }, [props.shoppingCart, props.shoppingCart.cartData]);

  const handleContinue = async () => {
    try {
      setDisableContinueBtn(true);

      const cartData = props.shoppingCart.cartData;

      if (_.isEmpty(cartData.configuration.selectedPaymentOption)) {
        setErrorMsg("Please select a payment option");
        window.scroll(0, 0);
        return;
      }

      if (
        cartData.configuration.expireDate &&
        isExpiredCreditCard(cartData.configuration.expireDate)
      ) {
        setErrorMsg("Please select a valid credit card!");
        window.scroll(0, 0);
        return;
      }

      // const tokenActionUrlData = await getTokenActionUrl(cartData);

      const {
        cardToken,
        cardName,
        cardType,
        paymentMethod,
        expireDate,
        paymentToken,
      } = cartData.configuration;
      await Promise.all([
        checkToken(),
        //setPaymentToken(cardToken, tokenActionUrlData),
        async () => {
          await updateCartConfiguration(
            { paymentToken },
            "shipping_and_payment"
          );
        },
      ]);

      if (paymentMethod[0] === "V") {
        const response = await serviceSetCreditCardDetails(
          cardName,
          cardType,
          paymentMethod,
          expireDate,
          cardToken
        );
        if (response && response.data && response.status === 409) {
          setErrorMsg("Expiration date should be a future date");
          window.scroll(0, 0);
          return;
        }
      } else {
        await serviceSetCreditCardDetails("", "", paymentMethod, "", cardToken);
      }

      // check billing address exist in EP Side
      const addressFroEP = await serviceGetBillingAddresses();
      if (addressFroEP) {
        if (!addressFroEP.chosen || addressFroEP.chosen.length == 0) {
          //  billing address not exist in EP Side update it by choice option
          if (addressFroEP.choice) {
            const hrefUrl = addressFroEP.choice[0].selectUrl.href;
            const res = await axios({
              method: "POST",
              url: hrefUrl,
              headers: REQ_HEADER,
            });
          }
        }
        cartData.configuration.paymentToken = cardToken;
        props.actions.setCartData(cartData);
        props.history.push("/confirm-order");
        // Call google analytic
        TriggerEventAddPayment(paymentMethod, props.shoppingCart);
        TriggerEventShippingInfo(props.shoppingCart);
      }
    } catch (error) {
      const { cartData } = props.shoppingCart;
      cartData.configuration.cardToken = "";
      cartData.configuration.paymentMethod = "";
      props.actions.setCartData(cartData);
      return;
    } finally {
      setDisableContinueBtn(false);
    }
  };
  return (
    <BlockUi tag="div" blocking={disableContinueBtn} loader={<Spinner />}>
      <PageTitle title={"Shipping & Payment"}></PageTitle>
      {isMobileOnly && (
        <div className="clearfix col-md-12 checkout-process mt-0 checkout-process-mob">
          <Link
            title="Back"
            className="link-black d-flex"
            to={SHOPPING_CART}
            tabIndex="0"
          >
            <Img src={arrowBackward} alt="" />
            {"Shopping Cart"}
          </Link>
        </div>
      )}
      <div className="container">
        {!isMobileOnly && (
          <div className="clearfix col-md-12 checkout-process">
            <Link
              title="Back"
              className="link-black d-flex"
              to={SHOPPING_CART}
              tabIndex="0"
            >
              <Img src={arrowBackward} alt="" />
              Back
            </Link>

            <div className="step-wrapper">
              <img src={invPath} alt="" />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-9 shipping-payment-wrapper">
            {!isMobileOnly && (
              <div className="section-heading-with-line">
                <h1 role="heading" aria-level="1" tabIndex={0}>
                  Shipping & Payment
                </h1>
                <span></span>
              </div>
            )}
            {errorMsg && (
              <ErrorAlert
                errorMessage={errorMsg}
                handleErrorClose={() => setErrorMsg("")}
              />
            )}
            <ShippingAddress />

            <ShippingInstructions />

            <PaymentOptions />
          </div>
          <div
            className={`col-md-3 s-cart-right-side-bar m-t-30 ${
              isMobileOnly && "order-summery"
            }`}
          >
            <OrderSummary />

            {isMobileOnly ? (
              <div className="summary-button-wrapper">
                <button
                  title="CONTINUE"
                  className="mt-2 summary-row btn-row btn red-btn d-flex justify-content-center"
                  onClick={() => {
                    handleContinue();
                  }}
                  disabled={disableContinueBtn}
                >
                  CONTINUE TO REVIEW
                </button>
              </div>
            ) : (
              <button
                title="CONTINUE"
                className="mt-2 summary-row btn-row btn red-btn d-flex justify-content-center"
                onClick={() => {
                  handleContinue();
                }}
                disabled={disableContinueBtn}
              >
                CONTINUE
              </button>
            )}
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryShippingNew);
