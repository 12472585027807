import React from "react";
import createMarkup from "./Markup";
import Img from "../common/Img";
import errorOutline from "../../assets/img/svg/error_outline.svg";
import close from "../../assets/img/svg/close.svg";

const ErrorAlert = ({ errorMessage, handleErrorClose }) => {
  return (
    <div
      style={{
        display: "flex",
        marginTop: 5,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="alert alert-error"
      role="alert"
      tabIndex={0}
    >
      <div style={{ display: "flex", flex: 1 }}>
        <div className="alert-icon">
          <Img src={errorOutline} alt="" />
        </div>
        <div className="error-content flex-grow-1">
          <div className="error-title">
            <span style={{ color: "#c00" }} tabIndex={0}>Error!</span>
          </div>
          <span
            style={{ fontSize: 14, color: "#c00" }}
            dangerouslySetInnerHTML={createMarkup(errorMessage)}
            tabIndex={0}
          />
        </div>
      </div>
      {handleErrorClose && (
        <a
          onClick={handleErrorClose}
          onKeyPress={handleErrorClose}
          tabIndex="0"
          className="error-close"
        >
          <Img src={close} alt="Error message close button" />
        </a>
      )}
    </div>
  );
};

export default ErrorAlert;
