import React from "react";
import moment from "moment";
import OrderSummary from "./OrderSummary";
import { getAddress } from "./commonFunc";
import { getSubTotal, formatPrice } from "./utils";
import Img from "../../common/Img";
import plansMap from "./mappings/plantsMap.json";
import shippingMethodsMap from "./mappings/shippingMethodsMap.json";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import { paymentDetails } from "../utils";

import ShippingAddressView from "./common/ShippingAddressView";
import _ from "lodash";
import { getBrowserStorage } from "../../../utils/cookies";

const ShippingDetails = ({ selectedOption, configuration }) => {
  const plants = selectedOption.index.split(",");
  return (
    <table>
      <tbody>
        {plants.map((plantIndex) => (
          <tr key={plantIndex} style={{ backgroundColor: "transparent" }}>
            <td style={{ width: 150 }}>
              {plansMap[selectedOption[`plant_${parseInt(plantIndex)}`]]}
            </td>
            <td>
              {configuration[`shipping_method_${parseInt(plantIndex)}`]
                ? shippingMethodsMap[
                    configuration[`shipping_method_${parseInt(plantIndex)}`]
                  ].displayName
                : "Ground Shipping"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

class QuickOrderRecieptBody extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      configuration,
      selectedAddressDetails,
      billingAddress,
      selectedShippingAddress,
      cartItems,
      orderNumber,
      paymentToken,
    } = this.props;
    const { driverNote } = configuration;
    let orderDate = getBrowserStorage("orderDate");
    return (
      <ErrorBoundary>
        <div style={{ padding: 30 }} className="quick-order-receipt">
          <div className="section-heading-with-line">
            <h2 role="heading" aria-level="2" tabIndex={0}>Order Reciept</h2>
            <span />
          </div>
          <div className="order-details">
            <div className="row">
              <div className="col-3">
                <p className="order-receipt-order-data order-no bold" tabIndex={0}>
                  Order No. : <span>{orderNumber}</span>
                </p>
                <br />
                <div>
                  <div className="bold mb-2" tabIndex={0}>Shipping To:</div>
                  {!_.isEmpty(selectedShippingAddress) && (
                    <ShippingAddressView
                      selectedShippingAddress={selectedShippingAddress}
                      cartAttn={configuration.attnName}
                    />
                  )}
                </div>
                <br />
                <div>
                  <div className="bold mb-2">Billed To:</div>
                  <p tabIndex={0}>
                    <span className="bold">{selectedAddressDetails.name}</span>{" "}
                    <br />
                    <span>{getAddress(billingAddress)}</span>
                  </p>
                </div>
                <div className="mt-4">
                  {driverNote && (
                    <div className="d-flex">
                      <p className="bold mb-2" tabIndex={0}>Driver's Note:&nbsp;</p>
                      <p tabIndex={0}>{driverNote}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-3">
                <p tabIndex={0}>
                  <span className="bold">Order Date:</span>{" "}
                  <p>
                    {moment(configuration.pickDate, "MM-DD-YYYY").format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </p>

                {configuration &&
                (configuration.po_job === "J" ||
                  configuration.po_job === "B") ? (
                  <p tabIndex={0}>
                    <br />
                    <span className="bold">Job Name:</span>{" "}
                    {configuration.jobName}
                  </p>
                ) : null}

                {configuration &&
                (configuration.po_job === "P" ||
                  configuration.po_job === "B") ? (
                  <p tabIndex={0}>
                    <br />
                    <span className="bold">PO No:</span> {configuration.po}
                  </p>
                ) : null}
                <br />
                {configuration.coupon && (
                  <p tabIndex={0}>
                    <strong>Promo Code: </strong>
                    {configuration.coupon}
                  </p>
                )}
              </div>
              <div className="col-3">
                <p className="bold" tabIndex={0}>Pick Date</p>

                {configuration && configuration.pickDate ? (
                  <p>
                    {moment(configuration.pickDate, "MM-DD-YYYY").format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                ) : (
                  <p>Pick date not available</p>
                )}
                <br />
                <div className="payment-type">
                  <p className="payment-type-head order-receipt-head bold" tabIndex={0}>
                    Payment Method:
                  </p>
                  <div className="order-receipt-data-wraper payment-method">
                    {paymentDetails(configuration, paymentToken)}
                  </div>
                </div>
              </div>
              <div className="col-3 s-cart-right-side-bar float-right">
                <OrderSummary />
              </div>
            </div>
          </div>
          <div className="product-table-wrapper">
            <table className="table table-striped product-list">
              <thead>
                <tr>
                  <td style={{ width: 120 }} className="" tabIndex={0}>
                    Item
                  </td>
                  <td tabIndex={0}>Qty</td>
                  <td tabIndex={0}>UOM</td>
                  <td tabIndex={0}>Description</td>
                  <td tabIndex={0}>Unit Price</td>
                  <td tabIndex={0}>Sub Total</td>
                  <td tabIndex={0}>Custom Item #</td>
                  <td tabIndex={0}>PO/Job name</td>
                  <td tabIndex={0}>Branch</td>
                  <td tabIndex={0}>
                    Shipping <br />
                    Method
                  </td>
                </tr>
              </thead>
              <tbody>
                {cartItems
                  ? cartItems.map((item, i) => (
                      <tr key={i}>
                        <td tabIndex={0}>{item.sku}</td>
                        <td tabIndex={0}>{item.quantity}</td>
                        <td tabIndex={0}>{item.productUnit}</td>
                        <td tabIndex={0}>{item.name}</td>
                        <td style={{ whiteSpace: "nowrap" }} tabIndex={0}>
                          $ {formatPrice(item.unitPrice)}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }} tabIndex={0}>
                          ${" "}
                          {formatPrice(
                            getSubTotal(item.quantity, item.unitPrice)
                          )}
                        </td>
                        <td tabIndex={0}>{item.ciNumber}</td>
                        <td tabIndex={0}>{item.configuration.poOrJobName}</td>
                        <td style={{ padding: 0 }} colSpan="2" tabIndex={0}>
                          <ShippingDetails {...item} />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <table className="table">
              <tbody />
            </table>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default QuickOrderRecieptBody;
