import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { setBrowserStorage } from "../../../utils/cookies";
import { isMobileOnly } from "react-device-detect";
import { updateCartConfiguration } from "app/services/servicesPIM";
import { setCartData } from "app/store/actions/shoppingCart";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function ChangeShippingModal({
  show,
  setShow,
  choice,
  chosen,
  setChosen,
  updateAddressChanged,
  closeModal,
  cartData,
  actions,
}) {
  const [newAddress, setNewAddress] = useState(null);
  const changeAddress = (addressData) => {
    setNewAddress(addressData);
  };

  useEffect(() => {
    setNewAddress(chosen);
  }, [chosen]);

  const changeAddressConfirm = async () => {
    setChosen(newAddress);
    setShow(false);

    setBrowserStorage("selectedShippingAddress", JSON.stringify(newAddress));
    if (newAddress?.id) {
      await updateCartConfiguration(
        {
          shippingAddressId: newAddress.id,
          isAPrimaryShippingAddress: newAddress.is_primary,
        },
        "shipping_and_payment"
      );

      const newCartData = {
        ...cartData,
        configuration: {
          ...cartData?.configuration,
          shippingAddressId: newAddress.id,
          isAPrimaryShippingAddress: newAddress.is_primary,
        },
      };
      actions.setCartData(newCartData);
    }
    await updateAddressChanged("T");
  };

  const openAddNewAddress = () => {
    closeModal();
    if (JSON.stringify(chosen) !== JSON.stringify(newAddress)) {
      setNewAddress(chosen);
    }
  };

  const handleClose = () => {
    setShow(false);

    if (JSON.stringify(chosen) !== JSON.stringify(newAddress)) {
      setNewAddress(chosen);
    }
  };

  return (
    <Modal
      className={
        isMobileOnly
          ? "bottom-modal change-shipping-address-popup"
          : "change-shipping-address-popup"
      }
      backdrop="static"
      show={show}
      onHide={() => handleClose()}
      tabIndex="0"
    >
      <Modal.Header>
        <Modal.Title tabIndex="0">
          {isMobileOnly ? "Shipping Address" : "Change Shipping Address"}
        </Modal.Title>

        <button
          type="button"
          className="close"
          onClick={() => {
            handleClose();
          }}
          title="Close"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <div className="change-ship-add-wpr">
        <div className={`${isMobileOnly ? "row set-scroll" : "row"}`}>
          {chosen &&
            choice.map((addressData) => (
              <div
                className="col-md-4 col-sm-6"
                key={addressData["street-address"]}
                onClick={() => changeAddress(addressData)}
                onKeyPress={() => changeAddress(addressData)}
              >
                <div
                  className={
                    JSON.stringify(addressData) === JSON.stringify(newAddress)
                      ? "address-block shadow-on-white checked-blue-border"
                      : "address-block shadow-on-white"
                  }
                  tabIndex="0"
                >
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      name="address"
                      class="custom-control-input "
                      value={addressData["street-address"]}
                      checked={
                        JSON.stringify(addressData) ===
                        JSON.stringify(newAddress)
                      }
                      tabIndex="0"
                    />
                    <label class="custom-control-label" for="stAddress">
                      <span>
                        {addressData["street-address"]}
                        , <br />
                        {addressData["locality"]}, {addressData["region"]},{" "}
                        {addressData["postal-code"]}
                        <br /> Phone : {addressData["phone-number"]}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ))}
          {isMobileOnly && (
            <div className="add-new-address-row">
              <Button
                className="add-shipto shadow-on-white add-new-address-btn"
                onClick={() => openAddNewAddress()}
              >
                <i class="material-icons">add</i> Add New Address
              </Button>
            </div>
          )}
        </div>
      </div>
      {isMobileOnly ? (
        <div className="button-wpr mob-btn-wrapper">
          <div className="row two-btn-row">
            <Button
              className="btn-add-to-cart red-border-btn"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>

            <Button
              className="btn-add-to-cart red-btn"
              onClick={() => changeAddressConfirm()}
            >
              Done
            </Button>
          </div>
        </div>
      ) : (
        <div className="button-wpr">
          <Button className="add-shipto" onClick={() => openAddNewAddress()}>
            <i class="material-icons">add</i> Add Shipping Address
          </Button>
          <Button
            className="btn-add-to-cart red-btn"
            onClick={() => changeAddressConfirm()}
          >
            Confirm
          </Button>
        </div>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  cartData: state.shoppingCart.cartData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeShippingModal);
