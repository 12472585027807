import { getBrowserName } from '../browserUtils';
import { removeLastSubdirectoryFromUrl } from '../stringUtils';

export const CONTENT_TYPE_JSONL = 'application/x-ndjson';

export const CONTENT_TYPE_JSON = 'application/json';

export const MULTI_SEARCH_ENGINE = {
  PRODUCT: process.env.REACT_APP_ELASTIC_SEARCH_PRODUCT_ENGINE!,
  BRAND: process.env.REACT_APP_ELASTIC_SEARCH_BRAND_ENGINE!,
  CATEGORY: process.env.REACT_APP_ELASTIC_SEARCH_CATEGORY_ENGINE!,
} as const;

export const WEB_VISIBLE_STATUS_PRODUCT = {
  YES: 'Y',
  NO: 'N',
} as const;

export const WEB_VISIBLE_STATUS_CATEGORY = {
  YES: 1,
  NO: 0,
} as const;

export const CURRENT_RUNNING_BROWSER = getBrowserName();

export const IS_RUNNING_BROWSER_ON_MOBILE = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const ELASTIC_SEARCH_CLICK_ANALYTICS_API_URL = `${removeLastSubdirectoryFromUrl(process.env.REACT_APP_ELASTIC_SEARCH_APP_SEARCH_API_URL)}/click`;

export const ELASTIC_SEARCH_API_URLS = [
  process.env.REACT_APP_ELASTIC_SEARCH_MULTI_SEARCH_API_URL!,
  process.env.REACT_APP_ELASTIC_SEARCH_APP_SEARCH_API_URL!,
  process.env.REACT_APP_ELASTIC_INDEX_SEARCH_API_URL,
  ELASTIC_SEARCH_CLICK_ANALYTICS_API_URL,
] as const;

export const FILTER_BY = {
  BRAND: 'brand',
} as const;

export const ES_ANALYTICS = {
  MATERIAL_NUMBER: 'esSku',
  PRODUCT_TITLE: 'esTitle',
  SEARCH_QUERY: 'esQuery',
  PRODUCT_DOCUMENT_ID: 'esDocId',
  PRODUCT_REQUEST_ID: 'esReqId',
} as const;

export const ANALYTICS_CAPTURED_PAGE = {
  SEARCH_PAGE: 'search-page',
  ADD_TO_CART_PAGE: 'add-to-cart',
} as const;
