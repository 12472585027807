import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

/**
 * Used to set the canonical URL in the `<head>`
 */
const CanonicalLinkSetter = () => {
  const location = useLocation();

  return (
    <Helmet>
      <link
        rel="canonical"
        href={`${process.env.REACT_APP_SITE_URL.replace(/\/$/g, "")}${
          location.pathname
        }`}
      />
    </Helmet>
  );
};

export default CanonicalLinkSetter;
