import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OrderRecieptBody from "./OrderRecieptBody";
import Img from "../../common/Img";
import orderReciept from "../../../assets/img/order-reciept.svg";
import backArrowGray from "../../../assets/img/svg/back_arrow_gray.svg";
import {
  setCartData,
  setCartItems,
  setOrderPrice,
} from "../../../store/actions/shoppingCart";
import { bindActionCreators } from "redux";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import Spinner from "./common/Spinner";
import {
  getBrowserStorage,
  removeBrowserStorage,
} from "../../../utils/cookies";
import BlockUi from "react-block-ui";
import { isMobileOnly } from "react-device-detect";
import OrderRecieptBodyMobile from "../../mobile/shopping-cart/OrderRecieptBodyMobile";
import {
  TriggerReset,
  TriggerEventPurchase,
} from "../../common/googleTagManager/TriggerFunction";
import PageTitle from "../../common/seo/PageTitle";
import { Redirect } from "react-router-dom";

const OrderReciept = (props) => {
  const orderNumber = getBrowserStorage("orderNum");

  const cartItems =
    props?.shoppingCart?.cartItems?.filter((item) => !!item?.selectedOption) ??
    [];
  const configuration = props?.shoppingCart?.cartData?.configuration ?? {};

  useEffect(() => {
    TriggerReset();
    getLoadCartAnalytics();

    return () => {
      // Cleanup when leaving the receipt page
      props.actions.setCartItems([]);
      props.actions.setOrderPrice({});
      removeBrowserStorage("orderNum");
    };
  }, []);

  const displayEmailAddress = () => {
    if (sessionStorage.getItem("changedEmail")) {
      return sessionStorage.getItem("changedEmail");
    } else if (
      !!props.cartData &&
      !!props.cartData.configuration &&
      props.cartData.configuration.orderEmail
    ) {
      return props.cartData.configuration.orderEmail;
    } else if (
      !!props.cartData &&
      !!props.cartData.configuration &&
      props.cartData.configuration.user_email
    ) {
      return props.cartData.configuration.user_email;
    } else {
      return "";
    }
  };

  const getLoadCartAnalytics = async () => {
    TriggerEventPurchase(
      props.orderPrice,
      props.cartItems,
      props.cartData,
      orderNumber
    );
  };

  if (!orderNumber || cartItems.length === 0) {
    <Redirect to="/" />;
  }

  return (
    <BlockUi tag="div" blocking={false} loader={<Spinner />}>
      <PageTitle title={"Order Receipt"}></PageTitle>
      <ErrorBoundary>
        <section
          className={
            !isMobileOnly
              ? "checkout-step-section"
              : "mob-order-receipt-container"
          }
        >
          <div className="container">
            {!isMobileOnly ? (
              <div className="order-reciept-wrapper">
                <div className="order-msg">
                  <div className="order-success">
                    <Img src={orderReciept} alt="" />
                    <h1 role="heading" aria-level="1" tabIndex={0}>Thank you for your order!</h1>
                    <p tabIndex={0}>The order has been successfully placed.</p>
                  </div>
                  <p tabIndex={0}>
                    A confirmation email will be sent shortly to{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {displayEmailAddress()}
                    </span>
                    . Go to{" "}
                    {/* <a title="My Account" className="link-red" href="/myaccount/orderhistory" to="/myaccount/orderhistory">
                      My Account
                    </a>{" "} */}
                    <Link
                      title="My Orders"
                      to="/myaccount/orderhistory"
                      className="link-red"
                      href="#"
                    >
                      My Account
                    </Link>{" "}
                    to check your order status.
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="back-btn-wpr">
                    <a className="back-btn" href="/">
                      <Img src={backArrowGray} alt="" />
                      Home
                    </a>
                  </div>
                </div>
                <div className="order-reciept-wrapper">
                  <div className="order-msg">
                    <div className="order-success">
                      <Img src={orderReciept} alt="" />
                      <h1>Thank you for your order!</h1>
                      <p>The order has been successfully placed.</p>
                    </div>
                    <p>
                      A confirmation will be Sent to{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {displayEmailAddress()}
                      </span>
                      . Go to{" "}
                      {/* <a title="My Account" className="link-red" href="/myaccount/orderhistory" to="/myaccount/orderhistory">
                      My Account
                    </a>{" "} */}
                      <Link
                        title="My Orders"
                        to="/myaccount/orderhistory"
                        className="link-red"
                        href="#"
                      >
                        My Account
                      </Link>{" "}
                      to check your order status.
                    </p>
                  </div>
                </div>
              </>
            )}
            {!isMobileOnly ? (
              <OrderRecieptBody
                configuration={configuration}
                emailAddress={displayEmailAddress()}
                cartItemFullData={cartItems}
              />
            ) : (
              <OrderRecieptBodyMobile
                configuration={configuration}
                emailAddress={displayEmailAddress()}
                cartItemFullData={cartItems}
              />
            )}
          </div>
        </section>
      </ErrorBoundary>
    </BlockUi>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
  cartItems: state.shoppingCart.cartItems,
  orderPrice: state.shoppingCart.orderPrice,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCartData, setCartItems, setOrderPrice },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderReciept);
