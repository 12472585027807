import React from "react";
import { getShippingMethodsList } from "../utils";
import shippingMethodsMap from "../mappings/shippingMethodsMap.json";
import plantsMap from "../mappings/plantsMap.json";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

const styles = {
  buluMessage: {
    marginLeft: 0,
    color: "#00adef",
    marginTop: 5,
  },
};

// 1. "inStock"
// 2.  "notInStock"
// 3. "limitedStock"
// 4. "notAvailable"

// const messageLine = ({ sku, quantity, name, selectedShipOption }) => {
//   if (selectedShipOption.type === "inStock") {
//     const plantIndex = paresInt(selectedShipOption.index);
//     const shipMethod = getShippingMethodsList(
//       selectedShipOption[`shipping_methods_${plantIndex}`]
//     )[0];
//     return (
//       <p>
//         Item# : {sku} <b>{name}</b> <br />
//         <span className="availble-item">
//           {" "}
//           {quantity} {"In Stock"}
//         </span>{" "}
//         {shippingMethodsMap[shipMethod]} |{" "}
//         <b>{plantsMap[selectedOption[`plant_${plantIndex}`]]}</b>
//         <br />
//       </p>
//     );
//   } else if (selectedShipOption.type === "notInStock") {
//     const plantIndex = paresInt(selectedShipOption.index);
//     const shipMethod = getShippingMethodsList(
//       selectedShipOption[`shipping_methods_${plantIndex}`]
//     )[0];
//     return (
//       <p>
//         Item# : {sku} <b>{name}</b> <br />
//         <span className="limited-item">
//           {" "}
//           {selectedShipOption} {"Not In Stock"}
//         </span>{" "}
//         {item.shipOption.availShipMethods} | <b>{item.shipOption.availPlant}</b>
//         <br />
//       </p>
//     );
//   }
// };

const NotificationCard = ({ notifications, page }) => {
  if (_.isEmpty(notifications)) return null;
  const messages = notifications[page];
  if (_.isEmpty(messages)) return null;
  return (
    <div className="clearfix ">
      <div className="shadow-on-white item-added-alert">
        <div className="summary-message">
          <div className="sum-message icon">
            <i className="material-icons">error_outline</i>
          </div>
          <div className="sum-message">
            <p>
              Important Message <br />
              <span className="summary-msg-item-sum">
                {messages.map((message) => (
                  <div style={styles.buluMessage}>{message}</div>
                ))}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  notifications: state.shoppingCart.notifications,
});

export default connect(mapStateToProps, null)(NotificationCard);
