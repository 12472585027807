import _ from "lodash";
import React, { Component } from "react";
import { CARTTOKEN } from "../components/common/Constants";
import { getBrowserStorage_Cookie } from "./cookies";

export const isLoggedIn = () => {
  const cartToken = getBrowserStorage_Cookie(CARTTOKEN);
  const isLoggedIn = !_.isEmpty(cartToken);
  return isLoggedIn;
};

export const handleError = (errorObj) => {
  if (errorObj.hideError && errorObj.hideError === true) return "";
  try {
    if (errorObj.response.data.is_visible === 1)
      return errorObj.response.data.description;
    else return "Unexpected error. Please retry.";
  } catch (error) {
    return "Unexpected error. Please retry.";
  }
};

export const paginationText = (currentPage, pageSize, totalItemsCount) => {
  const offset = currentPage * pageSize > 0 ? currentPage * pageSize : 0;
  const from = offset + 1;
  const to =
    totalItemsCount > (currentPage + 1) * pageSize
      ? (currentPage + 1) * pageSize
      : totalItemsCount;
  return (
    <span>
      {from} -{to} of {totalItemsCount}
    </span>
  );
};
