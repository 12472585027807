/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setCartItems,
  setOrderPrice,
} from "../../../store/actions/shoppingCart";
import { fetchOrderPrice } from "./commonFunc";
import { formatPrice } from "./utils";
import { isMobileOnly } from "react-device-detect";

class OrderSummary extends Component {
  static defaultProps = {
    orderPrice: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      cartUrl: null,
      priceUrl: null,
      orderUrl: null,
      checkoutUrlMain: null,
      purchaseformUrl: null,
      submitUrl: null,
      orderNumber: null,
      itemCount: null,
      orderPrice: null,
      shippingcost: null,
      tax: null,
      total: null,
    };
    // this.fetchOrderPrice = this.fetchOrderPrice.bind(this);
  }

  fetchOrderPrice = async () => {
    try {
      const orderPrice = await fetchOrderPrice();
      const {
        totalQuantity,
        shippingcost,
        tax,
        total,
        net,
        itemCount,
      } = await orderPrice;
      await this.props.actions.setOrderPrice({
        itemCount,
        totalQuantity,
        shippingcost,
        tax,
        total,
        net,
      });
    } catch (error) {}
  };

  componentDidMount = () => {
    if (
      this.props.match.path === "/order-reciept" ||
      this.props.match.path === "/quick-order-receipt"
    ) {
      const itemCount = this.props.orderPrice.itemCount;
      const orderPrice = this.props.orderPrice.net;
      const shippingcost = this.props.orderPrice.shippingcost;
      const tax = this.props.orderPrice.tax;
      const total = this.props.orderPrice.total;
      this.setState({ itemCount, orderPrice, shippingcost, tax, total });
      this.props.actions.setOrderPrice({});
    }
  };

  // componentDidUpdate = prevProps => {
  //   if (this.props.orderPrice.total !== prevProps.orderPrice.total) {
  //     this.fetchOrderPrice();
  //   }
  // };

  render() {
    return (
      <div className="clearfix side-bar-box mb-0">
        <div
          className={
            isMobileOnly
              ? "order-summary order-summary-mob"
              : "order-summary shadow-on-white order-summary-mob"
          }
        >
          <div className="sidebar-heading">
            <h3 role="heading" aria-level="3" tabIndex={0}>Order Summary</h3>
          </div>
          {this.props.orderPrice ? (
            <div className="summary-info">
              <div className="summary-row items-price">
                <div className="items-count" tabIndex={0}>
                  <span className="info-tag">
                    {this.props.orderPrice.itemCount
                      ? this.props.orderPrice.itemCount
                      : this.state.itemCount}
                  </span>
                  <span> Item(s)</span>
                </div>
                <span className="value bold-text" tabIndex={0}>
                  $
                  {formatPrice(
                    this.props.orderPrice.net
                      ? this.props.orderPrice.net
                      : this.state.orderPrice || 0
                  )}
                </span>
              </div>
              <div className="summary-row shopping">
                <span className="info-tag" tabIndex={0}>Shipping & Handling fee</span>
                <span className="value" tabIndex={0}>
                  $
                  {formatPrice(
                    this.props.orderPrice.shippingcost
                      ? this.props.orderPrice.shippingcost
                      : this.state.shippingcost || 0
                  )}
                </span>
              </div>
              <div className="summary-row sub-total">
                <span className="info-tag" tabIndex={0}>Subtotal</span>
                <span className="value" tabIndex={0}>
                  $
                  {formatPrice(
                    (
                      (this.props.orderPrice.shippingcost
                        ? this.props.orderPrice.shippingcost
                        : this.state.shippingcost) +
                        (this.props.orderPrice.net
                          ? this.props.orderPrice.net
                          : this.state.orderPrice) || 0
                    ).toFixed(2)
                  )}
                </span>
              </div>
              <div className="summary-row sales-tax">
                <span className="info-tag" tabIndex={0}>Sales Tax</span>
                <span className="value" tabIndex={0}>
                  $
                  {formatPrice(
                    this.props.orderPrice.tax
                      ? this.props.orderPrice.tax
                      : this.state.tax || 0
                  )}
                </span>
              </div>
              <div className="summary-row total">
                <span className="info-tag bold-text" tabIndex={0}>Total</span>
                <span className="value bold-text" tabIndex={0}>
                  $
                  {formatPrice(
                    this.props.orderPrice.total
                      ? this.props.orderPrice.total
                      : this.state.total || 0
                  )}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems, setOrderPrice }, dispatch),
});

const mapStateToProps = (state) => ({
  cartItems: state.shoppingCart.cartItems,
  orderPrice: state.shoppingCart.orderPrice,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderSummary));
