import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import shippingMethod from "../shopping-cart/mappings/shippingMethodsMap.json";
import plants from "../shopping-cart/mappings/plantsMap.json";
import status from "../shopping-cart/mappings/availabilityClassesMap.json";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";
import { getShippingMethodsList } from "../../routewise/shopping-cart/utils";

const StockStatus = (value) => {
  return (
    <span className={value.availabilityStatus.class}>
      {value.availabilityStatus.title}
    </span>
  );
};

export default function ProductStockDetails({
  data,
  homeBranchShippingMethod,
}) {
  const [items, setItemList] = useState([]);
  const [availabilityStatus, setAvailabilityStatus] = useState({});

  const shipMethod =
    data?.shipMethod ??
    data?.configuration?.shipping_method_1 ??
    data?.configuration?.shippingMethod1;

  useEffect(() => {
    if (data && data.selectedOption && data.optionType !== "willCall") {
      const itemList = [];
      const arr = data.selectedOption.index.split(",");

      arr.forEach((element, index) => {
        const item = {
          qty: data.selectedOption[`quantity_${element}`],
          backOrder: "",
          shipFrom: plants[`${data.selectedOption[`plant_${element}`]}`],
          shipMethod: shippingMethod["G"].displayName,
        };

        if (index === 0) {
          item.shipMethod = shippingMethod[shipMethod]?.displayName;
        }
        if (data.selectedOption[`quantity_${element}`]) {
          itemList.push(item);
        }
      });

      if (data.selectedOption[`backOrderQuantity_${arr[0]}`]) {
        const item = {
          qty: data.selectedOption[`backOrderQuantity_${arr[0]}`],
          backOrder:
            "Backordered, ETA " +
            moment(data.selectedOption[`backOrderDate_${arr[0]}`]).format(
              "ddd MMM DD, YYYY"
            ),
          shipFrom: plants[`${data.selectedOption[`plant_${[arr[0]]}`]}`],
          shipMethod: shipMethod ? shippingMethod[shipMethod].displayName : "",
        };

        if (data.status === "notInStock" || data.status === "limitedStock") {
          if (arr[0] > 1) {
            item.shipMethod = shippingMethod["G"].displayName;
          } else {
            if (shipMethod === "0" && item.backOrder !== "") {
              item.shipMethod =
                shippingMethod[
                  getShippingMethodsList(
                    data.selectedOption.shippingMethods_1
                  )[0]
                ].displayName;
            } else {
              item.shipMethod = shipMethod
                ? shippingMethod[shipMethod].displayName
                : "";
            }
          }
        }
        itemList.push(item);
      }
      setItemList(itemList);
    }

    if (data && data.selectedOption && data.optionType === "willCall") {
      const itemList = [];

      itemList.push({
        qty: data.selectedOption.willCallQuantity,
        backOrder: "",
        shipFrom: plants[`${data.selectedOption.willCallPlant}`],
        shipMethod: shippingMethod["W"].displayName,
      });

      if (data.selectedOption.index) {
        const arr = data.selectedOption.index.split(",");
        arr.forEach((element, index) => {
          const item = {
            qty: data.selectedOption[`quantity_${element}`],
            backOrder: "",
            shipFrom: plants[`${data.selectedOption[`plant_${element}`]}`],
            shipMethod: shippingMethod[shipMethod].displayName,
          };

          if (data.selectedOption[`quantity_${element}`]) {
            itemList.push(item);
          }
        });
      }

      if (data.selectedOption.backOrder === "T") {
        itemList.push({
          qty: data.selectedOption.backOrderQuantity_1,
          backOrder:
            "Backordered, ETA " +
            moment(data.selectedOption.backOrderDate_1).format(
              "ddd MMM DD, YYYY"
            ),
          shipFrom: plants[`${data.selectedOption.willCallPlant}`],
          shipMethod: shippingMethod[shipMethod].displayName,
        });
      }
      setItemList(itemList);
    }

    if (data && data.selectedOption) {
      if (data.selectedOption.backOrder === "F") {
        if (
          data.selectedOption.type === "shipDifferentLocation" ||
          data.selectedOption.type === "shipAlternativeBranch" ||
          data.selectedOption.type === "availableAll"
        ) {
          setAvailabilityStatus(status["inStock"]);
        }
      } else if (data.selectedOption.backOrder === "T") {
        if (
          data.selectedOption.type === "shipAlternativeBranch" ||
          data.selectedOption.type === "takeOnHand"
        ) {
          setAvailabilityStatus(status["limitedStock"]);
        } else if (data.selectedOption.type === "backOrderAll") {
          setAvailabilityStatus(status["Backordered"]);
        }
      } else {
        if (data.optionType == "willCall") {
          setAvailabilityStatus(status[data.selectedOption.status]);
        }
      }
    }

    if (!data.selectedOption) {
      setItemList([]);
    }
  }, [
    data,
    shipMethod,
    data.selectedOption,
    data.optionType,
    homeBranchShippingMethod,
  ]);

  if (items.length < 1) {
    return <Fragment />;
  }

  return (
    <div className="product-stock-details">
      <p className={isMobileOnly && "availability"}>
        <span tabIndex={0}>
          {!isMobileOnly ? (
            "Availability | "
          ) : (
            <p className="show-availability-txt">Availability:</p>
          )}
          {data && data.selectedOption && (
            <StockStatus availabilityStatus={availabilityStatus} />
          )}
        </span>
      </p>

      {items.map((e, i) => {
        return (
          <>
            <p key={i} className="ship-details">
              <span tabIndex={0}>
                <span
                  className="qut"
                  style={{ fontWeight: isMobileOnly ? "700" : "normal" }}
                >
                  Quantity {e.qty} {isMobileOnly ? ":" : "| "}
                </span>
                {e.backOrder} {!isMobileOnly && "  "} Ships from {e.shipFrom}
                {!isMobileOnly ? " | " : "."}
                {e.shipMethod}
                </span>
            </p>
            {!_.isEmpty(e.backOrder) && !isMobileOnly ? (
              <p className="simple-note">
                <span tabIndex={0}>
                  (Note: Dates are subject to change by suppliers without notice.)
                </span>
              </p>
            ) : null}
          </>
        );
      })}
    </div>
  );
}
