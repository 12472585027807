import React from "react";

const CrediCardDetails = ({ configuration, paymentToken }) => {
  const expDate = String(configuration.expireDate)
    .split(".")
    .join("/");
  const lastDigs = String(paymentToken).slice(12);
  return (
    <div>
      <div>Credit Card</div>
      <div>**** **** **** {lastDigs}</div>
      <div>
        {configuration.cardName}
        {" - "}
        {expDate}
      </div>
    </div>
  );
};

export default CrediCardDetails;
