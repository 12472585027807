import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { REQ_HEADER } from "app/components/routewise/shopping-cart/Tokens";

type ResponseType = {
  errors: {
    message: string;
  }[];
  "list-sku-price": {
    extended: number;
    price: number;
    "price-unit": string;
    sku: string;
    pricebreakdowns: {
      price1: number;
      price2: number;
      price3: number;
      price4: number;
      quantity1: number;
      quantity2: number;
      quantity3: number;
      quantity4: number;
    };
  }[];
};

export const priceCheckApi = async (sku: string, quantity: number) => {
  const response = await axios.post<ResponseType>(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/price-check`,
    {
      skuqty: [
        {
          sku,
          quantity,
        },
      ],
    },
    {
      headers: REQ_HEADER,
    }
  );

  return response.data;
};

const usePriceCheckMutation = () => {
  return useMutation({
    mutationFn: ({ sku, quantity }: { sku: string; quantity: number }) =>
      priceCheckApi(sku, quantity),
  });
};

export default usePriceCheckMutation;
