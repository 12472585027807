import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import createRootReducer from "./app/store/reducers";
import { composeWithDevTools } from "@redux-devtools/extension";
import Api from "./app/lib/api";

// tell us if we're on the server
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (url = "/") => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  const enhancers = [];

  const middleware = [
    thunk.withExtraArgument(Api),
    routerMiddleware(history),
    // logger
  ];

  const composedEnhancers =
    process.env.NODE_ENV === "development" && !isServer
      ? composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
      : compose(applyMiddleware(...middleware), ...enhancers);

  // Do we have preloaded state available?
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  );

  return {
    store,
    history,
  };
};
