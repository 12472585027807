/* eslint-disable prettier/prettier */
import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import parse from "html-react-parser";
import Img from "../../common/Img";
import { formatPrice } from "./utils";

const ProductDescription = ({ item }) => {
  try {
    return (
      <Container className="product-description">
        <Row>
          <Col md={4} className="inventry-productimg">
            {item.img ? (
              <a href="#/" title={parse(item.name)}>
                <Img
                  title={item.name}
                  style={{ width: 120 }}
                  src={`${process.env.REACT_APP_IMAGE_URL}/${item.img}`}
                  alt={item.name}
                />
              </a>
            ) : null}
          </Col>
          <Col md={8}>
            <div className="item-number">
              <div tabIndex={0}>item#: {item.sku}</div>
            </div>
            {item.ciNumber && (
              <div style={{ color: "#c00" }} tabIndex={0}>CI#: {item.ciNumber}</div>
            )}
            <div style={{ color: "black", fontWeight: 1000 }} tabIndex={0}>{item.name}</div>
            <div>
              <span tabIndex={0}>
                $ {formatPrice(parseFloat(item.unitPrice).toFixed(2))}
              </span>
              <span tabIndex={0}> | Qty:</span>
              <span tabIndex={0}>
                {item.quantity} {item.productUnit}
              </span>
            </div>
            <div>
              <span tabIndex={0}>Sub Total: </span>
              <span tabIndex={0}>
                ${" "}
                {formatPrice(
                  (
                    parseFloat(item.unitPrice) * parseInt(item.quantity)
                  ).toFixed(2)
                )}
              </span>
            </div>
            <div tabIndex={0}>PO/Job name: <span>{item.configuration.poOrJobName}</span></div>
          </Col>
        </Row>
      </Container>
    );
  } catch (error) {
    return null;
  }
};

export default ProductDescription;
