import Cookies from "universal-cookie";

const cookies = new Cookies();

const dt = new Date("2050-01-01");

export const setBrowserStorage_Cookie = (key, value) => {
  return cookies.set(key, value, { expires: dt, path: "/" });
};

export const getBrowserStorage_Cookie = (key) => {
  return cookies.get(key);
};

export const getAllBrowserStorage_Cookies = () => {
  return cookies.getAll();
};

export const removeBrowserStorage_Cookie = (key) => {
  return cookies.remove(key, { path: "/" });
};

export const clearBrowserStorage_Cookie = () => {
  const allCookies = cookies.getAll();

  Object.keys(allCookies).forEach((key) => {
    cookies.remove(key, { path: "/" });
  });
  localStorage.clear();
};

export const clearBrowserStorage = () => {
  localStorage.clear();
};

export const setBrowserStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getBrowserStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeBrowserStorage = (key) => {
  return localStorage.removeItem(key);
};
