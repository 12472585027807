import React from "react";
import { formatPrice } from "./../../routewise/shopping-cart/utils";
import { getUOMString } from "./../../routewise/shopping-cart/mappings/UomMapFunction";
import { isMobileOnly } from "react-device-detect";

const PriceView = ({
  displayPrice,
  priceUnit,
  savingAmount,
  discountPercentage,
  hideSavingAmount,
  removePrice,
  hideCutAmount,
}) => {
  return !isMobileOnly ? (
    <div className="price-block">
      <div className="unit-price" tabIndex={0}>
        {"$"}
        {formatPrice(displayPrice, 3, false)}
        <span className="uom">
          {priceUnit && getUOMString(priceUnit)
            ? ` / ${getUOMString(priceUnit)}`
            : ""}
        </span>
      </div>
      {savingAmount > 0 && (
        <div className="discount-price">
          <span className="old-price" tabIndex={0}>{`$${removePrice.toFixed(2)}`}</span>

          <span className="save-price" tabIndex={0}>
            {!hideSavingAmount
              ? `Save $${savingAmount.toFixed(2)} (${discountPercentage}%)`
              : `${discountPercentage}% off`}
          </span>
        </div>
      )}
    </div>
  ) : (
    <div className="mob-price-block">
      <div className="unit-price">
        {"$"}
        {formatPrice(displayPrice, 3, false)}
        <span className="uom">
          {priceUnit && getUOMString(priceUnit)
            ? ` / ${getUOMString(priceUnit)}`
            : ""}
        </span>
      </div>
      {savingAmount > 0 && (
        <div className="discount-price">
          {!hideSavingAmount ? (
            <>
              <span className="old-price">{`$${removePrice.toFixed(2)}`}</span>
              <span className="save-price">{`$${savingAmount.toFixed(
                2
              )} (${discountPercentage}%) off`}</span>
            </>
          ) : (
            <>
              <span className="old-price">{`$${removePrice.toFixed(2)}`}</span>
              <span className="save-price">{`${discountPercentage}% off`}</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default PriceView;
