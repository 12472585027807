import React from "react";
import moment from "moment";
import OrderSummary from "../../routewise/shopping-cart/OrderSummary";
import paymentTypesMap from "../../../components/routewise/shopping-cart/mappings/paymentTypesMap.json";
import CrediCardDetails from "../../routewise/shopping-cart/common/CreditCardDetails";
import ShippingAddressView from "../../routewise/shopping-cart/common/ShippingAddressView";
import _ from "lodash";
import CartItemListing from "../../routewise/shopping-cart/common/CartItemListing";
import { isMobileOnly } from "react-device-detect";
import PhoneNumberView from "../../routewise/shopping-cart/common/PhoneNumberView";
import { getBrowserStorage } from "../../../utils/cookies";
import { formatDate } from "../../../lib/utils";

const OrderRecieptBodyMobile = ({
  paymentToken,
  configuration,
  cartItemFullData,
  emailAddress,
}) => {
  const selectedAddressDetails = JSON.parse(
    getBrowserStorage("selectedAddressDetails")
  );
  const selectedShippingAddress = JSON.parse(
    getBrowserStorage("selectedShippingAddress")
  );
  const billingAddress = selectedAddressDetails["billing-address"];
  const orderNumber = getBrowserStorage("orderNum");
  const orderDate = formatDate(getBrowserStorage("orderDate"));

  return (
    <>
      <div className="order-receipt">
        <div className="detail-row">
          <div className="col-6">
            <p tabIndex={0}>
              <span>Order No. </span> {orderNumber}
            </p>
          </div>
          <div className="col-6">
            <p tabIndex={0}>
              <span>Order Date</span> {orderDate}
            </p>
          </div>
        </div>
        <div className="detail-row">
          <div className="col-6">
            {configuration.po && (
              <p tabIndex={0}>
                <span>P.O. No.</span> {configuration.po}
              </p>
            )}
          </div>
          <div className="col-6">
            {configuration.jobName && (
              <p tabIndex={0}>
                <span>Job Name</span> {configuration.jobName}
              </p>
            )}
          </div>
        </div>
        <div className="detail-row">
          <div className="col-6">
            <p tabIndex={0}>
              <span>Billed To</span>
              {selectedAddressDetails ? (
                <span className="address-text mb-0">
                  <strong>{billingAddress.name}</strong>
                  <br />
                  {billingAddress["street-address"]}, <br />
                  {billingAddress["locality"]}, {billingAddress["region"]},{" "}
                  {billingAddress["postal-code"]}
                </span>
              ) : null}
            </p>
          </div>
          <div className="col-6">
            <p tabIndex={0}>
              <span>Shipping To</span>
              {!_.isEmpty(selectedShippingAddress) && (
                <ShippingAddressView
                  selectedShippingAddress={selectedShippingAddress}
                  cartAttn={configuration.attnName}
                />
              )}
            </p>
          </div>
        </div>
        <div className="detail-row">
          <div className="col-6">
            <p tabIndex={0}>
              <span>Phone No.</span>
              {!_.isEmpty(selectedShippingAddress) && (
                <PhoneNumberView
                  selectedShippingAddress={selectedShippingAddress}
                />
              )}
            </p>
          </div>
          {emailAddress && (
            <div className="col-6">
              <p tabIndex={0}>
                <span>Order Confirmation Email</span>
                {emailAddress}
              </p>
            </div>
          )}
        </div>
        <div className="detail-row">
          <div className="col-6">
            <p tabIndex={0}>
              <span>Payment Terms</span>
              {configuration &&
              paymentToken &&
              configuration.paymentMethod[0] === "V" ? (
                <CrediCardDetails
                  configuration={configuration}
                  paymentToken={paymentToken}
                />
              ) : (
                paymentTypesMap[configuration.paymentMethod]
              )}
            </p>
          </div>
          <div className="col-6">
            {configuration && configuration.pickDate ? (
              <p tabIndex={0}>
                <span tabIndex={0}>Future Ship Date</span>
                {moment(configuration.pickDate, "MM-DD-YYYY").format(
                  "MM/DD/YYYY"
                )}
              </p>
            ) : (
              <p tabIndex={0}>Pick date not available</p>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <span className="separator-line"></span>
      </div>

      <div>
        {!isMobileOnly && !_.isEmpty(cartItemFullData) ? (
          <table className="table table-striped border-non-table ">
            <thead>
              <tr>
                <th />
                <th style={{ width: "40%" }}><span tabIndex={0}>Product Description</span></th>
                <th><span tabIndex={0}>Qty</span></th>
                <th><span tabIndex={0}>UOM</span></th>
                <th><span tabIndex={0}>Unit Price</span></th>
                <th><span tabIndex={0}>Sub Total</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
              </tr>
              <CartItemListing cartItems={cartItemFullData} />
            </tbody>
          </table>
        ) : null}
        {isMobileOnly && !_.isEmpty(cartItemFullData) && (
          <CartItemListing cartItems={cartItemFullData} />
        )}

        <div className="row">
          <span className="separator-line"></span>
        </div>

        <div className="row">
          <div
            className="col-12 s-cart-right-side-bar order-receipt-order-summary"
            style={{ marginTop: 30 }}
          >
            <OrderSummary />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderRecieptBodyMobile;
