import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import { setBrowserStorage } from "../../../../utils/cookies";
import { connect } from "react-redux";
import { loadCartData } from "../../shopping-cart/commonFunc";
import { bindActionCreators } from "redux";
import { setCartData } from "../../../../store/actions/shoppingCart";
import Img from "../../../common/Img";
import calendar from "../../../../assets/img/svg/calendar.svg";
import { updateCartConfiguration } from "app/services/servicesPIM";
class InputBox extends React.Component {
  render() {
    return (
      <button
        title={this.props.value}
        style={{
          borderStyle: "solid",
          borderColor: "gray",
          color: "gray",
        }}
        className="btn m-t-0"
        onClick={this.props.onClick}
      >
        {this.props.value}
      </button>
    );
  }
}

InputBox.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

const PickDate = ({ cartData, actions }) => {
  const [pickDate, setPickDate] = useState(new Date());
  const [dateErrors, setDateErrors] = useState([]);
  const divRef = useRef();

  useEffect(() => {
    if (divRef && isMobileOnly) {
      divRef.current.value = moment(pickDate).format("YYYY-MM-DD");
    }
  }, [pickDate, divRef]);

  useEffect(() => {
    if (cartData && cartData.configuration) {
      if (cartData.configuration.pickDate) {
        const pickDate = moment(
          cartData.configuration.pickDate,
          "MM-DD-YYYY"
        ).toDate();

        if (moment(pickDate).isBefore(new Date())) {
          // pickDate is old
          setPickDate(new Date());
          setBrowserStorage("pickDate", new Date());
          updatePickDate(new Date());
        } else {
          setPickDate(pickDate);
          setBrowserStorage("pickDate", pickDate);
          // updatePickDate(cartData.configuration.pickDate);
        }
      } else {
        setPickDate(new Date());
        setBrowserStorage("pickDate", new Date());
        updatePickDate(new Date());
      }
    }
  }, [cartData, cartData.configuration]);

  async function updatePickDate(date) {
    try {
      setDateErrors([]);

      // update state

      cartData.configuration.pickDate = date;
      actions.setCartData(cartData);

      await updateCartConfiguration(
        {
          pickDate: moment(date).format("MM-DD-YYYY"),
        },
        "shipping_and_payment"
      );

      setBrowserStorage("pickDate", date);
      // await loadCartData()
    } catch (error) {
      if (error.response && error.response.data.messages) {
        setDateErrors(error.response.data.messages);
      }
    }
  }
  const setPickDateLocal = (date) => {
    const diff = moment(new Date()).diff(date, "days");
    if (date == "") {
      if (divRef && isMobileOnly) {
        divRef.current.value = moment().format("YYYY-MM-DD");
        setDateErrors([]);
      }
    } else {
      if (diff <= 0) {
        setPickDate(date);
        updatePickDate(date);
      } else {
        const error = {
          "debug-message": "Please select current or future date",
        };
        setDateErrors([error]);
      }
    }
  };

  return (
    <div className="cart-info-tile">
      <div tabIndex={0}>{"Future Ship Date"}</div>
      <div className="date-picker">
        {isMobileOnly ? (
          <>
            <input
              type="date"
              onChange={(evt) => setPickDateLocal(evt.target.value)}
              data-date-format="YYYY-MM-DD"
              ref={divRef}
            />
            <Img src={calendar} alt="" />
          </>
        ) : (
          <>
            <Img src={calendar} alt="" />
            <DatePicker
              customInput={<InputBox />}
              selected={pickDate}
              onChange={setPickDateLocal}
            />
          </>
        )}
      </div>
      {dateErrors
        ? dateErrors.map((error, idx) => (
            <div
              style={{ color: "#c00", fontSize: 12, marginTop: 3 }}
              key={idx}
            >
              {error["debug-message"]}
            </div>
          ))
        : null}
      <br />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  cartData: state.shoppingCart.cartData,
});

export default connect(mapStateToProps, mapDispatchToProps)(PickDate);
