import { isMobileOnly } from "react-device-detect";

const PhoneNumberView = ({ selectedShippingAddress }) => {
  return (
    <>
      {isMobileOnly && (
        <div className="address-text">
          {selectedShippingAddress["phone-number"] ? (
            <p>{selectedShippingAddress["phone-number"]}</p>
          ) : null}
        </div>
      )}
    </>
  );
};

export default PhoneNumberView;
