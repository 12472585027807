import { JSEncrypt } from "jsencrypt";
import Cookies from "./../../lib/utils/cookies";
import jwt_decode from "jwt-decode";
import {
  AUTHENTICATED,
  IS_AUTHENTICATING,
  SHOW_HIDE_LOGIN,
  SHOW_HIDE_JOINNEW,
  SHOW_HIDE_FORGOT,
  SHOW_HIDE_RESET,
  SHOW_HIDE_RESET_PASSOWRD,
  SHOW_HIDE_FORCE_RESET,
  SHOW_HIDE_SUCCESS,
  SHOW_HIDE_REGISTER_SUCCESS,
  SHOW_FORGET_PSWD_MSG,
  SHOW_PSWD_RESET_SUCCESS,
  SUBSC_TOKEN_REFRESH,
  ON_TOKEN_REFRESH,
  SET_ADDRESSES,
  SET_ADDRESSES_TRUE,
  SHOW_HIDE_SALES_TAX
} from "../constants";
import {
  PIMSET,
  PIMACCOUNTSET,
  CARTTOKEN,
  CURRENT_ADDRESS,
  SHIPPING_ADDRESS,
  U_TIMEOUT,
  WURTHLAC_SHOWADDRESS,
  MEXICAN_ADDRESS,
} from "../../../../src/app/components/common/Constants";

import { setShippingAddress } from "./../../store/actions/shoppingCart";
import axios from "axios";
import qs from "querystring";
import { MYACCOUNT } from "./../../components/common/RouterConstants";

import {
  setBrowserStorage,
  removeBrowserStorage,
  getBrowserStorage,
  clearBrowserStorage,
  setBrowserStorage_Cookie,
  removeBrowserStorage_Cookie,
  getBrowserStorage_Cookie,
} from "../../utils/cookies";
import { generateTokenEncrypt } from "./../../components/common/commonFunc";

export const ForgotPassword = ({ email, key }) => (dispatch, getState, Api) =>
  new Promise((resolve, reject) => {
    let postData = {
      email,
      key,
    };

    Api.init((api) =>
      api
        .makePostRequest("/am/auth/password-reset", postData)
        .then((res) => {
          resolve(res);
        })
        .then(() => {
          resolve("done");
        })
        .catch((err) => {
          resolve(err.response);
        })
    );
  });

export const ChangePassword = ({ password, confirmedPassword, token }) => (
  dispatch,
  getState,
  Api
) =>
  new Promise(async (resolve, reject) => {
    var decoded = jwt_decode(token);
    var UUID = "";
    if (decoded && decoded.sub) {
      UUID = decoded.sub;
    }
    const encryptedPass = generateTokenEncrypt(password);
    const encryptedConfirmPass = generateTokenEncrypt(confirmedPassword);
    const encryptedKey = `${encryptedPass}:${encryptedConfirmPass}:${UUID}`;
    const base64Key = Buffer.from(encryptedKey).toString("base64");

    const reqBody = {
      password: base64Key,
      userName: UUID,
    };

    const loginToken = "Bearer " + token;
    try {
      Api.init((api) =>
        api
          .makePostRequest("/am/auth/password-reset-confirm", null, {
            headers: {
              Authorization: loginToken,
              "Content-Type": "application/json",
            },
            data: reqBody,
          })
          .then(async (user) => {
            resolve("done");
          })
          .catch((err) => {
            reject(err);
          })
      );
    } catch (error) {
      reject(error);
    }
  });

export const loginUser = ({ email, password, staySignedIn }) => (
  dispatch,
  getState,
  Api
) =>
  new Promise((resolve, reject) => {
    // Start our encrypt
    const encryptedPass = generateTokenEncrypt(password);
    const encryptedKey = `${encryptedPass}:${email}`;
    const base64Key = Buffer.from(encryptedKey).toString("base64");

    let postData = {
      userName: email,
      password: base64Key,
    };

    dispatch({ type: IS_AUTHENTICATING, payload: true });

    Api.init((api) =>
      api
        .makePostRequest("/am/auth/generate-token", null, {
          headers: {
            "Content-Type": "application/json",
            grant_type: "password",
            role: "REGISTERED",
            scope: "mobee",
            stay_signed_in: staySignedIn,
          },
          data: postData,
        })
        .then(async (user) => {
          //setBrowserStorage("userToken", user.data.token);

          if (user.data.status_code === "CHANGE_PASSWORD") {
            await sessionStorage.setItem("CHANGE_PASSWORD", 1);
            sessionStorage.tempPassChangeToken = user.data.token;
            dispatch({
              type: SHOW_HIDE_FORCE_RESET,
              payload: true,
            });
            dispatch({
              type: SHOW_HIDE_LOGIN,
              payload: false,
            });
            throw { hideError: true };
          }
          const u = user.data;
          return {
            access_token: u.token,
            scope: u.token,
            token_type: "Bearer",
          };
        })
        .then((userSsnData) => {
          if (staySignedIn) {
            Cookies.set("wurthlac", userSsnData, {
              expires: 3000,
              samesite: "lax",
            });
          } else {
            Cookies.set("wurthlac", userSsnData, { samesite: "lax" });
          }
          return true;
        })
        .then(() => {
          dispatch(establishCurrentUser()).then((loggedIn) => {
            if (loggedIn) {
            }
            resolve(loggedIn);
          });
        })
        .catch((err) => {
          reject(err);
        })
    );
  });

export const loginUserWithToken = (token) => async (dispatch) => {
  await dispatch({ type: IS_AUTHENTICATING, payload: true });
  await Cookies.set(
    "wurthlac",
    {
      access_token: token,
      scope: token,
      token_type: "Bearer",
    },
    { samesite: "lax" }
  );

  await dispatch(establishCurrentUser());
};

export const establishCurrentUser = () => (dispatch) =>
  new Promise(async (resolve) => {
    if (Cookies.check()) {
      dispatch({ type: IS_AUTHENTICATING, payload: true });
      dispatch(await getUserProfile())
        .then((profile) => resolve(true))
        .catch((err) => {
          dispatch({ type: IS_AUTHENTICATING, payload: false });
          resolve(false);
        });
    } else {
      dispatch({ type: IS_AUTHENTICATING, payload: false });
      resolve(false);
    }
  });

export const setCurrentUser = (user) => ({
  type: AUTHENTICATED,
  payload: {
    user,
  },
});

export const toggleLoginWindow = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_LOGIN,
      payload: value,
    });
    resolve(value);
  });

export const toggleJoinNewWindow = (payload) => (dispatch) => {
  dispatch({
    type: SHOW_HIDE_JOINNEW,
    payload,
  });
};

export const resetPwd = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_RESET,
      payload: value,
    });
    resolve(value);
  });
export const resetPasswordFromToken = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_RESET_PASSOWRD,
      payload: value,
    });
    resolve(value);
  });
export const toggleForceResetPwd = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_FORCE_RESET,
      payload: value,
    });
    resolve(value);
  });

export const toggleForgotWindow = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_FORGOT,
      payload: value,
    });
    resolve(value);
  });

export const toggleForgotPasswordSuccess = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_SUCCESS,
      payload: value,
    });
    resolve(value);
  });

export const toggleRegistrationMsg = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_HIDE_REGISTER_SUCCESS,
      payload: value,
    });
    resolve(value);
  });

export const setForgotPasswordMsgType = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_FORGET_PSWD_MSG,
      payload: value,
    });
    resolve(value);
  });

export const togglePasswordRestMsg = (value) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SHOW_PSWD_RESET_SUCCESS,
      payload: value,
    });
    resolve(value);
  });

export const revokeAccessToken = (errRefreshing, token) => (
  dispatch,
  getState,
  Api
) =>
  new Promise((resolve, reject) => {
    Api.init((api) =>
      api.makeDeleteRequest("/am/auth/generate-token").catch((err) => {
        reject(err);
      })
    );
    resolve();
  });

const setPermisition = async (shipTo, accNo) => {
  const loginTokenRes = await Cookies.getJSON("wurthlac");
  const loginToken =
    loginTokenRes.token_type + " " + loginTokenRes.access_token;
  try {
    const reqBody = `{
      "ship-to": "${shipTo}",
      "accountNo":  "${accNo}"
    }`;

    const tokenResp = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_ENDPOINT}/am/account-select`,
      headers: {
        Authorization: loginToken,
        "Content-Type": "application/json",
      },
      data: reqBody,
    });

    const logOff = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_ENDPOINT}/am/user-settings/log_off_time`,
      headers: {
        Authorization: loginTokenRes.token_type + " " + tokenResp.data.token,
        "Content-Type": "application/json",
      },
    });

    await setBrowserStorage_Cookie(U_TIMEOUT, logOff.data.timeout);
    Cookies.set("permisson", tokenResp.data.permission, {
      samesite: "lax",
    });

    //await setBrowserStorage("cartToken", tokenResp.data.token || "");
    await setBrowserStorage_Cookie(CARTTOKEN, tokenResp.data.token || "");

    if (
      window.location.href.includes("product-item") ||
      window.location.href.includes("category")
    ) {
      window.location.reload();
    } else {
      if (sessionStorage.getItem(PIMACCOUNTSET)) {
        sessionStorage.removeItem(PIMACCOUNTSET);
        window.location.href = MYACCOUNT;
      } else {
        window.location.href = "/";
      }
    }
  } catch (error) {}
};

const getUserProfile = () => (dispatch, getState, Api) =>
  new Promise((resolve, reject) => {
    const cookie = Cookies.getJSON("wurthlac");
    if (cookie) {
      //set loading address true
      dispatch({
        type: SET_ADDRESSES_TRUE,
        payload: true,
      });
      Api.init((api) =>
        api
          .makeGetRequest("/am/account-list", null, {
            headers: {
              Authorization: cookie.token_type + " " + cookie.scope,
            },
          })
          .then((response) => {
            if (response) {
              if (response.data.isOsr == true) {
                sessionStorage.setItem("isOsr", 1);
              } else {
                sessionStorage.removeItem("isOsr");
              }
              let tempAddressArray = [];

              if (response.data.accounts) {
                response.data.accounts.map((address) => {
                  let newAddressArray = {};

                  let tempA = [];
                  address["addresses"].map((e) => {
                    if (e["country-name"] != MEXICAN_ADDRESS) {
                      tempA.push(e);
                    }
                  });
                  newAddressArray = {
                    "account-no": address["account-no"],
                    isAssociate: address["isAssociate"],
                    name: address["name"],
                    addresses: tempA,
                    "billing-address": address["billing-address"],
                  };

                  tempAddressArray.push(newAddressArray);
                });
              }
              dispatch({
                type: SET_ADDRESSES,
                payload: tempAddressArray,
              });

              setBrowserStorage(
                "fullName",
                response.data["given-name"] + " " + response.data["family-name"]
              );

              dispatch({
                type: AUTHENTICATED,
                payload: {
                  authenticated: true,
                  user: {
                    "given-name": response.data["given-name"],
                    "family-name": response.data["family-name"],
                  },
                },
              });

              if (
                !getBrowserStorage_Cookie("accNo") &&
                response.data.accounts.length == 1 &&
                response.data.accounts[0].addresses.length == 1
              ) {
                if (!getBrowserStorage_Cookie(WURTHLAC_SHOWADDRESS)) {
                  resolve(response);
                } else {
                  sessionStorage.setItem(PIMSET, "ok");
                  const account = response.data.accounts[0];
                  const address = account.addresses[0];

                  setShippingAddress(address);
                  const accNo = account["account-no"];
                  const shipTo = account.addresses[0]["address-id"];
                  const selectedAddressDetails = account;

                  setBrowserStorage(
                    "defaultplant",
                    selectedAddressDetails.addresses[0].plant
                  );

                  setBrowserStorage_Cookie("accNo", accNo);
                  setBrowserStorage_Cookie("shipTo", shipTo);
                  setBrowserStorage(
                    "selectedAddressDetails",
                    JSON.stringify(selectedAddressDetails)
                  );

                  const currentAddress = selectedAddressDetails.addresses.filter(
                    (a) => a["address-id"] === shipTo
                  );
                  setBrowserStorage_Cookie(CURRENT_ADDRESS, currentAddress);

                  const name = account.addresses[0]["name"];
                  const streetAddress = account.addresses[0]["street-address"];
                  const locality = account.addresses[0]["locality"];
                  const region = account.addresses[0]["region"];
                  const postalCode = account.addresses[0]["postal-code"];

                  const selectShippingAddress = `${name}, ${streetAddress}, ${locality}, ${region}, ${postalCode}`;
                  setBrowserStorage_Cookie(
                    SHIPPING_ADDRESS,
                    selectShippingAddress
                  );
                  const token = setPermisition(shipTo, accNo);
                }
              } else {
                resolve(response);
              }
            }
            //set loading address true
            dispatch({
              type: SET_ADDRESSES_TRUE,
              payload: false,
            });
          })
          .catch((err) => {
            clearBrowserStorage();
            sessionStorage.clear();
            Object.keys(Cookies.get()).forEach(function(cookieName) {
              if (
                cookieName !== "wurthlac-showaddress" &&
                cookieName !== "region" &&
                cookieName !== "remember-me"
              ) {
                Cookies.remove(cookieName);
              }
            });
            reject(err);
            //set loading address true
            dispatch({
              type: SET_ADDRESSES_TRUE,
              payload: false,
            });
          })
      );
    } else {
      reject(false);
    }
  });

export const logoutUser = () => (dispatch) =>
  new Promise((resolve) => {
    sessionStorage.clear();
    Object.keys(localStorage).forEach(function(local) {
      if (local != "per_page") {
        removeBrowserStorage(local);
      }
    });

    Object.keys(Cookies.get()).forEach(function(cookieName) {
      if (
        cookieName !== "wurthlac-showaddress" &&
        cookieName !== "region" &&
        cookieName !== "remember-me"
      ) {
        removeBrowserStorage_Cookie(cookieName);
      }
    });

    resolve({});
    window.location.assign("/");
  });

export const subscribeTokenRefresh = (cb) => ({
  type: SUBSC_TOKEN_REFRESH,
  payload: cb,
});

export const onTokenRenewed = (errRefreshing, token) => (dispatch, getState) =>
  new Promise((resolve) => {
    getState().auth.tokenSubscribers.map((cb) => cb(errRefreshing, token));
    dispatch({ type: ON_TOKEN_REFRESH });
    resolve();
  });

  export const toggleSalesAndTaxWindow = (payload) => (dispatch) => {
    dispatch({
      type: SHOW_HIDE_SALES_TAX,
      payload,
    });
  };
  