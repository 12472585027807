import React from "react";
import { useState, useEffect } from "react";
import AddShippingDetails from "./EditShippingDetails";
import { serviceGetAddressesOrder } from "./services/servicesEP";
import {
  setBrowserStorage,
  setBrowserStorage_Cookie,
} from "../../../utils/cookies";
import Joi from "@hapi/joi";
import _ from "lodash";
import { Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ChangeShippingModal from "./ChangeShippingModal";
import { setCartItems, setCartData } from "../../../store/actions/shoppingCart";
import rightLineArrowGray from "./../../../assets/img/svg/right_line_arrow_gray.svg";
import { loadCartData } from "../shopping-cart/commonFunc";
import Img from "../../common/Img";
import { isMobileOnly } from "react-device-detect";
import { updateCartConfiguration } from "app/services/servicesPIM";

const DEFAULT_EMAIL_API = `${process.env.REACT_APP_API_ENDPOINT}/am/overridden_email`;

const ShippingAddressNew = ({ cartData, ...props }) => {
  const MAX_CHAR_ATTN = 40;
  const [addressData, setAddressData] = useState({
    addressLines: {},
  });

  const [attn, setAttn] = useState("");
  const [email, setEmail] = useState("");

  const [showModal, setShow] = useState(false);
  const [choice, setChoice] = useState([]);
  const [chosen, setChosen] = useState(null);

  const [errors, setErrors] = useState([]);

  const [addShipping, setShipping] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("changedEmail");
  }, []);

  useEffect(() => {
    if (_.isEmpty(cartData)) {
      (async () => {
        const d = await loadCartData();

        reloadAddress(d.cartUrl);
      })();
    } else {
      reloadAddress(cartData.cartUrl);
    }
  }, []);

  useEffect(() => {
    if (
      cartData &&
      cartData.configuration &&
      cartData.configuration.orderEmail
    ) {
      setEmail(cartData.configuration.orderEmail);
    }
    if (
      cartData &&
      cartData.configuration &&
      cartData.configuration.user_email &&
      !cartData.configuration.orderEmail
    ) {
      setEmail(cartData.configuration.user_email);
    }
    if (cartData && cartData.configuration && cartData.configuration.attnName) {
      setAttn(cartData.configuration.attnName);
    }
  }, [cartData, cartData.configuration]);

  const reloadAddress = async (cartUrl) => {
    try {
      await updateAddressChanged("T");
      const { choice, chosen } = await serviceGetAddressesOrder(cartUrl);
      if (!_.isEmpty(chosen)) {
        setChosen(chosen[0]);
        setBrowserStorage("selectedShippingAddress", JSON.stringify(chosen[0]));
      }
      choice.push(chosen[0]);
      setChoice(choice);
    } catch (error) {}
  };

  const updateAddressAttn = (attn) => {
    if (attn.length > MAX_CHAR_ATTN) return;
    setAttn(attn);
  };

  const updateAddressAttnFocusOut = async () => {
    cartData.configuration.attnName = attn;
    setCartData(cartData);

    await updateCartConfiguration({ attnName: attn }, "shipping_and_payment");
  };

  const updateEmailFocusOut = async () => {
    try {
      setErrors([]);
      if (email && email !== cartData.configuration.orderEmail) {
        const schema = Joi.string().email();
        await Joi.validate(email, schema);
      }

      // update the order email
      await updateCartConfiguration(
        { orderEmail: email },
        "shipping_and_payment"
      );

      await loadCartData();
    } catch (error) {
      if (error.name === "ValidationError") {
        setErrors([
          {
            "debug-message": "Invalid email format.",
          },
        ]);
        // setEmail("");
      } else {
        setErrors([
          {
            "debug-message": "Email update failed.",
          },
        ]);
        // setEmail("");
      }
    }
  };

  const updateAddressChanged = async (addressCheck) => {
    try {
      await updateCartConfiguration({ addressCheck });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmail = async (e) => {
    try {
      setEmail(e.target.value);
      const schema = Joi.string().email();
      const validEmail = await Joi.validate(e.target.value, schema);
      if (validEmail) {
        sessionStorage.setItem("changedEmail", e.target.value);
      }
    } catch (error) {
      // this catch block needs to be there. even though if this is empty.
    }
  };

  return (
    <div className={isMobileOnly ? "mt-2" : "mt-5"}>
      <h2 role="heading" aria-level="2" tabIndex={0}>{isMobileOnly ? "Shipping & Payment" : "Shipping Address"}</h2>
      <div className="row">
        <div className={!isMobileOnly && "col"}>
          {!isMobileOnly && chosen && (
            <Fragment>
              <div className="address-blk">
                <p className="address-text mb-0" tabIndex={0}>
                  {chosen["organization"]}
                  <br />
                  {chosen["street-address"]}, <br />
                  {chosen["locality"]}, {chosen["region"]},{" "}
                  {/* {addressData.addressLines["country-name"]},{" "} */}
                  {chosen["postal-code"]}
                </p>
                <p tabIndex={0}>Phone: {chosen["phone-number"]}</p>
              </div>
            </Fragment>
          )}
          <div className="select-add-address">
            {!isMobileOnly && (
              <>
                <span
                  title="Select another address"
                  onClick={() => {
                    setShow(true);
                    setShipping(false);
                  }}
                  onKeyPress={() => {
                    setShow(true);
                    setShipping(false);
                  }}
                  tabIndex="0"
                >
                  Select another address
                </span>
                |
              </>
            )}

            <AddShippingDetails
              onInventoryShipping={true}
              show={addShipping}
              loadingaddress={async () => await reloadAddress(cartData.cartUrl)}
              chosen={chosen}
              updateAddressChanged={updateAddressChanged}
              setChosen={setChosen}
              cartData={cartData}
              isCheckout={true}
            />
          </div>
        </div>

        <div className={`col ${isMobileOnly && "pl-2"}`}>
          <div className="address-attn">
            <div className="attn" tabIndex={0}>Email Order Confirmation:</div>
            <label className="visuallyhidden" htmlFor="emailOrdCon">
              Email Order Confirmation:
            </label>
            <input
              id="emailOrdCon"
              className={isMobileOnly ? "mob-input" : ""}
              value={email}
              onChange={(e) => {
                e.persist();
                handleEmail(e);
              }}
              onBlur={(e) => updateEmailFocusOut()}
            />
            {errors.map((e, idx) => {
              return (
                <div style={{ color: "#c00", fontSize: 12 }} key={idx}>
                  {e["debug-message"]}
                </div>
              );
            })}
          </div>
          <div className="address-attn">
            <div className="attn" tabIndex={0}>Attn: </div>
            <label className="visuallyhidden" htmlFor="name">
              Attn:
            </label>
            <input
              id="name"
              className={isMobileOnly ? "mob-input" : ""}
              placeholder="Name here..."
              value={attn}
              onChange={(e) => updateAddressAttn(e.target.value)}
              onBlur={(e) => updateAddressAttnFocusOut()}
            />
          </div>
        </div>
      </div>
      {isMobileOnly && (
        <div className="row">
          <div
            style={{
              width: "100%",
              backgroundColor: "#e7e7e7",
              margin: 10,
              display: "inline-flex",
            }}
            onClick={() => {
              setShipping(false);
              setShow(true);
            }}
            className="shipping-address-box"
          >
            <div className="address">
              <h4>Shipping Address</h4>

              {chosen && (
                <Fragment>
                  <div className="address-blk">
                    <p className="address-text mb-0">
                      {chosen["organization"]} {chosen["street-address"]},{" "}
                      {chosen["locality"]}, {chosen["region"]},{" "}
                      {/* {addressData.addressLines["country-name"]},{" "} */}
                      {chosen["postal-code"]}
                    </p>
                  </div>
                </Fragment>
              )}
            </div>

            <Img src={rightLineArrowGray} alt="" />
          </div>
        </div>
      )}
      <ChangeShippingModal
        show={showModal}
        setShow={setShow}
        choice={choice}
        chosen={chosen}
        setChosen={setChosen}
        updateAddressChanged={updateAddressChanged}
        closeModal={() => {
          setShow(false);
          setShipping(true);
        }}
        resetSetShipping={() => setShipping(false)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems, setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressNew);
