/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { REQ_HEADER } from "./Tokens";
import { CREDIT_CARDS_URL } from "./APIs";
import { getCreditCardSignature } from "../../../utils/api/shoppingCart";
import { connect } from "react-redux";
import { setTempCreditCard } from "../../../store/actions/shoppingCart";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Joi from "@hapi/joi";
import { SuccessAlert } from "../shopping-cart/common";
import visaLogo from "../../../assets/img/payment/visa.svg";
import amexLogo from "../../../assets/img/payment/amex.svg";
import mcLogo from "../../../assets/img/payment/mc.svg";
import disLogo from "../../../assets/img/payment/dis.svg";
import Img from "../../common/Img";
import plusSignLarge from "../../../assets/img/svg/plus_sign_large.svg";
import IframeLoading from "./IframeLoading";

import ErrorAlert from "../../common/ErrorAlert";
import { handleError } from "../../../utils/utils";
import { store } from "../../../../index";
import { isMobileOnly } from "react-device-detect";

const initailState = {
  show: false,
  close: false,
  btnDisabled: true,
  isExpireDateValid: true,
  successMsg: null,
  errorMsg: null,
  addressDetails: {
    cardName: "",
    expiryDate: "",
  },
  cardError: null,
  errors: {},
  responseErrors: "",
  saveFuture: false,
  tokenError: "",
  cardValidateTimeout: "",
  isLoadingCardValidation: false,
  errorMessage: "",
  cardType: "",
  previousCardDetails: "",
  receiveMessage: "",
  isLoading: true,
  cardTypeImage: "",
};
class AddCreditCard extends Component {
  static propTypes = {
    // loadingaddress: PropTypes.func
  };

  state = initailState;
  receiveMessage = this.receiveMessage.bind(this);

  tokenError = "";
  schema = {
    cardName: Joi.string().required().label("Card Name"),
    expiryDate: Joi.string().required().label("Expiration Date"),
  };

  setShow = (val) => {
    if (val === true) {
      this.getCreditCardRequestId();
    }
    this.setState({
      show: val,
      successMsg: null,
      errorMsg: null,
      errors: {},
      cardType: "",
      addressDetails: {
        cardName: "",
        expiryDate: "",
      },
    });
  };
  setClose = (val) => {
    this.setState({ close: val });
    this.setState(initailState);
  };

  setName = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.cardName = val;
    this.setState({ addressDetails });
  };

  setcardNum = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.cardNum = val.trim();
    this.setState({ addressDetails });
  };

  expireDateIsValid = () => {
    this.setState({
      isExpireDateValid: false,
    });
  };

  expireDateIsNotValid = () => {
    this.setState({
      errorMessage: "Expiry date is invalid.",
      isExpireDateValid: true,
    });
  };

  expireMonthValidating = (year, month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let millenniumYear = currentYear.toString();
    let millennium = millenniumYear.slice(0, 2);
    let validYear = parseInt(millennium.concat(year));
    let validMonth = parseInt(month);
    const currentMonth = currentDate.getMonth() + 1;

    if (validMonth > 12) {
      this.expireDateIsNotValid();
    } else if (validYear < currentYear) {
      this.expireDateIsNotValid();
    } else if (validYear == currentYear && validMonth < currentMonth) {
      this.expireDateIsNotValid();
    } else {
      this.expireDateIsValid();
    }
  };

  setexpiryDate = (val) => {
    let isLetter = Boolean(val.charAt(0).match(/[a-zA-Z]/));
    if (isLetter) {
      this.setState({
        errorMessage: "Expiry date is invalid.",
        isExpireDateValid: true,
      });
    } else {
      this.setState({
        errorMessage: "",
      });

      let month = val.slice(0, 2);
      let year = val.slice(3, 5);
      if (month && year) {
        this.expireMonthValidating(year, month);
      }

      const addressDetails = { ...this.state.addressDetails };
      if (val.length > 5) return;
      var firstTwo = val.slice(0, 2) || "";
      var lastTwo = val.slice(3, 5) || "";
      addressDetails.expiryDate =
        val.length <= 2 ? firstTwo : firstTwo + "/" + lastTwo;
      this.setState({ addressDetails });
    }
  };

  validate = () => {
    const result = Joi.validate(this.state.addressDetails, this.schema, {
      abortEarly: false,
    });
    if (!result.error) return;
    const errors = [];
    for (const item of result.error.details) {
      errors.push(item.message);
    }
    return errors;
  };

  showMe = () => {
    var mytoken = document.getElementById("mytoken");
    alert("Token=" + mytoken.value);
  };

  validateForm = () => {
    const tokenError = this.tokenError;
    const tokenValue = document.getElementById("token");

    if (tokenValue) {
      if (tokenError !== undefined) {
        return "Enter valid card number";
      } else if (tokenValue.value === "") {
        return "Enter valid card number";
      }
      const token = tokenValue.value;
      sessionStorage.setItem("cardToken", token);
      return "";
    }
  };

  cardValidate = () => {
    this.setState({
      isLoadingCardValidation: true,
      errorMessage: "",
      cardType: "",
      cardTypeImage: "",
    });
    const cardValidateTimeout = setTimeout(async () => {
      const error = this.validateForm();
      this.setState({ isLoadingCardValidation: false, errorMessage: error });
    }, 2000);
    this.setState({ cardValidateTimeout });
  };

  //WARNING! To be deprecated in React v17. Use componentDidUpdate instead.
  componentWillUpdate(nextProps, nextState) {
    try {
      this.state.cardValidateTimeout.clear();
    } catch (error) {}
  }

  componentDidUpdate() {
    window.addEventListener("message", this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
  }

  receiveMessage(event) {
    this.setState({ errorMessage: "" });

    if (event.data.includes("message")) {
      var errorinfo = event.data;
      if (
        errorinfo.error === "card_error" &&
        errorinfo.message.includes("Invalid card number")
      ) {
        var message =
          "error: " + errorinfo.error + "; message: " + errorinfo.message;
        this.setState({ errorMessage: message });
      } else {
        this.setState({ errorMessage: "" });
      }
    } else {
      // Gateway Tokens
      var vars = event.data.split("&");
      if (vars.length > 0) {
        var cardtoken = vars[0].split("=")[1];
        var cardType = vars[1].split("=")[1];
        var cardTypeExpire = vars[2].split("=")[1];

        this.setState({ cardType: cardType });
        sessionStorage.setItem("cardToken", cardtoken);

        //handle card token issue and card type issue
        if (cardtoken && cardType && cardTypeExpire === "undefined") {
          if (cardType != "UNKNOWN") {
            this.setState({ btnDisabled: false });
          } else {
            this.setState({
              errorMessage:
                "Unknown card type. Please enter a valid card number.",
              btnDisabled: true,
            });
          }
        } else {
          this.setState({ btnDisabled: true });
        }
        var cardTypeImgSrc;
        // Set the card type image source based on the card type
        switch (cardType) {
          case "VISA":
            cardTypeImgSrc = visaLogo;
            break;
          case "MC":
            cardTypeImgSrc = mcLogo;
            break;
          case "AMEX":
            cardTypeImgSrc = amexLogo;
            break;
          case "DISCOVER":
            this.setState({ cardType: "DIS" });
            cardTypeImgSrc = disLogo;
            break;
          case "UNKNOWN":
            cardTypeImgSrc = "";
            break;
          default:
            cardTypeImgSrc = "";
        }

        // Render the card type image
        var cardTypeImg = (
          <img
            className={cardType}
            src={cardTypeImgSrc}
            alt={cardType === "UNKNOWN" ? "" : cardType}
          />
        );

        // render the card type image in the component
        this.setState({ cardTypeImage: cardTypeImg });
      }
    }
  }

  handleIframeLoad = () => {
    this.setState({
      isLoading: false,
    });
  };

  //Get Credit card requerst id
  getCreditCardRequestId = async () => {
    await getCreditCardSignature()
      .then((response) => {
        const creditCardRequestId = response.data;
        this.setState({ creditCardRequestId });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkExistCreditCard = (creditCardList, tempCreditCards, token) => {
    let existCardCheck =
      creditCardList && creditCardList.find((el) => el.number === token);
    let existCardCheckTemp =
      tempCreditCards && tempCreditCards.number === token;
    return !existCardCheck && !existCardCheckTemp;
  };

  handleSubmit = async () => {
    try {
      const errors = this.validate();
      let token = sessionStorage.getItem("cardToken");
      if (!token)
        return this.setState({
          errorMessage: "Please enter valid card number.",
        });
      if (errors) return this.setState({ errorMessage: errors[0] });
      if (this.state.isExpireDateValid) return;
      this.setState({ errorMessage: "" });
      if (this.state.saveFuture) {
        try {
          const cardDetails = {
            token: token,
            expdate: this.state.addressDetails.expiryDate,
            holdername: this.state.addressDetails.cardName,
            //card_type: this.getCardType(token),
            card_type: this.state.cardType,
          };
          let existCardCheck = this.checkExistCreditCard(
            this.props.creditCardList,
            this.props.tempCreditCards,
            token
          );
          if (existCardCheck) {
            const res = await axios.post(CREDIT_CARDS_URL, cardDetails, {
              headers: REQ_HEADER,
            });
            this.props.reloadCardList(token);
          } else {
            return this.setState({
              errorMessage: "Error! Credit card already exists.",
            });
          }

          this.handleHide();
        } catch (error) {
          this.setState({ errorMessage: handleError(error) });
        }
      } else {
        let expiryDate = this.state.addressDetails.expiryDate;

        let [cmonth, cyear] = expiryDate.split("/");

        const tempCard = {
          type: this.state.cardType,
          number: token,
          name: this.state.addressDetails.cardName,
          expiry_date: cmonth + ".20" + cyear,
        };

        let existCardCheck = this.checkExistCreditCard(
          this.props.creditCardList,
          this.props.tempCreditCards,
          token
        );
        if (existCardCheck) {
          await store.dispatch(setTempCreditCard(tempCard));
          this.props.appendTempCard(tempCard);
        } else {
          return this.setState({
            errorMessage: "Error! Credit card already exists.",
          });
        }
        this.handleHide();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSuccessClose = (errorMessage) => {
    this.setState({ successMsg: null });
  };

  handleErrorClose = (errorMessage) => {
    this.setState({ errorMsg: null });
  };

  handleHide = () => {
    sessionStorage.cardToken = "";
    this.setState(initailState);
    this.setShow(false);
    this.props.showHidePaymentOptionMobileModal();
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        {this.props.onSingleCheckout && (
          <p className="add-new-payment-trigger-text">
            <a
              onClick={() => {
                this.setShow(true);
              }}
              onKeyPress={() => {
                this.setShow(true);
              }}
            >
              {" "}
              <span className="float-right" />
              <i className="material-icons">add</i> Add new card
            </a>
          </p>
        )}
        {!this.props.onSingleCheckout && (
          <a
            className="add-new-card"
            title="Add new credit card"
            onClick={() => {
              this.setShow(true);
              this.props.showHidePaymentOptionMobileModal();
            }}
            onKeyPress={() => {
              this.setShow(true);
              this.props.showHidePaymentOptionMobileModal();
            }}
          >
            <div className="card mb-3 shadow-on-white payment-card card-body-add-card non-card-options">
              <div className="d-flex" tabIndex={0}>
                <Img src={plusSignLarge} alt="" />
                Add new credit card
              </div>
            </div>
          </a>
        )}
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          tabIndex="0"
          className={isMobileOnly && "bottom-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title tabIndex="0">Add Credit Card</Modal.Title>
          </Modal.Header>

          <div className="p-4 add-new-credit-card-mob">
            {this.state.errorMessage && (
              <ErrorAlert
                handleErrorClose={() => this.setState({ errorMessage: "" })}
                errorMessage={this.state.errorMessage}
              />
            )}

            {this.state.successMsg && (
              <SuccessAlert
                key={1}
                handleSuccessClose={this.handleSuccessClose}
                errorMessage={this.state.successMsg}
              />
            )}
            <div className="form-group">
              <label htmlFor="cardName" tabIndex={0}>Name On Card *</label>
              <input
                autoFocus
                className="form-control mob-input"
                id="cardName"
                placeholder="Name On Card"
                value={this.state.addressDetails.cardName}
                onChange={(e) => this.setName(e.target.value)}
              />
            </div>

            <div className="form-group mb-2">
              <label htmlFor="name" tabIndex={0}>Card Number*</label>
              <div className="card-no-type">
                <div className="cardNmberIframe">
                  {this.state.creditCardRequestId &&
                    this.state.creditCardRequestId.requestid && (
                      <form name="tokenform" id="tokenform">
                        {isLoading && <IframeLoading />}
                        <iframe
                          id="tokenframe"
                          name="tokenframe"
                          title="Card number"
                          src={`${process.env.REACT_APP_SNAPPAY_URL}/Interop/InteropRequest?reqno=${this.state.creditCardRequestId.requestid}`}
                          frameBorder="0"
                          scrolling="no"
                          cardnumbernumericonly="true"
                          invalidinputevent="true"
                          onLoad={this.handleIframeLoad}
                          width="100%"
                          style={{ display: "block" }}
                        />
                        <input type="hidden" name="token" />
                      </form>
                    )}
                </div>
                {this.state.cardTypeImage}
              </div>
              <div className="input-error" tabIndex={0}>{this.state.cardError}</div>
            </div>

            <div className="d-flex expire-save-future">
              <div className="form-group">
                <label htmlFor="expiryDate" tabIndex={0}>
                  {isMobileOnly ? "Exp. Date*" : "Expiration Date*"}
                </label>
                <input
                  autoFocus
                  className="form-control mob-input"
                  autoComplete="off"
                  id="expiryDate"
                  placeholder="MM/YY"
                  value={this.state.addressDetails.expiryDate}
                  onChange={(e) => this.setexpiryDate(e.target.value)}
                />
              </div>
            </div>

            <div className="save-for-future">
              <div className="custom-control custom-checkbox">
                <input
                  autoFocus
                  type="checkbox"
                  className="custom-control-input mob-input"
                  id="saveFuture"
                  defaultChecked={false}
                  value={this.state.saveFuture}
                  onChange={(e) =>
                    this.setState({ saveFuture: e.target.checked })
                  }
                />
                <label className="custom-control-label" for="saveFuture" tabIndex={0}>
                  Save for future use
                </label>
              </div>
            </div>

            <div className="cancel-save two-btn-row">
              <button
                title="Cancel"
                type="button"
                className={
                  isMobileOnly ? "btn mr-2 red-border-btn" : "btn gray-btn mr-2"
                }
                onClick={() => {
                  this.setClose(false);
                  this.props.showHidePaymentOptionMobileModal();
                }}
              >
                Cancel
              </button>
              <button
                title="Submit"
                disabled={this.state.btnDisabled}
                type="submit"
                className="btn red-btn"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                {isMobileOnly ? "Done" : "Submit"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setTempCreditCard,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  tempCreditCards: state.shoppingCart.tempCreditCards,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCreditCard));
