import React, { memo, Component } from "react";
import { Lazy } from "react-lazy";
import noImage from "../../assets/img/image.png";

class Img extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
    this.onError = this.onError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({
        src: nextProps.src,
        errored: false,
      });
    }
  }

  componentDidMount() {
    this.setState({
      src: this.props.src,
      errored: false,
    });
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: noImage,
        errored: true,
      });
    }
  };

  render() {
    const { src } = this.state;

    return (
      <Lazy ltIE9>
        <picture>
          <source
            srcSet={src}
            type="image/webp"
            alt={
              this.props.title
                ? this.props.title
                : this.props.alt
                ? this.props.alt
                : "WurthLac"
            }
            style={this.props.style}
            width={this.props.width ? this.props.width : null}
            height={this.props.height ? this.props.height : null}
            onError={this.onError}
            className={this.props.className ? this.props.className : null}
          />
          {src.search(".webp") ? (
            <img
              alt={
                this.props.title
                  ? this.props.title
                  : this.props.alt
                  ? this.props.alt
                  : "WurthLac"
              }
              style={this.props.style}
              width={this.props.width ? this.props.width : null}
              height={this.props.height ? this.props.height : null}
              onError={this.onError}
              src={src.replace(".webp", ".jpg")}
              className={this.props.className ? this.props.className : null}
            />
          ) : (
            <img
              alt={
                this.props.title
                  ? this.props.title
                  : this.props.alt
                  ? this.props.alt
                  : "WurthLac"
              }
              style={this.props.style}
              width={this.props.width ? this.props.width : null}
              height={this.props.height ? this.props.height : null}
              onError={this.onError}
              src={src}
              className={this.props.className ? this.props.className : null}
            />
          )}
        </picture>
      </Lazy>
    );
  }
}

export default Img;
