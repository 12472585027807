import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng: "en",
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // react: {
    //   wait: true,
    //   bindStore: false,
    //   bindI18n: 'languageChanged'
    // },
    cache: {
      enabled: true,
    },
  });

export default i18n;
