import React from "react";
import visaLogo from "./../../../../assets/img/payment/visa.svg";
import amexLogo from "./../../../../assets/img/payment/amex.svg";
import mcLogo from "./../../../../assets/img/payment/mc.svg";
import disLogo from "./../../../../assets/img/payment/dis.svg";

const CreditCardDetailsMobile = ({ configuration, paymentToken }) => {
  const expDate = String(configuration.expireDate)
    .split(".")
    .join("/");
  const lastDigs = String(paymentToken).slice(12);

  const getExpDate = (expDate) => {
    let date = String(expDate).split("/");
    let year = expDate.slice(-2);

    return ` Exp: ${String(date[0] + "/" + year)
      .split(".")
      .join("/")}`;
  };
  return (
    <div className="d-flex credit-card-details-mob">
      <div className="card-type-icon">
        {configuration.cardType === "VISA" && <img src={visaLogo} alt="" />}
        {configuration.cardType === "MC" && <img src={mcLogo} alt="" />}
        {configuration.cardType === "AMEX" && <img src={amexLogo} alt="" />}
        {configuration.cardType === "DIS" && <img src={disLogo} alt="" />}
      </div>
      <div>
        <span className="card-info">
          **** **** **** {lastDigs} / {getExpDate(expDate)}
        </span>
        <span className="card-name">{configuration.cardName}</span>
      </div>
    </div>
  );
};

export default CreditCardDetailsMobile;
