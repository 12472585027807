import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { REQ_HEADER } from "./Tokens";
import { setCartItems, setCartData } from "../../../store/actions/shoppingCart";
import PickDate from "../shopping-cart/common/PickDate";
import { loadCartData } from "../shopping-cart/commonFunc";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";
import Img from "../../common/Img";
import rightLineArrowGray from "./../../../assets/img/svg/right_line_arrow_gray.svg";
import ShowDriverNoteMobile from "./ShowDriverNoteMobile";
import { updateCartConfiguration } from "app/services/servicesPIM";

const ShippingInstructions = ({ cartData }) => {
  useEffect(() => {
    (async () => {
      if (_.isEmpty(cartData)) {
        await loadCartData();
      }
    })();
  }, []);
  useEffect(() => {
    //use effect htmlFor set email
    if (
      cartData &&
      cartData.configuration &&
      cartData.configuration.driverNote
    ) {
      setDriverNote(cartData.configuration.driverNote);
    }

    if (
      cartData &&
      cartData.configuration &&
      cartData.configuration.completeDelivery
    ) {
      setShipOrderComplete(cartData.configuration.completeDelivery);
    }
  }, [cartData, cartData.configuration]);
  const [driverNote, setDriverNote] = useState("");
  const [shipOrderComplete, setShipOrderComplete] = useState("F");
  const [showDriverNoteModalMobile, setShowDriverNoteModalMobile] =
    useState(false);

  const handleDriverNote = async (e, isMobile) => {
    try {
      const note = isMobile ? e : e.target.value;
      cartData.configuration.driverNote = note;
      setCartData(cartData);
      setDriverNote(note);

      // update the driver note
      await updateCartConfiguration(
        { driverNote: note },
        "shipping_and_payment"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleHoldOrder = async (e) => {
    try {
      const { checked } = e.target;
      let completeDelivery = "T";

      if (!checked) {
        completeDelivery = "F";
      }

      await updateCartConfiguration(
        { completeDelivery },
        "shipping_and_payment"
      );
      cartData.configuration.completeDelivery = completeDelivery;
      setCartData(cartData);
      setShipOrderComplete(completeDelivery);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={`shipping-instruction-wrapper ${
        isMobileOnly && "shipping-instruction-mob"
      }`}
    >
      <h2 className={isMobileOnly && "title"} role="heading" aria-level="2" tabIndex={0}>Shipping Instructions</h2>
      <div className={isMobileOnly ? "col" : "row"}>
        <div className={!isMobileOnly && "col"}>
          <div className="ship-order">
            <label tabIndex={0}>Ship Order Complete</label>
            <div className="custom-control custom-checkbox" tabIndex={0}>
              <input
                id={1}
                value={1}
                name={"name"}
                className="custom-control-input"
                type="checkbox"
                checked={shipOrderComplete === "T" ? true : false}
                onChange={(e) => handleHoldOrder(e)}
              />
              <label className="custom-control-label" htmlFor={1}>
                Hold Order until all items are in
              </label>
            </div>
          </div>
          <div className="future-ship">{<PickDate />}</div>
        </div>
        <div className="col">
          <div className="cart-driver-notes">
            {!isMobileOnly ? (
              <div className="">
                <label tabIndex={0}>Driver's Notes:</label>
                <textarea
                  onChange={(e) => {
                    e.persist();
                    handleDriverNote(e, false);
                  }}
                  value={driverNote}
                  type="text"
                  rows="5"
                />
              </div>
            ) : (
              <div className="row">
                <div
                  className="driver-note-wrapper"
                  onClick={() => setShowDriverNoteModalMobile(true)}
                >
                  <div className="note">Driver's Notes</div>

                  <Img src={rightLineArrowGray} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <ShowDriverNoteMobile
          isMobileModalOpen={showDriverNoteModalMobile}
          handleChange={() => setShowDriverNoteModalMobile(false)}
          driverNote={driverNote}
          saveDriverNote={(e) => {
            handleDriverNote(e, true);
          }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems, setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingInstructions);
