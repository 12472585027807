import axios from "axios";
import { REQ_HEADER } from "app/components/routewise/shopping-cart/Tokens";

export const updateCartConfiguration = async (
  configuration: {
    sold_to?: string;
    ship_to?: string;
    paymentMethod?: string;
    orderEmail?: string;
    po_job?: string;
    jobName?: string;
    po?: string;
    user_email?: string;
    is_overridden?: string;
    overridden_email?: string;
    completeDelivery?: string;
    pickDate?: string;
    coupon?: string;
    osr?: string;
    attnName?: string;
    driverNote?: string;
    "first-name"?: string;
    cartItems?: string;
    delivering_plant?: string;
    avail_payment_options?: string;
    cardName?: string;
    cardType?: string;
    expireDate?: string;
    shippingAddressId?: number;
    isAPrimaryShippingAddress?: boolean;
    paymentToken?: string;
  },
  step: "cart_meta" | "shipping_and_payment" = "cart_meta"
) => {
  return await axios.patch<"OK">(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/cart-config`,
    { configuration, step },
    { headers: REQ_HEADER }
  );
};
