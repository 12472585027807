import React from "react";
import ConfirmShoppingCartItem from "../ConfirmShoppingCartItem";
import _ from "lodash";
import plantsMap from "../mappings/plantsMap.json";
import { getBrowserStorage } from "../../../../utils/cookies";

class CartItemListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsInStock: [],
      itemsInStockAlter: [],
      itemsSplitBackorder: [],
      itemsComBackorder: [],
      noBucket: [],
    };
  }

  componentDidMount() {
    let itemsInStock = [];
    let itemsInStockAlter = [];
    let itemsSplitBackorder = [];
    let itemsComBackorder = [];
    let itemsWillCall = [];
    let noBucket = [];
    const cartItems = this.props.cartItems;
    cartItems.map((i) => {
      let index;
      try {
        index = i.selectedOption.index.split(",").map((i) => parseInt(i));
      } catch (error) {
        index = [];
      }
      if (
        index.length === 1 &&
        i.selectedOption.type === "availableAll" &&
        parseInt(i.selectedOption.index[0]) === 1
      ) {
        itemsInStock.push({ ...i });
      } else if (
        index.length === 1 &&
        i.quantity ===
          parseInt(i.selectedOption[`quantity_${String(index[0])}`]) &&
        (i.selectedOption.type === "shipAlternativeBranch" ||
          i.selectedOption.type === "availableAll")
      ) {
        itemsInStockAlter.push({ ...i });
      } else if (
        i.selectedOption.type !== "backOrderAll" &&
        !i.selectedOption.willCallPlant &&
        (index.length > 1 || i.selectedOption.backOrder === "T")
      ) {
        itemsSplitBackorder.push({ ...i });
      } else if (
        i.selectedOption[`backOrderQuantity_${String(index[0])}`] &&
        parseInt(i.selectedOption[`backOrderQuantity_${String(index[0])}`]) ===
          i.quantity
      ) {
        itemsComBackorder.push({ ...i });
      } else if (
        i.selectedOption.willCallPlant &&
        !_.isEmpty(i.selectedOption.willCallPlant)
      ) {
        itemsWillCall.push({ ...i });
      } else {
        noBucket.push({ ...i });
      }
    });
    this.setState({
      itemsInStock,
      itemsInStockAlter,
      itemsSplitBackorder,
      itemsComBackorder,
      itemsWillCall,
      noBucket,
    });
  }

  render() {
    const {
      itemsInStock,
      itemsInStockAlter,
      itemsSplitBackorder,
      itemsComBackorder,
      itemsWillCall,
      noBucket,
    } = this.state;
    // const itemList = this.props.itemList;
    // return (

    // //   <div>
    // //     if (cartItems) {
    // //   cartRows = cartItems.map((item, i) => (
    // //     <ConfirmShoppingCartItem key={i} item={item} />
    // //   ));
    // // }
    // //   </div>
    // );
    const homePlant = getBrowserStorage("defaultplant");
    return (
      <>
        {!_.isEmpty(itemsInStock) && (
          <>
            <tr>
              <td className="lineitem-shipping-details-blue" colSpan="6" tabIndex={0}>
                Shipping from {plantsMap[homePlant]}
              </td>
            </tr>
            {itemsInStock.map((i) => (
              <ConfirmShoppingCartItem key={i.sku} item={i} />
            ))}
            <tr>
              <td className="confirm-order-border" colSpan="6">
                <hr size="20" />
              </td>
            </tr>
          </>
        )}
        {!_.isEmpty(itemsInStockAlter) && (
          <>
            {itemsInStockAlter.map((i) => (
              <ConfirmShoppingCartItem
                key={i.sku}
                type="itemsInStockAlter"
                item={i}
              />
            ))}
            <tr>
              <td className="confirm-order-border" colSpan="6">
                <hr size="20" />
              </td>
            </tr>
          </>
        )}
        {!_.isEmpty(itemsSplitBackorder) && (
          <>
            {itemsSplitBackorder.map((i) => (
              <ConfirmShoppingCartItem
                type="itemsSplitBackorder"
                key={i.sku}
                item={i}
              />
            ))}
            <tr>
              <td className="confirm-order-border" colSpan="6">
                <hr size="20" />
              </td>
            </tr>
          </>
        )}
        {!_.isEmpty(itemsComBackorder) && (
          <>
            {itemsComBackorder.map((i) => (
              <ConfirmShoppingCartItem
                key={i.sku}
                type="itemsComBackorder"
                item={i}
              />
            ))}
          </>
        )}
        {!_.isEmpty(itemsWillCall) && (
          <>
            {itemsWillCall.map((i) => (
              <ConfirmShoppingCartItem
                key={i.sku}
                type="itemsWillCall"
                item={i}
              />
            ))}
          </>
        )}
        {!_.isEmpty(noBucket) && (
          <>
            {noBucket.map((i) => (
              <ConfirmShoppingCartItem
                key={i.sku}
                type="itemsComBackorder"
                item={i}
              />
            ))}
          </>
        )}
      </>
    );
  }
}

export default CartItemListing;
