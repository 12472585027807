/* eslint-disable prettier/prettier */
import React from "react";

import shippingMethodsMap from "./mappings/shippingMethodsMap.json";
import plantsMap from "./mappings/plantsMap.json";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateCartProduct } from "../../../store/actions/shoppingCart";
import _ from "underscore";

class ConfirmedShippingDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
    };
  }

  handleEdit = async () => {
    const product = await this.props.shoppingCart.cartItems.find(
      (item) => item.sku === this.props.sku
    );
    product.shippingConfirmed = await false;
    product.editShipping = await true;
    await this.props.actions.updateCartProduct(product);
    this.props.checkPendingAvailable();
    this.props.checkConfirmAvailable();
  };

  render() {
    if (_.isEmpty(this.props.shoppingCart.cartItems)) return null;
    const {
      configuration: { shipping_method_1 },
      selectedOption,
      selectedOption: { index },
      shippingConfirmed,
    } = this.props;
    let plantIndexList = [];
    if (!_.isEmpty(index)) plantIndexList = index.split(",");

    const product = this.props.shoppingCart.cartItems.find(
      (item) => item.sku === this.props.sku
    );

    let showDefaultShipping = false;
    if (product.optionType === "willCall" && product.pickupRemaining) {
      showDefaultShipping = true;
    } else if (!product.optionType || product.optionType === "default") {
      showDefaultShipping = true;
    }
    return (
      <div
        className="shipping-detils-grey m-2"
        style={{
          backgroundColor: "#F7F7F7",
          padding: 15,
        }}
      >
        <div>
          {shippingConfirmed ? ( // &&  plantIndexList ? (
            <div>
              <a
                title="edit"
                style={{ float: "right", fontSize: 8 }}
                onClick={this.handleEdit}
                onKeyPress={this.handleEdit}
                tabIndex="0"
              >
                <i className="material-icons">edit</i>
              </a>
              {!_.isEmpty(selectedOption.willCallPlant) && (
                <div>
                  <div style={{ display: "flex", marginBottom: 5 }}>
                    <div style={{ width: 80 }} tabIndex={0}> Ship From </div>
                    <div style={{ width: 180, fontWeight: "bold" }} tabIndex={0}>
                      {" "}
                      {plantsMap[selectedOption["willCallPlant"]]}
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: 5 }}>
                    <div style={{ width: 80 }} tabIndex={0}> Qty </div>
                    <div
                      style={{
                        width: 180,
                        fontWeight: "bold",
                        paddingRight: 10,
                      }}
                    >
                      {selectedOption["willCallQuantity"] &&
                      selectedOption.status === "inStock" ? (
                        <span tabIndex={0}>
                          {selectedOption["willCallQuantity"]} {"Available Now"}
                        </span>
                      ) : null}
                      {selectedOption["willCallQuantity"] &&
                      selectedOption.status === "limitedStock" ? (
                        <span tabIndex={0}>
                          {selectedOption["willCallQuantity"]} {"Available Now"}
                        </span>
                      ) : null}
                      {selectedOption["willCallQuantity"] &&
                      selectedOption.status === "notInStock" ? (
                        <span tabIndex={0}>
                          {selectedOption["willCallQuantity"]} Backorder, <br />
                          {moment(
                            new Date(selectedOption[`willCallBackOrder`])
                          ).format("dddd, MMM. D, YY")}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: 5 }}>
                    <div style={{ width: 80 }} tabIndex={0}> Via </div>
                    <div style={{ width: 180, fontWeight: "bold" }} tabIndex={0}>
                      {"Will Call Pickup"}
                    </div>
                  </div>

                  {plantIndexList.length > 1 ||
                  !_.isEmpty(selectedOption.willCallPlant) ? (
                    <hr size="20" />
                  ) : null}
                </div>
              )}
              {showDefaultShipping &&
                plantIndexList.map((i) => (
                  <div key={i}>
                    {selectedOption[`quantity_${i}`] ||
                    selectedOption[`backOrderDate_${plantIndexList[0]}`] ? (
                      <div>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                          <div style={{ width: 80 }} tabIndex={0}> Ship From </div>
                          <div style={{ width: 180, fontWeight: "bold" }} tabIndex={0}>
                            {" "}
                            {plantsMap[selectedOption[`plant_${i}`]]}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                          <div style={{ width: 80 }} tabIndex={0}> Qty </div>
                          <div
                            style={{
                              width: 180,
                              fontWeight: "bold",
                              paddingRight: 10,
                            }}
                          >
                            {" "}
                            {selectedOption[`quantity_${i}`] ? (
                              <>
                                <span tabIndex={0}>
                                  {selectedOption[`quantity_${i}`]}{" "}
                                  {"Available Now"}
                                </span>
                                <br />
                              </>
                            ) : null}
                            {selectedOption.backOrder === "T" ? (
                              <span>
                                {selectedOption[`backOrderQuantity_${i}`] ? (
                                  <span tabIndex={0}>
                                    {
                                      selectedOption[
                                        `backOrderQuantity_${plantIndexList[0]}`
                                      ]
                                    }{" "}
                                    Backorder, <br />
                                    {moment(
                                      new Date(
                                        selectedOption[
                                          `backOrderDate_${plantIndexList[0]}`
                                        ]
                                      )
                                    ).format("dddd, MMM. D, YY")}
                                  </span>
                                ) : null}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: 5 }}>
                          <div style={{ width: 80 }}> Via </div>
                          <div style={{ width: 180, fontWeight: "bold" }}>
                            {parseInt(plantIndexList[0]) === 1 ? (
                              <div tabIndex={0}>
                                {" "}
                                {shippingMethodsMap[shipping_method_1]
                                  ? shippingMethodsMap[shipping_method_1]
                                      .displayName
                                  : null}
                              </div>
                            ) : (
                              <div tabIndex={0}> {"Ground Shipping"}</div>
                            )}
                          </div>
                        </div>
                        {plantIndexList.length > 1 ? <hr size="20" /> : null}
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateCartProduct }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmedShippingDetailsCard);
