import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";
import _ from "lodash";
import moment from "moment";
import Img from "../../common/Img";
import {
  formatPrice,
  getShippingMethodsList,
} from "../../routewise/shopping-cart/utils";
import plantsMap from "../../routewise/shopping-cart/mappings/plantsMap.json";
import shippingMethodsMap from "../../routewise/shopping-cart/mappings/shippingMethodsMap.json";
import { SHIP_TO_COOKIE } from "../../common/Constants";
import roundDivider from "../../../assets/img/svg/round_divider_dark.svg";

const ConfirmshoppingCartItemMobile = ({ item, type }) => {
  const [index, setIndex] = useState([]);
  const shipMethod =
    item?.shipMethod ??
    item?.configuration?.shipping_method_1 ??
    item?.configuration?.shippingMethod1;

  useEffect(() => {
    try {
      if (item) {
        setIndex(item.selectedOption.index.split(",").map((i) => parseInt(i)));
      }
    } catch (error) {
      setIndex([]);
    }
  }, []);

  // Render shipping method for backOrderQuantity_1
  const renderShippingMethodBOQ1 = () => {
    if (item?.status === "notInStock" || item?.status === "limitedStock") {
      if (index > 1) {
        return shippingMethodsMap["G"].displayName;
      } else {
        if (shipMethod === "0") {
          return shippingMethodsMap[
            getShippingMethodsList(item?.selectedOption.shippingMethods_1)[0]
          ].displayName;
        } else {
          return shipMethod ? shippingMethodsMap[shipMethod].displayName : "";
        }
      }
    }
    return "";
  };

  return (
    <>
      <div className="order-receipt-item-list">
        <div className="confirm-order-img">
          <a href="#/" title={parse(item.name)}>
            <Img
              title={item.name}
              style={{ height: 80 }}
              src={`${process.env.REACT_APP_IMAGE_URL}/${item.img}`}
              alt={item.name}
            />
          </a>
        </div>
        <div style={{ width: "100%" }}>
          <div className="d-flex">
            <span>{item.sku} </span>
            {item.ciNumber && (
              <span className="red-text"> | CI: {item.ciNumber}</span>
            )}
          </div>
          <strong> {item.name}</strong>
          <br />
          <div className="po-job-name">
            <p>
              PO/Job name: <span>{item.configuration.poOrJobName}</span>
            </p>
          </div>
          <div className="qty-total">
            <div className="qty-line">
              <p>
                Qty: <span>{item.quantity} Each</span>
              </p>
            </div>
            <div className="total-amt">
              $ {item.extended ? formatPrice(item.extended) : "0.00"}
            </div>
          </div>
        </div>
      </div>

      <div>
        {type === "itemsInStockAlter" && (
          <>
            <div className="line-items-details">
              <div className="lineitem-shipping-details-blue">
                <div className="d-flex text-bold" tabIndex={0}>
                  Ship from{" "}
                  {
                    plantsMap[
                      item.selectedOption[`plant_${item.selectedOption.index}`]
                    ]
                  }{" "}
                </div>
                <div className="d-flex" tabIndex={0}>
                  <span className="btm-qty">Qty: {item.quantity}</span>
                  <Img src={roundDivider} alt="" />
                  Ground Shipping
                </div>
              </div>
            </div>
          </>
        )}

        {type === "itemsComBackorder" &&
          index.map((i) => (
            <>
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_${i}`]]}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_${i}`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {i === SHIP_TO_COOKIE
                      ? shippingMethodsMap[item.configuration.shipping_method_1]
                          .displayName
                      : "Ground Shipping"}
                  </div>
                </div>
              </div>
            </>
          ))}

        {type === "itemsSplitBackorder" && (
          <>
            {index.map((i) => (
              <>
                {item.selectedOption[`quantity_${i}`] ? (
                  <div className="line-items-details">
                    <div className="lineitem-shipping-details-blue">
                      <div className="d-flex text-bold" tabIndex={0}>
                        Ship from {plantsMap[item.selectedOption[`plant_${i}`]]}
                      </div>
                      <div className="d-flex">
                        <span className="btm-qty" tabIndex={0}>
                          Qty: {item.selectedOption[`quantity_${i}`]}
                        </span>
                        <Img src={roundDivider} alt="" />
                        {i === SHIP_TO_COOKIE
                          ? shippingMethodsMap[
                              item.configuration.shipping_method_1
                            ].displayName
                          : "Ground Shipping"}
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
            {!_.isEmpty(item.selectedOption.backOrderQuantity_1) && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_1`]]}{" "}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_1`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {moment(
                      new Date(item.selectedOption.backOrderDate_1)
                    ).format("dddd, MMM. D, YYYY")}
                    <Img src={roundDivider} alt="" />{" "}
                    {renderShippingMethodBOQ1()}
                  </div>
                </div>
              </div>
            )}
            {!_.isEmpty(item.selectedOption.backOrderQuantity_2) && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_2`]]}{" "}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_2`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {moment(
                      new Date(item.selectedOption.backOrderDate_2)
                    ).format("dddd, MMM. D, YYYY")}
                    <Img src={roundDivider} alt="" />
                    {"Ground Shipping"}
                  </div>
                </div>
              </div>
            )}
            {!_.isEmpty(item.selectedOption.backOrderQuantity_3) && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_3`]]}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_3`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {moment(
                      new Date(item.selectedOption.backOrderDate_3)
                    ).format("dddd, MMM. D, YYYY")}{" "}
                    <Img src={roundDivider} alt="" />
                    {"Ground Shipping"}
                  </div>
                </div>
              </div>
            )}
            {!_.isEmpty(item.selectedOption.backOrderQuantity_4) && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_4`]]}{" "}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_4`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {moment(
                      new Date(item.selectedOption.backOrderDate_4)
                    ).format("dddd, MMM. D, YYYY")}
                    <Img src={roundDivider} alt="" />
                    {"Ground Shipping"}
                  </div>
                </div>
              </div>
            )}
            {!_.isEmpty(item.selectedOption.backOrderQuantity_5) && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_5`]]}{" "}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`backOrderQuantity_5`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {moment(
                      new Date(item.selectedOption.backOrderDate_5)
                    ).format("dddd, MMM. D, YYYY")}
                    <Img src={roundDivider} alt="" />
                    {"Ground Shipping"}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {type === "itemsWillCall" && (
          <>
            <div className="line-items-details">
              <div className="lineitem-shipping-details-blue">
                <div className="d-flex text-bold" tabIndex={0}>
                  Ship from {plantsMap[item.selectedOption[`willCallPlant`]]} |{" "}
                </div>
                <div className="d-flex">
                  <span className="btm-qty" tabIndex={0}>
                    Qty: {item.selectedOption[`willCallQuantity`]}
                  </span>
                  <Img src={roundDivider} alt="" />
                  {"Will Call Pickup"}
                </div>
              </div>
            </div>
            {index[0] && item.selectedOption[`quantity_${index[0]}`] && (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-blue">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_${index[0]}`]]} |{" "}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty: {item.selectedOption[`quantity_${index[0]}`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {index[0] === 1
                      ? shippingMethodsMap[
                          item.configuration[`shipping_method_${index[0]}`]
                        ].displayName
                      : "Ground Shipping"}
                  </div>
                </div>
              </div>
            )}
            {index[0] ? (
              <div className="line-items-details">
                <div className="lineitem-shipping-details-gray">
                  <div className="d-flex text-bold" tabIndex={0}>
                    Backordered <Img src={roundDivider} alt="" /> Ship from{" "}
                    {plantsMap[item.selectedOption[`plant_${index[0]}`]]}
                  </div>
                  <div className="d-flex">
                    <span className="btm-qty" tabIndex={0}>
                      Qty:
                      {item.selectedOption[`backOrderQuantity_${index[0]}`]}
                    </span>
                    <Img src={roundDivider} alt="" />
                    {index[0] === 1
                      ? shippingMethodsMap[
                          item.configuration[`shipping_method_${index[0]}`]
                        ].displayName
                      : "Ground Shipping"}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmshoppingCartItemMobile;
