import {
  SET_SHIPPING_ADDRESS,
  SET_CART_ITEMS,
  SET_CART_TOKEN,
  SET_ORDER_PRICE,
  SET_PROMO_CODE,
  SET_LOAD_CART_FUNC,
  SET_SHIPPING_PROD_LANDING,
  SET_CART_DATA,
  UPDATE_CART_PRODUCT,
  SET_NOTIFICATIONS,
  USE_SINGLE_CHECKOUT,
  SET_TEMP_CREDIT_CARD,
  SET_PAYMENT_METHOD,
  SET_CHECKOUT_IS_PROCESSING,
} from "../constants";
import { setBrowserStorage } from "../../utils/cookies";

export function setShippingAddress(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_SHIPPING_ADDRESS,
      payload: value,
    });
  };
}

export function setCartItems(valueArray) {
  setBrowserStorage("cartItems", JSON.stringify(valueArray));
  return async (dispatch) => {
    dispatch({
      type: SET_CART_ITEMS,
      payload: valueArray,
    });
  };
}

export function setCartToken(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_CART_TOKEN,
      payload: value,
    });
  };
}

export function setOrderPrice(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_ORDER_PRICE,
      payload: value,
    });
  };
}
export function setPromoCode(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_PROMO_CODE,
      payload: value,
    });
  };
}

export function setLoadCartFunc(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_LOAD_CART_FUNC,
      payload: value,
    });
  };
}

export function setShippingProdLanding(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_SHIPPING_PROD_LANDING,
      payload: value,
    });
  };
}

export function setCartData(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_CART_DATA,
      payload: value,
    });
  };
}

export function updateCartProduct(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CART_PRODUCT,
      payload: value,
    });
  };
}

export function setNotifications(messages, page) {
  return async (dispatch) => {
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: messages,
      page,
    });
  };
}

export function setUseSingleCheckout(value) {
  return async (dispatch) => {
    dispatch({
      type: USE_SINGLE_CHECKOUT,
      payload: value,
    });
  };
}

export function setTempCreditCard(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_TEMP_CREDIT_CARD,
      payload: value,
    });
  };
}

export function setPaymentMethod(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: value,
    });
  };
}

export function setCheckoutIsProcessing(value, callback) {
  return async (dispatch) => {
    dispatch({
      type: SET_CHECKOUT_IS_PROCESSING,
      payload: value,
    });
    if (callback && value === false) {
      callback();
    }
  };
}
