/* eslint-disable prettier/prettier */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { REQ_HEADER } from "./Tokens";
import { PropTypes } from "prop-types";
import Joi from "@hapi/joi";
import _ from "lodash";
import editIcon from "../../../assets/img/svg/edit.svg";
import { ErrorAlert } from "./common";
import Autocomplete from "react-autocomplete";
import { STATE } from "../../../lib/utils/constant";
import Img from "../../common/Img";
import plusSign from "../../../assets/img/svg/plus_sign.svg";
import { isMobileOnly } from "react-device-detect";
import { serviceGetAddressesOrder } from "./services/servicesEP";
import { setCartData } from "app/store/actions/shoppingCart";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const initailState = {
  show: false,
  close: false,
  addressDetails: {
    name: "",
    addLine1: "",
    locality: "",
    region: "",
    zipCode: "",
    phoneNo: "",
  },
  errors: "",
  responseErrors: "",
};

class EditShippingDetails extends Component {
  static propTypes = {
    loadingaddress: PropTypes.func,
    isCheckout: PropTypes.boolean,
  };

  state = initailState;

  schema = {
    name: Joi.string().allow("").max(30),
    addLine1: Joi.string().required().label("Address Line 1"),
    locality: Joi.string().required().label("City"),
    region: Joi.string().required().label("State"),
    // .error(() => "Please enter valid State"),
    zipCode: Joi.string()
      .required()
      .label("Zip Code")
      .regex(/^[0-9]{5}(?:-[0-9]{4})?$/)
      .error((e) => {
        let msg;
        switch (e[0].type) {
          case "any.empty":
            msg = '"Zip Code" is not allowed to be empty';

            break;

          default:
            msg = '"Zip Code" field format should be : NNNNN or NNNNN-NNNN';
            break;
        }
        return msg;
      }),

    phoneNo: Joi.string()
      .required()
      .label("Phone No")
      .regex(/^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$/)
      .error((e) => {
        let msg;
        switch (e[0].type) {
          case "any.empty":
            msg = '"Phone No" is not allowed to be empty';

            break;

          default:
            msg =
              '"Phone No" field format should be : NNN-NNN-NNNN (where "N" is a number between 0-9)"';
            break;
        }
        return msg;
      }),
  };

  componentDidMount() {
    if (this.props.editShippingDetails) {
      const addressDetails = {};
      addressDetails.name = this.props.addressData.name["family-name"];
      addressDetails.addLine1 =
        this.props.addressData.addressLines["street-address"];
      addressDetails.region = this.props.addressData.addressLines.region;
      addressDetails.locality = this.props.addressData.addressLines.locality;
      addressDetails.zipCode =
        this.props.addressData.addressLines["postal-code"];
      addressDetails.phoneNo =
        this.props.addressData.addressLines["phone-number"];
      this.setState({ addressDetails });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.setShow(true);
    }
  }

  setShow = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.name = "";
    addressDetails.addLine1 = "";
    addressDetails.locality = "";
    addressDetails.region = "";
    addressDetails.zipCode = "";
    addressDetails.phoneNo = "";
    this.setState({ show: val, addressDetails });
  };
  setClose = (val) => {
    this.setState({ close: val });
  };

  setName = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.name = val.trimStart();
    this.setState({ addressDetails });
  };

  setAddLine1 = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.addLine1 = val.trimStart();
    this.setState({ addressDetails });
  };

  setCity = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.locality = val.trimStart();
    this.setState({ addressDetails });
  };

  setLocState = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.region = val.trimStart();
    this.setState({ addressDetails });
  };

  setZipCode = (val) => {
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.zipCode = val.trim();
    this.setState({ addressDetails });
  };

  formatPhoneNumber = (number) => {
    const beg = String(number).slice(0, 3);
    const mid = String(number).slice(3, 6);
    const last = String(number).slice(6);
    return beg + "-" + mid + "-" + last;
  };

  setPhoneNo = (val) => {
    val = val.trimStart();
    const addressDetails = { ...this.state.addressDetails };
    addressDetails.phoneNo = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    // if (val.trim().length > 10 ) return;
    this.setState({ addressDetails });
  };

  validate = () => {
    const result = Joi.validate(this.state.addressDetails, this.schema, {
      abortEarly: true,
    });

    if (_.isEmpty(result.error)) return;
    let errors;
    if (result.error.details) {
      errors = result.error.details[0].message;
    }

    return errors;
  };

  addCustomShippingAddress = async () => {
    const errors = this.validate();
    this.setState({ errors });

    if (errors) return;
    try {
      const data = {
        region: this.state.addressDetails.region,
        locality: this.state.addressDetails.locality,
        "family-name": this.state.addressDetails.name,
        "postal-code": this.state.addressDetails.zipCode,
        "phone-number": this.state.addressDetails.phoneNo,
        "street-address": this.state.addressDetails.addLine1,
        context: this.props?.isCheckout ? "created_on_checkout" : null,
      };
      
      await axios({
        url: this.props.editShippingDetails
          ? `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address/${this.props.addressData.addressId}`
          : `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/shipping-address`,
        method: this.props.editShippingDetails ? "PUT" : "POST",
        headers: REQ_HEADER,
        data,
      });

      // Update cart config
      const { choice } = await serviceGetAddressesOrder();
      if (choice.length > 0) {
        const newAddress = choice[choice.length - 1];

        if (newAddress?.id && !_.isEmpty(this.props.cartData)) {
          const cartData = {
            ...this.props.cartData,
            configuration: {
              ...this.props.cartData.configuration,
              shippingAddressId: newAddress?.id,
            },
          };
          this.props.actions.setCartData(cartData);
        }
      }

      this.props.loadingaddress();
      this.setState({ show: false });
      if (this.props.editShippingDetails)
        this.props.setSuccessMessage("Shipping address updated.");
    } catch (error) {
      // Check for zip code related errors
      if (error?.response?.data?.message["postalCode"]) {
        return this.setState({ errors: '"Zip Code" field format should be : NNNNN or NNNNN-NNNN' });
      }
      // Check for state related errors
      else if (error?.response?.data?.message["region"]) {
        return this.setState({ errors: "Please enter a valid state" });
      }
      // Check for phone number related errors
      else if (error?.response?.data?.message["phoneNumber"]) {
        return this.setState({ errors: '"Phone No" field format should be : NNN-NNN-NNNN (where "N" is a number between 0-9)"' });
      }
    }
  };

  handleErrorClose = () => {
    this.setState({ errors: "" });
  };

  handleEdit = async () => {
    this.setState({ show: true });
  };

  stateHandler = (e) => {
    const { addressDetails } = this.state;
    addressDetails.region = e.target.value;
    this.setState({ addressDetails });
  };

  stateOnSelectHandler = (value) => {
    const { addressDetails } = this.state;
    addressDetails.region = value;
    this.setState({ addressDetails });
  };

  getCityInput = () => {
    return (
      <>
        {" "}
        <label tabIndex={0} htmlFor="city">City</label>
        <input
          autoFocus
          className="form-control"
          id="city"
          placeholder="City"
          value={this.state.addressDetails.locality}
          onChange={(e) => this.setCity(e.target.value)}
        />
      </>
    );
  };

  getStateInput =  () => {
    return (
      <>
        {" "}
        <label tabIndex={0} htmlFor="state">State</label>
        <Autocomplete
          getItemValue={(item) => item.id}
          className
          wrapperStyle={{
            position: "relative",
            display: "inline-block",
          }}
          inputProps={{ id: "states-autocomplete" }}
          items={STATE}
          shouldItemRender={(item, value) =>
            item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          renderItem={(item, isHighlighted) => (
            <div
              className="dropdown-lists"
              tabIndex={0}
              style={{
                background: isHighlighted ? "lightgray" : "white",
              }}
              key={item?.id}
            >
              {item.id}
            </div>
          )}
          value={this.state.addressDetails.region}
          onChange={this.stateHandler}
          onSelect={this.stateOnSelectHandler}
        />
      </>
    );
  };

  getZipCodeInput = () => {
    return (
      <>
        <label tabIndex={0} htmlFor="zipCode">{isMobileOnly ? "Zip" : "Zip code*"}</label>
        <input
          autoFocus
          className="form-control"
          id="zipCode"
          placeholder="Zip Code"
          value={this.state.addressDetails.zipCode}
          onChange={(e) => this.setZipCode(e.target.value)}
        />
      </>
    );
  };

  getPhoneInput = () => {
    return (
      <>
        <label tabIndex={0} htmlFor="phoneNo">
          <strong> {isMobileOnly ? "Phone" : "Phone Number*"}</strong>
        </label>
        <input
          autoFocus
          className="form-control"
          id="phoneNo"
          placeholder={!isMobileOnly ? "Phone No" : "Phone Number"}
          value={this.state.addressDetails.phoneNo}
          onChange={(e) => this.setPhoneNo(e.target.value)}
        />
      </>
    );
  };

  handleClose = () => {
    this.setShow(false);
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="add-new-address">
        {!isMobileOnly &&
          (this.props.editShippingDetails ? (
            <button onClick={this.handleEdit}>
              <img src={editIcon} alt="" />
            </button>
          ) : (
            <a
              title="Add new shipping address"
              className="link-black"
              onClick={() => this.setShow(true)}
              onKeyPress={() => this.setShow(true)}
              tabIndex="0"
            >
              {this.props.displayCard ? (
                <div
                  style={{
                    height: 160,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                    textAlign: "center",
                    padding: 30,
                    fontWeight: "bold",
                    fontFamily: "Arial",
                    textTransform: "none",
                  }}
                  className="shadow-on-white"
                  tabIndex="0"
                >
                  {"+ Add new shipping address"}
                </div>
              ) : (
                <span tabIndex={0} title="Add new address" className="d-flex">
                  <Img src={plusSign} alt="" /> {"Add new address"}
                </span>
              )}
            </a>
          ))}
        <Modal
          show={this.state.show}
          onHide={() => this.setShow(false)}
          tabIndex="0"
          className={isMobileOnly && "bottom-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title tabIndex="0">
              {isMobileOnly ? "Add Shipping Address" : "Shipping Address"}
            </Modal.Title>
          </Modal.Header>
          <div className="shipping-address-popup">
            {!_.isEmpty(errors) && (
              <ErrorAlert
                errorMessage={errors}
                handleErrorClose={this.handleErrorClose}
              />
            )}
            <div className="form-group">
              <label tabIndex={0} htmlFor="name">
                Attn{isMobileOnly && <span> (Optional)</span>}
              </label>
              <input
                autoFocus
                type="text"
                className="form-control"
                id="name"
                aria-describedby="nameHelp"
                placeholder={isMobileOnly ? "Name Here" : "Attn"}
                value={this.state.addressDetails.name}
                onChange={(e) => this.setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label tabIndex={0} htmlFor="exampleInputPassword1">Address Line 1</label>
              <input
                autoFocus
                className="form-control"
                id="addressLine"
                placeholder="Address Line 1"
                value={this.state.addressDetails.addLine1}
                onChange={(e) => this.setAddLine1(e.target.value)}
              />
            </div>

            {!isMobileOnly ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="form-group col-lg-4 p-0">
                    {this.getCityInput()}
                  </div>
                  <div className="form-group col-lg-4 pl-0 states-autocomplete">
                    {this.getStateInput()}
                  </div>

                  <div className="form-group col-lg-3 p-0">
                    {this.getZipCodeInput()}
                  </div>
                </div>
                <div className="mb-3">{this.getPhoneInput()}</div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="form-group col-lg-6 pl-0">
                      {this.getCityInput()}
                    </div>
                    <div className="form-group col-lg-6 pl-0">
                      {this.getZipCodeInput()}
                    </div>
                  </div>
                  <div>
                    <div className="form-group col-lg-6 pl-0 pr-0 states-autocomplete mob-state">
                      {this.getStateInput()}
                    </div>
                    <div className="form-group col-lg-6 pl-0 pr-0">
                      {this.getPhoneInput()}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={`${isMobileOnly ? "two-btn-row" : "text-right"}`}>
              {isMobileOnly && (
                <button
                  type="button"
                  class="red-border-btn btn"
                  onClick={() => this.handleClose()}
                >
                  Cancel
                </button>
              )}

              <button
                type="submit"
                class="red-btn btn"
                onClick={this.addCustomShippingAddress}
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

export default connect(null, mapDispatchToProps)(EditShippingDetails);
