import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Route, Redirect } from "react-router-dom";
import AuthenticatingMsg from "../AuthenticatingMsg";

const UnauthenticatedRoute = ({
  component: Component,
  redirect = true,
  ...rest
}) => {
  let query = queryString.parse(rest.location.search);
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticating ? (
          <AuthenticatingMsg />
        ) : !rest.isAuthenticated || !redirect ? (
          <Component {...props} />
        ) : (
          <Redirect to={query.redirect || "/"} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
});

export default connect(mapStateToProps, null)(UnauthenticatedRoute);
