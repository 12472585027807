/* eslint-disable prettier/prettier */
import React, { Component, useState } from "react";
import EditShippingDetails from "./EditShippingDetails";
import { PropTypes } from "prop-types";
import { serviceGetAddressesOrder } from "./services/servicesEP";
import ErrorBoundary from "../../common/errorHandling/ErrorBoundary";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { setCartItems, setCartData } from "../../../store/actions/shoppingCart";
import { connect } from "react-redux";
import { setBrowserStorage, getBrowserStorage } from "../../../utils/cookies";
import { updateCartConfiguration } from "app/services/servicesPIM";
import { setDefaultShippingAddress } from "./services/servicesPIM.new";

const MAX_CHAR_ATTN = 40;

const AddressCard = (props) => {
  const addressData = props.addressData;
  const selected = props.selected;

  const selectAddress = async (e) => {
    const { checked } = e.target;
    if (checked) {
      props.updateAddressChanged("T");
      try {
        const res = await setDefaultShippingAddress(
          props.addressData.addressId,
          props.addressData.isPrimary
        );
        props.reloadAddress();
        setBrowserStorage(
          "selectedShippingAddress",
          JSON.stringify(addressData)
        );
      } catch (error) {}
    }
    // else {
    // props.updateAddressChanged("F");
    // }
  };

  return (
    <div className="shipping-address-card">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={`address-option-${props.index}`}
          className="custom-control-input"
          onClick={selectAddress}
          checked={selected}
        />
        <label
          className="custom-control-label"
          htmlFor={`address-option-${props.index}`}
        >
          {/* {addressData.name &&
            addressData.name["family-name"] !== "null" &&
            !props.selected &&
            addressData.name["family-name"]} */}
          <p className="address-text mb-0">
            <strong>{addressData.addressLines["organization"]}</strong>
            <br />
            {addressData.addressLines["street-address"]}, <br />
            {addressData.addressLines["locality"]},{" "}
            {addressData.addressLines["region"]},{" "}
            {/* {addressData.addressLines["country-name"]},{" "} */}
            {addressData.addressLines["postal-code"]}
          </p>
          <div>Phone: {addressData.addressLines["phone-number"]}</div>
        </label>
      </div>
      <hr />
    </div>
  );
};

class ShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartUrl: "",
      addresslist: [],
      defaultAddLink: "",
      customAddlist: [],
      namelist: [],
      addressCheck: "F",
      cartConfigUrl: "",
      addressAttn: "",
      chosen: [],
      choice: [],
    };
    this.updateAddressChanged = this.updateAddressChanged.bind(this);
  }

  async componentDidMount() {
    try {
      const cartUrl = JSON.parse(getBrowserStorage("cartData")).cartUrl;
      this.setState({ cartUrl });
      await this.updateAddressChanged();
      const { choice, chosen } = await serviceGetAddressesOrder(cartUrl);
      if (!_.isEmpty(chosen)) {
        if (this.props.setIsAddressSelected)
          this.props.setIsAddressSelected(true);
        this.setState({ addressAttn: chosen[0].name["family-name"] });
        setBrowserStorage("selectedShippingAddress", JSON.stringify(chosen[0]));
      } else {
        if (this.props.setIsAddressSelected)
          this.props.setIsAddressSelected(false);
      }

      this.setState({ choice, chosen });
    } catch (error) {}
  }

  reloadAddress = async () => {
    const { choice, chosen } = await serviceGetAddressesOrder(
      this.state.cartUrl
    );
    if (!_.isEmpty(chosen))
      this.setState({ addressAttn: chosen[0].name["family-name"] });
    else {
      if (this.props.setIsAddressSelected)
        this.props.setIsAddressSelected(false);
    }
    this.setState({ choice, chosen });
  };

  async updateAddressChanged(addressCheck) {
    try {
      await updateCartConfiguration({ addressCheck });
    } catch (error) {}
  }

  async updateAddressAttn(attn) {
    if (attn.length > MAX_CHAR_ATTN) return;
    this.setState({ addressAttn: attn });
  }

  async updateAddressAttnFocus(attn) {
    const cartData = {
      ...this.props.cartData,
    };
    cartData.configuration.attnName = attn;
    this.props.actions.setCartData(cartData);
    //axios call
  }

  render() {
    const { choice, chosen } = this.state;
    return (
      <ErrorBoundary>
        <div className="address-attn">
          <div className="attn">Attn: </div>
          <label className="visuallyhidden" for="name">
            Attn:
          </label>
          <input
            id="name"
            value={
              this.state.addressAttn === "null" ? "" : this.state.addressAttn
            }
            onChange={(e) => this.updateAddressAttn(e.target.value)}
            onBlur={(e) => this.updateAddressAttnFocus(e.target.value)}
          />
        </div>
        <div className="shipping-address-list">
          {!_.isEmpty(chosen) && (
            <AddressCard
              index={true}
              addressData={chosen[0]}
              selected={true}
              updateAddressChanged={this.updateAddressChanged}
            />
          )}
          {!_.isEmpty(choice) &&
            choice.map((addressData, i) => (
              <AddressCard
                index={i}
                addressData={addressData}
                selected={false}
                updateAddressChanged={this.updateAddressChanged}
                reloadAddress={this.reloadAddress}
              />
            ))}
        </div>
        <EditShippingDetails loadingaddress={this.reloadAddress} isCheckout={true} />
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartItems, setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddress);
