import React from "react";
import _ from "lodash";
import { isMobileOnly } from "react-device-detect";

const ShippingAddressView = ({ selectedShippingAddress, cartAttn }) => {
  let attn;
  if (!_.isEmpty(cartAttn)) attn = cartAttn;
  else if (
    !_.isEmpty(selectedShippingAddress["family-name"]) &&
    selectedShippingAddress["family-name"] !== "null"
  )
    attn = selectedShippingAddress["family-name"];
  else attn = "";
  return (
    <>
      {!isMobileOnly ? (
        <div className="address-text">
          <strong>{selectedShippingAddress["organization"]}</strong>
          <br />
          {!_.isEmpty(attn) ? (
            <>
              <span>Attn: {attn}</span>
              <br />
            </>
          ) : null}
          {selectedShippingAddress["street-address"]}, <br />
          {selectedShippingAddress["locality"]},{" "}
          {selectedShippingAddress["region"]},{" "}
          {selectedShippingAddress["postal-code"]}
          {selectedShippingAddress["phone-number"] ? (
            <p>Phone: {selectedShippingAddress["phone-number"]}</p>
          ) : null}
        </div>
      ) : (
        <span className="address-text">
          <strong>{selectedShippingAddress["organization"]}</strong>
          <br />
          {!_.isEmpty(attn) ? (
            <>
              <span>Attn: {attn}</span>
              <br />
            </>
          ) : null}
          {selectedShippingAddress["street-address"]}, <br />
          {selectedShippingAddress["locality"]},{" "}
          {selectedShippingAddress["region"]},{" "}
          {selectedShippingAddress["postal-code"]}
        </span>
      )}
    </>
  );
};

export default ShippingAddressView;
