import {
  OSR_DETAILS_API,
  SOLDTOACC_IMAGE_UPLOAD_API,
  VIEW_CART_DEFAULT_URL,
} from "../APIs";
import axios from "axios";
import {
  getBrowserStorage,
  getBrowserStorage_Cookie,
} from "../../../../utils/cookies";
import { getNextShipMethod } from "../../../common/commonFunc";
import { updateCartConfiguration } from "app/services/servicesPIM";
import { formatCardExpDate } from "../utils";
import { BACK_ORDER_ALL, TRUE } from "app/components/common/Constants";
import { REQ_HEADER } from "../Tokens";

const WILLCALL_VAR = "willCall";

export const serviceGetCompanyProfile = async () => {
  const shipToAccNo = getBrowserStorage_Cookie("accNo");
  const url = OSR_DETAILS_API + shipToAccNo;
  try {
    const res = await axios({
      method: "GET",
      url,
    });
    return res;
  } catch (error) {}
};

export const serviceUploadCompanyImage = async (imageFile) => {
  const shipToAccNo = getBrowserStorage_Cookie("accNo");
  const url = SOLDTOACC_IMAGE_UPLOAD_API + String(shipToAccNo).trim();
  var formData = new FormData();
  formData.append("image", imageFile);
  try {
    const res = await axios({
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      url,
      data: formData,
    });
    return res;
  } catch (error) {}
};

export const serviceUpdateItemsPIM = async (
  itemsList,
  prodLandingSelectOption = null
) => {
  try {
    const itemsConfigList = await itemsList.map((item) => {
      let will_call_avail = "";
      let will_call_plant = "";

      if (prodLandingSelectOption !== null) {
        item.selectedOption = prodLandingSelectOption.selectedOption;
      }
      const avail_1 =
        item.selectedOption && item.selectedOption.quantity_1
          ? item.selectedOption.quantity_1
          : "";

      const avail_2 =
        item.selectedOption && item.selectedOption.quantity_2
          ? item.selectedOption.quantity_2
          : "";

      const avail_3 =
        item.selectedOption && item.selectedOption.quantity_3
          ? item.selectedOption.quantity_3
          : "";

      const avail_4 =
        item.selectedOption && item.selectedOption.quantity_4
          ? item.selectedOption.quantity_4
          : "";

      const avail_5 =
        item.selectedOption && item.selectedOption.quantity_5
          ? item.selectedOption.quantity_5
          : "";

      const plant_1 =
        item.selectedOption && item.selectedOption.plant_1
          ? item.selectedOption.plant_1
          : "";

      const plant_2 =
        item.selectedOption && item.selectedOption.plant_2
          ? item.selectedOption.plant_2
          : "";

      const plant_3 =
        item.selectedOption && item.selectedOption.plant_3
          ? item.selectedOption.plant_3
          : "";

      const plant_4 =
        item.selectedOption && item.selectedOption.plant_4
          ? item.selectedOption.plant_4
          : "";

      const plant_5 =
        item.selectedOption && item.selectedOption.plant_5
          ? item.selectedOption.plant_5
          : "";

      if (item.optionType === WILLCALL_VAR) {
        will_call_plant =
          item.selectedOption && item.selectedOption.willCallPlant
            ? item.selectedOption.willCallPlant
            : "";

        will_call_avail =
          item.selectedOption && item.selectedOption.willCallQuantity
            ? item.selectedOption.willCallQuantity
            : "";
      }
      let backOrderAll =
        item.selectedOption && item.selectedOption.type === BACK_ORDER_ALL
          ? TRUE
          : "";
      const itemConfig = {
        lineitemguid: item.lineItemguid,
        quantity: item.quantity,
        cartitemconfiguration: {
          avail_1: avail_1,
          avail_2: avail_2,
          avail_3: avail_3,
          avail_4: avail_4,
          avail_5: avail_5,
          plant_1: plant_1,
          plant_2: plant_2,
          plant_3: plant_3,
          plant_4: plant_4,
          plant_5: plant_5,
          poOrJobName: item.configuration.poOrJobName || "",
          shipping_method_1:
            item?.configuration?.shipping_method_1 ??
            item?.configuration?.shippingMethod1 ??
            "",
          shipping_method_2:
            item.selectedOption && item.selectedOption.shippingMethods_2
              ? getNextShipMethod(
                  item.selectedOption,
                  item.selectedOption.shippingMethods_2
                )
              : "",
          shipping_method_3:
            item.selectedOption && item.selectedOption.shippingMethods_3
              ? getNextShipMethod(
                  item.selectedOption,
                  item.selectedOption.shippingMethods_3
                )
              : "",
          shipping_method_4:
            item.selectedOption && item.selectedOption.shippingMethods_4
              ? getNextShipMethod(
                  item.selectedOption,
                  item.selectedOption.shippingMethods_4
                )
              : "",
          shipping_method_5:
            item.selectedOption && item.selectedOption.shippingMethods_5
              ? getNextShipMethod(
                  item.selectedOption,
                  item.selectedOption.shippingMethods_5
                )
              : "",
          will_call_avail: will_call_avail,
          will_call_plant: will_call_plant,
          hashvalue:
            item.selectedOption && item.selectedOption.hash
              ? item.selectedOption.hash
              : "",
          selectedOption: "",
          backorder_all: backOrderAll,
        },
      };

      return itemConfig;
    });
    const cleanedItemsList = _.compact(itemsConfigList);
    const data = {
      cartitembatchconfiguration: cleanedItemsList,
    };
    const res = await axios.put(VIEW_CART_DEFAULT_URL, data, {
      headers: REQ_HEADER,
    });
    return res;
  } catch (error) {}
};

export const serviceSetCreditCardDetails = async (
  cardName,
  cardType,
  paymentMethod,
  expireDate,
  paymentToken
) => {
  try {
    return await updateCartConfiguration(
      {
        cardName,
        cardType,
        paymentMethod,
        expireDate: formatCardExpDate(expireDate),
        paymentToken,
      },
      "shipping_and_payment"
    );
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
