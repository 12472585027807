import React, { Fragment } from "react";
import parse from "html-react-parser";
import { formatPrice } from "./utils";
import ProductStockDetails from "../product-item/ProductStockDetails";
import {
  SPECIAL_SHIPPING_FLAG,
  WEB_DIRECT_FLAG_TRUE,
  HAZARDOUS_FLAG_TRUE,
} from "../../common/Constants";
import DisclaimerIcons from "../../common/disclaimer-icons/disclaimerIcons";
import Img from "../../common/Img";
import AvailabilityBreakdownLoader from "../../common/loading/AvailabilityBreakdownLoader";
import { Link } from "react-router-dom";
import PriceCalculation from "../../common/priceComponent/PriceCalculation";

const ConfirmOrderItem = ({ item }) => {
  const getItemUrl = () => {
    const sku = item.sku ? item.sku : "";
    const groupId = item.groupId ? item.groupId : "";

    return `/product-item/${groupId}/${sku}`;
  };
  return (
    <Fragment>
      <tr className="border-divider">
        <td className="d-flex">
          <Link to={getItemUrl()}>
            <Img
              title={parse(item.name)}
              alt={parse(item.name)}
              width="92"
              height="92"
              src={`${process.env.REACT_APP_IMAGE_URL}/${item.img}`}
            />
          </Link>

          <div className="prod-des-details">
            <p className="item__link" tabIndex={0}>
              <Link to={getItemUrl()}>
                {"Item#: "}
                {item.sku}
              </Link>
              {item.ciNumber && (
                <span style={{ color: "#CC0000" }}>
                  {" | CI: "}
                  {item.ciNumber || "CI not available"}
                </span>
              )}
            </p>
            <h4 role="heading" aria-level="4" tabIndex={0}> {item.name}</h4>
            <p tabIndex={0}>{item.title}</p>
            <p tabIndex={0}>{item.ksNumber}</p>
            <DisclaimerIcons
              disclaimerIconClassName="shopping-cart-disclaimer-icon"
              webDirectStatus={
                item.webDirect === WEB_DIRECT_FLAG_TRUE ? true : false
              }
              hazardousStatus={
                item.hazardous === HAZARDOUS_FLAG_TRUE ? true : false
              }
              specialShippingStatus={
                SPECIAL_SHIPPING_FLAG.find((el) => el === item.specialShipping)
                  ? true
                  : false
              }
            />
            <span>
              <label tabIndex={0}>PO/Job name:</label> {item.configuration.poOrJobName}
            </span>
          </div>
        </td>
        <td className="">
          {
            <span key={item.sku} tabIndex={0}>
              <PriceCalculation
                price={
                  item.originalprice
                    ? parseFloat(item.originalprice)
                    : item.unitPrice
                }
                priceUnit={item.priceUnit && item.priceUnit}
                salePrice={item.originalprice ? item.price : 0}
                hideSavingAmount={true}
              />
            </span>
          }
        </td>
        <td className="qty-field">
          <span tabIndex={0}>{item.quantity}</span>
        </td>
        <td className="text-center">
          <div tabIndex={0}>{item.uomLable}</div>
        </td>
        <td className="sub-total-field">
          <span tabIndex={0}>$ {item.extended ? formatPrice(item.extended) : "0.00"}</span>
        </td>
      </tr>

      <tr>
        <td colSpan="6" className="availability-wpr">
          {item && item.selectedOption ? (
            <ProductStockDetails data={item} />
          ) : (
            <AvailabilityBreakdownLoader />
          )}
        </td>
      </tr>
    </Fragment>
  );
};

export default ConfirmOrderItem;
