import uomMap from "./uomMap.json";
import uomMapSecond from "./uomMapSecond.json";

export const getUOMString = (priceUnit) => {

  if (uomMap[priceUnit]) {
    return uomMap[priceUnit];
  } else {
    if (uomMapSecond[priceUnit]) {
      return uomMapSecond[priceUnit];
    } else {
      return priceUnit;
    }
  }

};
