import React from "react";
import { setCartData } from "../../../../store/actions/shoppingCart";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { REQ_HEADER } from "../Tokens";
import axios from "axios";
// import { serviceUpdateCartConfig } from "../services/servicesEP";
import { getBrowserStorage } from "../../../../utils/cookies";

const MAX_CHAR_COUNT = 144;

class DriverNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveLater: false,
    };
  }

  validate = (diverNote) => {
    if (diverNote.length <= MAX_CHAR_COUNT) return true;
    return false;
  };

  handleChange = (e) => {
    this.setState({ showUpdate: true });
    const { value } = e.target;
    let cartData = _.cloneDeep(this.props.shoppingCart.cartData);
    cartData.configuration.driverNote = value;
    if (!this.validate(value)) return;
    this.props.actions.setCartData(cartData);
  };

  handleCheckBox = async (e) => {
    await this.setState({ saveLater: !this.state.saveLater });
    if (this.state.saveLater) {
      try {
        const addressDetails = JSON.parse(
          getBrowserStorage("selectedShippingAddress")
        );

        const { cartData } = this.props.shoppingCart;
        const { driverNote } = cartData.configuration;
        const data = {
          address: {
            locality: addressDetails.addressLines.locality,
            "postal-code": addressDetails.addressLines["postal-code"],
            region: addressDetails.addressLines.region,
            "street-address": addressDetails.addressLines["street-address"],
            "phone-number": addressDetails.addressLines["phone-number"],
          },
          name: {
            "family-name": addressDetails.name["family-name"],
          },
          "driver-note": driverNote,
        };

        const res = await axios({
          url: addressDetails.url,
          method: "PUT",
          headers: REQ_HEADER,
          data,
        });
      } catch (error) {}
    }
  };

  render() {
    if (_.isEmpty(this.props.shoppingCart.cartData)) return null;
    const { cartData } = this.props.shoppingCart;
    const driverNote = cartData.configuration.driverNote;
    return (
      <div className="cart-driver-notes">
        <div className="title" tabIndex={0}>Driver's Notes:</div>
        <textarea
          onChange={this.handleChange}
          value={driverNote}
          type="text"
          rows="3"
        />
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="driver-notes"
            onChange={this.handleCheckBox}
            checked={this.state.saveLater}
          />
          <label class="custom-control-label" for="driver-notes">
            Save notes for later
          </label>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverNote);
