import React from "react";

const Spinner = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 10,
        }}
      >
        <div className="spinner-border text-danger" role="status"></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="cart-error-issue"
      >
        Please wait...this may take a while
      </div>
    </div>
  );
};

export default Spinner;
