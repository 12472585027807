import axios from "axios";
import { REQ_HEADER } from "../../components/routewise/shopping-cart/Tokens";
import { GET_SHIPPING_OPTIONS_URL } from "../../components/routewise/shopping-cart/APIs";

export const getTopBrands = () => {
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/rest/gettopbrandlist`
  );
};

export const postSkusPrices = (skuList) => {
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/price-check`,
    { skuqty: skuList },
    { headers: REQ_HEADER }
  );
};
export const postSkusPricesFeature = (skuList, cancelToken) => {
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/pim/webservice/ecommerce/price-check`,
    {
      skuqty: skuList,
    },
    {
      cancelToken: cancelToken, // 2nd step
    }
  );
};

export const postShippingOption = (skuQtyList, page = null) => {
  return axios.post(
    GET_SHIPPING_OPTIONS_URL,
    { skuidqty: skuQtyList, page },
    { headers: REQ_HEADER }
  );
};

export const putPOJobName = (url, POJobPostBody) => {
  return axios.put(
    url,
    { configuration: POJobPostBody },
    { headers: REQ_HEADER }
  );
};
