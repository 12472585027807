import React, { useEffect, useState, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import {
  setCartData,
  setPaymentMethod,
} from "../../../store/actions/shoppingCart";
import { loadCartData, loadCartItems } from "./commonFunc";
import {
  getTokenActionUrl,
  setPaymentToken,
  getPaymentToken,
} from "../../../services/paymentAPI";
import { serviceSetCreditCardDetails } from "./services/servicesPIM";
import paymentTypesMap from "./mappings/paymentTypesMap.json";
import CreditCardList from "./payment/CreditCardList";
import ErrorAlert from "../../common/ErrorAlert";
import { handleError } from "../../../utils/utils";
import Spinner from "./common/Spinner";
import { isMobileOnly } from "react-device-detect";
import Img from "../../common/Img";
import rightLineArrowGray from "./../../../assets/img/svg/right_line_arrow_gray.svg";
import ShowPaymentMethodMobile from "./ShowPaymentMethodMobile";
import visaLogo from "../../../assets/img/payment/visa.svg";
import amexLogo from "../../../assets/img/payment/amex.svg";
import mcLogo from "../../../assets/img/payment/mc.svg";
import disLogo from "../../../assets/img/payment/dis.svg";
import paymentIcon from "../../../assets/img/svg/paymentIcon.svg";
import { CardDeck } from "react-bootstrap";

const PaymentOptions = ({ cartData, resetError, ...props }) => {
  const [currentPaymentToken, setCurrentPaymentToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [otherOptions, setOtherOptions] = useState([]);
  const [tokenActionUrl, setTokenActionUrl] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [paymentOptionPopupShow, setPaymentOptionPopupShow] = useState(false);
  const [paymentOptionPopupDisplayHide, setPaymentOptionPopupDisplayHide] =
    useState(false);

  useEffect(() => {
    if (props.shoppingCart && _.isEmpty(props.shoppingCart.cartData)) {
      (async () => {
        await loadCartData();
      })();
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(cartData) && !isDirty) {
      setIsDirty(true);
      (async () => {
        // const tokenActionUrlData = await getTokenActionUrl(cartData);

        const availOptionsStr = cartData.configuration.avail_payment_options;
        let availOptions = [];
        if (availOptionsStr) {
          availOptions = availOptionsStr
            .replaceAll('\\"', "")
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll('"', "")
            .split(",");
        }

        setOtherOptions(availOptions.filter((option) => option[0] !== "V"));
        //setTokenActionUrl(tokenActionUrlData);
        setLoading(false);
      })();
    }
  }, [cartData]);

  const handleErrorMessage = (errorMessage) => {
    setErrorMessage({ errorMessage });
  };

  const setPaymentTokenServer = async (cardToken) => {
    try {
      const { cardName, cardType, paymentMethod, expireDate } =
        props.shoppingCart.cartData.configuration;
      setPaymentToken(cardToken, tokenActionUrl);

      const res = await serviceSetCreditCardDetails(
        cardName,
        cardType,
        paymentMethod,
        expireDate
      );
    } catch (error) {
      setErrorMessage(handleError(error));
    }
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [selectedPaymentCartOption, setSelectedPaymentCartOption] =
    useState("");
  const [isSelectOtherOption, setIsSelectOtherOption] = useState(false);

  const onSelect = (e) => {
    const { value } = e.target;

    const cartData = props.shoppingCart.cartData;
    cartData.configuration.cardToken = "0000";
    cartData.configuration.paymentMethod = value;
    cartData.configuration.selectedPaymentOption = `other-payement-options-${value}`;
    props.actions.setCartData(cartData);
    props.actions.setPaymentMethod(`other-payement-options-${value}`);
  };

  useEffect(() => {
    if (props.shoppingCart && props.shoppingCart.selectedShippingMethod) {
      setIsSelectOtherOption(false);
      const cartData = props.shoppingCart.cartData;
      if (
        !!cartData &&
        cartData.configuration &&
        cartData.configuration.selectedPaymentOption
      ) {
        setSelectedPaymentOption(props.shoppingCart.selectedShippingMethod);
        setSelectedPaymentCartOption(cartData.configuration);
      } else {
        setSelectedPaymentOption("");
        setSelectedPaymentCartOption("");
      }
    } else {
      setSelectedPaymentOption("");
      setSelectedPaymentCartOption("");
    }
  }, [props.shoppingCart, props.shoppingCart.selectedShippingMethod]);

  useEffect(() => {
    // Load cart data if its empty
    if (_.isEmpty(cartData)) {
      loadCartItems();
    }
  }, [cartData]);

  const showOtherPaymentOption = () => {
    return otherOptions.map((option, idx) => (
      <div
        key={idx}
        className={
          selectedPaymentOption === `other-payement-options-${option}`
            ? "checked-blue-border card mb-3 shadow-on-white non-card-options payment-card"
            : "card mb-3 shadow-on-white non-card-options payment-card"
        }
      >
        <div className="card-body">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              value={option}
              checked={
                selectedPaymentOption === `other-payement-options-${option}`
              }
              id={`other-payement-options-${option}`}
              name="PaymentMethod"
              className="custom-control-input"
              onClick={(e) => {
                onSelect(e);
                setIsSelectOtherOption(true);
              }}
              tabIndex="0"
            />
            <label
              className="custom-control-label payment-title"
              htmlFor={`other-payement-options-${option}`}
            >
              {paymentTypesMap[option]}
            </label>
          </div>
        </div>
      </div>
    ));
  };
  const showAddCreditCard = () => {
    return (
      <CreditCardList
        setErrorMessage={handleErrorMessage}
        setPaymentTokenServer={setPaymentTokenServer}
        loading={loading}
        otherPaymentOption={showOtherPaymentOption()}
        showHidePaymentOptionMobileModal={() =>
          setPaymentOptionPopupDisplayHide(!paymentOptionPopupDisplayHide)
        }
      />
    );
  };
  const getExpDate = (expDate) => {
    let date = String(expDate).split(".");
    let year = expDate.slice(-2);

    return ` Exp: ${String(date[0] + "/" + year)
      .split(".")
      .join("/")}`;
  };
  const maskedCardNumber = (cardNumber) => {
    const cardLastDigs = String(cardNumber).slice(12);
    return `•••• •••• •••• ${cardLastDigs}`;
  };

  const checkIsValidCard = () => {
    return (
      !_.isEmpty(selectedPaymentCartOption) &&
      selectedPaymentCartOption.cardType != "" &&
      !selectedPaymentCartOption.selectedPaymentOption.includes(
        "other-payement-options-"
      )
    );
  };
  const showSelectedCreditCardDetails = () => {
    return checkIsValidCard() ? (
      <>
        <div className="card-type-icon">
          {selectedPaymentCartOption.cardType === "VISA" && (
            <img src={visaLogo} alt="" />
          )}
          {selectedPaymentCartOption.cardType === "MC" && (
            <img src={mcLogo} alt="" />
          )}
          {selectedPaymentCartOption.cardType === "AMEX" && (
            <img src={amexLogo} alt="" />
          )}
          {selectedPaymentCartOption.cardType === "DIS" && (
            <img src={disLogo} alt="" />
          )}
        </div>
        <div className="card-details" style={{ marginRight: 5 }}>
          {maskedCardNumber(selectedPaymentCartOption.cardToken)}
          {" / "}
        </div>
        <div className="card-details">
          {getExpDate(selectedPaymentCartOption.expireDate)}
        </div>
      </>
    ) : (
      <div>
        {
          paymentTypesMap[
            selectedPaymentOption.replace("other-payement-options-", "")
          ]
        }
      </div>
    );
  };

  return !isMobileOnly ? (
    <div className="payment-option-wrapper">
      <h2 role="heading" aria-level="2" tabIndex={0}>Payment Options</h2>
      {errorMessage && (
        <ErrorAlert
          errorMessage={errorMessage}
          handleErrorClose={() => setErrorMessage("")}
        />
      )}
      {!_.isEmpty(cartData) ? (
        <Fragment>
          <div className="row">
            <div className="col-md-12">
              <h4 role="heading" aria-level="4" tabIndex={0}>Choose your payment method</h4>
              {showOtherPaymentOption()}
            </div>

            <div className="col-md-12">
              <div className="full-sep-border sep-payment" />
            </div>

            <div className="col-md-12 card-selection-option">
              {showAddCreditCard()}
            </div>
          </div>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </div>
  ) : (
    <div className="payment-method-wrapper-mob">
      <div className="row">
        <div
          className="payment-method"
          onClick={() => setPaymentOptionPopupShow(true)}
        >
          {_.isEmpty(selectedPaymentCartOption) &&
          selectedPaymentCartOption == "" ? (
            <>
              <div className="paymentIcon">
                <Img src={paymentIcon} alt="" />
              </div>
              <div className="label">
                <div>Select payment method</div>
              </div>
            </>
          ) : (
            <div className="label">
              <div
                className="label-text"
                style={{ marginLeft: !checkIsValidCard() && 0 }}
              >
                Payment method
              </div>
              <div className="d-flex selected-pay-method">
                {showSelectedCreditCardDetails()}
              </div>
            </div>
          )}

          <Img src={rightLineArrowGray} alt="" />
        </div>
      </div>
      <ShowPaymentMethodMobile
        isMobileModalOpen={paymentOptionPopupShow}
        handleChange={() => setPaymentOptionPopupShow(false)}
        otherPaymentOption={showOtherPaymentOption()}
        clickAddCreditCard={showAddCreditCard()}
        paymentOptionPopupDisplayHide={paymentOptionPopupDisplayHide}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setCartData, setPaymentMethod }, dispatch),
});

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  cartData: state.shoppingCart.cartData,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptions);
