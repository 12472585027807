import React from "react";

const DisclaimerIcon = ({ iconClassName, icon, title }) => {
  return (
    <div className={iconClassName}>
      <img src={icon} title={title} />
    </div>
  );
};
export default DisclaimerIcon;
