import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./auth";
import global from "./global";
import shoppingCart, * as fromShoppingCart from "./shoppingCart";
import profile from "../profile";
import register from "./register";
import myAccount from "./myAccount";
import categoryFilter from "./categoryFilter";
import laminateFilter from "./laminateFilter";
import bulkUpload from "./bulkUpload";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    global,
    shoppingCart,
    register,
    profile,
    myAccount,
    categoryFilter,
    bulkUpload,
    laminateFilter,
  });

export const selectShippingAddress = (state) =>
  fromShoppingCart.selectShippingAddress(state.shoppingCart);
