import Cookies from "js-cookie";
class MyCookies {
  static get = (name = null) => {
    return Cookies.get(name);
  };
  static check = () => {
    return Object.keys(Cookies.get()).length > 0;
  };
  static getJSON = (name) => {
    return Cookies.getJSON(name);
  };
  static set = (name, value, attributes) => {
    Cookies.set(name, value, attributes);
  };
  static remove = (name, attributes) => {
    Cookies.remove(name, attributes);
  };
  static removeAll = () => {
    Object.keys(MyCookies.get()).forEach((cookieName) => {
      MyCookies.remove(cookieName);
    });
  };

  static getSession = (name) => {
    return sessionStorage.getItem(name);
  };

  static seStession = (name) => {
    sessionStorage.setItem(name);
  };
}
export default MyCookies;
