import axios from "axios";

export const addToMyFavourite = (
  sku,
  categoryId,
  categoryName,
  subCategoryId,
  subCategoryName,
  brandId,
  brandName
) => {
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/add`,
    {
      sku,
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      brandId,
      brandName,
    }
  );
};

export const getMyProductFavourite = (skuList) => {
  return axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/favourite-skus`,
    skuList
  );
};

export const getMyFavoriteItems = (
  params,
  brandParams,
  categoryParams,
  subCategoryParams
) => {
  if (brandParams) {
    params = { ...params, brandIds: brandParams };
  }
  if (categoryParams) {
    params = { ...params, categoryIds: categoryParams };
  }
  if (subCategoryParams) {
    params = { ...params, subCategoryIds: subCategoryParams };
  }

  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/lists`,
    { params }
  );
};

export const removeMyFavouriteItem = (sku) => {
  return axios.delete(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/delete`,
    {
      data: { sku },
    }
  );
};

export const getMyFavoriteFilterData = (
  params,
  brandParams,
  categoryParams,
  subCategoryParams
) => {
  if (brandParams) {
    params = { ...params, brands: brandParams };
  }
  if (categoryParams) {
    params = { ...params, categories: categoryParams };
  }
  if (subCategoryParams) {
    params = { ...params, subCategories: subCategoryParams };
  }

  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/filters`,
    { params }
  );
};

export const getTotalFavoriteItems = () => {
  return axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/am/my-favourite/total-count`
  );
};
