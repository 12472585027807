import _ from "lodash";
import NumberFormat from "react-number-format";
import React from "react";
import moment from "moment";
import jwtDecode from "jwt-decode";

import shippingMethodsMap from "./mappings/shippingMethodsMap.json";
import {
  CARTTOKEN,
  DEFAULT_SHIPTO,
  DEFAULT_SHIPTO_ERROR,
} from "../../common/Constants";
import { getBrowserStorage_Cookie } from "../../../utils/cookies";

export const getSubTotal = (quantity, unitPrice) => {
  const subTotal = (parseFloat(quantity) * parseFloat(unitPrice)).toFixed(2);
  return subTotal;
};

export const setResponseErrors = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.messages
  ) {
    return error.response.data.messages;
  } else {
    return [];
  }
};

export const getShippingMethodsList = (shippingMethodsStr) => {
  if (shippingMethodsStr) {
    const shipMethodList = shippingMethodsStr.replace(/\s/g, "").split(",");
    if (!_.isEmpty(shipMethodList)) {
      return shipMethodList.filter((item) =>
        shippingMethodsMap.availabelShippingMethos.includes(item)
      );
    }
  } else {
    return [];
  }
};

export const getDefaultShippTo = (shippingOption = {}) => {
  const cartToken = getBrowserStorage_Cookie(CARTTOKEN);
  const metaData = jwtDecode(cartToken).metadata;

  let shipMethodInit = jwtDecode(metaData, { header: true }).ship_via;
  if (!shipMethodInit) {
    shipMethodInit = DEFAULT_SHIPTO;
  }

  if (shipMethodInit == DEFAULT_SHIPTO_ERROR) {
    shipMethodInit = DEFAULT_SHIPTO;
  }
  if (
    shippingOption &&
    shippingOption.shippingMethods_1 &&
    shippingOption.type
  ) {
    const availShipMethods = getShippingMethodsList(
      shippingOption.shippingMethods_1
    );
    if (shippingOption.type === "backOrderAll" && shipMethodInit === "0") {
      shipMethodInit = availShipMethods[0];
    }
  }
  return shipMethodInit;
};

export const formatCardExpDate = (date) => {
  const [month, year] = String(date).split(".");
  if (month.length === 1) return `0${month}.${year}`;
  else return date;
};

export const isExpiredCreditCard = (expDate) => {
  const today = moment();
  const expiryDate = moment(expDate, "MM.YYYY").endOf("month");
  if (expiryDate < today) {
    return true;
  }
  return false;
};

export const redirectToShippingCart = (props) => {
  const { cartData, cartItems } = props.shoppingCart;
  if (_.isEmpty(cartData) || _.isEmpty(cartItems)) {
    props.history.push("/shopping-cart");
  }
};

export const formatPrice = (
  amount,
  decimalScale = 2,
  fixedDecimalScale = true
) => {
  let decimalCount = 0;
  const numStr = String(amount);
  if (numStr.includes(".")) {
    decimalCount = numStr.split(".")[1].length;
  }
  if (decimalCount < 2) {
    amount = parseFloat(amount).toFixed(2);
  }

  return (
    <NumberFormat
      value={amount}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={""}
    />
  );
};

export const formatCount = (amount) => {
  return (
    <NumberFormat
      value={amount}
      displayType={"text"}
      thousandSeparator={true}
      prefix={""}
    />
  );
};
