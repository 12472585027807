import jwtDecode from "jwt-decode";
import axios from "axios";
import Cookies from "./../../../lib/utils/cookies";
import { CARTTOKEN } from "../../common/Constants";
import {
  setBrowserStorage,
  getBrowserStorage,
  getBrowserStorage_Cookie,
} from "../../../utils/cookies";

let token = getBrowserStorage_Cookie(CARTTOKEN) || "";

// if (token) {
//   if (jwtDecode(token).exp < Date.now() / 1000) {
//     getToken();
//   }
// }

// export async function getToken() {
//   try {
//     const shipTo = getBrowserStorage("shipTo");
//     const accNo = getBrowserStorage("accNo");
//     const loginTokenRes = Cookies.getJSON("wurthlac");

//     const reqBody = `{
//       "ship-to": "${shipTo}",
//       "accountNo":  "${accNo}"
//     }`;

//     const loginToken =
//       loginTokenRes.token_type + " " + loginTokenRes.access_token;
//     const tokenResp = await axios({
//       method: "post",
//       url: `${process.env.REACT_APP_API_ENDPOINT}/am/account-select`,
//       headers: {
//         Authorization: loginToken,
//         "Content-Type": "application/json"
//       },
//       data: reqBody
//     });

//     setBrowserStorage("cartToken", String(tokenResp.data.token));
//     token = getBrowserStorage("cartToken");
//   } catch (error) {

//   }
// }

export const SHOPPING_CART_VIEW_AUTH = "Bearer " + token;
export const REQ_HEADER = {
  Authorization: SHOPPING_CART_VIEW_AUTH,
  "Content-Type": "application/json",
};
