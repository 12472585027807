import React, { Children } from "react";
import { Helmet } from "react-helmet";

function PageTitle({ title }) {
  const companyName = "Wurth Louis and Company";
  return (
    <Helmet>
      <title>{`${companyName} | ${title}`}</title>
    </Helmet>
  );
}

export default PageTitle;
